import { useSelector } from 'react-redux';
import { RootState } from '../Infraestructure/AdapterStore';
import './ElementLoader.scss';
import logo from '../../../assets/img/IconosAppSGSOMovil/ic_nuevo2_round.png';

export const ElementLoader = () => {
    const { loading, textLoading } = useSelector((state: RootState) => state.generico);
    return (
        <div className='ElementLoader' hidden={!loading}>
            <div className={'loader-wrapper'} />
            <img className="loading-image" src={logo} alt="Loading..." />
            <span className="loading-span">{textLoading}</span>
        </div>
    )
};
