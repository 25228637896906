import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../../context/shared/Infraestructure/AdapterStore';

interface Props {
    element: JSX.Element;
    isLogin?: boolean;
    privateContent?: boolean;
};

export const AuthGuard = ({ element, isLogin, privateContent }: Props) => {
    const modeRedirectGuest = new URLSearchParams(window.location.search).get('redirectGuest');
    const { auth: { auth, user } } = useSelector((state: RootState) => state);

    if (user.IdUsuario === 0 && auth && modeRedirectGuest) {
        const objRedirectGuest: any = {
            'RDI': `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_RDI}/${process.env.REACT_APP_ROUTE_PATH_MAIN_RDI_FORM}`,
            'CPT': `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_CHARLA_PRETAREA}`
        }
        return <Navigate replace to={objRedirectGuest[modeRedirectGuest] || `/${process.env.REACT_APP_ROUTE_PATH_MAIN}`} />;
    }

    return privateContent
        ? auth
            ? element
            : <Navigate replace to={`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`} />
        : auth
            ? <Navigate replace to={`/${process.env.REACT_APP_ROUTE_PATH_MAIN}`} />
            : element;
};
