import { Dispatch } from "redux";
import { EntityDataUsuario } from "../Domain/EntityDataUsuario";
import { EntitySelectDelegacion } from "../Domain/EntityDelegacion";
import { EntityFindGenerico } from "../Domain/EntityFindGenerico";
import { EntitySelectOTs } from "../Domain/EntityOTs";
import { RepositoryGenerico } from "../Domain/RepositoryGenerico";
import { AdapterData } from "./AdapterData";
import { AdapterGenerico } from "./AdapterGenerico";
import { AdapterIndexedDB } from "./AdapterIndexedDB";
import { AdapterService } from "./AdapterService";
import { AdapterWebSocket } from "./AdapterWebSocket";
import { DtoResponseList } from "../../RDI/Listar/Domain/DtoResponseList";
import { EntityAutochequeo } from "../Domain/EntityAutochequeo";
import { EntityNotification } from "../Domain/Generic/EntityNotification";
import { changeCountNotifications } from "./SliceGenerico";
import { EntityActividad } from "../Domain/EntityActividad";
import { LanguageAppCatalog } from "./LanguageTranslate";
import { EntityPais } from "../Domain/EntityPais";
import { EntityTipoIncidente } from "../Domain/Catalogos/EntityTipoIncidente";
import { EntityFactorRiesgo } from "../Domain/Catalogos/EntityFactorRiesgo";

export abstract class RepositoryImplGenerico<T> implements RepositoryGenerico<T> {
    protected websocket: AdapterWebSocket;
    protected dbLocal: AdapterIndexedDB;
    protected dispatch: Dispatch;
    protected service: AdapterService;
    protected schema: string;
    protected entity: string;
    protected urlBase: string;
    protected urlBaseNewBackend: string;
    protected data: Array<T>;

    constructor(websocket: AdapterWebSocket, dbLocal: AdapterIndexedDB, dispatch: Dispatch, schema: string, entity: string, urlBase: string = process.env.REACT_APP_URL_MASTER || '') {
        this.websocket = websocket;
        this.dbLocal = dbLocal;
        this.dispatch = dispatch;

        this.schema = schema;
        this.entity = entity;

        this.urlBase = urlBase;
        this.urlBaseNewBackend = process.env.REACT_APP_URL_MASTER_NEW_BACKEND || '';

        this.service = new AdapterService();

        this.data = [];
    }

    public async find(params: EntityFindGenerico): Promise<Array<T>> {
        let response: Array<T> | T | null = await this.service.bgCall<T>("POST", `${this.urlBase}/${this.schema}/${this.entity}/find`, JSON.stringify(params), "bearer", "json", 'json', {}, 2);
        response = response === null ? [] : !Array.isArray(response) ? [response] : response;
        return response;
    }

    public async saveOne(obj: T): Promise<T | null> {
        let response: Array<T> | T | null = await this.service.bgCall<T>("POST", `${this.urlBase}/${this.schema}/${this.entity}/save`, JSON.stringify(obj), "bearer", "json", 'json', {}, 2);
        response = response as T;
        return response;
    }

    public async saveMany(obj: Array<T>): Promise<Array<T>> {
        let response: Array<T> | T | null = await this.service.bgCall<T>("POST", `${this.urlBase}/${this.schema}/${this.entity}/save`, JSON.stringify(obj), "bearer", "json", 'json', {}, 2);
        response = response as Array<T>;
        return response;
    }

    public async update(filter: object, update: object): Promise<Array<T>> {
        let response: Array<T> | T | null = await this.service.bgCall<T>("PATCH", `${this.urlBase}/${this.schema}/${this.entity}/update`, JSON.stringify({ filter, update }), "bearer", "json", 'json', {}, 2);
        response = response as Array<T>;
        return response;
    }

    public async delete(filter: any): Promise<Array<T>> {
        let response: Array<T> | T | null = await this.service.bgCall<T>("DELETE", `${this.urlBase}/${this.schema}/${this.entity}/delete`, JSON.stringify({ filter }), "bearer", "json", 'json', {}, 2);
        response = response as Array<T>;
        return response;
    }

    public async getOtForRol(user: EntityDataUsuario): Promise<EntitySelectOTs[]> {
        // const list = AdapterGenerico.formatoPersonalizadoSelect((await this.dbLocal.selectAllStore('OT')), 'Codigo', 'OT') as EntitySelectOTs[];
        const list = AdapterGenerico.formatoPersonalizadoSelect(AdapterData.ot, 'Codigo', ['Codigo', 'OT'], ' - ') as EntitySelectOTs[];
        let response: EntitySelectOTs[] = []
        switch (user.DatosRol.Codigo) {
            case '02AP': response = list.filter(row => (user.Pais.map(itemPais => itemPais.IdPais)).includes(row.dataComplete.Pais.IdPais)); break;
            case '03AD': response = list.filter(row => (user.Delegacion.map(itemDel => itemDel.Codigo)).includes(row.dataComplete.Delegacion.Codigo)); break;
            case '04U':
            case '05DC':
                const groupIdOt = user.Delegacion.reduce((total, currentValue) => {
                    const groupId: number[] = currentValue.OT.map(row => row.IdOT);
                    return [...total, ...groupId];
                }, [] as number[])
                response = list.filter(row => groupIdOt.includes(row.dataComplete.IdOT));
                break;
            case '01SA':
            default: response = list; break;
        }

        list.map(item => ({ ...item, label: AdapterGenerico.formattingNameOT(item.dataComplete.Codigo, item.dataComplete.OT) }))
        return response;
    }

    public async getDelegacionForRol(user: EntityDataUsuario): Promise<EntitySelectDelegacion[]> {
        const list = AdapterGenerico.formatoPersonalizadoSelect(await this.dbLocal.selectAllStore('Delegacion'), 'Codigo', ['Codigo', 'Delegacion'], ' - ') as EntitySelectDelegacion[];
        let response: EntitySelectDelegacion[] = []

        switch (user.DatosRol.Codigo) {
            case '02AP': response = list.filter(row => (user.Pais.map(itemPais => itemPais.IdPais)).includes(row.dataComplete.Pais.IdPais)); break;
            case '03AD':
            case '04U':
            case '05DC':
                response = list.filter(row => (user.Delegacion.map(itemDel => itemDel.Codigo)).includes(row.dataComplete.Codigo));
                break;
            case '01SA':
            default: response = list; break;
        }

        return response;
    }

    public async customClearRDI() {
        const [response]: [DtoResponseList[]] = await this.dbLocal.selectAllStore(['RDI']);
        const filtered = response.filter(row => row.guest || row.Estado.IdEstado === -1);
        await this.dbLocal.clearStore('RDI');
        return filtered;
    }

    public async customGetListRDI(mode: 'guest' | 'user') {
        const [response]: [DtoResponseList[]] = await this.dbLocal.selectAllStore(['RDI']);
        let result = mode === 'guest' ?
            response.filter(row => row.guest || row.Estado.IdEstado === -1)
            :
            response.filter(row => !row.guest);

        return result;
    }
    
    public async customClearAutochequeo() {
        const [response]: [EntityAutochequeo[]] = await this.dbLocal.selectAllStore(['Autochequeo']);
        const filtered = response.filter(row => row.guest || [-1, -2].includes(row.Estado?.IdEstado));
        await this.dbLocal.clearStore('Autochequeo');
        return filtered;
    }

    public async customGetListAutochequeo(mode: 'guest' | 'user') {
        const response: EntityAutochequeo[] | null = await this.dbLocal.selectAllStore('Autochequeo');
        if (!response) return [];

        let result = mode === 'guest' ?
            response.filter(row => (row.guest || [-1].includes(row.Estado.IdEstado)) && row.Estado.IdEstado !== -2)
            :
            response.filter(row => !row.guest && ![-2].includes(row.Estado.IdEstado));

        return result;
    }

    public generateId(): string {
        return new Date().getTime().toString();
    }

    public async getListNotificationPending(): Promise<EntityNotification[]> {
        const result: EntityNotification[] = (await this.dbLocal.selectAllStore('Notificacion')) || [];
        return result.filter(row => !row.personalInspeccionado.some(x => x.revisado))
    }

    public async confirmReadNotification(params: { identificacion: string; _id: string }): Promise<EntityNotification[] | null> {
        let url = `${this.urlBaseNewBackend}/Maestro/Notificacion/updateMany`;
        const { ...rest } = params;

        try {
            const payload = {
                filter: {
                    "personalInspeccionado.identificacion": rest.identificacion,
                    "modulo._id": rest._id
                },
                update:{
                   "personalInspeccionado.$.revisado":true
                }
            }

            const payloadFindNotificationUpdated = {
                fields: { "actualizar": 0, "estado": 0, "rol": 0, "registrar": 0, "pais": 0 },
                filter:{
                    "personalInspeccionado.identificacion": rest.identificacion,
                    "modulo._id": rest._id
                }
            }
    
            // Actualizar la notificación leída
            await this.service.call<any>("PATCH", url, JSON.stringify(payload), "basicNewBackend", "json", 'json', {}, 0);
            let responeAllNotificationModified: EntityNotification[] | null = (await this.service.call<any>("POST", `${this.urlBaseNewBackend}/Maestro/Notificacion/find`, JSON.stringify(payloadFindNotificationUpdated), "basicNewBackend", "json", 'json', {}, 0));

            // Actualizar la BD local
            if (!responeAllNotificationModified) return null;
            await this.dbLocal.insertDataStore({ nameStore: 'Notificacion', data: responeAllNotificationModified });
            const result = await this.getListNotificationPending();
            this.dispatch(changeCountNotifications(result.length));

            return responeAllNotificationModified;
        } catch(error) {
            return null;
        }
    }

    // Obtener catálogos genéricos con traducciones
    public async getTranslateCatalog(catalogo: 'Actividad' | 'Pais' | 'TipoIncidente' | 'FactorRiesgo'): Promise<any[]> {
        const result = await this.dbLocal.selectAllStore(catalogo);
        switch (catalogo) {
            case 'Actividad':
                let typed: EntityActividad[] = result;
                typed = typed.map(row => ({
                    ...row,
                    label: LanguageAppCatalog('standard', row.Idioma, row.Actividad)
                }))
                return typed;
            case 'Pais': 
                let typedPais: EntityPais[] = result;
                typedPais = typedPais.map(row => ({
                    ...row,
                    label: LanguageAppCatalog('standard', row.iteminIdioma, row.Pais)
                }))
                return typedPais;
            case 'TipoIncidente':
                let typedTipoIncidente: EntityTipoIncidente[] = result;
                typedTipoIncidente = typedTipoIncidente.map(row => ({
                    ...row,
                    label: LanguageAppCatalog('standard', row.iteminIdioma, row.NombreIncidente)
                }));
                return typedTipoIncidente;
            case 'FactorRiesgo':
                let typedFactorRiesgo: EntityFactorRiesgo[] = result;
                typedFactorRiesgo = typedFactorRiesgo.map(row => ({
                    ...row,
                    label: LanguageAppCatalog('standard', row.iteminIdioma || row.FactorRiego.map(x => ({ codigo: x.Idioma, nombre: x.Descripcion })), row.Descripcion),
                    Riesgos: row.Riesgos.map(itemRiesgo => ({
                        ...itemRiesgo,
                        label: LanguageAppCatalog('standard', itemRiesgo.iteminIdioma || itemRiesgo.IdiomasRiesgo.map(x => ({ codigo: x.Idioma, nombre: x.Riesgo })), itemRiesgo.Riesgo)
                    }))
                }))
                return typedFactorRiesgo;
            default: return[];
        }
    }
}