import { useState } from 'react';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { EntityList } from '../Domain/EntityList';
import './ViewItem.scss';
interface IViewItem {
    data: EntityList;
    onRedirectForm: (_id?: string | undefined) => void;
    onDelete: (_id: string) => void;
    onSendEmail: (_id: string, data: EntityList) => void;
}



export const ViewItem = (props: IViewItem) => {
    const [isTouch, setTouch] = useState(false);
    const languageTranslate = LanguageTranslate();
    const { data } = props;

    return (
        <>

            <div className="row pb-3 ViewInspeccionItem">
                <div className="col-12">
                    <div className={`shadow-lg group-content-item ${[-1, -2].includes(data.Estado?.IdEstado) ? 'pending' : [6].includes(data.Estado?.IdEstado) ? 'anomaly' : ''}`}>
                        <div className="row" style={{ margin: '1px' }}>
                            <div className="col-10" onClick={() => setTouch(!isTouch)}>
                                <div>
                                    <span className="text-danger">{languageTranslate.textoNro} {data.IdInspeccion}</span>
                                </div>
                                <div>
                                    <span>{languageTranslate.moduloInspeccion.list.item.jefeObra}:</span>
                                    {data.DatosInspeccion.JefeObra && <span className='d-inline-block text-uppercase text-truncate text-muted w-100'>{data.DatosInspeccion.JefeObra.Nombres + " " + data.DatosInspeccion.JefeObra.Apellidos}</span>}
                                </div>
                                <div>
                                    <span>{languageTranslate.moduloInspeccion.list.item.autor}:</span>
                                    <span className='text-muted'> {data.DatosInspeccion.Inspector?.Nombres} {data.DatosInspeccion.Inspector?.Apellidos} </span>
                                </div>
                                <div>
                                    <span>{languageTranslate.moduloInspeccion.list.item.nroNoConformidad}:</span>
                                    <span className='text-muted'> {data.DatosInspeccion.DetalleInspeccion?.filter(row => row.Sancionado === "SI").length || '-'} </span>
                                </div>
                                <div>
                                    <span>{languageTranslate.moduloInspeccion.list.item.fechaInspeccion}:</span>
                                    {data.DatosInspeccion.FechaInspeccion && data.DatosInspeccion.FechaInspeccion.FechaInspeccion && <span className='text-muted'> {AdapterGenerico.convertDateToString(new Date(data.DatosInspeccion.FechaInspeccion.FechaInspeccion), 2)}</span>}
                                </div>
                                {
                                    isTouch && (
                                        <>
                                            <div className='d-flex w-100'>
                                                <span>{languageTranslate.moduloInspeccion.list.item.empresa}:</span>
                                                <span className='d-inline-block text-uppercase text-truncate text-muted'>&nbsp;{data.DatosGenerales.Empresa?.RazonSocial}</span>
                                            </div>
                                            <div className='d-flex'>
                                                <span>{languageTranslate.moduloInspeccion.list.item.subContrata}:</span>
                                                <span className='d-inline-block text-uppercase text-truncate text-muted'>&nbsp;{data.DatosGenerales.EmpresaColaboradora?.RazonSocial}</span>
                                            </div>
                                            <div className='d-flex'>
                                                <span>{languageTranslate.moduloInspeccion.list.item.tipoOperacion}:</span>
                                                <span className='d-inline-block text-uppercase text-truncate text-muted'>&nbsp;{data.DatosInspeccion.TipoOperacion?.TipoOperacion}</span>
                                            </div>
                                            <div>
                                                <span>{languageTranslate.moduloInspeccion.list.item.personal}:</span>
                                                {
                                                    data.DatosInspeccion.PersonalInspeccionado?.map((psl) =>
                                                        <span key={psl.IdPersonal} className='d-inline-block text-uppercase text-truncate text-muted w-100'>- {psl.Nombres + " " + psl.Apellidos}</span>
                                                    )
                                                }
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            <div className='col-2 d-flex justify-content-center flex-column' style={{ alignItems: 'center' }}>
                                {
                                    //6 = Anomalias; 5 = Ok; -1 = Sin conexión; -2 = Incompleta;
                                    [-2].includes(data.Estado?.IdEstado) ? (
                                        <>
                                            <i onClick={() => props.onRedirectForm(data._id)} className="icon-custom mb-2 fa-solid fa-pen" style={{ fontSize: '20px' }} />
                                            <i onClick={() => props.onDelete(data._id)} className="icon-custom mt-2 fa-solid fa-trash" style={{ fontSize: '20px' }} />
                                        </>
                                    ) : (
                                        <>
                                            {[6].includes(data.dataSend?.INSP.Estado.IdEstado || data.Estado?.IdEstado) && <i onClick={() => props.onRedirectForm(data._id)} className="icon-custom mb-2 fa-solid fa-pen" style={{ fontSize: '20px' }} />}
                                            {[5].includes(data.dataSend?.INSP.Estado.IdEstado || data.Estado?.IdEstado) && <i onClick={() => props.onRedirectForm(data._id)} className="icon-custom mt-2 fa-solid fa-eye" style={{ fontSize: '20px' }} />}
                                            {[6, 5].includes(data.dataSend?.INSP.Estado.IdEstado || data.Estado?.IdEstado) && <i onClick={() => props.onSendEmail(data._id, data)} className="icon-custom mt-2 fa-solid fa-envelope" style={{ fontSize: '20px' }} />}
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}