import AsyncSelect from 'react-select/async';
import Select, { GroupBase, MenuListProps } from 'react-select';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import makeAnimated from "react-select/animated";
import { AdapterGeolocation } from '../Infraestructure/AdapterGeolocation';
import { useEffect, useState } from 'react';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { AdapterGenerico } from '../Infraestructure/AdapterGenerico';
import { LanguageTranslate } from '../Infraestructure/LanguageTranslate';

const languageTranslate = LanguageTranslate();

interface InputBase {
  label: string;
  name: string;
  onChange: (name: string, value: any) => void;
  values: any;
  isRequired?: boolean;
  disabled?: boolean;
  className?: string;
  isHorizontal?: boolean;
}

interface IInputSelect extends InputBase {
  options?: any[];
  loading?: boolean;
  isMulti?: boolean;
  disabledVirtualized?: boolean;
  isClearable?: boolean;
  arrFilterOption?: Array<string | string[]>;
  isAsync?: boolean;
  delaySearch?: number;
  closeMenuOnSelect?: boolean;
  blurInputOnSelect?: boolean;
  controlShouldRenderValue?: boolean;
}

interface IInput extends InputBase {
  type?: string;
  uppercase?: boolean;
  ocultLabel?: boolean
}

const colorStyles = {
  placeholder: (defaultStyles: any) => {
    return {
      ...defaultStyles,
      color: "#cdcdce"
    };
  }
};

export const InputSelect = (props: IInputSelect) => {
  const { label, name, onChange, options, values, disabled, isRequired, loading, className, disabledVirtualized, isHorizontal, arrFilterOption } = props;
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);

  useEffect(() => {
    if (options && options?.length > 20) {
      setFilteredOptions(() => [...options?.slice(0, 20)])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const extraProps = {
    filterOption: (option: FilterOptionOption<any>, inputValue: string) => {
      if (!inputValue) return true;
      return arrFilterOption?.some(row => 
        Array.isArray(row) ? 
        AdapterGenerico.searchText(inputValue, (row.reduce((prev, current) => { prev += `${option.data.dataComplete[current]} `; return prev; }, '')))
        :
        AdapterGenerico.searchText(inputValue, (option.data.dataComplete[row] || ''))
      ) as any;
    }
  };

  const extraPropsGeneric = {
    filterOption: (option: FilterOptionOption<any>, inputValue: string) => {
      if (!inputValue) return true;
      return AdapterGenerico.searchText(inputValue, option.label);
    }
  }

  const extraPropsAsync = {
    loadOptions: (textSearch: string) => loadOptionsGeneric(textSearch, inputValue, filteredOptions, setFilteredOptions, props.delaySearch, props.options, arrFilterOption),
    filterOption: (payload: { value: string; label: string; data: any }, textSearch: string) => {
      const result = arrFilterOption?.some(row => 
        Array.isArray(row) ? 
        AdapterGenerico.searchText(textSearch, (row.reduce((prev, current) => { prev += `${payload.data.dataComplete[current]} `; return prev; }, '')))
        :
        AdapterGenerico.searchText(textSearch, (payload.data.dataComplete[row] || ''))
      ) as any;
      return result;
    }
  };

  let ComponentsVirtualized: any = {
    MenuList: MenuListVirtualized,
  }

  const CostumeSelect = props.isAsync ? AsyncSelect : Select;

  return (
    <div className={`${className || ''} form-row d-flex mb-3`}>
      <div className={`${isHorizontal ? 'd-flex justify-content-between' : 'form-group'} w-100`}>
        <label className="mb-1" style={props.isHorizontal ? { width: '49%' } : {}}> {label} {""} {isRequired && <span className="text-danger">*</span>}</label>
        <CostumeSelect
          {...(Array.isArray(arrFilterOption) ? (props.isAsync ? extraPropsAsync : extraProps) : extraPropsGeneric)}
          placeholder={ props.isAsync ? languageTranslate.btnBuscar : languageTranslate.btnSeleccionar }
          className={`custom-select ${isHorizontal ? 'w-50' : ''}`}
          components={disabledVirtualized ? animatedComponents : ComponentsVirtualized}
          onChange={(value) => onChange(name, value)}
          value={values[name]}
          isDisabled={disabled}
          isMulti={props.isMulti}
          options={options || []}
          isLoading={loading}
          defaultOptions={options}
          isClearable={props.isClearable}
          closeMenuOnSelect={!props.closeMenuOnSelect}
          blurInputOnSelect={props.blurInputOnSelect || !props.closeMenuOnSelect}
          controlShouldRenderValue={props.controlShouldRenderValue || !props.closeMenuOnSelect}
          onInputChange={(newValue, action) => { if (action.action !== 'set-value') setInputValue(newValue); return newValue; }}
          inputValue={inputValue}
          styles={colorStyles}
        />
      </div>
    </div>
  )
}

let delayTimerPersonnel: NodeJS.Timer;
const loadOptionsGeneric = (textSearch: string, inputValue: string, filteredOptions: any[], setFilteredOptions: React.Dispatch<React.SetStateAction<any[]>>, delaySearch: number = 1000, options: any[] = [], arrFilterOption: Array<string | string[]> = [] ) => {
  return new Promise<any>((resolve) => {
    if (delayTimerPersonnel) clearTimeout(delayTimerPersonnel)
    delayTimerPersonnel = setTimeout(() => {
      if (textSearch === '') return resolve([]);
      else if (textSearch === inputValue && filteredOptions.length > 0) return resolve(filteredOptions);
      else {
        const result = options?.filter(option => arrFilterOption?.some(row => 
          Array.isArray(row) ? 
          AdapterGenerico.searchText(textSearch, (row.reduce((prev, current) => { prev += `${option.dataComplete[current]} `; return prev; }, '')))
          :
          AdapterGenerico.searchText(textSearch, (option.dataComplete[row] || '')) as any
        )) || [];
        const newResponse = result.slice(0, 50)
        setFilteredOptions(newResponse);
        resolve(newResponse)
      }
    }, delaySearch);
  })
}

export const InputSelectPersonnel = (props: IInputSelect) => {
  const { label, name, onChange, options, values, disabled, isRequired, loading, className, isHorizontal, arrFilterOption } = props;
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);

  useEffect(() => {
    if (options && options?.length > 20) {
      setFilteredOptions(options?.slice(0, 20))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let ComponentsVirtualized: any = {
    MenuList: (propsMenuList: MenuListProps<any, boolean, GroupBase<unknown>>) => MenuListVirtualized({ ...propsMenuList, selectOptionCustomPersonnel: true }),
    Option: OptionPersonnel
  }

  const customOnchange = (value: any) => {
    if (props.controlShouldRenderValue) {
      setInputValue(() => '');
    }
    onChange(name, value)
  }

  const handleKeyDown = (event: any) => {
    // Verificar si la tecla presionada es la tecla de eliminar (Backspace)
    if (event.key === 'Backspace' && !event.target.value) {
      // Si estamos en el input y no hay texto, evitamos la eliminación
      event.preventDefault();
      // Puedes agregar aquí cualquier lógica adicional si es necesario
    }
  };

  return (
    <div className={`${className || ''} form-row d-flex mb-3`}>
      <div className={`${isHorizontal ? 'd-flex justify-content-between' : 'form-group'} w-100`}>
        { label ? <label className="mb-1" style={props.isHorizontal ? { width: '49%' } : {}}> {label} {""} {isRequired && <span className="text-danger">*</span>}</label> : null }
        <AsyncSelect
          className={`custom-select custom-select-personnel ${isHorizontal ? 'w-50' : ''}`}
          placeholder={languageTranslate.btnBuscar}
          components={ComponentsVirtualized}
          onChange={customOnchange}
          value={values[name]}
          isDisabled={disabled}
          isMulti={props.isMulti}
          options={options || []}
          defaultOptions={filteredOptions}
          isLoading={loading}
          isClearable={props.isClearable}
          closeMenuOnSelect={!props.closeMenuOnSelect}
          blurInputOnSelect={props.blurInputOnSelect || !props.closeMenuOnSelect}
          controlShouldRenderValue={props.controlShouldRenderValue || !props.closeMenuOnSelect}
          onInputChange={(newValue, action: any) => { if (!["input-blur", "set-value", "menu-close"].includes(action.action)) { setInputValue(newValue); } return newValue; }}
          inputValue={inputValue}
          loadOptions={(textSearch) => loadOptionsGeneric(textSearch, inputValue, filteredOptions, setFilteredOptions, props.delaySearch, props.options, arrFilterOption)}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  )
}

const animatedComponents = makeAnimated();
const itemSizeVirtualized = 35;

const calculatedHeight = (props: any, index: number) => {
  const redondearHaciaArriba = (numero: number) => {
    const parteEntera = Math.floor(numero);
    const parteDecimal = numero - parteEntera;
    // Si la parte decimal es mayor que 0.01, redondear hacia arriba agregando 1 a la parte entera
    return parteDecimal > 0.01 ? parteEntera + 1 : parteEntera;
  }

  const line = ((props.options[index]?.label || '').length * 12) / (window.innerWidth - 120);
  const result = redondearHaciaArriba(line * 12) + (line * 2) + 21;
  return result < 40 ? itemSizeVirtualized : result;
}

const MenuListVirtualized = (props: any) => {
  const { selectOptionCustomPersonnel } = props;
  const rows = Array.isArray(props.children) ? props.children : [];
  const allOptions = props.options;
  const RowRenderer = ({ index, style }: any) => {
    return (
      <div
        className='option-virtualized-custom'
        style={{ ...style, wordWrap: 'break-word', height: calculatedHeight(props, index), borderBottom: selectOptionCustomPersonnel ? '1px solid #f3f4f3' : '' }}
        key={allOptions[index].value}
      >
        {rows[index]}
      </div>
    )
  }

  const RowRendererEmpty = ({ index, style }: any) => <div style={style} key={-1}> No Options </div>
  return (
    <div style={{ height: (rows.length || 0) * (selectOptionCustomPersonnel ? 70 : itemSizeVirtualized), maxHeight: 300, minHeight: (selectOptionCustomPersonnel ? 70 : itemSizeVirtualized) }}>
      <AutoSizer>
        {({ height, width }: any) => (
          <List
            className="List"
            height={height || 0}
            itemCount={rows.length}
            itemSize={(index) => selectOptionCustomPersonnel ? 70 : calculatedHeight(props, index)}
            width={width || 0}
          >
            {rows.length === 0 ? RowRendererEmpty : RowRenderer}
          </List>
        )}
      </AutoSizer>
    </div>
  )
}

const OptionPersonnel = ({ innerProps, label, isSelected, ...rest }: any) => {
  let isTouchScrolling = false;
  const customOnClick = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (isTouchScrolling) {
      return;
    }
    rest.selectOption(rest.data);
  }
  const handleTouchMove = () => {
    // Establecer el estado para indicar que se está realizando un desplazamiento táctil
    isTouchScrolling = true;
  };

  const onTouchCancel = () => {
    // Establecer el estado en false después de que finalice el desplazamiento táctil
    isTouchScrolling = false;
  };
  return (
    <div
      style={{
        backgroundColor: isSelected ? '#01397d54' : 'white',
        padding: '8px',
      }}
      onClick={customOnClick}
      onTouchEnd={customOnClick}
      onTouchMove={handleTouchMove}
      onTouchCancel={onTouchCancel}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <span>{label}</span>
        <span className='text-muted' style={{ fontSize: 12 }} >{rest.data.dataComplete?.CodigoDelegacion}-{rest.data.dataComplete?.Delegacion}</span>
        <span className='text-muted' style={{ fontSize: 12 }} >{rest.data.dataComplete?.RazonSocial}</span>
      </div>
    </div>
  );
}

export const InputText = (props: IInput) => {
  const { label, name, onChange, values, disabled, isRequired, type, uppercase, className, isHorizontal } = props;

  const prevOnchange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedText = evt.target.value.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, '');
    onChange(name, uppercase ? cleanedText.toLocaleUpperCase() : cleanedText)
  }

  return (
    <div className={`${className || ''} form-row d-flex mb-3`}>
      <div className={`${isHorizontal ? 'd-flex justify-content-between' : 'form-group'} w-100`}>
        {
          props.ocultLabel ? 
            null
            :
            <label className="mb-1" style={props.isHorizontal ? { width: '49%' } : {}}> {label} {" "} {isRequired && <span className="text-danger">*</span>}</label>
        }
        <input
          type={type ? type : "text"}
          className={`form-control ${isHorizontal ? 'w-50' : ''}`}
          placeholder={label}
          onChange={prevOnchange}
          value={values[name]}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export const InputCheckItems = (props: IInput) => {
  const { label, name, onChange, values, disabled, isRequired, className } = props;
  return (
    <div className={`${className || ''} form-row d-flex mb-3`}>
      <div className="form-group w-100 d-flex">
        <label className="mb-1" style={{ marginRight: 'auto' }}>{label} {" "} {isRequired && <span className="text-danger">*</span>}</label>
        <div className="form-check form-switch">
          <input
            checked={values[name]}
            disabled={disabled}
            onChange={(evt) => onChange(name, evt.target.checked)}
            className="form-check-input"
            type="checkbox"
          />
        </div>
      </div>
    </div>
  )
}

export const InputCheck = (props: IInput) => {
  const { label, name, onChange, values, disabled, isRequired, className } = props;
  return (
    <div className={`${className || ''} form-row d-flex mb-3`}>
      <div className="form-group w-100 d-flex">
        <label className="mb-1" style={{ marginRight: 'auto' }}>{label} {" "} {isRequired && <span className="text-danger">*</span>}</label>
        <div className="form-check form-switch">
          <input
            checked={values[name]}
            disabled={disabled}
            onChange={(evt) => onChange(name, evt.target.checked)}
            className="form-check-input"
            type="checkbox"
          />
        </div>
      </div>
    </div>
  )
}

export const InputAutoCompleteLocation = (props: IInput) => {
  const [loading, setLoading] = useState(false);
  const { label, name, onChange, values, disabled, isRequired, className } = props;
  // const languageTranslate = LanguageTranslate();

  const autoCompleteLocation = async () => {
    /* if (!new AdapterCheckConnection().isStable()) {
      // AdapterGenerico.createMessage(languageTranslate.textoAlerta, 'Debe contar con internet para usar el autocompletado de la ubicación actual', 'warning');
      return;
    };*/

    setLoading(true);
    onChange(name, (await AdapterGeolocation.getAllGeolocation()).address)
    setLoading(false);
  }

  return (
    <div className={`${className || ''} form-row d-flex mb-3`}>
      <div className="form-group w-100">
        <label className="mb-1">{label} {" "} {isRequired && <span className="text-danger">*</span>}</label>
        <div className='input-group'>
          <input
            type={"text"}
            className="form-control"
            placeholder={label}
            onChange={(evt) => onChange(name, evt.target.value)}
            value={values[name]}
            disabled={disabled || loading}
          />
          <button className="btn btn-primary addon-button-primary" disabled={props.disabled || loading} type="button" onClick={autoCompleteLocation}><i className='fa-solid fa-location-dot' /></button>
        </div>
      </div>
    </div>
  )
}

export const InputSelectSimple = (props: IInputSelect) => {
  const { name, onChange, options, values, disabled, isHorizontal, className } = props;

  return (
    <Select
      className={`custom-select ${className ?? ''} ${isHorizontal ? 'w-50' : ''}`}
      components={animatedComponents}
      onChange={(value) => onChange(name, value)}
      value={values[name]}
      isDisabled={disabled}
      isMulti={props.isMulti}
      options={options || []}
      isClearable={props.isClearable}
    />
  )
}

export const InputTextArea = (props: IInput) => {
  const { label, name, onChange, values, disabled, isRequired, type, uppercase, className, isHorizontal } = props;
  return (
    <div className={`${className || ''} form-row d-flex mb-3`}>
      <div className={`${isHorizontal ? 'd-flex justify-content-between' : 'form-group'} w-100`}>
        {
          props.ocultLabel ? 
            null
            :
            <label className="mb-1" style={props.isHorizontal ? { width: '49%' } : {}}> {label} {" "} {isRequired && <span className="text-danger">*</span>}</label>
        }
        <textarea
          className={`form-control ${isHorizontal ? 'w-50' : ''}`}
          placeholder={label}
          onChange={(evt) => onChange(name, uppercase ? evt.target.value.toLocaleUpperCase() : evt.target.value)}
          value={values[name]}
          disabled={disabled}
          rows={5}
        />
      </div>
    </div>
  )
}