import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { DtoResponseForm } from "../Domain/DtoResponseForm";
import { EntitySelectEmpresa } from "../Domain/EntitySelectEmpresa";
import { EntityAnomaliaInspeccion } from "../../../shared/Domain/EntityAnomaliaInspeccion";
import { EntityDetalleInspeccion } from "../Domain/EntityDetalleInspeccion";
import { EntitySelectPersonal } from "../Domain/EntitySelectPersonal";
import { EntityParamsSaveItem } from "../Domain/EntityParamsSaveItem";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { EntitySelectAnomaliaInspeccion } from "../Domain/EntitySelectAnomaliaInspeccion";
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { EntityAplica } from "../Domain/EntityAplica";
import { AdapterConfigure } from "./AdapterConfigure";
import { addCountProcess } from "../../../shared/Infraestructure/SliceGenerico";
import { DtoRequestForm } from "../Domain/DtoRequestForm";
import { AdapterGeolocation } from "../../../shared/Infraestructure/AdapterGeolocation";
import { EntityFileLocal } from "../../../shared/Domain/EntityFileLocal";
import { AdapterData } from "../../../shared/Infraestructure/AdapterData";
import { AdapterStorage } from "../../../shared/Infraestructure/AdapterStorage";
import { AdapterCheckConnection } from "../../../shared/Infraestructure/AdapterCheckConnection";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { EntityArea } from "../../../shared/Domain/EntityArea";

const languageTranslate = LanguageTranslate();

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async save(params: DtoRequestForm, modeSave: 1 | 2): Promise<DtoResponseForm | null> {
        try {
            let response = await this._save(params, modeSave);
            let rowLocal = await this._saveLocal(params, modeSave);

            await this.dbLocal.deleteByIndexStore({ nameStore: 'Inspeccion', value: rowLocal._id });
            await this.dbLocal.insertDataStore({ nameStore: 'Inspeccion', data: response ? response : rowLocal });

            return !!response ? response : rowLocal;
        } catch (error) {
            throw error;
        }
    }

    private async _saveLocal(params: DtoRequestForm, modeSave: 1 | 2): Promise<any> {
        const StatusOffline = { Estado: "No Enviado", IdEstado: -1 }

        let response = { ...params.INSP, modeSave, Estado: StatusOffline, dataSend: params };
        if (!!params.INSP._id) return response;

        await this.dbLocal.insertDataStore({ nameStore: 'Inspeccion', data: response });
        return response;
    }

    private async _save(params: DtoRequestForm, modeSave: 1 | 2): Promise<DtoResponseForm | null> {
        const url = `${this.urlBase}${modeSave === 1 ? AdapterConfigure.SAVE : AdapterConfigure.SAVE_SURVEY}`
        if (!new AdapterCheckConnection().isStable()) { this.dispatch(addCountProcess()); return null; }

        const formData = new FormData();
        formData.append("listFotos", JSON.stringify(params.listFotos))
        params.listFile.forEach(file => formData.append('File', file.file, file.name));
        if (modeSave === 1) {
            const { _id, ...rest } = params.INSP;
            formData.append("INSP", JSON.stringify(rest))
        } else {
            formData.append("INSP", JSON.stringify({
                IdInspeccion: params.INSP.IdInspeccion,
                DetalleInspeccion: JSON.stringify(params.INSP.DatosInspeccion.DetalleInspeccion),
                Estado: params.INSP.Estado,
                Procesos: params.INSP.Procesos,
                DatosInspeccion: params.INSP.DatosInspeccion
            }))
        }
        
        const response = await this.service.call<any>("POST", url, formData, "bearer", "form", 'json', {}, 0);
        return modeSave === 1 ? response.Data as DtoResponseForm : params.INSP;
    }

    public async searchCuadrillaPersonal(dni: string) {
        const url = `${this.urlBase}/search/ControlPreventivoBusqueda`;
        const params = JSON.stringify({ Query: JSON.stringify({ Identificacion: dni }) });
        const response = await this.service.call<any>("POST", url, params, 'basic', "json", "json", {}, 0);
        return response;
    }

    public async getAnomaliaInspeccion(): Promise<[] | EntityAnomaliaInspeccion[]> {
        const response: EntityAnomaliaInspeccion[] = await this.dbLocal.selectAllStore('AnomaliaInspeccion');
        if (!response) return [];
        // let idiomaPreferencia = AdapterStorage.get(['language'])?.language || 'es';
        // let newResult = response.filter(row => row.Idioma.some(item => item.Idioma === idiomaPreferencia));
        // if (newResult.length === 0) newResult = response.filter(row => row.Idioma.some(item => item.Idioma === 'es'));
        return response;
    }

    public async getTipoInspeccion(): Promise<[] | any[]> {
        return await this.dbLocal.selectAllStore('TipoInspeccion');
    }

    public async getFormatoInspecciones(): Promise<[] | any[]> {
        return await this.dbLocal.selectAllStore('FormatoInspecciones');
    }

    public async getPersonal(): Promise<[] | any[]> {
        return AdapterData.personal;
        // return await this.dbLocal.selectAllStore('Personal');
    }

    public async getActividad(): Promise<[] | any[]> {
        return await this.dbLocal.selectAllStore('Actividad');
    }

    public async getArea(): Promise<[] | EntityArea[]> {
        return await this.dbLocal.selectAllStore('Area');
    }

    public async getOT(): Promise<[] | any[]> {
        return AdapterData.ot;
        // return await this.dbLocal.selectAllStore('OT');
    }

    public async getEmpresa(): Promise<[] | EntitySelectEmpresa[]> {
        return await this.dbLocal.selectAllStore('Empresa');
    }

    public formatterReadData(data: DtoResponseForm) {
        const { DatosInspeccion: { Conjunta, TipoMomentoTrabajo, Lugar, PersonalInspeccionado, ResponsableGrupo, Obra, TrabajoRealizar, Placa, JefeObra, Coordinador, Capataz, FormatoInspeccion, TipoOperacion, ResponsableLevantamiento, ParalizacionTrabajo, DetalleInspeccion, ObservacionOPS, ReconocimientoOPS, InspeccionConSubContrata, FueraHorario }, DatosGenerales: { Empresa, EmpresaColaboradora, Cliente, Actividad, Area, OT } } = data;
        return ({
            data: {
                // tab1
                OT: { label: OT.OT, value: OT.Codigo },
                Area: { label: Area.Area, value: Area.IdArea },
                Empresa: { label: Empresa.Codigo + " - " + Empresa.RazonSocial, value: Empresa.IdEmpresa },
                Actividad: { label: Actividad.Actividad, value: Actividad.Codigo },
                Cliente: { label: Cliente.Cliente, value: Cliente.IdCliente, dataComplete: Cliente },
                JefeObra: { label: JefeObra.Nombres + " " + JefeObra.Apellidos, value: JefeObra.Identificacion, dataComplete: JefeObra },
                Coordinador: { label: Coordinador.Nombres + " " + Coordinador.Apellidos, value: Coordinador.Identificacion, dataComplete: Coordinador },
                Encargado: { label: Capataz.Nombres + " " + Capataz.Apellidos, value: Capataz.Identificacion, dataComplete: Capataz },

                // tab2
                ResponsableLevantamiento: { label: ResponsableLevantamiento.Nombres + " " + ResponsableLevantamiento.Apellidos, value: ResponsableLevantamiento.Identificacion },
                ConjuntaTipo: Conjunta.Conjunta === "SI" ? true : false,
                ConjuntaPersonal: { label: Conjunta.Personal.Nombres + " " + Conjunta.Personal.Apellidos, value: Conjunta.Personal.Identificacion, dataComplete: Conjunta.Personal },
                SubContrata: { label: EmpresaColaboradora?.Codigo + " - " + EmpresaColaboradora?.RazonSocial, value: EmpresaColaboradora?.IdEmpresa, dataComplete: EmpresaColaboradora },
                InspeccionConSubContrata,
                Formato: { label: FormatoInspeccion.FormatoInspeccion, value: FormatoInspeccion.IdFormatoInspeccion, dataComplete: FormatoInspeccion },
                TipoOperacion: { label: TipoOperacion.TipoOperacion, value: TipoOperacion.IdTipoOperacion, dataComplete: TipoOperacion },
                ParalizacionTrabajo: ParalizacionTrabajo === "SI" ? true : false,
                TipoMomentoTrabajo: { label: TipoMomentoTrabajo.TipoMomentoTrabajo, value: TipoMomentoTrabajo.IdTipoMomentoTrabajo, dataComplete: TipoMomentoTrabajo },
                FueraHorario: !!FueraHorario,
                Lugar: Lugar.Descripcion,
                Obra: Obra,
                TrabajoRealizar: TrabajoRealizar,
                Placa: Placa,
                ReconocimientoOPS: ReconocimientoOPS ? true : false,
                ObservacionOPS: ObservacionOPS,

                // tab3
                Personal: PersonalInspeccionado.find(row => row.Identificacion === '00000000') ? [] : PersonalInspeccionado.map((psn) => ({ label: psn.Nombres + " " + psn.Apellidos, value: psn.Identificacion, dataComplete: psn })),
                PersonalResponsableTrabajo: { label: ResponsableGrupo.Nombres + " " + ResponsableGrupo.Apellidos, value: ResponsableGrupo.Identificacion, dataComplete: ResponsableGrupo },

                // tab4
                ObservacionGeneral: data.DatosInspeccion?.ObservacionGeneral || '',
                TipoInspeccion: AdapterGenerico.formatoPersonalizadoSelect((data.DatosInspeccion?.TipoInspeccion || []), 'IdTipoInspeccion', 'TipoInspeccion')
            },
            DetalleInspeccion: DetalleInspeccion.map((dtl, index) => ({
                ...dtl,
                IdAnomalia: Date.now() + index,
                Anomalia: {
                    ...dtl.Anomalia,
                    Fotos: dtl.Anomalia.Fotos.map((foto) => ({
                        type: 'BD',
                        valueToShow: this.formatterReadImage(data.Procesos.Registrar.IdFecha, foto),
                        value: foto
                    }))
                },
                Levantamiento: {
                    ...dtl.Levantamiento,
                    Fotos: dtl.Levantamiento.Fotos.map((foto) => ({
                        type: 'BD',
                        valueToShow: this.formatterReadImage(data.Procesos.Registrar.IdFecha, foto),
                        value: foto
                    }))
                },
                Personal: dtl.Personal.find(row => row.Identificacion === '00000000') ? [] : dtl.Personal,
            }))
        })
    }

    public async formatterSaveData(data: any, isOps: boolean, user: EntityDataUsuario, Aplica: EntityAplica[], DetalleInspeccion: EntityDetalleInspeccion[], generateId: string) {
        const listFotos: string[] = [];
        const listFile: EntityFileLocal[] = [];
        let Resultado = { IdResultado: 0, Resultado: 'Correcto' };
        let ContadorDeResultadosAnomalosCompletos = 0;
        let ContadorDeResultadosAnomalos = 0;
        const FechaInspeccion = data?.FechaInspeccion?.FechaInspeccion || AdapterGenerico.getFechaISO_Local().substring(0, 19);
        const IdFecha = data?.FechaInspeccion?.IdFecha || AdapterGenerico.ObtenerIdFechaISO(FechaInspeccion);
        let geolocation = { lat: '', lng: '' };
        let nivelGravedadResult = { NivelGravedad: "", Codigo: "" };
        const IdiomaSelected = AdapterStorage.get(['language'])?.language || 'es';

        let paralizacionTrabajo: boolean = false;

        const detalleInspeccion = DetalleInspeccion.map(({ IdAnomalia, ...rest }) => {
            let Fecha = { IdFecha: 0, Fecha: '', TimeZone: 0 };
            // Cuando un ítem es de tipo No Conforme
            if (rest.Anomalia.ResultadoAnomalia === 1) {
                // Agrega el nivel de gravedad mas alto
                if (parseInt(nivelGravedadResult.Codigo || "0") < parseInt(rest.NivelGravedad.Codigo || "0")) {
                    nivelGravedadResult.NivelGravedad = rest.NivelGravedad.NivelGravedad;
                    nivelGravedadResult.Codigo = rest.NivelGravedad.Codigo;
                }
                // Contador de resultados anomalos
                ContadorDeResultadosAnomalos++;
            }
            if (!["", null, undefined].includes(rest.Levantamiento.Descripcion)) {
                Fecha = { IdFecha, Fecha: FechaInspeccion, TimeZone: AdapterGenerico.getTimeZoneOffSet() };
                ContadorDeResultadosAnomalosCompletos++;
            }
            if (rest.ParalizacionTrabajoItem === 'SI') {
                paralizacionTrabajo = true;
            }

            const labelAnomaliaTranslate = rest.Anomalia.Idioma?.find(row => row.codigo === IdiomaSelected)?.nombre || rest.Anomalia.Anomalia
            const labelTipoAnomaliaTranslate = rest.Anomalia.TipoAnomalia.Idioma?.find(row => row.codigo === IdiomaSelected)?.nombre || rest.Anomalia.TipoAnomalia.TipoAnomalia
            const labelTipoInspeccionTranslate = rest.TipoInspeccion.Idioma?.find(row => row.codigo === IdiomaSelected)?.nombre || rest.TipoInspeccion.TipoInspeccion

            return ({
                ...rest,
                TipoInspeccion: {
                    ...rest.TipoInspeccion,
                    TipoInspeccion: labelTipoInspeccionTranslate
                },
                Anomalia: {
                    ...rest.Anomalia,
                    Anomalia: labelAnomaliaTranslate,
                    TipoAnomalia: {
                        ...rest.Anomalia.TipoAnomalia,
                        TipoAnomalia: labelTipoAnomaliaTranslate
                    },
                    Fotos: rest.Anomalia.Fotos.map(image => {
                        if (image.type === 'Local') {
                            listFotos.push(image.value.path);
                            listFile.push({ name: image.value.path, file: image.value.file });
                            return image.value.path
                        } else {
                            return image.value
                        }
                    })
                },
                Levantamiento: {
                    ...rest.Levantamiento,
                    Fotos: rest.Levantamiento.Fotos.map(image => {
                        if (image.type === 'Local') {
                            listFotos.push(image.value.path);
                            listFile.push({ name: image.value.path, file: image.value.file });
                            return image.value.path
                        } else {
                            return image.value
                        }
                    }),
                    Fecha: {
                        IdFecha: rest?.Levantamiento?.Fecha?.Fecha ? AdapterGenerico.ObtenerIdFechaISO(rest.Levantamiento.Fecha.Fecha) : '',
                        Fecha: rest?.Levantamiento?.Fecha?.Fecha || '',
                        TimeZone: AdapterGenerico.getTimeZoneOffSet()
                    }
                },
                Personal: rest.Personal.length > 0 ? rest.Personal : [
                    {
                        "Identificacion" : "00000000",
                        "Apellidos" : "SIN",
                        "Nombres" : "PERSONAL",
                        "Cargo" : "OPERARIO",
                        "Fotos" : []
                    }
                ],
                FechaPrevista: Fecha.Fecha || rest.FechaPrevista
            })
        })

        if (ContadorDeResultadosAnomalosCompletos !== ContadorDeResultadosAnomalos)
            Resultado = { Resultado: 'Anomala', IdResultado: 1 };

        const userComunTemplate = (payload?: any) => ({
            IdPersonal: payload?.IdPersonal || 0,
            Codigo: payload?.Codigo || "",
            Identificacion: payload?.Identificacion || "",
            Apellidos: payload?.Apellidos || (payload?.ApellidoPaterno ? `${payload.ApellidoPaterno} ${payload?.ApellidoMaterno  || ''}` : ""),
            Nombres: payload.Nombres || "",
        })

        await AdapterGeolocation.getLocation().then((res) =>
            (geolocation = { lat: `${res.latitud}`, lng: `${res.longitud}` })
        ).catch((reason) => { });

        const _data: any = {
            IdAppLocal: `${user.Identificacion}_${generateId}`,
            IdInspeccion: 0,
            TodoCorrecto: false, //Resultado.IdResultado === 0 ?
            DatosGenerales: {
                Pais: {
                    IdPais: data.Pais.dataComplete.IdPais,
                    Pais: data.Pais.dataComplete.Pais
                },
                Grupo: {
                    IdGrupo: data.Grupo.dataComplete.IdGrupo,
                    Grupo: data.Grupo.dataComplete.Grupo
                },
                Empresa: {
                    IdEmpresa: data.Empresa.dataComplete.IdEmpresa,
                    Codigo: data.Empresa.dataComplete.Codigo,
                    TipoDocumento: data.Empresa.dataComplete.TipoDocumento.TipoDocumento,
                    NroDocumento: data.Empresa.dataComplete.NroDocumento,
                    RazonSocial: data.Empresa.dataComplete.RazonSocial,
                    Nombre: data.Empresa.dataComplete.Nombre,
                    NombreBreve: data.Empresa.dataComplete.Nombre,
                    TipoEmpresa: data.Empresa.dataComplete.TipoEmpresa[0]
                },
                EmpresaColaboradora: {
                    IdEmpresa: data?.SubContrata?.dataComplete?.IdEmpresa || 0,
                    Codigo: data?.SubContrata?.dataComplete?.Codigo || '',
                    TipoDocumento: data?.SubContrata?.dataComplete?.TipoDocumento || '',
                    NroDocumento: data?.SubContrata?.dataComplete?.NroDocumento || '',
                    RazonSocial: data?.SubContrata?.dataComplete?.RazonSocial || '',
                    Nombre: data?.SubContrata?.dataComplete?.Nombre || '',
                    NombreBreve: data?.SubContrata?.dataComplete?.Nombre || '',
                    TipoEmpresa: data?.SubContrata?.dataComplete?.TipoEmpresa[0] || {
                        IdTipoEmpresa: 2,
                        TipoEmpresa: 'EMPRESA COLABORADORA'
                    }
                },
                Cliente: {
                    IdCliente: data.Cliente.dataComplete.IdEmpresa || 0,
                    Codigo: data.Cliente.dataComplete.Codigo || "",
                    TipoDocumento: data.Cliente.dataComplete.TipoDocumento.TipoDocumento,
                    NroDocumento: data.Cliente.dataComplete.NroDocumento,
                    Cliente: data.Cliente.dataComplete.Nombre || ""
                },
                Delegacion: {
                    IdDelegacion: data.Delegacion.dataComplete.IdDelegacion,
                    Codigo: data.Delegacion.dataComplete.Codigo,
                    Delegacion: data.Delegacion.dataComplete.Delegacion
                },
                Proyecto: {
                    IdProyecto: 0,
                    Proyecto: ""
                },
                OT: {
                    IdOT: data.OT.dataComplete.IdOT,
                    Codigo: data.OT.dataComplete.Codigo,
                    OT: data.OT.dataComplete.OT,
                    TipoOT: {
                        IdTipoOT: data.OT.dataComplete.TipoOT.IdTipoOT,
                        TipoOT: data.OT.dataComplete.TipoOT.TipoOT
                    }
                },
                Actividad: {
                    IdActividad: data.Actividad.dataComplete.IdActividad,
                    Codigo: data.Actividad.dataComplete.Codigo,
                    Actividad: data.Actividad?.label || data.Actividad.dataComplete.Actividad
                },
                Area: {
                    IdArea: data.Area.dataComplete.IdArea,
                    Area: data.Area.dataComplete.Area
                },
                DatosApp: {
                    Origen: `${process.env.REACT_APP_TITLE}`,
                    IsOnline: true,
                    Latitude: geolocation.lat,
                    Longitude: geolocation.lng,
                    Modo: `${process.env.REACT_APP_PUBLISH_ENV}`,
                    PlayStore: false,
                    VersionName: `${process.env.REACT_APP_VERSION_SYSTEM}`,
                    IdOrigen: 2
                }
            },
            DatosInspeccion: {
                ReconocimientoOPS: isOps ? (data.ReconocimientoOPS ? "SI" : "NO") : "",
                ObservacionOPS: isOps ? data.ObservacionOPS : "",
                Obra: data.Obra,
                TrabajoRealizar: data.TrabajoRealizar,
                Lugar: {
                    Descripcion: data.Lugar,
                    Longitud: geolocation.lng,
                    Latitud: geolocation.lat
                },
                Placa: data.Placa || "",
                FechaInspeccion: {
                    IdFecha: IdFecha,
                    FechaInspeccion: FechaInspeccion,
                    TimeZone: AdapterGenerico.getTimeZoneOffSet()
                },
                Inspector: {
                    Codigo: user.DatosPersonal[0]?.Codigo || "",
                    IdUsuario: user.IdUsuario,
                    IdPersonal: user.DatosPersonal[0]?.IdPersonal || ("" as any),
                    Identificacion: user.Identificacion,
                    Apellidos: `${user.ApellidoPaterno} ${user.ApellidoMaterno}`,
                    Nombres: user.Nombres,
                    Cargo: user.DatosPersonal[0]?.EmpresaHistorial[0].CargoPersonal.Cargo || "",
                    Perfil: user.Perfil,
                    TipoPerfil: user.TipoPerfil
                },
                ResponsableGrupo: {
                    ...userComunTemplate(data.PersonalResponsableTrabajo?.dataComplete || {}),
                    Cargo: ""
                },
                ResponsableLevantamiento: {
                    ...userComunTemplate(ContadorDeResultadosAnomalos > 0 ? data.ResponsableLevantamiento?.dataComplete : {}),
                    Cargo: ""
                },
                ObservacionGeneral: data?.ObservacionGeneral || '',
                JefeObra: userComunTemplate(data.JefeObra?.dataComplete || {}),
                Coordinador: userComunTemplate(data.Coordinador?.dataComplete || {}),
                Capataz: userComunTemplate(data.Encargado?.dataComplete || {}),
                PersonalInspeccionado: (data.Personal || []).length === 0 ? 
                    [{
                        "Identificacion" : "00000000",
                        "Apellidos" : "SIN",
                        "Nombres" : "PERSONAL",
                        "Cargo" : "OPERARIO",
                        "Fotos" : []
                    }]
                :
                (data.Personal || []).map(({ dataComplete }: any) => ({
                    ...userComunTemplate(dataComplete),
                    Cargo: dataComplete.Cargo
                })),
                TipoMomentoTrabajo: {
                    IdTipoMomentoTrabajo: data.TipoMomentoTrabajo.dataComplete.id,
                    TipoMomentoTrabajo: data.TipoMomentoTrabajo.label || data.TipoMomentoTrabajo.dataComplete.Incidente
                },
                FueraHorario: !!data.FueraHorario,
                Conjunta: {
                    Conjunta: data.ConjuntaTipo ? "SI" : "NO",
                    Personal: {
                        ...userComunTemplate(data.ConjuntaTipo ? (data.ConjuntaPersonal?.dataComplete || {}) : {}),
                        Cargo: data.ConjuntaTipo ? (data.ConjuntaPersonal?.dataComplete.Cargo || "") : ""
                    }
                },
                TipoOperacion: {
                    Codigo: data.TipoOperacion.dataComplete.Codigo,
                    IdTipoOperacion: data.TipoOperacion.dataComplete.IdTipoOperacion,
                    TipoOperacion: data.TipoOperacion.label
                },
                FormatoInspeccion: {
                    IdFormatoInspeccion: data.Formato.dataComplete.IdFormatoInspeccion,
                    FormatoInspeccion: data.Formato.dataComplete.FormatoInspeccion
                },
                Aplica: Aplica,
                ParalizacionTrabajo: paralizacionTrabajo ? 'SI' : ((data.ParalizacionTrabajo && ContadorDeResultadosAnomalos > 0) ? "SI" : "NO"),
                NivelGravedad: nivelGravedadResult,
                Resultado: ContadorDeResultadosAnomalos > 0 ? 'Anomala' : 'Correcto',
                IdResultado: ContadorDeResultadosAnomalos > 0 ? 1 : 0,
                Idioma: IdiomaSelected,
                DetalleInspeccion: detalleInspeccion,
                InspeccionConSubContrata: data.SubContrata ? !!data.InspeccionConSubContrata : false,
                TipoInspeccion: (data.TipoInspeccion || []).map((row: any) => ({
                    _id: row.dataComplete._id,
                    IdTipoInspeccion: row.dataComplete.IdTipoInspeccion,
                    TipoInspeccion: row.label || row.dataComplete.TipoInspeccion,
                    TipoOperacion: {
                        Codigo: data.TipoOperacion.dataComplete.Codigo,
                        IdTipoOperacion: data.TipoOperacion.dataComplete.IdTipoOperacion,
                        TipoOperacion: data.TipoOperacion.label
                    }
                }))
            },
            Procesos: {
                Registrar: {
                    IdFecha: 0,
                    Fecha: "",
                    TimeZone: AdapterGenerico.getTimeZoneOffSet(),
                    IdUsuario: user.IdUsuario,
                    Usuario: user.Usuario,
                    DatosUsuario: {
                        ApellidoPaterno: user.ApellidoPaterno,
                        ApellidoMaterno: user.ApellidoMaterno,
                        Nombres: user.Nombres
                    },
                    Observacion: "NUEVA INSPECCION"
                }
            },
            Estado: {
                IdEstado: Resultado.IdResultado === 0 ? 5 : 6,
                Estado: Resultado.IdResultado === 0 ? 'Inspeccion OK' : 'Inspeccion con Anomalias'
            }
        }

        return {
            INSP: _data,
            listFotos,
            listFile
        }
    }

    public async formatterSaveDataSurvey(indexStore: string, DetalleInspeccion: EntityDetalleInspeccion[], user: EntityDataUsuario) {
        const listFotos: string[] = [];
        const listFile: EntityFileLocal[] = [];
        let Resultado = { IdResultado: 0, Resultado: 'Correcto' };
        let ContadorDeResultadosAnomalosCompletos = 0;
        let ContadorDeResultadosAnomalos = 0;
        const FechaLevantamiento = AdapterGenerico.getFechaISO_Local().substring(0, 19);
        const IdFechaLevantamiento = AdapterGenerico.ObtenerIdFechaISO(FechaLevantamiento);

        const detalleInspeccion = DetalleInspeccion.map(({ IdAnomalia, ...rest }) => {
            if (rest.Anomalia.ResultadoAnomalia === 1) ContadorDeResultadosAnomalos++;
            if (!["", null, undefined].includes(rest.Levantamiento.Descripcion)) {
                ContadorDeResultadosAnomalosCompletos++;
            }

            return ({
                ...rest,
                Anomalia: {
                    ...rest.Anomalia,
                    Fotos: rest.Anomalia.Fotos.map(image => {
                        if (image.type === 'Local') {
                            listFotos.push(image.value.path)
                            listFile.push({ name: image.value.path, file: image.value.file });
                            return image.value.path
                        } else {
                            return image.value
                        }
                    })
                },
                Levantamiento: {
                    ...rest.Levantamiento,
                    Fotos: rest.Levantamiento.Fotos.map(image => {
                        if (image.type === 'Local') {
                            listFotos.push(image.value.path);
                            listFile.push({ name: image.value.path, file: image.value.file });
                            return image.value.path
                        } else {
                            return image.value
                        }
                    }),
                    Fecha: {
                        IdFecha: rest?.Levantamiento?.Fecha?.Fecha ? AdapterGenerico.ObtenerIdFechaISO(rest?.Levantamiento.Fecha.Fecha) : '',
                        Fecha: rest?.Levantamiento?.Fecha?.Fecha || '',
                        TimeZone: AdapterGenerico.getTimeZoneOffSet()
                    }
                }

            })
        })

        if (ContadorDeResultadosAnomalosCompletos !== ContadorDeResultadosAnomalos)
            Resultado = { Resultado: 'Anomala', IdResultado: 1 };

        const data: DtoResponseForm = await this.dbLocal.selectByIndexStore({ nameStore: 'Inspeccion', value: indexStore });

        const _data: any = {
            ...data,
            IdInspeccion: data.IdInspeccion,
            DatosInspeccion: {
                ...data.DatosInspeccion,
                DetalleInspeccion: detalleInspeccion,
                Bitacora: {
                    Accion: "LEVANTAMIENTO INSPECCION",
                    Fecha: FechaLevantamiento,
                    IdFecha: IdFechaLevantamiento,
                    DatosUsuario: {
                        Usuario: user.Usuario,
                        ApellidoPaterno: user.ApellidoPaterno,
                        ApellidoMaterno: user.ApellidoMaterno,
                        Nombres: user.Nombres,
                        Identificacion: user.Identificacion
                    },
                    Datos :{
                        Old:  {},
                        New : {}
                    }
                }
            },
            Estado: {
                IdEstado: Resultado.IdResultado === 0 ? 5 : 6,
                Estado: Resultado.IdResultado === 0 ? 'Inspeccion OK' : 'Inspeccion con Anomalias'
            },
            Procesos: {
                ...data.Procesos,
                Modificar: {
                    IdFecha: IdFechaLevantamiento,
                    Fecha: FechaLevantamiento,
                    TimeZone: AdapterGenerico.getTimeZoneOffSet(),
                    IdUsuario: user.IdUsuario,
                    Usuario: user.Usuario,
                    DatosUsuario: {
                        ApellidoPaterno: user.ApellidoPaterno,
                        ApellidoMaterno: user.ApellidoMaterno,
                        Nombres: user.Nombres
                    },
                    Observacion: "LEVANTAMIENTO INSPECCION"
                }
            }
        }

        return {
            INSP: _data,
            listFotos,
            listFile
        }
    }

    public formatterReadImage(idFecha: number | string, pathFile: string): string {
        let variablesDescarga = `${process.env.REACT_APP_PATH_IMAGES_INSP}||${idFecha}||${pathFile}`;
        let path_imagen = btoa(unescape(encodeURIComponent(variablesDescarga)));
        return this.urlBase + '/download/Imagen/' + path_imagen;
    }

    public formatterSaveItem({ values }: EntityParamsSaveItem): EntityDetalleInspeccion {
        const Critica = values.Grave ? 'SI' : 'NO';
        const ParalizacionTrabajoItem = values.ParalizacionTrabajoItem ? 'SI' : 'NO';

        const data = {
            IdAnomalia: values.IdAnomalia || Date.now(),
            TipoInspeccion: {
                IdTipoInspeccion: values.TipoInspeccion.dataComplete.IdTipoInspeccion,
                TipoInspeccion: values.TipoInspeccion.dataComplete.TipoInspeccion,
                Idioma: values.TipoInspeccion.dataComplete?.iteminIdioma ? values.TipoInspeccion.dataComplete?.iteminIdioma.map((row: { codigo: string; texto: string }) => ({ codigo: row.codigo, nombre: row.texto })) : [{ code: 'es', nombre: values.TipoInspeccion.dataComplete.TipoInspeccion }]
            },
            Anomalia: {
                Codigo: values.Item.dataComplete.Codigo,
                Anomalia: values.Item.dataComplete.Anomalia,
                Idioma: values.Item.dataComplete?.iteminIdioma || [{ codigo: 'es', nombre: values.Item.dataComplete.Anomalia }],
                ResultadoAnomalia: values.Resultado.value,
                Critica: Critica,
                TipoSituacion: values.TipoSituacion ? values.TipoSituacion.dataComplete.id : 0,
                TipoAnomalia: {
                    Codigo: values.TipoItem.dataComplete.Codigo,
                    TipoAnomalia: values.TipoItem.dataComplete.TipoAnomalia,
                    Idioma: values.TipoItem.dataComplete?.iteminIdioma || [{ codigo: 'es', nombre: values.TipoItem.dataComplete.TipoAnomalia }]
                },
                DetalleAnomalia: {
                    Codigo: values.Detalle?.dataComplete.Codigo || 0,
                    DetalleAnomalia: values.Detalle?.dataComplete.DetalleAnomalia || '',
                    Critica: Critica,
                    Idioma: values.Detalle?.dataComplete?.iteminIdioma || (values.Detalle?.dataComplete.DetalleAnomalia ? [{ codigo: 'es', nombre: values.Detalle?.dataComplete.DetalleAnomalia }] : [])
                },
                Fotos: values.FotoInspeccion || []
            },
            Descripcion: values.Descripcion ?? '',
            Personal: values.Personal.map((personal: EntitySelectPersonal) => ({
                Identificacion: personal.dataComplete.Identificacion,
                Apellidos: personal.dataComplete.Apellidos,
                Nombres: personal.dataComplete.Nombres,
                Cargo: personal.dataComplete.Cargo,
                Fotos: []
            })),
            AccionPropuesta: values.AccionPropuesta || '',
            Levantamiento: {
                Descripcion: values.DescripcionLevantamiento || '',
                Fotos: values.FotoLevantamiento || [],
                Fecha: {
                    IdFecha: values.FechaLevantamiento ? AdapterGenerico.ObtenerIdFechaISO(values.FechaLevantamiento) : '',
                    Fecha: values.FechaLevantamiento || '',
                    TimeZone: AdapterGenerico.getTimeZoneOffSet()
                }
            },
            Sancionado: values.Resultado.value === 1 ? 'SI' : 'NO',
            TipoSancion: "Ninguna",
            ParalizacionTrabajoItem: ParalizacionTrabajoItem,
            NivelGravedad: {
                Codigo: values.NivelGravedad?.dataComplete?.value || values.NivelGravedad?.dataComplete?.Codigo || '01',
                NivelGravedad: values.NivelGravedad?.dataComplete?.textotOriginal || values.NivelGravedad?.dataComplete?.label || values.NivelGravedad?.dataComplete?.NivelGravedad || 'Leve'
            },
            FechaPrevista: values.FechaPrevista
        };
        return data;
    }

    public formatDataForEditingItem(DetalleInspeccion: EntityDetalleInspeccion, AnomaliaInspeccion: EntitySelectAnomaliaInspeccion[]) {
        let { Anomalia, Personal, TipoInspeccion, Descripcion, Levantamiento, AccionPropuesta, IdAnomalia, Incompleta, ParalizacionTrabajoItem, NivelGravedad, FechaPrevista } = DetalleInspeccion;
        const TipoItemFinded = AnomaliaInspeccion.find(inp => inp.dataComplete.Codigo === Anomalia.TipoAnomalia.Codigo) || null;
        const ItemFinded = TipoItemFinded?.dataComplete.Anomalia?.find((aml: any) => aml.dataComplete.Codigo === Anomalia.Codigo) || null;
        const DetalleFinded = (ItemFinded as any)?.dataComplete?.TipoInspeccion?.find((row: any) => row.id_TipoInspeccion === TipoInspeccion.IdTipoInspeccion)?.DetalleAnomalia.find((row: any) => row.value === Anomalia?.DetalleAnomalia?.Codigo);

        if (!NivelGravedad || !NivelGravedad.Codigo) {
            NivelGravedad = {
                Codigo: '01',
                NivelGravedad: languageTranslate.moduloInspeccion.form.nivelSeguridad.textoLeve
            };
        }

        // Traducir opcinoes de los catálogos de BD 
        const NivelGravedadTranslate = {
            "01": languageTranslate.moduloInspeccion.form.nivelSeguridad.textoLeve,
            "02": languageTranslate.moduloInspeccion.form.nivelSeguridad.textoMedia,
            "03": languageTranslate.moduloInspeccion.form.nivelSeguridad.textoAlta
        }

        const NivelGravedadOiginal = {
            "01": "Leve",
            "02": "Media",
            "03": "Alta"
        }

        Object.assign(NivelGravedad, {
            NivelGravedad: NivelGravedadTranslate[NivelGravedad.Codigo as keyof typeof NivelGravedadTranslate],
            textotOriginal: NivelGravedadOiginal[NivelGravedad.Codigo as keyof typeof NivelGravedadOiginal]
        })

        if (TipoInspeccion.Idioma) TipoInspeccion.TipoInspeccion = TipoInspeccion.Idioma.find(row => row.codigo === languageTranslate.code)?.nombre || TipoInspeccion.TipoInspeccion;
        // if (TipoItemFinded?.dataComplete?.iteminIdioma) TipoItemFinded.label = TipoItemFinded?.dataComplete?.iteminIdioma?.find(row => row.codigo === languageTranslate.code)?.nombre || TipoItemFinded.label;
        // if (ItemFinded?.iteminIdioma) ItemFinded. = TipoItemFinded?.dataComplete?.iteminIdioma?.find(row => row.codigo === languageTranslate.code)?.nombre || TipoItemFinded.label;
        // if (Anomalia.DetalleAnomalia?.Idioma) Anomalia. = TipoItemFinded?.dataComplete?.iteminIdioma?.find(row => row.codigo === languageTranslate.code)?.nombre || TipoItemFinded.label;

        return ({
            IdAnomalia,
            Grave: Anomalia.Critica === "SI",
            ParalizacionTrabajoItem: ParalizacionTrabajoItem === "SI",
            NivelGravedad: AdapterGenerico.formatoPersonalizadoSelect(NivelGravedad, 'Codigo', 'NivelGravedad'),
            Personal: AdapterGenerico.formatoPersonalizadoSelect(Personal, 'Identificacion', ['Nombres', 'Apellidos']),
            TipoInspeccion: AdapterGenerico.formatoPersonalizadoSelect(TipoInspeccion, 'IdTipoInspeccion', 'TipoInspeccion'),
            TipoItem: TipoItemFinded,
            Item: ItemFinded,
            Detalle: Incompleta ? null : DetalleFinded,
            TipoSituacion: Anomalia.TipoSituacion === 0 ? null : AdapterGenerico.formatoPersonalizadoSelect({ id: Anomalia.TipoSituacion, TipoSituacion: Anomalia.TipoSituacion === 1 ? languageTranslate.moduloInspeccion.form.tipoSituacion.textoActoInseguro : languageTranslate.moduloInspeccion.form.tipoSituacion.textoCondicionInsegura }, 'id', 'TipoSituacion'),
            Resultado: {
                value: Anomalia.ResultadoAnomalia,
                label: Anomalia.ResultadoAnomalia === 0 ? languageTranslate.moduloInspeccion.form.textoCorrecto : languageTranslate.moduloInspeccion.form.textoNoConforme
            },
            FotoLevantamiento: Levantamiento.Fotos?.map(item => ({
                type: item.type,
                valueToShow: item.type === 'Local' ? (window.URL ? URL : webkitURL).createObjectURL(item.value.file as File) : item.valueToShow,
                value: item.value,
            })),
            FotoInspeccion: Anomalia.Fotos?.map(item => ({
                type: item.type,
                valueToShow: item.type === 'Local' ? (window.URL ? URL : webkitURL).createObjectURL(item.value.file as File) : item.valueToShow,
                value: item.value,
            })),
            LevantamientoInmediato: !!Levantamiento.Descripcion,
            DescripcionLevantamiento: Levantamiento.Descripcion,
            Descripcion: Descripcion,
            AccionPropuesta: AccionPropuesta,
            FechaPrevista: FechaPrevista,
            FechaLevantamiento: Levantamiento.Fecha.Fecha || ''
        })
    }

    public generateId(): string {
        return new Date().getTime().toString();
    }

    public async autoSave(data: any, stepper: number, isOps: boolean = false) {
        let temp: DtoResponseForm = {} as DtoResponseForm;
        let geolocation = { lat: '', lng: '' };

        let loadData: DtoResponseForm = {} as DtoResponseForm;
        try { loadData = await this.dbLocal.selectByIndexStore({ nameStore: 'Inspeccion', value: data._id }); } catch (err) { }
        loadData = loadData?._id ? loadData : { DatosGenerales: {}, DatosInspeccion: {} } as DtoResponseForm;

        await AdapterGeolocation.getLocation().then((res) =>
            (geolocation = { lat: `${res.latitud}`, lng: `${res.longitud}` })
        ).catch((reason) => { });

        const userComunTemplate = (payload?: any) => ({
            IdPersonal: payload?.IdPersonal || "",
            Codigo: payload?.Codigo || "",
            Identificacion: payload?.Identificacion || "",
            Apellidos: payload?.Apellidos || (payload ? `${payload.ApellidoPaterno} ${payload.ApellidoMaterno}` : ""),
            Nombres: payload.Nombres || "",
        })

        if (stepper === 1) {
            temp = {
                ...loadData,
                IdInspeccion: 0,
                DatosGenerales: {
                    ...loadData.DatosGenerales,
                    Pais: {
                        IdPais: data.Pais.dataComplete.IdPais,
                        Pais: data.Pais.dataComplete.Pais
                    },
                    Grupo: {
                        IdGrupo: data.Grupo.dataComplete.IdGrupo,
                        Grupo: data.Grupo.dataComplete.Grupo
                    },
                    Empresa: {
                        IdEmpresa: data.Empresa.dataComplete.IdEmpresa,
                        Codigo: data.Empresa.dataComplete.Codigo,
                        TipoDocumento: data.Empresa.dataComplete.TipoDocumento.TipoDocumento,
                        NroDocumento: data.Empresa.dataComplete.NroDocumento,
                        RazonSocial: data.Empresa.dataComplete.RazonSocial,
                        Nombre: data.Empresa.dataComplete.Nombre,
                        NombreBreve: data.Empresa.dataComplete.Nombre,
                        TipoEmpresa: data.Empresa.dataComplete.TipoEmpresa[0]
                    },
                    Cliente: {
                        IdCliente: data.Cliente.dataComplete.IdCliente,
                        Codigo: data.Cliente.dataComplete.Codigo || "",
                        TipoDocumento: data.Cliente.dataComplete.TipoDocumento.TipoDocumento,
                        NroDocumento: data.Cliente.dataComplete.NroDocumento,
                        Cliente: data.Cliente.dataComplete.Cliente
                    },
                    Delegacion: {
                        IdDelegacion: data.Delegacion.dataComplete.IdDelegacion,
                        Codigo: data.Delegacion.dataComplete.Codigo,
                        Delegacion: data.Delegacion.dataComplete.Delegacion
                    },
                    Proyecto: {
                        IdProyecto: 0,
                        Proyecto: ""
                    },
                    OT: {
                        IdOT: data.OT.dataComplete.IdOT,
                        Codigo: data.OT.dataComplete.Codigo,
                        OT: data.OT.dataComplete.OT,
                        TipoOT: {
                            IdTipoOT: data.OT.dataComplete.TipoOT.IdTipoOT,
                            TipoOT: data.OT.dataComplete.TipoOT.TipoOT
                        }
                    },
                    Actividad: {
                        IdActividad: data.Actividad.dataComplete.IdActividad,
                        Codigo: data.Actividad.dataComplete.Codigo,
                        Actividad: data.Actividad.dataComplete.Actividad
                    },
                    Area: {
                        IdArea: data.Area.dataComplete.IdArea,
                        Area: data.Area.dataComplete.Area
                    },
                    DatosApp: {
                        Origen: `${process.env.REACT_APP_TITLE}`,
                        IsOnline: true,
                        Latitude: geolocation.lat,
                        Longitude: geolocation.lng,
                        Modo: `${process.env.REACT_APP_PUBLISH_ENV}`,
                        PlayStore: false,
                        VersionName: `${process.env.REACT_APP_VERSION_SYSTEM}`,
                        IdOrigen: 2
                    }
                },
                DatosInspeccion: {
                    ...loadData.DatosInspeccion,
                    ReconocimientoOPS: isOps ? loadData.DatosInspeccion?.ReconocimientoOPS ? 'SI' : 'NO' : '',
                    JefeObra: userComunTemplate(data.JefeObra?.dataComplete || {}),
                    Coordinador: userComunTemplate(data.Coordinador?.dataComplete || {}),
                    Capataz: userComunTemplate(data.Encargado?.dataComplete || {}),
                }
            }
        }
        if (stepper === 2) {
            temp = {
                ...loadData,
                DatosGenerales: {
                    ...loadData.DatosGenerales,
                    EmpresaColaboradora: {
                        IdEmpresa: data?.SubContrata?.dataComplete?.IdEmpresa || 0,
                        Codigo: data?.SubContrata?.dataComplete?.Codigo || '',
                        TipoDocumento: data?.SubContrata?.dataComplete?.TipoDocumento || '',
                        NroDocumento: data?.SubContrata?.dataComplete?.NroDocumento || '',
                        RazonSocial: data?.SubContrata?.dataComplete?.RazonSocial || '',
                        Nombre: data?.SubContrata?.dataComplete?.Nombre || '',
                        NombreBreve: data?.SubContrata?.dataComplete?.Nombre || '',
                        TipoEmpresa: data?.SubContrata?.dataComplete?.TipoEmpresa[0] || {
                            IdTipoEmpresa: 2,
                            TipoEmpresa: 'EMPRESA COLABORADORA'
                        }
                    },
                },
                DatosInspeccion: {
                    ...loadData.DatosInspeccion,
                    ReconocimientoOPS: data.ReconocimientoOPS ? (data.ReconocimientoOPS ? "SI" : "NO") : "",
                    ObservacionOPS: data.ReconocimientoOPS ? data.ObservacionOPS : "",
                    Obra: data.Obra,
                    TrabajoRealizar: data.TrabajoRealizar,
                    Lugar: {
                        Descripcion: data.Lugar,
                        Longitud: geolocation.lng,
                        Latitud: geolocation.lat
                    },
                    Placa: data.Placa || "",
                    ResponsableLevantamiento: {
                        ...userComunTemplate(data.ResponsableLevantamiento?.dataComplete || {}),
                        Cargo: ""
                    },
                    TipoMomentoTrabajo: {
                        IdTipoMomentoTrabajo: data.TipoMomentoTrabajo.dataComplete.id,
                        TipoMomentoTrabajo: data.TipoMomentoTrabajo.dataComplete.Incidente
                    },
                    Conjunta: {
                        Conjunta: data.ConjuntaTipo ? "SI" : "NO",
                        Personal: {
                            ...userComunTemplate(data.ConjuntaPersonal?.dataComplete || {}),
                            Cargo: data.ConjuntaPersonal?.dataComplete.Cargo || ""
                        }
                    },
                    TipoOperacion: data.Formato.dataComplete.TipoOperacion,
                    FormatoInspeccion: {
                        IdFormatoInspeccion: data.Formato.dataComplete.IdFormatoInspeccion,
                        FormatoInspeccion: data.Formato.dataComplete.FormatoInspeccion
                    },
                    ParalizacionTrabajo: data.ParalizacionTrabajo ? "SI" : "NO"
                },
            }
        }
        if (stepper === 3) {
            temp = {
                ...loadData,
                DatosInspeccion: {
                    ...loadData.DatosInspeccion,
                    ResponsableGrupo: {
                        ...userComunTemplate(data.PersonalResponsableTrabajo?.dataComplete || {}),
                        Cargo: ""
                    },
                    PersonalInspeccionado: data.Personal?.map(({ dataComplete }: any) => ({
                        ...userComunTemplate(dataComplete),
                        Cargo: dataComplete.Cargo
                    })) || [],
                },
            }
        }
        if (stepper === 4) {
            temp = {
                ...loadData,
                DatosInspeccion: {
                    ...loadData.DatosInspeccion,
                    DetalleInspeccion: data.DatosInspeccion.DetalleInspeccion
                }
            }
        }

        temp.Estado = {
            IdEstado: -2,
            Estado: 'Incompleta',
            isOps: isOps
        }

        await this.dbLocal.insertDataStore({ nameStore: 'Inspeccion', data: { ...temp, _id: data._id, dataIncomplete: data } });
    }
}