const SCHEMA: string = 'Maestro';
const ENTITY: string = 'Pais';

const TIMEZONE: string = 'America/Lima';
const NOTIFICACIONHISTORICO = '/search/NotificacionesHistorico';
const REGISTRONOTIFICACIONHISTORICO = '/send/ProcesoNotificacionesHistorico';
const ENVIAREMAILACEPTATERMINOS = '/send/EnviarEmailAceptaTerminos'

export const AdapterConfigure = {
    TIMEZONE,

    SCHEMA,
    ENTITY,
    NOTIFICACIONHISTORICO,
    REGISTRONOTIFICACIONHISTORICO,
    ENVIAREMAILACEPTATERMINOS
};