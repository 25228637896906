const SCHEMA: string = 'Maestro';
const ENTITY: string = 'Pais';
const SAVE: string = '/send/ProcesoInspeccion';
const SAVE_SURVEY: string = '/send/ProcesoInspeccionLevantamiento';
const REDIRECT_LIST: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_INSPECCION}`;
const REDIRECT_LIST_OPS: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_OPS}`;

const CODE_PERFIL_DIRECTOR_OPERACIONES = "08";
const CODE_PERFIL_GERENTE_PREVENCION_SSOMA = "09";
const TIPO_EXTRA_CONJUNTA = [CODE_PERFIL_DIRECTOR_OPERACIONES, CODE_PERFIL_GERENTE_PREVENCION_SSOMA]

export const AdapterConfigure = {
    REDIRECT_LIST,
    REDIRECT_LIST_OPS,
    SAVE,
    SCHEMA,
    ENTITY,
    SAVE_SURVEY,
    TIPO_EXTRA_CONJUNTA
};