import { RepositoryMain } from "../Domain/RepositoryMain";
import { DtoRequestForm } from "../Domain/DtoRequestForm";
import { DtoResponseForm } from "../Domain/DtoResponseForm";

export class useCaseSaveSurvey {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: DtoRequestForm): Promise<DtoResponseForm | null> {
        return await this._exec(params);
    }

    private async _exec(params: DtoRequestForm): Promise<DtoResponseForm | null> {
        return await this.repository.save(params, 2);
    }

}