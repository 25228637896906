import React from 'react';
import { ElementEmptyList } from '../../../../shared/Components/ElementEmptyList';
import { InputAutoCompleteLocation, InputSelect, InputText } from '../../../../shared/Components/ElementInputsCostume';
import ElementStepperContent from '../../../../shared/Components/ElementStepperContent';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import { EntityTipologiaCP } from '../../Domain/EntityTipologiaCP';
import { ComponentAccordion } from './Inputs';
import { EntityLoadData } from '../../Domain/EntityUtils';

interface Props {
    tipologiaCP: EntityTipologiaCP[];
    onChangeContentForTab: (newKeyTab: number) => Promise<void>;
    onChange: (key: string, value: string) => void;
    formRegistro: { values: any; };
    keyTab: number;
    selectInformation: EntityLoadData;
    loading?: boolean;
    isBlockInputs?: boolean;
}

export const ViewDatosTipos = (props: Props) => {
    const languageTranslate = LanguageTranslate();
    const languageTranslateForm = languageTranslate.moduloAutoChequeo.form;

    return (
        <ElementStepperContent
          step={-1}
          currentStep={props.keyTab}
          title={languageTranslateForm.tituloDatosControlPreventivo}
          onSubmit={props.onChangeContentForTab}
        >
            <InputSelect
                label={languageTranslateForm.inputs.ot}
                name="OT"
                onChange={props.onChange}
                values={props.formRegistro.values}
                isRequired
                options={props.selectInformation.OT}
                loading={props.loading}
                disabled
            />

            <InputText
                label={languageTranslateForm.inputs.ordenServicio}
                name='OrdenServicio'
                onChange={props.onChange}
                values={props.formRegistro.values}
            />

            <InputAutoCompleteLocation
                label={languageTranslateForm.inputs.lugar}
                name="Lugar"
                onChange={props.onChange}
                values={props.formRegistro.values}
                isRequired
            />

            <div className='p-3 mb-4 rounded border'>
                <label>{languageTranslateForm.textTipologia} <span className="text-danger">*</span></label>
                <div className='my-3 border' />

                {
                    props.tipologiaCP.length === 0 && <ElementEmptyList  text={languageTranslateForm.tipologiaVacio} />
                }

                {
                    props.tipologiaCP.map(item => 
                        <ComponentAccordion
                            key={`${item.idFamilia}-${item.items.length}`}
                            title={item.titleFamilia}
                            cards={item.items}
                            onChange={props.onChange} value={props.formRegistro.values}
                        />
                    )
                }
            </div>

        </ElementStepperContent>
    );
}