import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { DtoRequestLogin } from "../Domain/DtoRequestLogin";
import { DtoResponseLogin } from "../Domain/DtoResponseLogin";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { MenuAllModule } from "../../../shared/Domain/EntityMenu";
import { AdapterConfigure } from "./AdapterConfigure";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { AdapterLoadMaster } from "../../../shared/Infraestructure/AdapterLoadMaster";

const languageTranslate = LanguageTranslate();

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async login(params: DtoRequestLogin): Promise<DtoResponseLogin | null> {
        return this._login(params);
    }

    private async _login(params: DtoRequestLogin): Promise<DtoResponseLogin | null> {
        if (!window.navigator.onLine) {
            this.dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoSinConexion, languageTranslate.textoDescripcionSinConexion, 'warning');
            return null;
        }

        let url: string = `${this.urlBase}${AdapterConfigure.LOGIN}`;
        
        let response:DtoResponseLogin = await this.service.bgCall<any>("POST", url, JSON.stringify(params), "basic", "json", 'json', {}, 0);
        //await this.websocket.emit<DtoResponseLogin>('Login', params);
        response = typeof response === 'string' ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;

        response.OpcionesApp.push(1, 2, 3);
        const menu = MenuAllModule.filter(row => response.OpcionesApp.some(opcApp => row.codigoModule === opcApp));

        response.permisos = { menu };

        const listTipoOperacion = await (new AdapterLoadMaster(this.websocket, this.dbLocal, this.dispatch, "", "", this.urlBase).selectTipoOperacion(response.TipoOperacion.map(row => row.IdTipoOperacion)));
        response.TipoOperacion = listTipoOperacion.length > 0 ? listTipoOperacion : response.TipoOperacion;

        return response;
    }
}