import { EntityOTs } from "../../../shared/Domain/EntityOTs";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseSelectOTs {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(): Promise<Array<EntityOTs>> {
        return await this._exec();
    }

    private async _exec(): Promise<Array<EntityOTs>> {
        return await this.repository.selectOTs();
    }
}