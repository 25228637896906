import * as React from 'react';
import View from './Components/View';
import { Controller } from './Infaestructure/controller';
import { ViewFormComentarios } from './Components/ViewComentarios/ViewFormComentarios';
import { ViewListComentarios } from './Components/ViewComentarios/ViewListComentarios';

const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
            controller.init();
        // eslint-disable-next-line
    }, [controller.location.key]);

    return (
        <>
            <View
                onChangeContentForTab={controller.onChangeContentForTab}
                autoCompletePersonal={controller.autoCompletePersonal}
                keyTab={controller.keyTab}
                selectInformation={controller.selectInformation}
                selectInformationGuest={controller.selectInformationGuest}
                loading={controller.loading}
                formRegistro={controller.formRegistro}
                onChange={controller.onChange}
                question={controller.question}
                listQuestionShow={controller.listQuestionShow}
                titleQuestionGroupToDisplay={controller.titleQuestionGroupToDisplay}
                conditionals={controller.conditionals}
                moduleForm={controller.moduleForm}
                tipologiaCP={controller.tipologiaCP}
                navbarModule={controller.navbarModule}
                openModalComentarios={controller.openModalComentarios}
                openModalListaComentarios={controller.openModalListaComentarios}
            />

            {/* MODAL DE COMENTARIOS */}
            <ViewListComentarios
                Comentarios={controller.configModalComentarios.comentarios}
                show={controller.configModalComentarios.list.show}
                closeModal={controller.closeModalListaComentarios}
                openModalForm={controller.openModalComentarios}
                onSubmitFeedback={controller.onSubmitFeedback}
                valueFeedback={controller.configModalComentarios.list.feedback}
                disabledFunctions={controller.configModalComentarios.disabledFunctions}
            />

            <ViewFormComentarios
                Comentarios={controller.configModalComentarios.comentarios}
                show={controller.configModalComentarios.show}
                closeModal={controller.closeModalComentarios}
                onSubmitForm={controller.onSubmitModalComentarios}
                configModalComentarios={controller.configModalComentarios}
                onChange={controller.onChangeFormModalComentarios}
            />

        </>
    );
};

export default ManagerEntity;

export const ManagerEntityModule = () => {
    const controller = Controller();

    return ({
        controllerFormAutochequeo: controller,
        componentListComentarios: ViewListComentarios,
        componentFormComentario: ViewFormComentarios,
    })
}