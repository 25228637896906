import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { EntityNotification } from "../../../shared/Domain/Generic/EntityNotification";
import { changeCountNotifications } from "../../../shared/Infraestructure/SliceGenerico";
import { AdapterCheckConnection } from "../../../shared/Infraestructure/AdapterCheckConnection";
import { EntityInformationDataInitial } from "../../../shared/Domain/EntityInformationDataInitial";
import { AdapterStorage } from "../../../shared/Infraestructure/AdapterStorage";
// import { changeCountNotifications } from "../../../shared/Infraestructure/SliceGenerico";
//import { EntityListEmail } from "../Domain/EntityListEmail";

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {

    public async deleteInspeccion(_id: string): Promise<void> {
        await this.dbLocal.deleteByIndexStore({ nameStore: 'Inspeccion', value: _id });
    }

    public async getList(): Promise<EntityNotification[]> {
        const response = await this.dbLocal.selectAllStore('Notificacion');
        return response || [];
    }

    public async getNotificationsPending(params: { identificacion: string }): Promise<void> {
        const reloadNotification = async () => {
            const allNotification: EntityNotification[] = await this.dbLocal.selectAllStore('Notificacion');
            const pendingRead =  allNotification.filter(row => !row.personalInspeccionado.some(x => x.revisado)).length;
            this.dispatch(changeCountNotifications(pendingRead))
        }

        if (!(new AdapterCheckConnection().isStable())) {
            reloadNotification();
            return;
        };

        let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial | null; } = AdapterStorage.get(["servicesCalleds"]);

        try {
            let url = `${this.urlBaseNewBackend}/Maestro/Notificacion/find`;

            const payload = {
                "fields":{ "actualizar": 0, "estado": 0, "rol": 0, "registrar": 0, "pais": 0 },
                "filter":{ "personalInspeccionado.identificacion": params.identificacion }
            };
    
            // , "force": true 
            let response: EntityNotification[] | null = (await this.service.call<any>("POST", url, JSON.stringify(payload), "basicNewBackend", "json", 'json', { "Request-Decrypt-Response": true }, 0));

            if (servicesCalleds) {
                const date = new Date();
                servicesCalleds.Notificacion.date = date;
                AdapterStorage.set('servicesCalleds', servicesCalleds);
            }
            if (!Array.isArray(response)) {
                await reloadNotification();
                return;
            };
            
            await this.dbLocal.insertDataStore({nameStore: 'Notificacion', data: response});
            await reloadNotification();
        } catch(err) {}
    }
}