import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { EntityLoadData } from "../Domain/EntityLoadData";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntitySelectTipoInspeccion } from "../Domain/EntitySelectTipoInspeccion";
import { EntitySelectAnomaliaInspeccion } from "../Domain/EntitySelectAnomaliaInspeccion";
import { EntityAnomaliaInspeccion } from "../../../shared/Domain/EntityAnomaliaInspeccion";
import { EntitySelectResultado } from "../Domain/EntitySelectResultado";
import { EntitySelectTipoSituacion } from "../Domain/EntitySelectTipoSituacion";
import { EntitySelectNivelGravedad } from "../Domain/EntitySelectNivelGravedad";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";

const languageTranslate = LanguageTranslate();

export class UseCaseLoadDataSurvey {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(): Promise<EntityLoadData> {
        const [TipoInspeccion, AnomaliaInspeccion, Resultado, TipoSituacion, NivelGravedad] = await Promise.all([
            this.getTipoInspeccion(),
            this.getAnomaliaInspeccion(),
            this.getResultado(),
            this.getTipoSituacion(),
            this.getNivelGravedad(),
        ]);
        return ({
            OT: [],
            Empresa: [],
            SubContrata: [],
            Cliente: [],
            Area: [],
            Actividad: [],
            JefeObra: [],
            Coordinador: [],
            Encargado: [],
            ResponsableLevantamiento: [],
            InspeccionConjunta: [],
            Formato: [],
            TipoMomento: [],
            Personal: [],
            TipoOperacion:[],
            TipoInspeccion: TipoInspeccion,
            AnomaliaInspeccion: AnomaliaInspeccion,
            Resultado: Resultado,
            TipoSituacion: TipoSituacion,
            NivelGravedad: NivelGravedad
        })
    }

    private async getTipoInspeccion(): Promise<EntitySelectTipoInspeccion[]> {
        return AdapterGenerico.formatoPersonalizadoSelect(await this.repository.getTipoInspeccion(), '_id', 'TipoInspeccion') as EntitySelectTipoInspeccion[];
    }

    private async getAnomaliaInspeccion(): Promise<EntitySelectAnomaliaInspeccion[]> {
        const Anomalia: EntityAnomaliaInspeccion[] = await this.repository.getAnomaliaInspeccion();
        return AdapterGenerico.formatoPersonalizadoSelect(Anomalia.map(item => ({
            ...item,
            Anomalia: AdapterGenerico.formatoPersonalizadoSelect(item.Anomalia.map(aml => ({
                ...aml,
                TipoInspeccion: aml.TipoInspeccion.map(tpi => ({
                    ...tpi,
                    DetalleAnomalia: AdapterGenerico.formatoPersonalizadoSelect(tpi.DetalleAnomalia, 'Codigo', 'DetalleAnomalia')
                }))
            })), 'Codigo', 'Anomalia')
        })), 'Codigo', 'TipoAnomalia') as EntitySelectAnomaliaInspeccion[];
    }

    private getResultado(): EntitySelectResultado[] {
        const list = [{ value: 1, label: languageTranslate.moduloInspeccion.form.textoNoConforme }, { value: 0, label: languageTranslate.moduloInspeccion.form.textoCorrecto }]
        return AdapterGenerico.formatoPersonalizadoSelect(list,'value', 'label') as EntitySelectResultado[];
    }

    private getTipoSituacion(): EntitySelectTipoSituacion[] {
        return [
            { value: 1, label: languageTranslate.moduloInspeccion.form.tipoSituacion.textoActoInseguro, dataComplete: { id: 1, TipoSituacion: "Acto Inseguro" } },
            { value: 2, label: languageTranslate.moduloInspeccion.form.tipoSituacion.textoCondicionInsegura, dataComplete: { id: 2, TipoSituacion: "Condición Insegura" } }
        ];
    }
    private getNivelGravedad(): EntitySelectNivelGravedad[] {
        const list = [{ value: "01", label: languageTranslate.moduloInspeccion.form.nivelSeguridad.textoLeve, textotOriginal: 'Leve' }, { value: "02", label: languageTranslate.moduloInspeccion.form.nivelSeguridad.textoMedia, textotOriginal: 'Media' }, { value: "03", label: languageTranslate.moduloInspeccion.form.nivelSeguridad.textoAlta, textotOriginal: 'Alta' }]
        return AdapterGenerico.formatoPersonalizadoSelect(list,'value', 'label') as EntitySelectNivelGravedad[];
    }
}