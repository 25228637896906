//Dependency External
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';

//Adapters
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { addLoading, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterConfigure } from './AdapterConfigure';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';

//Repository
import { RepositoryImplMain } from './RepositoryImplMain';
import { UseCaseRecuperarPassword } from '../Application/UseCaseRecuperarPassword';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';

//UseCase

//Entities


export const Controller = () => {
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
    const dispatch: Dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();
    const languageTranslate = LanguageTranslate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const form = useFormik({
        initialValues: { username: '', identificacion: '', correoElectronico: '', },
        validationSchema: Yup.object({
            username: Yup.string().required(languageTranslate.moduloRecuperarPassword.validate.form.username).min(3, languageTranslate.moduloRecuperarPassword.validate.form.usernameMinimo),
            identificacion: Yup.string().required(languageTranslate.moduloRecuperarPassword.validate.form.identificacion).matches(/^[a-zA-Z0-9]{0,12}$/i, languageTranslate.moduloRecuperarPassword.validate.form.identificacionFormato),
            correoElectronico: Yup.string().required(languageTranslate.moduloRecuperarPassword.validate.form.correoElectronico).matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, languageTranslate.moduloRecuperarPassword.validate.form.correoElectronicoFormato),
        }),

        onSubmit: (values, formikHelpers) => { },
    });

    const onChange = (name: string, value: any) => {
        if (value === null) { return; }
        form.setFieldValue(name, value);
    };

    const onSubmit = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await form.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await form.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

            await (new UseCaseRecuperarPassword(repository)).exec({ IdUsuario: 0, Usuario: form.values.username, Identificacion: form.values.identificacion, Email: form.values.correoElectronico });

            dispatch(removeLoading());
            form.resetForm();
            AdapterGenerico.createMessage(languageTranslate.textoExitoso, languageTranslate.moduloRecuperarPassword.validate.recuperarPasswordCorrecto, 'success');
            onClickLogin();
        } catch (error) {
            console.error(error);

            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        };
    };

    const onClickLogin = () => {
        navigate(`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`, { replace: true });
    };


    return {
        form,
        onChange,
        onSubmit,
        onClickLogin,
    };
}