import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import ViewModal from "./Components/ViewModal";
import { View } from './Components/View';

const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        try {
            controller.init();
        } catch (error) {
            window.location.reload();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <View
                setModalData={controller.setModalData}
                modalData={controller.modalData}
                formRegistro={controller.formRegistro}
                onChange={controller.onChange}
                onSubmit={controller.onSubmit}
                onCancel={controller.onCancel}
                selectInformation={controller.selectInformation}
                selectInformationGuest={controller.selectInformationGuest}
                onChangeContentForTab={controller.onChangeContentForTab}
                isModeInvite={controller.isModeInvite}
                configForm={controller.configForm}
            />
            <ViewModal
                modalData={controller.modalData}
                selectNewImage={controller.selectNewImage}
                setModalData={controller.setModalData}
                selectedIndex={controller.selectedIndex}
                deleteImage={controller.deleteImage}
                configForm={controller.configForm}
            />
        </>
    );
};

export default ManagerEntity;