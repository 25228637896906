import { AdapterConfigure } from "./AdapterConfigure";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  addLoading,
  changeSaludo,
  hideIconMenu,
  removeLoading,
} from "../../../shared/Infraestructure/SliceGenerico";
import { EntityInformationDataInitial } from "../../../shared/Domain/EntityInformationDataInitial";

import { useState } from "react";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { AdapterStorage } from "../../../shared/Infraestructure/AdapterStorage";
import { AdapterLoadMaster } from "../../../shared/Infraestructure/AdapterLoadMaster";
import { AdapterLimitProccess } from "../../../shared/Infraestructure/AdapterLimitProccess";

export const Controller = () => {
  const { permisoVariables } = useSelector((state: RootState) => state.auth);
  const { websocket, dbLocal } = useSelector(
    (state: RootState) => state.generico
  );
  const dispatch: Dispatch = useDispatch();
  const languageTranslate = LanguageTranslate();

  const repositoryLoadMaster = new AdapterLoadMaster(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
  let dataInitial = repositoryLoadMaster.initServicesCalled();

  const [informationDataInitial, setInformationDataInitial] =
    useState<EntityInformationDataInitial>(dataInitial);

  const init = async () => {
    dispatch(changeSaludo(false));
    dispatch(hideIconMenu());
  };

  const onClickLoadCatalogo = async (name: string, force: boolean = false) => {
    try {
      dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
      const servicesCalleds = repositoryLoadMaster.initServicesCalled();
      let permisos = {
        delegacion: permisoVariables.arrIdDelegaciones,
        grupo: permisoVariables.arrIdGrupos,
        ot: permisoVariables.arrIdOT,
        pais: permisoVariables.arrIdPaises
      }

      switch (name) {
        case "Pais":
          await repositoryLoadMaster.selectPais(permisos, servicesCalleds, 'All');
          break;
        case "Proyecto":
          await repositoryLoadMaster.selectProyecto(permisos, servicesCalleds, 'All')
          break;
        case "Empresa":
          await repositoryLoadMaster.selectEmpresas(permisos, servicesCalleds, 'All');
          break;
        case "Delegacion":
          await repositoryLoadMaster.selectDelegacion(permisos, servicesCalleds, 'All');
          break;
        case "Personal":
          await repositoryLoadMaster.selectPersonal(permisos, servicesCalleds, 'All', true);
          break;
        case "Actividad":
          await repositoryLoadMaster.selectActividad(permisos, servicesCalleds, 'All');
          break;
        case "FactorRiesgo":
          await repositoryLoadMaster.selectFactorRiesgo(permisos, servicesCalleds, 'All');
          break;
        case "TipoIncidente":
          await repositoryLoadMaster.selectTipoIncidente(permisos, servicesCalleds, 'All');
          break;
        case "OT":
          await repositoryLoadMaster.selectOT(permisos, servicesCalleds, 'All', true);
          break;
        case "Area":
          await repositoryLoadMaster.selectArea(permisos, servicesCalleds, 'All');
          break;
        case "FormatoInspecciones":
          await repositoryLoadMaster.selectFormatoInspecciones(permisos, servicesCalleds, 'All');
          break;
        case "TipoInspeccion":
          await repositoryLoadMaster.selectTipoInspeccion(permisos, servicesCalleds, 'All');
          break;
        case "AnomaliaInspeccion":
          await repositoryLoadMaster.selectAnomaliaInspeccion(permisos, servicesCalleds, 'All');
          break;
        case "AutochequeoTipologia":
          await repositoryLoadMaster.selectAutochequeoTipologia(permisos, servicesCalleds, 'All');
          break;
        case "AutochequeoTitulos":
          await repositoryLoadMaster.selectAutochequeoTitulos(permisos, servicesCalleds, 'All');
          break;
        case "AutochequeoItems":
          await repositoryLoadMaster.selectAutochequeoItems(permisos, servicesCalleds, 'All', true);
          break;
        case "NotificacionesTerminos":
          await repositoryLoadMaster.selectNotificationTerminos(permisos, servicesCalleds, 'All');
          break;
        case "CTEPCS":
          await repositoryLoadMaster.selectCTEPCS(permisos, servicesCalleds, 'All');
          break;
        case "CTMedidaPreventivas":
          await repositoryLoadMaster.selectCTMedidaPreventivas(permisos, servicesCalleds, 'All');
          break;
        case "CTOpcionAudio":
          await repositoryLoadMaster.selectCTOpcionAudio(permisos, servicesCalleds, 'All');
          break;
        case "CTOpcionLA":
          await repositoryLoadMaster.selectCTOpcionLA(permisos, servicesCalleds, 'All');
          break;
        case "CTPreguntaLA":
          await repositoryLoadMaster.selectCTPreguntaLA(permisos, servicesCalleds, 'All');
          break;
        case "CTTipoPregunta":
          await repositoryLoadMaster.selectCTTipoPregunta(permisos, servicesCalleds, 'All');
          break;
        case "CTTipoRiesgo":
          await repositoryLoadMaster.selectCTTipoRiesgo(permisos, servicesCalleds, 'All');
          break;
        default:
          break;
      }

      AdapterStorage.set("servicesCalleds", servicesCalleds);
      setInformationDataInitial(servicesCalleds);
      dispatch(removeLoading());
    } catch (error) {
      dispatch(removeLoading());
    }
  };

  const onClickLoadAll = async () => {
    try {
      dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

      let permisos = {
        delegacion: permisoVariables.arrIdDelegaciones,
        grupo: permisoVariables.arrIdGrupos,
        ot: permisoVariables.arrIdOT,
        pais: permisoVariables.arrIdPaises
      }

      const servicesCalleds = repositoryLoadMaster.initServicesCalled();
      await repositoryLoadMaster.loadMasterFromVariableLimit(permisos, servicesCalleds, 4)
      AdapterStorage.set("servicesCalleds", servicesCalleds);

      setInformationDataInitial(servicesCalleds);
      dispatch(removeLoading());
    } catch (error) {
      dispatch(removeLoading());
    }
  };

  const onClickLoadModule = async (name: string) => {
    try {
      dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
      const servicesCalleds = repositoryLoadMaster.initServicesCalled();
      let permisos = {
        delegacion: permisoVariables.arrIdDelegaciones,
        grupo: permisoVariables.arrIdGrupos,
        ot: permisoVariables.arrIdOT,
        pais: permisoVariables.arrIdPaises
      }

      switch (name) {
        case 'General':
          await AdapterLimitProccess.exec(
            [
              () => repositoryLoadMaster.selectOT(permisos, servicesCalleds, 'All', true),
              () => repositoryLoadMaster.selectEmpresas(permisos, servicesCalleds, 'All'),
              () => repositoryLoadMaster.selectPersonal(permisos, servicesCalleds, 'All', true),
              () => repositoryLoadMaster.selectDelegacion(permisos, servicesCalleds, 'All')
            ], 2
          )
        break;
        case 'ModuloInspecciones':
          await AdapterLimitProccess.exec([
            () => repositoryLoadMaster.selectArea(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectActividad(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectFormatoInspecciones(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectTipoInspeccion(permisos, servicesCalleds, 'All')
          ], 2)
        break;
        case 'AutochequeoCP':
          await AdapterLimitProccess.exec([
            () => repositoryLoadMaster.selectArea(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectActividad(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectAutochequeoTitulos(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectAutochequeoTipologia(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectAutochequeoItems(permisos, servicesCalleds, 'All', true),
            () => repositoryLoadMaster.selectCTPreguntaLA(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectCTTipoPregunta(permisos, servicesCalleds, 'All')
          ], 3);
        break;
        case 'CharlaPretarea':
          await AdapterLimitProccess.exec([
            () => repositoryLoadMaster.selectActividad(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectCTEPCS(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectCTMedidaPreventivas(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectCTOpcionAudio(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectCTOpcionLA(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectCTPreguntaLA(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectCTTipoPregunta(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectCTTipoRiesgo(permisos, servicesCalleds, 'All'),
          ], 3);
        break;
        case 'RDI':
          await AdapterLimitProccess.exec([
            () => repositoryLoadMaster.selectPais(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectActividad(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectFactorRiesgo(permisos, servicesCalleds, 'All'),
            () => repositoryLoadMaster.selectTipoIncidente(permisos, servicesCalleds, 'All'),
          ], 2);
        break;
      }

      AdapterStorage.set("servicesCalleds", servicesCalleds);
      setInformationDataInitial(servicesCalleds);
      dispatch(removeLoading());
    } catch (error) {
      dispatch(removeLoading());
    }
  };

  return {
    init,

    informationDataInitial,

    onClickLoadCatalogo,
    onClickLoadAll,
    onClickLoadModule
  };
};
