const SCHEMA: string = process.env.REACT_APP_MASTER_MAIN_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MASTER_MAIN_ENTITY || '';

const LIST_REDIRECT_NOTIFICATION = {
    'CHPT': `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GESTIONAUTOCHEQUEO}`,
    'RDI': `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_RDI}`,
    'LA': `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GESTIONAUTOCHEQUEO}`,
    'CP': `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GESTIONAUTOCHEQUEO}`,
    'INSP': `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_INSPECCION}`,
    'NA': `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_HOME}`
}

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    LIST_REDIRECT_NOTIFICATION,
};