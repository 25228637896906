import { EntityPais } from "../../../shared/Domain/EntityPais";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseSelectPais {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(): Promise<Array<EntityPais>> {
        return await this._exec();
    }

    private async _exec(): Promise<Array<EntityPais>> {
        return await this.repository.selectPais();
    }
}