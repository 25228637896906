import { EntityLiteOT } from "../Domain/CatalogosLite/EntityLiteOT";
import { EntityLitePersonal } from "../Domain/CatalogosLite/EntityLitePersonal";
import { EntityOTs } from "../Domain/EntityOTs"
import { EntityPersonal } from "../Domain/EntityPersonal";

interface props {
    ot: Array<EntityOTs>;
    personal: Array<EntityPersonal>;
    otLite: Array<EntityLiteOT>;
    personalLite: Array<EntityLitePersonal>;
};

export const AdapterData: props = {
    ot: [],
    personal: [],
    otLite: [],
    personalLite: []
};