import { EntityEmpresa } from "../../../shared/Domain/EntityEmpresa";
import { EntitySelectBase } from "../../../shared/Domain/EntitySelectBase";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseSelectEmpresa {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(): Promise<EntitySelectBase<EntityEmpresa>[]> {
        return await this._exec();
    }

    private async _exec(): Promise<EntitySelectBase<EntityEmpresa>[]> {
        return await this.repository.selectEmpresa();
    }
}