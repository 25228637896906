import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdapterIndexedDB } from './AdapterIndexedDB';
import { AdapterWebSocket } from './AdapterWebSocket';
import { LanguageTranslate } from './LanguageTranslate';

interface SliceGenericoState {
	iconMenu: boolean;
	showSaludo: boolean;

	loading: boolean;
	textLoading: string;

	websocket: AdapterWebSocket;

	dbLocal: AdapterIndexedDB;

	online: boolean;
	countProcess: number;

	textLoadingMaestro: string;
	loadingMaestros: boolean;
	availableUpdate: boolean;
	countNotifications: number;
};

const initialState: SliceGenericoState = {
	iconMenu: false,
	showSaludo: true,

	loading: false,
	textLoading: '',

	websocket: new AdapterWebSocket(process.env.REACT_APP_WS_HOST, Number(process.env.REACT_APP_WS_PORT) || 80, process.env.REACT_APP_WS_SECURE === 'true' ? true : false, process.env.REACT_APP_WS_PATH, {}),

	dbLocal: new AdapterIndexedDB(`db_${process.env.REACT_APP_SISTEMA}_${process.env.REACT_APP_VERSION_MAJOR}`, Number(process.env.REACT_APP_INDEXEDDB_VERSION) || 1, [
		{ name: 'Pais', pk: '_id', index: [], encrpyt: false },
		{ name: 'Proyecto', pk: '_id', index: [], encrpyt: false },
		{ name: 'Empresa', pk: '_id', index: [], encrpyt: false },
		{ name: 'Delegacion', pk: '_id', index: [], encrpyt: false },
		{ name: 'Personal', pk: 'IdPersonal', index: [], encrpyt: false },
		{ name: 'Actividad', pk: '_id', index: [], encrpyt: false },
		{ name: 'TipoIncidente', pk: '_id', index: [], encrpyt: false },
		{ name: 'FactorRiesgo', pk: '_id', index: [], encrpyt: false },
		{ name: 'OT', pk: '_id', index: [], encrpyt: false },

		{ name: 'RDI', pk: '_id', index: [], encrpyt: false },
		{ name: 'Inspeccion', pk: '_id', index: [], encrpyt: false },
		{ name: 'Area', pk: '_id', index: [], encrpyt: false },
		{ name: 'FormatoInspecciones', pk: '_id', index: [], encrpyt: false },
		{ name: 'TipoInspeccion', pk: '_id', index: [], encrpyt: false },
		{ name: 'AnomaliaInspeccion', pk: '_id', index: [], encrpyt: false },
		{ name: 'AutochequeoTipologia', pk: '_id', index: [], encrpyt: false },
		{ name: 'AutochequeoTitulos', pk: '_id', index: [], encrpyt: false },
		{ name: 'AutochequeoItems', pk: '_id', index: [], encrpyt: false },

		{ name: 'CTEPCS', pk: '_id', index: [], encrpyt: false },
		{ name: 'CTMedidaPreventivas', pk: '_id', index: [], encrpyt: false },
		{ name: 'CTOpcionAudio', pk: '_id', index: [], encrpyt: false },
		{ name: 'CTOpcionLA', pk: '_id', index: [], encrpyt: false },
		{ name: 'CTPreguntaLA', pk: '_id', index: [], encrpyt: false },
		{ name: 'CTTipoPregunta', pk: '_id', index: [], encrpyt: false },
		{ name: 'CTTipoRiesgo', pk: '_id', index: [], encrpyt: false },

		{ name: 'Autochequeo', pk: '_id', index: [], encrpyt: false },
		{ name: 'NotificacionesTerminos', pk: '_id', index: [], encrpyt: false },
		{ name: 'TermsAccept', pk: '_id', index: [], encrpyt: false },
		
		// Catálogos Lite sin login
		{ name: 'CTLiteEmpresa', pk: '_id', index: [], encrpyt: false },
		{ name: 'CTLiteOT', pk: '_id', index: [], encrpyt: false },
		{ name: 'CTLitePersonal', pk: '_id', index: [], encrpyt: false },
		{ name: 'CTLitePais', pk: '_id', index: [], encrpyt: false },

		// Catalogo de notificiones
		{ name: 'Notificacion', pk: '_id', index: [], encrpyt: false }
	]),

	online: navigator.onLine,
	countProcess: 0,

	textLoadingMaestro: LanguageTranslate().textoConfigurandoAreaTrabajo,
	loadingMaestros: true,
	availableUpdate: false,

	countNotifications: 0
};

/*initialState.websocket.create();
initialState.websocket.init();*/

initialState.dbLocal.run();

const genericoSlice = createSlice({
	name: 'genericoSlice',
	initialState,
	reducers: {
		addLoading: (state, action) => {
			return {
				...state,
				loading: true,
				textLoading: action.payload.textLoading,
			};
		},
		removeLoading: state => {
			return {
				...state,
				loading: false,
				textLoading: '',
			};
		},
		showIconMenu: state => {
			return {
				...state,
				iconMenu: true,
			};
		},
		hideIconMenu: state => {
			return {
				...state,
				iconMenu: false,
			};
		},
		changeSaludo: (state, action) => {
			return {
				...state,
				showSaludo: action.payload,
			};
		},
		changeOnline: (state, action) => {
			return {
				...state,
				online: action.payload,
			};
		},
		changeCountProcess: (state, action) => {
			return {
				...state,
				countProcess: action.payload,
			};
		},
		addCountProcess: (state) => {
			let count: number = state.countProcess;
			count += 1;
			return {
				...state,
				countProcess: count,
			};
		},
		addLoadingMaestros: (state, action: PayloadAction<{ textLoadingMaestro: string }>) => {
			return {
				...state,
				textLoadingMaestro: action.payload.textLoadingMaestro || LanguageTranslate().textoConfigurandoAreaTrabajo,
				loadingMaestro: true
			}
		},
		removeLoadingMaestros: (state) => {
			return {
				...state,
				loadingMaestros: false
			};
		},
		changeAvailableUpdate: (state, action) => {
			return {
				...state,
				availableUpdate: action.payload,
			};
		},
		changeCountNotifications: (state, action) => {
			return {
				...state,
				countNotifications: action.payload
			}
		}
	},
});

export const {
	showIconMenu,
	hideIconMenu,
	addLoading,
	removeLoading,
	changeSaludo,
	changeOnline,
	changeCountProcess,
	addCountProcess,
	addLoadingMaestros,
	removeLoadingMaestros,
	changeAvailableUpdate,
	changeCountNotifications
} = genericoSlice.actions;

export default genericoSlice.reducer;