const SCHEMA: string = process.env.REACT_APP_MASTER_LOGIN_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MASTER_LOGIN_ENTITY || '';

const LOGIN_KEY_ENCRIPT: string = process.env.REACT_APP_LOGIN_KEY_ENCRIPT_ || '';
const LOGIN = '/send/Login';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,

    LOGIN_KEY_ENCRIPT,
    LOGIN
};