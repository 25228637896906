// Domain
import { EntityTipoIncidente } from "../../../shared/Domain/Catalogos/EntityTipoIncidente";
import { EntityFactorRiesgo } from "../../../shared/Domain/Catalogos/EntityFactorRiesgo";
import { DtoRequestForm, DtoRequestFormRDI } from "../Domain/DtoRequestForm";
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { EntityActividad } from "../../../shared/Domain/EntityActividad";
import { EntityEmpresa } from "../../../shared/Domain/EntityEmpresa";
import { EntityPais } from "../../../shared/Domain/EntityPais";
import { EntityOTs } from "../../../shared/Domain/EntityOTs";
import { DtoResponseForm } from "../Domain/DtoResponseForm";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityDataForm, EntityLoadData, EntityLoadDataGuest, EntityModalImage, EntityModeForm } from "../Domain/Utils";

// Infraestructure
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { AdapterGeolocation } from "../../../shared/Infraestructure/AdapterGeolocation";
import { addCountProcess } from "../../../shared/Infraestructure/SliceGenerico";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { AdapterData } from "../../../shared/Infraestructure/AdapterData";
import { AdapterConfigure } from "./AdapterConfigure";
import { EntityLiteEmpresa } from "../../../shared/Domain/CatalogosLite/EntityLiteEmpresa";
import { EntityLitePersonal } from "../../../shared/Domain/CatalogosLite/EntityLitePersonal";
import { EntitySelectBase } from "../../../shared/Domain/EntitySelectBase";
import { EntityDelegacion } from "../../../shared/Domain/EntityDelegacion";
import { AdapterCheckConnection } from "../../../shared/Infraestructure/AdapterCheckConnection";
import { LanguageApp, LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";

const languageTranslate = LanguageTranslate();

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async save(params: DtoRequestForm, modeForm: EntityModeForm): Promise<DtoResponseForm | null> {
        try {
            let response = await this._save(params, modeForm);
            let rowLocal = await this._saveLocal(params, modeForm);

            if (response !== null) {
                await this.dbLocal.deleteByIndexStore({ nameStore: 'RDI', value: rowLocal._id });
                await this.dbLocal.insertDataStore({ nameStore: 'RDI', data: response });
            }

            return !!response ? response : rowLocal;
        } catch (error) {
            throw error;
        }
    }

    private async _saveLocal(params: DtoRequestForm, modeForm: EntityModeForm): Promise<any> {
        const StatusOffline = { Estado: "No Enviado", IdEstado: -1 }

        const _idTemp = new Date().getTime().toString();
        let response = { _id: _idTemp, ...params.RDI, Estado: StatusOffline, modeForm, dataSend: params, guest: modeForm === 'createGuest' };

        await this.dbLocal.insertDataStore({ nameStore: 'RDI', data: response });
        return response;
    }

    private async _save(params: DtoRequestForm, modeForm: EntityModeForm): Promise<DtoResponseForm | null> {
        const url = `${this.urlBase}${AdapterConfigure.SAVE_RDI}`
        if (!new AdapterCheckConnection().isStable()) { this.dispatch(addCountProcess()); return null; }

        const formData = new FormData()
        formData.append('RDI', JSON.stringify(params.RDI));
        // params.File.forEach((file) => formData.append('File', file, file.name));
        params.File.forEach((file, index) => 
            formData.append('File', file, `${AdapterGenerico.formatoParaGuardarImagenes()}_1${index}_${
              params.RDI.Procesos.Registrar.IdUsuario
            }.${file.name.split('.').pop() || ''}`)
        )

        params.FileSubsanacion.forEach((file, index) => 
            formData.append('FileSolucion', file, `${AdapterGenerico.formatoParaGuardarImagenes()}_2${index}_${
                params.RDI.Procesos.Registrar.IdUsuario
            }.${file.name.split('.').pop() || ''}`)
        )

        formData.append('listFotosCargadas', JSON.stringify(params.listFotosCargadas));
        if (modeForm === 'createGuest') formData.append("Guest", "true");

        const response = await this.service.call<any>("POST", url, formData, "bearer", "form", 'json', {}, 0);
        if (Array.isArray(response) && response.length > 0) return ({ ...response[0], guest: modeForm === 'createGuest' })
        
        return response;
    }

    public async loadDataGuest(): Promise<EntityLoadDataGuest> {
        const TIPO_EMPRESA_EMPRESA = 1;
        const TIPO_EMPRESA_SUBCONTRATA = 2;
        const TIPO_EMPRESA_CLIENTE = 3;

        const [liteEmpresa, TipoIncidencia, FactorRiesgo]: [EntityLiteEmpresa[], EntityTipoIncidente[], EntityFactorRiesgo[]] = await Promise.all([
            this.dbLocal.selectAllStore('CTLiteEmpresa'),
            this.dbLocal.selectAllStore('TipoIncidente'),
            this.getTranslateCatalog('FactorRiesgo'),
        ]);

        const SubcontrataFormatted = AdapterGenerico.formatoPersonalizadoSelect(liteEmpresa.filter(row => row.IdTipoEmpresa.some(_row => _row === TIPO_EMPRESA_SUBCONTRATA)), '_id', 'Nombre') as any;
        const EmpresaFormatted = AdapterGenerico.formatoPersonalizadoSelect(liteEmpresa.filter(row => row.IdTipoEmpresa.some(_row => _row === TIPO_EMPRESA_EMPRESA)), '_id', 'Nombre') as any;
        const ClienteFormatted = AdapterGenerico.formatoPersonalizadoSelect(liteEmpresa.filter(row => row.IdTipoEmpresa.some(_row => _row === TIPO_EMPRESA_CLIENTE) && !!row.EstadoPNET), '_id', 'Nombre') as any;

        const FactorRiesgoFormatted = this.templateFactorRiesgo(FactorRiesgo);
        const TipoIncidenciaFormatted = AdapterGenerico.formatoPersonalizadoSelect(TipoIncidencia, 'Codigo', 'NombreIncidente') as any;
        const OTFormatted = AdapterGenerico.formatoPersonalizadoSelect(AdapterData.otLite, '_id', ['Codigo', 'OT'], ' - ') as any[];
        const listPersonal: EntitySelectBase<EntityLitePersonal>[] = AdapterGenerico.formatoPersonalizadoSelect(AdapterData.personalLite, '_id', ['Nombres', 'Apellidos']) as any;
        const personalForCountry = listPersonal.reduce((groups: any, personal) => {
            // SuperAdminsitrador y Adminsitrador País
            groups['general'] = []

            const keyPais = personal.dataComplete.IdPais;
            if (groups[keyPais]) {
                groups[keyPais].push(personal);
            } else {
                groups[keyPais] = [];
                groups[keyPais].push(personal);
            }

            return groups;
        }, {});

        return ({
            Subcontrata: SubcontrataFormatted,
            Empresa: EmpresaFormatted,
            Cliente: ClienteFormatted,
            FactorRiesgo: FactorRiesgoFormatted,
            OT: OTFormatted,
            Personal: personalForCountry,
            TipoIncidencia: TipoIncidenciaFormatted,
            TipoIncidente: [
                { value: 1, label: languageTranslate.moduloRDI.form.TipoIncidentePersonal, dataComplete: { IdTipoIncidente: 1, Incidente: "PP(Pers.Propio)", Abreviatura: "PP" } },
                { value: 2, label: languageTranslate.moduloRDI.form.TipoIncidenteSubContrata, dataComplete: { IdTipoIncidente: 2, Incidente: "PS(Pers.Contra)", Abreviatura: "PS" } },
            ],
            Standar: [
                { value: 1, label: languageTranslate.btnSi },
                { value: 0, label: languageTranslate.btnNo },
            ]
        })
    }

    public async loadData(): Promise<EntityLoadData> {
        const TIPO_EMPRESA_EMPRESA = 1;
        const TIPO_EMPRESA_SUBCONTRATA = 2;
        const TIPO_EMPRESA_CLIENTE = 3;
        let EmpresasPorTipo = {
            Empresa: [] as any[],
            SubContrata: [] as any[],
            Cliente: []  as any[]
        }

        const [ListPais, ListDelegacion, ListEmpresa, ListActividad, ListTipoIncidente, ListFactorRiesgo]: [EntityPais[], EntityDelegacion[], EntityEmpresa[], EntityActividad[], EntityTipoIncidente[], EntityFactorRiesgo[]] = await Promise.all([
            this.getTranslateCatalog('Pais'),
            this.dbLocal.selectAllStore('Delegacion'),
            this.dbLocal.selectAllStore('Empresa'),
            this.getTranslateCatalog('Actividad'),
            this.getTranslateCatalog('TipoIncidente'),
            this.getTranslateCatalog('FactorRiesgo'),
        ]);
        // AdapterData.personal

        const PaisFormatted = AdapterGenerico.formatoPersonalizadoSelect(ListPais, 'IdPais', 'label') as EntitySelectBase<EntityPais>[];
        const DelegacionFormatted = AdapterGenerico.formatoPersonalizadoSelect(ListDelegacion, 'Codigo', ['Codigo', 'Delegacion'], ' - ') as EntitySelectBase<EntityDelegacion>[];
        const EmpresaFormatted = ListEmpresa.reduce((total, currentValue) => {
            const arrTipoEmpresa = currentValue.TipoEmpresa;
            const item = AdapterGenerico.formatoPersonalizadoSelect(currentValue, 'IdEmpresa', 'RazonSocial');
            if (arrTipoEmpresa.some(tipo => tipo.IdTipoEmpresa === TIPO_EMPRESA_EMPRESA && !!tipo.EstadoPNET)) {
                total.Empresa.push(item);
            }
            if (arrTipoEmpresa.some(tipo => tipo.IdTipoEmpresa === TIPO_EMPRESA_SUBCONTRATA)) {
                total.SubContrata.push(item);
            }
            if (arrTipoEmpresa.some(tipo => tipo.IdTipoEmpresa === TIPO_EMPRESA_CLIENTE && !!tipo.EstadoPNET)) {
                total.Cliente.push(item);
            }
            return total;
        }, EmpresasPorTipo);
        const personalForCountry = AdapterData.personal.reduce((groups: any, personal) => {
            // Super admin y Admin país
            const keyPais = ([1, 2].includes(personal.IdRol || 0)) ? AdapterConfigure.KEY_ALL_ADMIN : personal.IdPais;
            const item = AdapterGenerico.formatoPersonalizadoSelect(personal, 'Identificacion', ['Nombres', 'Apellidos'])
            if (groups[keyPais]) {
                groups[keyPais].push(item);
            } else {
                groups[keyPais] = [];
                groups[keyPais].push(item);
            }

            return groups;
        }, {});
        const ActividadFormatted = AdapterGenerico.formatoPersonalizadoSelect(ListActividad, 'Codigo', 'label') as EntitySelectBase<EntityActividad>[];
        const TipoIncidenciaFormatted = AdapterGenerico.formatoPersonalizadoSelect(ListTipoIncidente, 'Codigo', 'label') as EntitySelectBase<EntityTipoIncidente>[];
        const FactorRiesgoFormatted = this.templateFactorRiesgo(ListFactorRiesgo);
        const OTFormatted = AdapterGenerico.formatoPersonalizadoSelect(AdapterData.ot, 'Codigo', ['Codigo', 'OT'], ' - ') as EntitySelectBase<EntityOTs>[];

        return ({
            Actividad: ActividadFormatted,
            Cliente: EmpresaFormatted.Cliente,
            Delegacion: DelegacionFormatted,
            Empresa: EmpresaFormatted.Empresa,
            FactorRiesgo: FactorRiesgoFormatted,
            Pais: PaisFormatted,
            Personal: personalForCountry,
            Standar: [
                { value: 1, label: languageTranslate.btnSi },
                { value: 0, label: languageTranslate.btnNo },
            ],
            Subcontrata: EmpresaFormatted.SubContrata,
            TipoIncidencia: TipoIncidenciaFormatted,
            OT: OTFormatted,
            TipoIncidente: [
                { value: 1, label: languageTranslate.moduloRDI.form.TipoIncidentePersonal, dataComplete: { IdTipoIncidente: 1, Incidente: "PP(Pers.Propio)", Abreviatura: "PP" } },
                { value: 2, label: languageTranslate.moduloRDI.form.TipoIncidenteSubContrata, dataComplete: { IdTipoIncidente: 2, Incidente: "PS(Pers.Contra)", Abreviatura: "PS" } },
            ]
        })
    }

    private templateFactorRiesgo(List: EntityFactorRiesgo[]): EntitySelectBase<EntityFactorRiesgo>[] {
        return List.map((group) => {
            const { Descripcion, Codigo, IdFactorRiesgo } = group;
            return ({
                label: group.label,
                value: Codigo,
                dataComplete: group,
                options: AdapterGenerico.formatoPersonalizadoSelect(group.Riesgos.map((riesgo) => ({ ...riesgo, dataFather: { Codigo, Descripcion: group.label, IdFactorRiesgo } })), ['CodigoRiesgo', 'DetalleRiesgo'], 'label')
            })
        })
    }
    
    public async getOneOT(_id: string): Promise<EntityOTs | null> {
        return await this.dbLocal.selectByIndexStore({ nameStore: 'OT', value: _id })
    }

    public formatterReadImage(idFecha: number, file: string): string {
        let variablesDescarga = `${process.env.REACT_APP_PATH_IMAGES_RDI}||${idFecha}||${file}`;
        let path_imagen = btoa(unescape(encodeURIComponent(variablesDescarga)));
        return this.urlBase + "/download/Imagen/" + path_imagen;
    }

    public formatterReadData(data: DtoResponseForm): EntityDataForm {
        return ({
            // Edit
            _id: data._id,
            IdIncidente: data.IdIncidente,
            IdFecha: data?.Procesos?.Registrar?.IdFecha || 0,

            // Guest
            IdAppLocal: data.IdAppLocal || '',
            OTGuest: { label: '', value: '', dataComplete: {} as any },

            OT: { label: '', value: '', dataComplete: {} as any },
            Pais: { label: data.Pais.Pais, value: data.Pais.IdPais, dataComplete: {} as any },
            Delegacion: { label: data.Delegacion.Delegacion, value: data.Delegacion.Codigo, dataComplete: {} as any },
            Empresa: { label: data.Empresa.Nombre, value: data.Empresa.IdEmpresa, dataComplete: {} as any },
            Subcontrata: { label: data.Subcontrata?.Nombre ?? '', value: data.Subcontrata?.IdEmpresa ?? '', dataComplete: {} as any },
            Cliente: { label: data.Cliente.Nombre, value: data.Cliente.IdEmpresa, dataComplete: {} as any },
            Actividad: { label: data.Actividad.Actividad, value: data.Actividad.IdActividad, dataComplete: {} as any },
            UsuarioRegistro: { label: data.UsuarioRegistro.Nombres + " " + data.UsuarioRegistro.Apellidos, value: data.UsuarioRegistro.Identificacion, dataComplete: {} as any },
            FechaIncidente: data.FechaIncidente.Fecha + "T" + data.HoraIncidente.Hora,
            TipoIncidencia: { label: data.TipoIncidente.Incidente, value: data.TipoIncidente.IdTipoIncidente, dataComplete: {} as any },
            BreveDescripcionIncidente: data.BreveDescripcionIncidente,
            MedidasPropuestas: data.MedidasPropuestas,
            UsuarioMP: data.UsuarioMP?.Identificacion ? { label: data.UsuarioMP.Nombres + " " + data.UsuarioMP.Apellidos, value: data.UsuarioMP.Identificacion, dataComplete: {} as any } : null as any,
            Obra: data.Obra,
            Provincia: data.Provincia,
            Direccion: data.Direccion,
            FactorRiesgo: { label: data.FactorRiesgo?.Riesgo?.Riesgo || '', value: data.FactorRiesgo.IdFactorRiesgo, dataComplete: {} },
            TipoIncidente: { label: data.TipoIncidente.Incidente, value: data.TipoIncidente.IdTipoIncidente, dataComplete: { Abreviatura: data.TipoIncidente.Abreviatura, IdTipoIncidente: data.TipoIncidente.IdTipoIncidente, Incidente: data.TipoIncidente.Incidente } },
            CondicionInsegura: { label: data.Actuacion.Descripcion, value: data.Actuacion.IdActuacion, dataComplete: {} },
            VehiculoAfectado: { label: data.VehiculoAfectado.Descripcion, value: data.VehiculoAfectado.IdVehiculoAfectado, dataComplete: {} },
            Placa: data.VehiculoAfectado.Placa,
            ParalizacionTrabajo: { label: data.ParalizacionTrabajo.Descripcion, value: data.ParalizacionTrabajo.IdParalizacion, dataComplete: {} },
            DescripcionSolucion: data.ObservacionSolucion?.Descripcion || '',
        })
    }

    public async formatterSaveDataRDI(data: EntityDataForm, user: EntityDataUsuario): Promise<DtoRequestFormRDI> {
        let geolocation = { lat: '', lng: '' };
        const FechaIncidente = (data.FechaIncidente as string).split("T")
        const FechaTemp = AdapterGenerico.getFechaISO_Local();
        const IdiomaTemp = LanguageApp();

        await AdapterGeolocation.getLocation().then((res) =>
            (geolocation = { lat: `${res.latitud}`, lng: `${res.longitud}` })
        ).catch((reason) => { });

        return ({
            ...{
                    ...( data.OTGuest ? {
                        OTGuest: {
                            _id: data.OTGuest?.dataComplete._id || '',
                            Codigo: data.OTGuest?.dataComplete.Codigo || '',
                            OT: data.OTGuest?.dataComplete.OT || '',
                        }
                    } : {}
                )
            },
            IdAppLocal: data.IdAppLocal,
            Actividad: {
                IdActividad: data.Actividad?.dataComplete.IdActividad || 0,
                Codigo: data.Actividad?.dataComplete.Codigo || "",
                Actividad: data.Actividad?.label || data.Actividad.dataComplete?.Actividad || ""
            },
            Actuacion: {
                IdActuacion: parseInt(data.CondicionInsegura.value),
                Descripcion: parseInt(data.CondicionInsegura.value) === 1 ? "SI" : "NO"
            },
            BreveDescripcionIncidente: data.BreveDescripcionIncidente,
            Cliente: {
                _id: data.Cliente?.dataComplete._id,
                Codigo: data.Cliente?.dataComplete.Codigo || "",
                IdEmpresa: data.Cliente?.dataComplete.IdEmpresa || 0,
                Nombre: data.Cliente?.dataComplete.Nombre || "",
                NroDocumento: data.Cliente?.dataComplete.NroDocumento || "",
                RazonSocial: data.Cliente?.dataComplete.RazonSocial || "",
                TipoDocumento: data.Cliente?.dataComplete.TipoDocumento || ""
            },
            DatosApp: {
                Origen: `${process.env.REACT_APP_TITLE}`,
                IsOnline: true,
                Latitude: geolocation.lat,
                Longitude: geolocation.lng,
                Modo: `${process.env.REACT_APP_PUBLISH_ENV}`,
                PlayStore: false,
                VersionName: `${process.env.REACT_APP_VERSION_SYSTEM}`,
                IdOrigen: 2
            },
            Delegacion: {
                Codigo: data.Delegacion?.dataComplete.Codigo || "",
                Delegacion: data.Delegacion?.dataComplete.Delegacion || "",
                IdDelegacion: data.Delegacion?.dataComplete.IdDelegacion || 0
            },
            Direccion: data?.Direccion,
            Empresa: {
                _id: data.Empresa.dataComplete._id,
                Codigo: data.Empresa?.dataComplete.Codigo || "",
                IdEmpresa: data.Empresa?.dataComplete.IdEmpresa || 0,
                Nombre: data.Empresa?.dataComplete.Nombre || "",
                NroDocumento: data.Empresa?.dataComplete.NroDocumento || "",
                RazonSocial: data.Empresa?.dataComplete.RazonSocial || "",
                TipoDocumento: data.Empresa?.dataComplete.TipoDocumento || ""
            },
            Estado: {
                Estado: 'Activo',
                IdEstado: 1
            },
            FactorRiesgo: {
                Codigo: data.FactorRiesgo.dataComplete.dataFather.Codigo,
                Descripcion: data.FactorRiesgo.dataComplete.dataFather.Descripcion,
                IdFactorRiesgo: data.FactorRiesgo.dataComplete.dataFather.IdFactorRiesgo,
                Riesgo: {
                    CodigoRiesgo: data.FactorRiesgo.dataComplete.CodigoRiesgo,
                    DetalleRiesgo: data.FactorRiesgo.label,
                    Estado: data.FactorRiesgo.dataComplete.Estado,
                    Idioma: IdiomaTemp,
                    Riesgo: data.FactorRiesgo.label
                }
            },
            FechaIncidente: {
                IdFecha: AdapterGenerico.ObtenerIdFechaISO(FechaIncidente[0]),
                Fecha: FechaIncidente[0],
                TimeZone: AdapterGenerico.getTimeZoneOffSet()
            },
            FechaRegistro: {
                IdFecha: AdapterGenerico.ObtenerIdFechaISO(FechaTemp),
                Fecha: FechaTemp,
                TimeZone: AdapterGenerico.getTimeZoneOffSet()
            },
            Fotos: "[]",
            HoraIncidente: {
                Hora: FechaIncidente[1]
            },
            IdIncidente: 0, // se coloca 0 por que será un nuevo registro
            Idioma: IdiomaTemp,
            IdNombreIncidente: data.TipoIncidencia.dataComplete.IdNombreIncidente,
            IdSubIncidente: null, // no se alamacena el valor en mobile
            MedidasPropuestas: data?.MedidasPropuestas || "",
            NombreIncidente: data.TipoIncidencia.label,
            Obra: data?.Obra || "",
            Pais: {
                IdPais: data.Pais.dataComplete?.IdPais || 0,
                Pais: data.Pais.dataComplete?.Pais || ""
            },
            ParalizacionTrabajo: {
                IdParalizacion: parseInt(data.ParalizacionTrabajo.value),
                Descripcion: parseInt(data.ParalizacionTrabajo.value) === 1 ? "SI" : "NO"
            },
            Procesos: {
                Registrar: {
                    IdUsuario: user.IdUsuario,
                    Usuario: user.Usuario,
                    TimeZone: AdapterGenerico.getTimeZoneOffSet(),
                    DatosUsuario: {
                        Apellidos: `${user.ApellidoPaterno} ${user.ApellidoMaterno}`,
                        Identificacion: user.Identificacion,
                        Nombres: user.Nombres
                    }
                },
                Eliminar: {
                    IdUsuario: 0,
                    Usuario: "",
                    DatosUsuario: {
                        Apellidos: ``,
                        Identificacion: "",
                        Nombres: ""
                    }
                },
                Modificar: {
                    IdUsuario: 0,
                    Usuario: "",
                    DatosUsuario: {
                        Apellidos: ``,
                        Identificacion: "",
                        Nombres: ""
                    }
                },
            },
            Provincia: data?.Provincia || "",
            Subcontrata: {
                _id: data.Subcontrata?.dataComplete._id || data.SubContrataGuest?.dataComplete._id || '',
                Codigo: data.Subcontrata?.dataComplete.Codigo || '',
                IdEmpresa: data.Subcontrata?.dataComplete.IdEmpresa || 0,
                Nombre: data.Subcontrata?.dataComplete.Nombre || data.SubContrataGuest?.dataComplete.Nombre || '',
                NroDocumento: data.Subcontrata?.dataComplete.NroDocumento || '',
                RazonSocial: data.Subcontrata?.dataComplete.RazonSocial || '',
                TipoDocumento: data.Subcontrata?.dataComplete.TipoDocumento || ''
            },
            SubIncidente: null, // no se alamacena el valor en mobile
            TipoIncidente: {
                IdTipoIncidente: data.TipoIncidente.dataComplete.IdTipoIncidente,
                Incidente: data.TipoIncidente.label,
                Abreviatura: data.TipoIncidente.dataComplete.Abreviatura
            },
            UsuarioMP: {
                _id: data.UsuarioMP?.dataComplete._id || data.UsuarioMPGuest?.dataComplete._id || "",
                Codigo: data.UsuarioMP?.dataComplete.Codigo || data.UsuarioMPGuest?.dataComplete.Codigo || "",
                Identificacion: data.UsuarioMP?.dataComplete.Identificacion || "",
                Apellidos: data.UsuarioMP?.dataComplete.Apellidos || data.UsuarioMPGuest?.dataComplete.Apellidos || "",
                Nombres: data.UsuarioMP?.dataComplete.Nombres || data.UsuarioMPGuest?.dataComplete.Nombres || "",
                Cargo: data.UsuarioMP?.dataComplete.Cargo || "",
            },
            UsuarioRegistro: {
                _id: data.UsuarioRegistro?.dataComplete._id || data.UsuarioRegistroGuest?.dataComplete._id || '',
                Codigo: data.UsuarioRegistro?.dataComplete.Codigo || data.UsuarioRegistroGuest?.dataComplete.Codigo || '',
                Identificacion: data.UsuarioRegistro?.dataComplete.Identificacion || '',
                Apellidos: data.UsuarioRegistro?.dataComplete.Apellidos || data.UsuarioRegistroGuest?.dataComplete.Apellidos || '',
                Nombres: data.UsuarioRegistro?.dataComplete.Nombres || data.UsuarioRegistroGuest?.dataComplete.Nombres || '',
                Cargo: data.UsuarioRegistro?.dataComplete.Cargo || '',
            },
            ObservacionSolucion: {
                Comentario: data.DescripcionSolucion ? 'SOLUCIONADO' : 'PENDIENTE',
                Descripcion: data.DescripcionSolucion || '',
                FechaLevantamiento: data.DescripcionSolucion ? AdapterGenerico.convertDateToString(new Date(), 3) : null,
                FilesLevantamiento: data.DescripcionSolucion ? data.FileSubsanacion : [],
                IdEstado: data.DescripcionSolucion ? 1 : 10
            },
            VehiculoAfectado: {
                IdVehiculoAfectado: parseInt(data.VehiculoAfectado.value),
                Descripcion: parseInt(data.VehiculoAfectado.value) === 1 ? "SI" : "NO",
                Placa: parseInt(data.VehiculoAfectado.value) === 1 ? data.Placa : ""
            },
            Grupo: {
                IdGrupo: data.Delegacion?.dataComplete.Grupo?.IdGrupo || 0,
                Grupo: data.Delegacion?.dataComplete.Grupo?.Grupo || ""
            }
        });
    }

    public async saveRDILevantamiento(data: EntityDataForm, user: EntityDataUsuario, images: File[]): Promise<DtoResponseForm | null> {
        const url = `${this.urlBase}${AdapterConfigure.SAVE_RDI_LEVANTAMIENTO}`
        const FechaTemp = AdapterGenerico.getFechaISO_Local().split('T')[0];
        const HoraTemp = AdapterGenerico.getFechaISO_Local().split('T')[1].split(':').slice(0, 2).join(':')

        const formData = new FormData()
        formData.append("_id", data?._id || '')
        formData.append("IdIncidente", `${data.IdIncidente || ''}`)
        formData.append("IdFecha", `${data?.IdFecha}`)
        formData.append("Descripcion", data.DescripcionSolucion)
        formData.append("FechaLevantamiento", FechaTemp)
        formData.append("HoraLevantamiento", HoraTemp)
        formData.append("UsuarioLevantamiento", user.Identificacion);
        formData.append("HoraLevantamiento", HoraTemp)

        images.forEach((file, index) => 
            formData.append('FilesLevantamiento', file, `${AdapterGenerico.formatoParaGuardarImagenes()}_2${index}_${
                user.IdUsuario
            }.${file.name.split('.').pop() || ''}`)
        )

        const { data: response } = await this.service.call<any>("POST", url, formData, "bearer", "form", 'json', {}, 0);
        if (Array.isArray(response) && response.length > 0) return ({ ...response[0] })
        
        return response;
    }

    public formatterSaveDataImages(listImage: EntityModalImage[]) {
        const imageFormatted = {
            File: [] as File[],
            listFotosCargadas: [] as string[]
        }
        listImage.forEach((img) => img.type === 'BD' ? imageFormatted.listFotosCargadas.push(img.file as string) : imageFormatted.File.push(img.file as File))
        return imageFormatted;
    }
}