import { EntityList } from "../Domain/EntityList";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseList {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: any): Promise<EntityList[] | null> {
        return await this._exec(params);
    }

    private async _exec(params: any): Promise<EntityList[] | null> {
        return await this.repository.getList(params);
    }
}