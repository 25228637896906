import { ElementEmptyList } from "../../../shared/Components/ElementEmptyList";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { EntityConfigForm, EntityModal } from "../Domain/Utils";
import { AdapterConfigure } from "../Infraestructure/AdapterConfigure";
import "./ViewModal.scss";

interface IViewModal {
  selectNewImage: (newIndex: number) => void;
  modalData: EntityModal;
  setModalData: React.Dispatch<React.SetStateAction<EntityModal>>;
  deleteImage: (position: number) => void;
  selectedIndex: number;
  configForm: EntityConfigForm;
}

const ViewModal = (props: IViewModal) => {
  const languageTranslate = LanguageTranslate();
  const listImages = props.modalData.type === 'images' ? props.modalData.images : props.modalData.imagesSubsanacion;
  const countImages = listImages.length;
  const closeModal = () =>
    props.setModalData((prev) => ({ ...prev, show: false }));

  return (
    <>
      {props.modalData.show ? (
        <div className="ViewRDIModal">
          <div className="container-modal">
            <div className="header d-flex">
              <h6 className="mb-0"> {languageTranslate.moduloRDI.form.modal.textoTitulo} ({ countImages === 0 ? '0' : props.selectedIndex + 1}) </h6>
              <p className="mb-0" onClick={closeModal}>
                <i className="fa-sharp fa-solid fa-circle-xmark" style={{ fontSize: "20px" }} />
              </p>
            </div>
            <div className="body">
              {
                countImages === 0 ? (
                  <>
                    <ElementEmptyList className="image-empty" text={languageTranslate.moduloRDI.form.modal.textoSinImagenes}/>
                  </>
                ) : (
                  <>
                    <div className="main-image">
                      <img
                        className={`shadow`}
                        height={330}
                        width="100%"
                        src={listImages[props.selectedIndex].valueToShow}
                        alt="not source"
                      />
                      <button disabled={AdapterConfigure.MODE_BLOCK_INPUTS.includes(props.configForm.mode)} className="btn btn-dark" onClick={() => props.deleteImage(props.selectedIndex)}> {languageTranslate.moduloRDI.form.modal.btnEliminarImagen} </button>
                    </div>
                    <div className="group-image">
                      {listImages.map((image, index) => (
                        <img
                          key={index}
                          onClick={() => props.selectNewImage(index)}
                          className={ props.selectedIndex === index ? "img-selected" : ""}
                          height={50}
                          width={50}
                          src={image.valueToShow}
                          alt="not source"
                        />
                      ))}
                    </div>
                  </>
                )
              }
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ViewModal;
