// Domain
import { EntityNotificacionTerminos } from '../../../shared/Domain/EntityNotificacionTerminos';
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';
import { EntityMenu } from '../../../shared/Domain/EntityMenu';

// Components
import { ElementEmptyList } from '../../../shared/Components/ElementEmptyList';
import ElementModalCostume from '../../../shared/Components/ElementModalCostume';

// Infraestructure
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';

// Styles
import './Style.scss';

interface PropsView {
    menu: Array<EntityMenu>;
    onClickAyuda: Function;
    onClickReport: Function;
    onClickMenu: Function;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    Notifications: EntityNotificacionTerminos[];
    changeCountryGuest: () => Promise<void>;
    user: EntityDataUsuario;
    onAcceptTerms: () => void;
};

export const ViewMain = (props: PropsView) => {
    const languageTranslate = LanguageTranslate();

    return (
        <div className="ViewMasterHome">
            <div className="container">
                {
                    props.menu.map(row => (
                        <div className="row pb-3" key={row.id}>
                            <div className="col-12">
                                <div className='shadow-lg' style={{ width: '100%', padding: 5, margin: 0, borderRadius: '10px', cursor: 'pointer' }} onClick={() => props.onClickMenu(row)}>
                                    <div className="row">
                                        <div className="col-2">
                                            <button className='btn btn-secondary btn-icon-first' style={{ marginTop: '5px' }}>
                                                <i className={`text-white ${row.icono}`} />
                                            </button>
                                        </div>
                                        <div className="col-8">
                                            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{languageTranslate.menu[row.codigo as keyof typeof languageTranslate.menu]?.titulo}</span>
                                            <br />
                                            <span style={{ fontSize: '10px' }}>{languageTranslate.menu[row.codigo as keyof typeof languageTranslate.menu]?.descripcion}</span>
                                        </div>
                                        <div className="col-2 position-relative">
                                            <button className='btn btn-lg btn-secondary text-white text-center'>
                                                {">"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                { props.menu?.length === 0 ? <ElementEmptyList text='Usuario sin módulos asignados' /> : null }
            </div>
            <ElementModalCostume 
                closeModal={() => props.setShowModal(false)}
                show={props.showModal}
                title={`Notificación (${props.Notifications.length})`}
                disabledButtonClose={props.Notifications[0]?.TipoNotificacion.IdTipoNotificacion === 1}
            >
                {
                    props.Notifications.map((row) => (
                        <div key={row.IdNotificacion}>
                            <label className='mb-4'> <strong> {row.TituloNotificacion} </strong> </label>
                            <div className='mb-3' dangerouslySetInnerHTML={{ __html: row.Contenido.Cuerpo }} />
                            { row.Contenido.Opciones.map(opcion => <div key={opcion} className='mb-3' style={{ fontWeight: 'bolder' }} dangerouslySetInnerHTML={{ __html: opcion }} />) }
                            <div className='mb-4' dangerouslySetInnerHTML={{ __html: row.Contenido.PiePagina }} />
                            <button type="submit" className="btn btn-primary" style={{ width: '100%', backgroundColor: '#01397D' }} onClick={props.onAcceptTerms}>
                                { row.Opciones[0].InnerHTML }
                            </button>
                        </div>
                    ))
                }
            </ElementModalCostume>

            {
                props.user.IdUsuario === 0 ?
                    <div className="position-fixed btn-change-country bg-primary" onClick={props.changeCountryGuest}>
                        <i className="fa-solid fa-earth-americas" style={{ fontSize: 20 }}/>
                    </div>
                : null
            }
        </div>
    )
};