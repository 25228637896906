export class AdapterChronometer {
    private timer: NodeJS.Timer | null = null;
    private secondsTimer: number = 1000;
    private sec: number = 0;
    private min: number = 0;
    private hrs: number = 0;
    private setTime: React.Dispatch<React.SetStateAction<{ sec: number; min: number; hrs: number; }>>;

    constructor(_setTime: React.Dispatch<React.SetStateAction<{ sec: number; min: number; hrs: number; }>>) {
        this.setTime = _setTime;
    }

    public start() {
        this.timer = null;
        this.timer = setInterval(() => {
            this.tick();
        }, this.secondsTimer);
    }

    public pause() {
        if (this.timer) clearTimeout(this.timer);
    }

    public reset() {
        this.pause();
        this.sec = 0;
        this.min = 0;
        this.hrs = 0;
        this.setTime({ sec: 0, min: 0, hrs: 0 });
    }
    
    public resetAndStart() {
        this.reset();
        this.start();
    }

    private tick() {
        this.sec++;
        const limit: number = 60;
        if (this.sec >= limit) {
            this.sec = 0;
            this.min++;
            if (this.min >= limit) {
                this.min = 0;
                this.hrs++;
            }
        }
        return this.setTime({ sec: this.sec, min: this.min, hrs: this.hrs });
    }
}