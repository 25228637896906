import { RepositoryMain } from "../Domain/RepositoryMain";
import { DtoRequestForm } from "../Domain/DtoRequestForm";
import { EntityAutochequeo } from "../../../shared/Domain/EntityAutochequeo";

export class useCaseSaveCierreControlPreventivo {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: DtoRequestForm): Promise<EntityAutochequeo | null> {
        return await this._exec(params);
    }

    private async _exec(params: DtoRequestForm): Promise<EntityAutochequeo | null> {
        return await this.repository.save(params, 2, 'CCP');
    }

}