import { EntityLoadDataGuest } from "../Domain/EntityUtils";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseLoadDataGuest {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(): Promise<EntityLoadDataGuest> {
        return (await this.repository.loadDataGuest());
    }
}