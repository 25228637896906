import './Page404.scss';
import logo from '../../../assets/img/IconosAppSGSOMovil/ic_nuevo2_round.png'
import { useNavigate } from 'react-router-dom';
import { LanguageTranslate } from '../Infraestructure/LanguageTranslate';

export const Page404 = () => {
    const navigate = useNavigate();
    const languageTranslate = LanguageTranslate();

    return <div className="page404">
        <div className="container">
            <div className="row pt-5">
                <div className="col-12 text-center">
                    <img src={logo} alt="Logo" className='img-fluid' width={100} />
                </div>
                <div className="col-12 text-center pt-3">
                    <h1>404</h1>
                    <h6>{languageTranslate.modulo404.textoDescripcion}</h6>
                </div>
            </div>
            <div className="row pt-3">
                <div className="col-12 text-center">
                    <button className='btn btn-outline-light' onClick={() => { navigate('/main/home') }}>{languageTranslate.modulo404.textoHome}</button>
                </div>
            </div>
        </div>
    </div>
}