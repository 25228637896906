import { useState } from "react"
import { EntityInformationDataInitial } from "../../../shared/Domain/EntityInformationDataInitial";
import { ElementItemCard } from "../../../shared/Components/ElementItemCard";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";

interface ContainerProps {
    title: string;
    reloadGroup: Function;
    reloadItem: Function;
    listCatalogo: string[];
    informationDataInitial: EntityInformationDataInitial;
    moduleKey: 'General' | 'ModuloInspecciones' | 'AutochequeoCP' | 'CharlaPretarea' | 'RDI';
}

const languageTranslate = LanguageTranslate();

export const ElementGroupModule = (props: ContainerProps) => {
    const [show, setShow] = useState(false);

    return (
        <div className="GroupListModule shadow-lg mb-3">
            <div className="HeaderGroup" onClick={() => setShow(!show)}>
                <div className="ContentHeader">
                    <div>
                        <span className="text-danger">Módulo: </span>
                        <span className="text-muted"> {props.title} </span>
                    </div>
                    <i className={`IconArrow ${show ? 'fa-solid fa-sort-up' : 'fa-solid fa-sort-down Active' }`} />
                </div>
            </div>
            {
                show ?
                    <div className="BodyGroup">
                        <button className=' btn btn-sm btn-primary w-auto btn-accion' onClick={() => props.reloadGroup(props.moduleKey)}>
                            <i className="fa-solid fa-refresh" style={{ paddingRight: '5px' }} />{languageTranslate.moduloCatalogo.textoRefrescarTodo}
                        </button>
                        {
                            props.listCatalogo.map(row => {
                                const item = props.informationDataInitial[row as keyof typeof props.informationDataInitial];
                                if (!item) return null;
                                return (
                                    <ElementItemCard
                                        key={row}
                                        textHeader={row}
                                        textSubHeader={
                                            <span style={{ fontSize: '13px', paddingLeft: '5px', color: 'black' }} onClick={() => props.reloadItem(row)}>
                                                <i className="fa-solid fa-refresh" style={{ fontSize: '14px', cursor: 'pointer', paddingRight: '10px' }} />{languageTranslate.moduloCatalogo.textoRefrescar}
                                            </span>
                                        }
                                        ago={!item.called ? '' : AdapterGenerico.calculoTiempo(item.date as Date, languageTranslate.code as 'es' | 'en' | 'fr' | 'it')}
                                        state={item.called ? languageTranslate.moduloCatalogo.textoSincronizado : languageTranslate.moduloCatalogo.textoNoSincronizado}
                                    />
                                )
                            }) 
                        }
                    </div>
                : null 
            }
        </div>
    )
}