import React, { useEffect, useState } from "react";
import { Recorder } from "vmsg";
import { ConcatBlobs } from "../Infraestructure/ConcatBlobs";
import { LanguageTranslate } from "../Infraestructure/LanguageTranslate";
import { AdapterGenerico } from "../Infraestructure/AdapterGenerico";
import { AdapterBlockScreen } from "../Infraestructure/AdapterBlockScreen";

interface Props {
    onChange: (name: string, value: any) => void;
    value: Blob | string | null;
    name: string;
    disabled?: boolean;
}

const recorder = new Recorder({ wasmURL: require('./../../../assets/vmsg/vmsg.wasm') });
const languageTranslate = LanguageTranslate();
const adapterBlockScreen = new AdapterBlockScreen();

const ElementAudioCostume = (props: Props) => {
    const [audio, setAudio] = useState<Blob | null>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isListen, setIsListen] = useState<{ audioListen: HTMLAudioElement | null; isReproduce: boolean; }>({ audioListen: null, isReproduce: false });
    const [loading, setLoading] = useState<Boolean>(false);
    const timerMinutesText = Timer();


    useEffect(() => {
        if (!props.value) return;
        // cargar cuando se renderice el componente la duración
        const audioParsed: HTMLAudioElement = new Audio();
        audioParsed.preload = "metadata";
        audioParsed.src = typeof props.value === 'string' ? props.value : URL.createObjectURL(props.value);
        audioParsed.onloadedmetadata = (evt) => {
            timerMinutesText.update(AdapterGenerico.convertirNumeroATiempo(audioParsed.duration))
        }
        audioParsed.load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!props.value) return;
        getInitialValue();
        const audioParsed: HTMLAudioElement = new Audio();
        audioParsed.preload = "metadata";
        audioParsed.src = typeof props.value === 'string' ? props.value : URL.createObjectURL(props.value);
        setIsListen({ audioListen: audioParsed, isReproduce: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value])

    useEffect(() => {
        if (document.hidden && isRecording) {
            recording(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document.hidden])

    const getInitialValue = async () => {
        if (typeof props.value === 'string') {
            try {
                const response = await fetch(props.value);
                if (response.ok) {
                    const audioData = await response.arrayBuffer();
                    const blob = new Blob([audioData], { type: 'audio/mpeg' });
                    ConcatBlobs([blob], updateAudio);
                }
            } catch(err) {}
        } else {
            setAudio(props.value)
        }
    }

    const verifiedPermissAudio = async (): Promise<{ status: boolean; message: string; }> => {
        return new Promise((resolve, reject) => {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia({ audio: true })
                    .then(stream => {
                        resolve({ status: true, message: 'Si tiene permiso' });
                    })
                    .catch(error => {
                        resolve({ status: false, message: 'No se pudo acceder al micrófono, configure los permisos del dispositivo' })
                    });
            } else {
                resolve({ status: false, message: 'El navgeador no soporta el micrófono' })
            }
        })
    }

    const recording = async (recording: boolean) => {
        if (recording) {
            try {
                const responsePermissAudio = await verifiedPermissAudio();
                if (!responsePermissAudio.status) return AdapterGenerico.createMessage('', responsePermissAudio.message, 'warning');
                await recorder.initAudio();
                await recorder.initWorker();
                recorder.startRecording();
                timerMinutesText.start();
                setIsRecording(true);
                adapterBlockScreen.active();
            } catch (err) {}
        } else {
            const blob = await recorder.stopRecording();
            const blobs_: Blob[] = [];
            if (audio) blobs_.push(audio);
            blobs_.push(blob);

            setLoading(true);
            ConcatBlobs(blobs_, updateAudio);
            timerMinutesText.stop();
            setIsRecording(false);
            adapterBlockScreen.stop();
        }
    }

    const updateAudio = (blob: Blob) => {
        setAudio(blob)
        setIsListen(() => ({ audioListen: new Audio(URL.createObjectURL(blob)), isReproduce: false }));
        props.onChange(props.name, blob);
        setLoading(false);
    }

    const deleteRecording = () => {
        if (isListen.isReproduce) isListen.audioListen?.pause();
        setAudio(null);
        setIsListen({ audioListen: null, isReproduce: false });
        timerMinutesText.reset();
        props.onChange(props.name, null)
    }

    const listen = () => {
        if (!audio) return;
        if (!isListen.audioListen) return;

        if (isListen.isReproduce) { 
            isListen.audioListen.pause();
            setIsListen((prev) => ({...prev, isReproduce: false}));
        } else { 
            isListen.audioListen.currentTime = 0;
            isListen.audioListen.play();
            setIsListen((prev) => ({...prev, isReproduce: true}));
            isListen.audioListen.onended = () => { setIsListen((prev) => ({...prev, isReproduce: false})); }
        }
    }

    return (
        <div className="d-flex justify-content-center align-items-center flex-wrap my-3 border p-2 rounded">
            {
                props.disabled ?
                    null
                    :
                    <h2 className="w-100 text-center">
                        {timerMinutesText.timeText}
                    </h2>
            }
            <>
                {
                    loading ?
                    <p style={{textAlign: 'center'}} className="text-muted">{languageTranslate.textoCargandoAudio}</p>
                    :
                    <>
                    <>
                        {
                            props.disabled ? null :
                            <>
                                {
                                    <div className='bg-primary rounded-circle text-white d-flex justify-content-center align-items-center mx-2' style={{ height: 40, width: 40 }}>
                                        {
                                            isRecording ?
                                                <i className="fa-solid fa-stop" onClick={() => recording(false)} style={{ fontSize: 15 }} /> :
                                                <i className="fa-solid fa-microphone" onClick={() => recording(true)} style={{ fontSize: 15 }} />
                                        }
                                    </div>
                                }
                                {
                                    !isRecording && audio && (
                                        <div className='bg-light rounded-circle text-white d-flex justify-content-center align-items-center mx-2' style={{ height: 40, width: 40 }}>
                                            <i className="fa-solid fa-trash" onClick={deleteRecording} style={{ fontSize: 15, color: 'black' }} />
                                        </div>
                                    )
                                }
                            </>
                        }
                    </>
                        {
                            !isRecording && audio &&
                            <>
                                <button onClick={listen} className='btn btn-dark w-75 my-3'>
                                    {
                                        isListen.isReproduce ?
                                        <>
                                            <i className="fa-solid fa-pause" style={{ marginRight: 4, fontSize: 15 }} />
                                            {languageTranslate.btnDetener}
                                        </>
                                        : 
                                        <>
                                            <i className="fa-solid fa-play" style={{ marginRight: 4, fontSize: 15 }} />
                                            {languageTranslate.btnReproducir}
                                        </>
                                    }
                                </button>
                            </>
                        }
                    </>
                }            
            </>
        </div>
    )
}

const Timer = (defaultTime: { sec: number, min: number, hrs: number } = { sec: 0, min: 0, hrs: 0 }) => {
    const [interv, setInterv] = useState<NodeJS.Timeout | null>(null);
    const [time, setTime] = useState(defaultTime);
    let { sec, min, hrs } = time;
    const timeText: string = `${(hrs > 9 ? hrs : `0${hrs}`) + ":" + (min > 9 ? min : `0${min}`) + ":" + (sec > 9 ? sec : `0${sec}`)}`;

    function tick() {
        sec++;
        const limit: number = 60;
        if (sec >= limit) {
            sec = 0;
            min++;
            if (min >= limit) {
                min = 0;
                hrs++;
            }
        }
        return setTime({ sec, min, hrs });
    }

    function run() {
        tick();
        start();
    }

    function start() {
        setInterv(setTimeout(run, 1000));
    }

    function stop() {
        if (interv) clearTimeout(interv);
    }

    function reset() {
        setTime({ sec: 0, min: 0, hrs: 0 })
    }

    function resetAndStart() {
        setTime({ sec: 0, min: 0, hrs: 0 })
        if (interv) clearTimeout(interv);
        setInterv(setTimeout(run, 1000));
    }

    function update(props: { sec: number; min: number; hrs: number }) {
        setTime(() => ({...props}));
    }

    return {
        start,
        stop,
        reset,
        resetAndStart,
        timeText,
        update
    }
}

export default ElementAudioCostume;