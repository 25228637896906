
//import { EntityListEmail } from "../Domain/EntityListEmail";
import { EntityList } from "../Domain/EntityList";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseEnvioCorreo {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async envio(params: any): Promise<EntityList[] | null> {
        return await this._envio(params);
    }

    private async _envio(params: any): Promise<EntityList[] | null> {
        return await this.repository.envioEmailInspecciones(params);
    }
}