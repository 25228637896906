import { AdapterStorage } from "./AdapterStorage";

export class AdapterCheckConnection {
    private weakConnectionTypes = ['slow-2g', '2g'];
    private threshold = 2000;
    private forceOffline = false;

    constructor() {
        const { user } = AdapterStorage.get(['user']);
        const { [`preference${user?.IdUsuario || 0}`]: preference } = AdapterStorage.get(`preference${user?.IdUsuario || 0}`);
        this.forceOffline = !!preference?.forceOffline;
    }

    public isStable(): boolean {
        // No forzar el modo offline en calidad o desarrollo
        // if (['quality', 'development'].includes(process.env.REACT_APP_PUBLISH_ENV || '')) return true;
        if (this.forceOffline) return false;
        if (navigator.onLine) {
            if (this.isConnectionTypeWeak()) return false;
            if (this.isLatencyHigh()) return false;
            return true;
        }
        return false;
    }

    private getConnectionType(): string | undefined {
        const connection = (navigator as any).connection || (navigator  as any).mozConnection || (navigator as any).webkitConnection;

        if (connection) {
          return connection?.type || connection.effectiveType;
        } else {
          return undefined;
        }
    }

    private isConnectionTypeWeak(): boolean {
        const connectionType = this.getConnectionType();
        return !!(connectionType && this.weakConnectionTypes.includes(connectionType))
    }

    private isLatencyHigh(): boolean {
        const connection = (navigator as any).connection || (navigator  as any).mozConnection || (navigator as any).webkitConnection;
        return (connection && connection.rtt && (connection.rtt > this.threshold))
    }
}

  
  

  