import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseDelete {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(_id: string): Promise<void> {
        return await this._exec(_id);
    }

    private async _exec(_id: string): Promise<void> {
        return await this.repository.deleteInspeccion(_id);
    }
}