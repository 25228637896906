import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { AdapterConfigure } from "./AdapterConfigure";
import { EntityList } from "../Domain/EntityList";
//import { EntityListEmail } from "../Domain/EntityListEmail";

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {

    public async deleteInspeccion(_id: string): Promise<void> {
        await this.dbLocal.deleteByIndexStore({ nameStore: 'Inspeccion', value: _id });
    }

    public async getList(params: any): Promise<EntityList[] | null> {
        return await this._getList(params);
    }

    private async _getList(params: any): Promise<EntityList[] | null> {
        let url: string = `${this.urlBase}${AdapterConfigure.INSPECCIONES_LIST}`;
        const response: any[] = await this.service.call<any>(
            "POST",
            url,
            JSON.stringify(params),
            "basic",
            "json",
            "json",
            {},
            0
            )        
        //await this.service.call<any>("POST", url, params, "basic", "json", 'json', {}, 0);//JSON.parse(await this.websocket.emit(AdapterConfigure.INSPECCIONES_LIST, params));
        let data: EntityList[] = await this.dbLocal.selectAllStore('Inspeccion');
        data = data.filter(row => [-1, -2].includes(row?.Estado?.IdEstado));
        response.push(...data);
        await this.dbLocal.clearStore('Inspeccion');
        await this.dbLocal.insertDataStore({ nameStore: 'Inspeccion', data: response });
        return response;
    }

    public async envioEmailInspecciones(params: any): Promise<EntityList[] | null> {
        return await this._envioEmailInspecciones(params);
    }

    private async _envioEmailInspecciones(params: any): Promise<EntityList[] | null> {

        let url : string = `${this.urlBase}${AdapterConfigure.ENVIAR_EMAIL_INSPECCIONES}`;
        
        const response: any[] = await this.service.call<any>(
            "POST",
            url,
            JSON.stringify(params),
            "basic",
            "json",
            "json",
            {},
            0
            )
        //await this.websocket.emit(AdapterConfigure.ENVIAR_EMAIL_INSPECCIONES, params)//JSON.parse();

        return response;
    }
}