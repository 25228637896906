import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseLogout {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: string, user: EntityDataUsuario): Promise<void> {
        return await this._exec(params, user);
    }

    private async _exec(params: string, user: EntityDataUsuario): Promise<void> {
        await this.repository.logout(params, user);
    }
}