// Library
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Dispatch } from 'redux';
import * as Yup from 'yup';

// Domain
import { EntityConfigForm, EntityDataForm, EntityLoadData, EntityLoadDataGuest, EntityModal, EntityModalImage, EntityModeForm, initEntityConfigForm, initEntityDataForm, initEntityLoadData, initEntityLoadDataGuest } from "../Domain/Utils";
import { EntitySelectBase } from "../../../shared/Domain/EntitySelectBase";
import { EntityPersonal } from "../../../shared/Domain/EntityPersonal";
import { DtoResponseForm } from "../Domain/DtoResponseForm";

// Infraestructure
import { addLoading, changeSaludo, removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
// import { AdapterGeolocation } from "../../../shared/Infraestructure/AdapterGeolocation";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { AdapterValidator } from "../../../shared/Infraestructure/AdapterValidator";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { AdapterConfigure } from "./AdapterConfigure";

// Use Case
import { UseCaseLoadData } from "../Application/useCaseLoadData";
import { UseCaseLoadDataGuest } from "../Application/useCaseLoadDataGuest";
import { EntityLiteOT } from "../../../shared/Domain/CatalogosLite/EntityLiteOT";
import { EntityOTs } from "../../../shared/Domain/EntityOTs";

const languageTranslate = LanguageTranslate();

export const Controller = () => {
    const { generico: { websocket, dbLocal }, auth: { user, preferencia, permisoVariables } } = useSelector((state: RootState) => state);
    const { modalData, selectNewImage, selectedIndex, setModalData, addNeWImage, addNewImageSubsanacion, deleteImage } = ControllerModal();
    const [selectInformation, setSelectInformation] = useState<EntityLoadData>(initEntityLoadData)
    const [selectInformationGuest, setSelectInformationGuest] = useState<EntityLoadDataGuest>(initEntityLoadDataGuest)
    const [configForm, setConfigForm] = useState<EntityConfigForm>(initEntityConfigForm);
    const isModeInvite = user.IdUsuario === AdapterConfigure.ID_USER_INVITE;
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const { formRegistro } = ControllerForm({ keyTab: configForm.keyTab, mode: configForm.mode });

    const onChange = (name: keyof EntityDataForm, value: any) => {

        switch (name) {
            case 'File':
                Array.from(value as FileList).forEach(file => {
                    addNeWImage({
                        type: 'Local',
                        valueToShow: (window.URL ? URL : webkitURL).createObjectURL(file),
                        file: file
                    })
                })
                return;
            case 'FileSubsanacion':
                Array.from(value as FileList).forEach(file => {
                    addNewImageSubsanacion({
                        type: 'Local',
                        valueToShow: (window.URL ? URL : webkitURL).createObjectURL(file),
                        file: file
                    })
                })
                break;
            case 'Pais':
                const payloadResetPais: any = {
                    Delegacion: null,
                    Empresa: null,
                    Subcontrata: null,
                    Cliente: null,
                    UsuarioMP: null
                }
        
                if (value?.dataComplete?.IdPais !== formRegistro.values.UsuarioRegistro?.dataComplete.IdPais) {
                    Object.assign(payloadResetPais, { UsuarioRegistro: null });
                } 
        
                formRegistro.setValues({ ...formRegistro.values, ...payloadResetPais });
            ;break;
            case 'OTGuest':
                const OTGuestSelected: EntitySelectBase<EntityLiteOT> | null = value;
                const payloadAutocompleteOTGuest: any = {
                    Pais: OTGuestSelected ? { label: OTGuestSelected.dataComplete.Pais, value: OTGuestSelected.dataComplete.IdPais, dataComplete: {} as any } : null,
                    Delegacion: OTGuestSelected ? { label: OTGuestSelected.dataComplete.Delegacion, value: OTGuestSelected.dataComplete.IdDelegacion, dataComplete: {} as any } : null,
                    Cliente: OTGuestSelected ? { label: OTGuestSelected.dataComplete.Cliente, value: OTGuestSelected.dataComplete.IdCliente, dataComplete: {} as any } : null,
                    Actividad: OTGuestSelected ? { label: OTGuestSelected.dataComplete.Actividad, value: OTGuestSelected.dataComplete.IdActividad, dataComplete: {} as any } : null
                }

                if (payloadAutocompleteOTGuest.Cliente) {
                    payloadAutocompleteOTGuest.Cliente = selectInformationGuest.Cliente.find(row => row.value === payloadAutocompleteOTGuest.Cliente.value) || null;
                }

                formRegistro.setValues({ ...formRegistro.values, ...payloadAutocompleteOTGuest })
            ;break;
            case 'TipoIncidencia':
                const CODIGO_CONDICION_INSEGURA = "C/S";
                const autoCompleteCondicionInsegura = CODIGO_CONDICION_INSEGURA === value?.value ? { value: 1, label: languageTranslate.btnSi, dataComplete: {} } : { value: 0, label: languageTranslate.btnNo, dataComplete: {} };
                formRegistro.setValues({ ...formRegistro.values, CondicionInsegura: autoCompleteCondicionInsegura });
            ;break;
            case 'OT':
                const OTSelected: EntitySelectBase<EntityOTs> | null = value;
                const payloadAutocompleteOT: any = {
                    Pais: OTSelected ? { label: OTSelected.dataComplete.Pais.Pais, value: OTSelected.dataComplete.Pais.IdPais, dataComplete: { IdPais: OTSelected.dataComplete.Pais.IdPais, Pais: OTSelected.dataComplete.Pais.Pais } } : null,
                    Delegacion: null,
                    Cliente: null,
                    Actividad: null
                };

                const findDelegacionFromOT = selectInformation.Delegacion.find(row => row.dataComplete.IdDelegacion === OTSelected?.dataComplete.Delegacion.IdDelegacion);
                const findClienteFromOT = selectInformation.Cliente.find(row => row.dataComplete.IdEmpresa === OTSelected?.dataComplete.Cliente.IdCliente);
                const findActividadFromOT = selectInformation.Actividad.find(row => row.dataComplete.IdActividad === OTSelected?.dataComplete.Actividad.IdActividad);

                payloadAutocompleteOT.Delegacion = findDelegacionFromOT || null;
                payloadAutocompleteOT.Cliente = findClienteFromOT || null;
                payloadAutocompleteOT.Actividad = findActividadFromOT || null;

                formRegistro.setValues({ ...formRegistro.values, ...payloadAutocompleteOT });
            ; break;
        }

        formRegistro.setFieldValue(name, value);
    };

    const onSubmit = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await formRegistro.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formRegistro.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }
            if ((modalData.images.length + modalData.imagesSubsanacion.length) > 10) throw Error(languageTranslate.moduloRDI.form.validate.formRegistro.cantidadImagenes);

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            let response: DtoResponseForm | null = null; 

            if (configForm.mode === 'levantamiento') {
                const filesSubsanacion = repository.formatterSaveDataImages(modalData.imagesSubsanacion);
                if (filesSubsanacion.File.length === 0 && configForm.mode === 'levantamiento') {
                    throw Error(languageTranslate.moduloRDI.form.validate.formRegistro.fotoSolucion)
                }
                response = await repository.saveRDILevantamiento(formRegistro.values, user, filesSubsanacion.File)
            } else {
                const RDI = await repository.formatterSaveDataRDI(formRegistro.values, user);
                const { File, listFotosCargadas } = repository.formatterSaveDataImages(modalData.images);
                const filesSubsanacion = repository.formatterSaveDataImages(RDI.ObservacionSolucion.Descripcion ? modalData.imagesSubsanacion : []);
                response = await repository.save({ File, listFotosCargadas, RDI, FileSubsanacion: filesSubsanacion.File }, configForm.mode)
            }

            if (response !== null)
                configForm.mode === 'levantamiento' ?
                    dbLocal.updateByIndexStore({ nameStore: 'RDI', value: response }) :
                    dbLocal.insertDataStore({ nameStore: 'RDI', data: response })

            dispatch(removeLoading());
            await AdapterGenerico.createMessage('', languageTranslate.moduloRDI.form.textMensajeAgradecimiento, 'success', false).then(result => {
                navigate(AdapterConfigure.REDIRECT_PATH_CANCEL);
            })
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        };
    };

    const onCancel = (e: Event) => {
        // mensaje aún tienes cambios, desea salir?
        navigate(AdapterConfigure.REDIRECT_PATH_CANCEL)
    }

    const init = async () => {
        try {
            dispatch(changeSaludo(false));
            params?.id ? modeEdit() : modeCreate();
        } catch (error) {
            window.location.reload();
        }
    };

    const modeCreate = async () => {
        
        if (user.IdUsuario !== AdapterConfigure.ID_USER_INVITE) {
            // Si el usuario no esta en modo invitado autocompletará los inputs de acuerdo a la data
            let loadData = await new UseCaseLoadData(repository).exec();
            if (permisoVariables.arrIdPaises.length > 0) {
                loadData.Pais = loadData.Pais.filter(row => permisoVariables.arrIdPaises.includes(row.dataComplete.IdPais));
                loadData.Empresa = loadData.Empresa.filter(row => permisoVariables.arrIdPaises.includes(row.dataComplete.Pais.IdPais));
                loadData.Cliente = loadData.Cliente.filter(row => permisoVariables.arrIdPaises.includes(row.dataComplete.Pais.IdPais));
                loadData.Subcontrata = loadData.Subcontrata.filter(row => permisoVariables.arrIdPaises.includes(row.dataComplete.Pais.IdPais));
                loadData.Delegacion = loadData.Delegacion.filter(row => permisoVariables.arrIdPaises.includes(row.dataComplete.Pais.IdPais));
            }

            if (permisoVariables.arrIdDelegaciones.length > 0) {
                loadData.Delegacion = loadData.Delegacion.filter(row => permisoVariables.arrIdDelegaciones.includes(row.dataComplete.IdDelegacion));
            }

            if (permisoVariables.arrIdGrupos.length > 0) {
                loadData.Empresa = loadData.Empresa.filter(row => permisoVariables.arrIdGrupos.includes(row.dataComplete.Grupo.IdGrupo));
                loadData.Cliente = loadData.Cliente.filter(row => permisoVariables.arrIdGrupos.includes(row.dataComplete.Grupo.IdGrupo));
                loadData.Subcontrata = loadData.Subcontrata.filter(row => permisoVariables.arrIdGrupos.includes(row.dataComplete.Grupo.IdGrupo));
                loadData.Delegacion = loadData.Delegacion.filter(row => permisoVariables.arrIdGrupos.includes(row.dataComplete.Grupo.IdGrupo));
                loadData.Personal = Object.entries(loadData.Personal).reduce((prev, [key, value]) => {
                    prev[key] = (value as EntitySelectBase<EntityPersonal>[]).filter(row => permisoVariables.arrIdGrupos.includes(row.dataComplete.Grupo))
                    return prev;
                }, {} as any)
            }

            setSelectInformation(loadData);
            formRegistro.setValues(await initialValuesModeCreate(loadData));
        } else {
            let loadData = await new UseCaseLoadDataGuest(repository).exec();
            if (permisoVariables.arrIdPaises.length > 0 && user.IdUsuario !== 0) {
                loadData.Subcontrata = loadData.Subcontrata.filter(row => permisoVariables.arrIdPaises.includes(row.dataComplete.IdPais));
            }
            // const Direccion = (await AdapterGeolocation.getAllGeolocation()).address;
            // formRegistro.setValues((prev) => ({ ...prev, Direccion }));
            setSelectInformationGuest((prev) => ({ ...loadData}) );
        }

        setConfigForm((prev) => ({ ...prev, mode: isModeInvite ? 'createGuest' : 'create' , loading: false }));
    }

    const onChangeContentForTab = async (newKeyTab: number) => {
        if (newKeyTab === configForm.keyTab) return;
        if (newKeyTab < configForm.keyTab) return setConfigForm((prev) => ({ ...prev, keyTab: newKeyTab }));;

        try {
            try { await formRegistro.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formRegistro.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }
            const tempKeyTab = (newKeyTab - 1) === configForm.keyTab ? newKeyTab : (configForm.keyTab + 1);
            AdapterGenerico.scrollTopByClassName('ViewInspeccionForm');
            return setConfigForm((prev) => ({ ...prev, keyTab: tempKeyTab }));;
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        }
    }

    const initialValuesModeCreate = async (data: EntityLoadData): Promise<EntityDataForm> => {
        const result: any = {
            ...initEntityDataForm
        };
        const _idInvalid = 0;
        // Object.assign(result, { Direccion: (await AdapterGeolocation.getAllGeolocation()).address });

        // si tiene preferencias asignadas
        if (user.IdUsuario !== _idInvalid) {
            Object.assign(result, { UsuarioRegistro: ([...data.Personal[user.PaisBase.IdPais], ...data.Personal[AdapterConfigure.KEY_ALL_ADMIN]]).find((item) => item.value === user.Identificacion) });
        }

        if (preferencia.ot) {
            Object.assign(result, { OT: preferencia.ot });
        }
        
        if (preferencia.pais) {
            const IdPaisPreference = (preferencia.pais as any).dataComplete.IdPais;
            Object.assign(result, { Pais: { ...preferencia.pais, value: IdPaisPreference } });
            user.IdUsuario !== _idInvalid && Object.assign(result, { UsuarioRegistro: ([...data.Personal[user.PaisBase.IdPais], ...data.Personal[AdapterConfigure.KEY_ALL_ADMIN]]).find((item) => item.value === user.Identificacion) });
        } else {
            if (user.IdUsuario === _idInvalid && permisoVariables.arrIdPaises[0]) {
                const findPais = data.Pais.find(row => row.dataComplete?.IdPais === permisoVariables.arrIdPaises[0])
                if (findPais) Object.assign(result, { Pais: { ...findPais } });
            }
        }

        if (preferencia.delegacion) {
            Object.assign(result, { Delegacion: preferencia.delegacion });
        }
        
        if (preferencia.ot) {
            if (user.IdUsuario!== _idInvalid) {
                // Object.assign(result, { Empresa: data.Empresa.find((item) => item.value === user.Empresa.IdEmpresa && item.dataComplete.Pais.Codigo === (preferencia.ot as any).dataComplete.Pais.Codigo) })
            }

            const findedOT = await repository.getOneOT((preferencia.ot as any).dataComplete._id);
            if (findedOT) {
                let Actividad = data.Actividad.find((item) => item.dataComplete?.IdActividad === findedOT.Actividad.IdActividad);
                if (!!Actividad) { Object.assign(result, { Actividad }) }

                let Cliente = data.Cliente.find((item) => item.dataComplete.NroDocumento === findedOT.Cliente.NroDocumento);
                if (!!Cliente) { Object.assign(result, { Cliente }) }
            }
        }

        if (preferencia.empresa)
            Object.assign(result, { Empresa: preferencia.empresa });

        result.IdAppLocal = `${user.Identificacion}_${Date.now()}`;
        return result;
    }

    const modeEdit = async () => {
        const rdiFinded: DtoResponseForm | null = await dbLocal.selectByIndexStore({ nameStore: 'RDI', value: params.id })
        if (rdiFinded === null) return;
        const formattedImages: EntityModalImage[] = rdiFinded.FilePath?.map((file) => ({
            type: 'BD',
            valueToShow: repository.formatterReadImage(rdiFinded.Procesos.Registrar.IdFecha, file),
            file
        }));

        const formattedImagesSubsanacion: EntityModalImage[] = rdiFinded.ObservacionSolucion.FilesLevantamiento?.map((file) => ({
            type: 'BD',
            valueToShow: repository.formatterReadImage(rdiFinded.Procesos.Registrar.IdFecha, file),
            file
        })) || [];

        const isModeLevantamiento = !!(rdiFinded.ObservacionSolucion?.IdEstado !== 1);
        if (isModeLevantamiento) {
            let loadData = await new UseCaseLoadData(repository).exec();
            setSelectInformation(loadData);
        }

        addNeWImage(formattedImages)
        addNewImageSubsanacion(formattedImagesSubsanacion);
        formRegistro.setValues(repository.formatterReadData(rdiFinded));
        setConfigForm((prev) => ({ ...prev, loading: false, mode: isModeInvite ? 'viewGuest' : isModeLevantamiento ? 'levantamiento' : 'view', keyTab: isModeLevantamiento ? 2 : 1 }))
    }

    return {
        setModalData,
        modalData,
        selectNewImage,
        selectedIndex,
        deleteImage,
        isModeInvite,

        formRegistro,
        selectInformation,
        selectInformationGuest,
        onChange,
        onSubmit,
        onCancel,
        configForm,

        init,
        onChangeContentForTab,
    };
};

const ControllerModal = () => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [modalData, setModalData] = useState<EntityModal>({
        images: [],
        imagesSubsanacion: [],
        show: false,
        type: 'images'
    });

    const selectNewImage = (newIndex: number) => {
        if (newIndex === selectedIndex) return;
        setSelectedIndex(newIndex);
    }

    useEffect(() => {
        setSelectedIndex(0);
    }, [modalData.show])

    const addNeWImage = (newImage: EntityModalImage | EntityModalImage[]) =>
        setModalData((prev) => ({ ...prev, images: [...prev.images, ...(Array.isArray(newImage) ? newImage : [newImage])] }));

    const addNewImageSubsanacion = (newImage: EntityModalImage | EntityModalImage[]) =>
        setModalData((prev) => ({ ...prev, imagesSubsanacion: [...prev.imagesSubsanacion, ...(Array.isArray(newImage) ? newImage : [newImage])] }));

    const deleteImage = (position: number = selectedIndex) => {
        const currentImages = modalData.type === 'images' ? modalData.images : modalData.imagesSubsanacion;
        currentImages.splice(position, 1);

        setModalData((prev) => ({ ...prev, [prev.type]: currentImages }))
        selectNewImage(0);
    }

    return {
        setModalData,
        modalData,
        selectNewImage,
        selectedIndex,
        addNeWImage,
        addNewImageSubsanacion,
        deleteImage
    }
}

const ControllerForm = (props: { keyTab: number; mode: EntityModeForm }) => {
    const languageTranslate = LanguageTranslate();

    const formRegistro = useFormik({
        initialValues: initEntityDataForm,
        validationSchema: Yup.object({

            // tab 1 - GUEST
            OTGuest: Yup.object().when([], { is: () => (props.keyTab === 1 && props.mode === 'createGuest'), then: Yup.object().required(languageTranslate.moduloRDI.form.validate.formRegistro.ot).nullable() }).nullable(),
            UsuarioRegistroGuest: Yup.object().nullable(),

            // tab 1
            Pais: Yup.object().when([], { is: () => (props.keyTab === 1 && props.mode === 'create'), then: Yup.object().required(languageTranslate.moduloRDI.form.validate.formRegistro.ot).nullable() }).nullable(),
            Delegacion: Yup.object().when([], { is: () => (props.keyTab === 1 && props.mode === 'create'), then: Yup.object().required(languageTranslate.moduloRDI.form.validate.formRegistro.delegacion).nullable() }).nullable(),
            Empresa: Yup.object().when([], { is: () => (props.keyTab === 1 && props.mode === 'create'), then: Yup.object().required(languageTranslate.moduloRDI.form.validate.formRegistro.empresa).nullable() }).nullable(),
            Cliente: Yup.object().when([], { is: () => (props.keyTab === 1 && props.mode === 'create'), then: Yup.object().required(languageTranslate.moduloRDI.form.validate.formRegistro.cliente).nullable() }).nullable(),
            Actividad: Yup.object().when([], { is: () => (props.keyTab === 1 && props.mode === 'create'), then: Yup.object().required(languageTranslate.moduloRDI.form.validate.formRegistro.actividad).nullable() }).nullable(),
            UsuarioRegistro: Yup.object().when([], { is: () => (props.keyTab === 1 && props.mode === 'create'), then: Yup.object().required(languageTranslate.moduloRDI.form.validate.formRegistro.usuarioRegistro).nullable() }).nullable(),

            // tab 2
            FechaIncidente: Yup.string().when([], { is: () => props.keyTab === 2, then: Yup.string().required(languageTranslate.moduloRDI.form.validate.formRegistro.fechaIncidente).nullable() }).nullable(),
            TipoIncidencia: Yup.object().when([], { is: () => props.keyTab === 2, then: Yup.object().required(languageTranslate.moduloRDI.form.validate.formRegistro.tipoIncidencia).nullable() }).nullable(),
            BreveDescripcionIncidente: Yup.string().when([], { is: () => props.keyTab === 2, then: Yup.string().required(languageTranslate.moduloRDI.form.validate.formRegistro.breveDescripcionIncidente).nullable() }).nullable(),
            FactorRiesgo: Yup.object().when([], { is: () => props.keyTab === 2, then: Yup.object().required(languageTranslate.moduloRDI.form.validate.formRegistro.factorRiesgo).nullable() }).nullable(),
            TipoIncidente: Yup.object().when([], { is: () => props.keyTab === 2, then: Yup.object().required(languageTranslate.moduloRDI.form.validate.formRegistro.tipoIncidente).nullable() }).nullable(),
            CondicionInsegura: Yup.object().when([], { is: () => props.keyTab === 2, then: Yup.object().required(languageTranslate.moduloRDI.form.validate.formRegistro.condicionInsegura).nullable() }).nullable(),
            VehiculoAfectado: Yup.object().when([], { is: () => props.keyTab === 2, then: Yup.object().required(languageTranslate.moduloRDI.form.validate.formRegistro.vehiculoAfectado).nullable() }).nullable(),

            // tab 2 - solucion
            DescripcionSolucion: Yup.string().when([], { is: () => (props.keyTab === 2 && props.mode === 'levantamiento'), then: Yup.string().required(languageTranslate.moduloRDI.form.validate.formRegistro.descripcionSolucion).nullable() }).nullable(),
            MedidasPropuestas: Yup.string().when([], { is: () => (props.keyTab === 2 && props.mode === 'levantamiento'), then: Yup.string().required(languageTranslate.moduloRDI.form.validate.formRegistro.propuestaSolucion).nullable() }).nullable(),
            UsuarioMP: Yup.object().when([], { is: () => (props.keyTab === 2 && props.mode === 'levantamiento'), then: Yup.object().required(languageTranslate.moduloRDI.form.validate.formRegistro.responsableSolucion).nullable() }).nullable(),
        }),

        onSubmit: (values, formikHelpers) => { },
        enableReinitialize: true
    });

    return { formRegistro }
}