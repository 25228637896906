import { Suspense } from "react";
import { BrowserRouter, Navigate, Route } from "react-router-dom"
import { AuthGuard } from "./AuthGuard"
import { RoleGuard } from "./RoleGuard";
import { ElementLoader } from "../../../context/shared/Components/ElementLoader"
import { ElementLoaderLazy } from "../../../context/shared/Components/ElementLoaderLazy";
import { RoutesWithNotFound } from "../../../context/shared/Components/RoutesWithNotFound"

//Module Master
import ViewMasterLogin from '../../../context/Master/Login'
import ViewMasterRecuperarPassword from '../../../context/Master/RecuperarPassword'
import ViewMasterMain from '../../../context/Master/Main';
import ViewMasterProfile from '../../../context/Master/Profile';
import ViewMasterVariable from '../../../context/Master/Variable';
import ViewMasterNotification from '../../../context/Master/Notification'

//Module Home
import ViewMasterHome from '../../../context/Master/Home';

//Module RDI
import ViewRDIListar from '../../../context/RDI/Listar';
import ViewRDIForm from '../../../context/RDI/Form';

//Module Inspeccion
import ViewInspeccionList from '../../../context/Inspeccion/List';
import ViewInspeccionForm from '../../../context/Inspeccion/Form';

//Module Autochequeo
import ViewAutoChequeo from '../../../context/Autochequeo/Form';

//Modulo GestionAutochequeo
import ViewGestionAutoChequeo from '../../../context/Autochequeo/List';

export const AppRoutes = () => {
    return (
        <Suspense fallback={<ElementLoaderLazy />}>
            <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
                <ElementLoader />
                <RoutesWithNotFound>
                    <Route path="" element={<Navigate to={`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`} />} />
                    <Route path={`${process.env.REACT_APP_ROUTE_PATH_LOGIN}`} element={<AuthGuard element={<ViewMasterLogin />} privateContent={false} />} />
                    <Route path={`${process.env.REACT_APP_ROUTE_PATH_RECOVERPASSWORD}`} element={<AuthGuard element={<ViewMasterRecuperarPassword />} privateContent={false} />} />
                    <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN}`} element={<AuthGuard element={<ViewMasterMain />} privateContent={true} />}>
                        <Route path={``} element={<ViewMasterHome />} />
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_HOME}`} element={<ViewMasterHome />} />
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_PROFILE}`} element={<ViewMasterProfile />} />
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_VARIABLE}`} element={<ViewMasterVariable />} />
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_NOTIFICATION}`} element={<ViewMasterNotification />} />
                        <Route element={<RoleGuard />}>
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_RDI}`}>
                                <Route path={``} element={<ViewRDIListar />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_RDI_FORM}`} element={<ViewRDIForm />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_RDI_FORM}/:id`} element={<ViewRDIForm />} />
                            </Route>
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_INSPECCION}`}>
                                <Route path={``} element={<ViewInspeccionList />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_INSPECCION_FORM}`} element={<ViewInspeccionForm />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_INSPECCION_FORM}/:id`} element={<ViewInspeccionForm />} />
                            </Route>
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_OPS}`}>
                                <Route path={``} element={<ViewInspeccionList />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_OPS_FORM}`} element={<ViewInspeccionForm />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_OPS_FORM}/:id`} element={<ViewInspeccionForm />} />
                            </Route>
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_AUTOCHEQUEO}`} element={<ViewAutoChequeo />} />
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_CHARLA_PRETAREA}`} element={<ViewAutoChequeo />} />
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_CONTROLPREVENTIVO}`} element={<ViewAutoChequeo />} />
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_CIERRE_CONTROLPREVENTIVO}/:id`} element={<ViewAutoChequeo />} />
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_GESTIONAUTOCHEQUEO}`} element={<ViewGestionAutoChequeo />} />
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_GESTIONAUTOCHEQUEO}/:id`} element={<ViewAutoChequeo />} />
                        </Route>
                    </Route>
                </RoutesWithNotFound>
            </BrowserRouter>
        </Suspense>
    )
}