import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AdapterStorage } from "../../../shared/Infraestructure/AdapterStorage";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import {
  changePermisoVariable,
  signOut,
} from "../../../shared/Infraestructure/SliceAuthentication";
import {
  changeCountNotifications,
  hideIconMenu,
  showIconMenu,
} from "../../../shared/Infraestructure/SliceGenerico";
import { UseCaseLogout } from "../Application/UseCaseLogout";
import { AdapterConfigure } from "./AdapterConfigure";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";

export const Controller = () => {
  const { user, auth } = useSelector((state: RootState) => state.auth);
  const { iconMenu, showSaludo, websocket, dbLocal, online, countProcess, countNotifications } =
    useSelector((state: RootState) => state.generico);
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch = useDispatch();
  const languageTranslate = LanguageTranslate();

  const repository: RepositoryImplMain = new RepositoryImplMain(
    websocket,
    dbLocal,
    dispatch,
    AdapterConfigure.SCHEMA,
    AdapterConfigure.ENTITY
  );

  const init = async () => {};

  const onClickHome = async () => {
    try {
      navigate(
        `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_HOME}`,
        { replace: true }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onClickHelp = async () => {
    try {
      let confirmacion: boolean = await AdapterGenerico.createMessage(
        languageTranslate.moduloMain.textoTituloPreguntaAyuda,
        languageTranslate.moduloMain.textoDescripcionPreguntaAyuda,
        "question",
        true
      );
      if (!confirmacion) return;
      window.open(
        "https://www.cobraperu.com.pe/helpcenter/sistema-sgso/",
        "_blank"
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onClickReport = async () => {
    try {
      let confirmacion: boolean = await AdapterGenerico.createMessage(
        languageTranslate.moduloMain.textoTituloPreguntaReporte,
        languageTranslate.moduloMain.textoDescripcionPreguntaReporte,
        "question",
        true
      );
      if (!confirmacion) return;
      window.open("https://www.cobraperu.com.pe/glpi/", "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const onClickProfile = async () => {
    try {
      navigate(
        `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROFILE}`,
        { replace: true }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onClickNotification = async () => {
    try {
      navigate(
        `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_NOTIFICATION}`,
        { replace: true }
      );
    } catch (error) {
      console.error(error);
    }
  }

  const onClickVariable = async () => {
    try {
      navigate(
        `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_VARIABLE}`,
        { replace: true }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onClickLogout = async () => {
    let { token }: { token: string } = AdapterStorage.get("token");
    new UseCaseLogout(repository).exec(token, user);
    dispatch(hideIconMenu());
    dispatch(signOut());
    dispatch(
      changePermisoVariable({
        arrIdPaises: [],
        arrIdGrupos: [],
        arrIdDelegaciones: [],
        arrIdOT: [],
      })
    );
    dispatch(
      changeCountNotifications(0)
    );
    navigate(`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`, { replace: true });
  };

  const onShowIconMenu = () => {
    dispatch(showIconMenu());
  };

  const onHideIconMenu = () => {
    dispatch(hideIconMenu());
  };

  const onClickReloadApp = () => {
    window.history.pushState({}, '', `${window.location.pathname}?isForceApp=true`)
    window.location.reload()
  }

  const end = async () => {};

  return {
    auth,
    user,

    iconMenu,
    showSaludo,

    online,
    countProcess,
    countNotifications,

    init,
    end,

    onClickHome,
    onClickHelp,
    onClickReport,
    onClickProfile,
    onClickVariable,
    onClickLogout,
    onShowIconMenu,
    onHideIconMenu,
    onClickNotification,
    onClickReloadApp,
  };
};
