import { EntityDataUsuario } from "../Domain/EntityDataUsuario";
import { AdapterStorage } from "./AdapterStorage";

interface ContainerProps {
  serviceName: string;
  countRegister: number;
}

export const AdapterMetrics = () => {
  let startTime: number = 0;
  const startMetric = () => (startTime = performance.now());
  const endMetric = (props: ContainerProps) => {
    const user: EntityDataUsuario = AdapterStorage.get(["user"]).user;
    const endTime = performance.now();
    const payload = {
      service: props.serviceName,
      time: Number((endTime - startTime).toFixed(2)),
      count: props.countRegister,
      date: new Date(),
      user: {
        Nombres: user?.Nombres ?? "",
        ApellidoMaterno: user?.ApellidoMaterno ?? "",
        ApellidoPaterno: user?.ApellidoPaterno ?? "",
        Identificacion: user?.Identificacion ?? "",
        IdUsuario: user?.IdUsuario ?? "",
      },
      pais: {
        IdPais: user?.PaisBase.IdPais ?? "",
        Pais: user?.PaisBase.Pais ?? "",
      },
    };

    try {
      // var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
      // let url = `${process.env.REACT_APP_URL_MASTER}/register/datosConsumo`
      // let options: RequestInit = {
      //     method: 'POST',
      //     body: JSON.stringify(payload),
      //     headers:myHeaders
      // }
      // fetch(url, options)
    } catch (error) {}
  };

  return {
    startMetric,
    endMetric,
  };
};
