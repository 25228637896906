import { RepositoryMain } from "../Domain/RepositoryMain";
import { DtoRequestForm } from "../Domain/DtoRequestForm";
import { EntityAutochequeo } from "../../../shared/Domain/EntityAutochequeo";
import { KeyModule } from "../Domain/EntityModuleForm";

export class useCaseSaveListaAutochequeo {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: DtoRequestForm, modeForm: KeyModule): Promise<EntityAutochequeo | null> {
        return await this._exec(params, modeForm);
    }

    private async _exec(params: DtoRequestForm, modeForm: KeyModule): Promise<EntityAutochequeo | null> {
        return await this.repository.save(params, 1, modeForm);
    }

}