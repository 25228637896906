import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { EntityLoadData } from "../Domain/EntityUtils";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseLoadData {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(user: EntityDataUsuario, permisoVariabls: {
        arrIdPaises: number[];
        arrIdGrupos: number[];
        arrIdDelegaciones: number[];
        arrIdOT: number[];
    }): Promise<EntityLoadData> {
        return await this.repository.loadData(user, permisoVariabls);
    }
}