import { EntityEPCS } from "../../../shared/Domain/Catalogos/EntityEPCS";
import { EntityMedidaPreventivas } from "../../../shared/Domain/Catalogos/EntityMedidaPreventivas";
import { EntityOpcionAudio } from "../../../shared/Domain/Catalogos/EntityOpcionAudio";
import { EntityTipoRiesgo } from "../../../shared/Domain/Catalogos/EntityTipoRiesgo";
import { EntityLiteEmpresa } from "../../../shared/Domain/CatalogosLite/EntityLiteEmpresa";
import { EntityLiteOT } from "../../../shared/Domain/CatalogosLite/EntityLiteOT";
import { EntityLitePersonal } from "../../../shared/Domain/CatalogosLite/EntityLitePersonal";
import { EntityActividad } from "../../../shared/Domain/EntityActividad";
import { EntityDelegacion } from "../../../shared/Domain/EntityDelegacion";
import { EntityEmpresa } from "../../../shared/Domain/EntityEmpresa";
import { EntityOTs } from "../../../shared/Domain/EntityOTs";
import { EntityPersonal } from "../../../shared/Domain/EntityPersonal";
import { EntitySelectBase } from "../../../shared/Domain/EntitySelectBase";

export interface EntityFormIncomplete {
    _id: string;
    dataIncomplete: any;
    Estado: {
        Estado: string;
        IdEstado: number;
    };
    module: string;
}

export type keysInputsDynamic = 'respuesta' | 'observacion' | 'charlaPreTarea' | 'personal' | 'images' | 'estadoEquipo' | 'riesgos' | 'medidasPreventivas' | 'epcs' | 'otroEPCS' | 'otroRiesgo' | 'otroMedidasPreventivas';

export interface EntityLoadDataGuest {
    OTLite: EntitySelectBase<EntityLiteOT>[];
    PersonalLite: EntitySelectBase<EntityLitePersonal>[];
    PersonalJefeObra: EntitySelectBase<EntityLitePersonal>[];
    SubContrataLite: EntitySelectBase<EntityLiteEmpresa>[];
}

export const initEntityLoadDataGuest: EntityLoadDataGuest = {
    OTLite: [],
    PersonalLite: [],
    PersonalJefeObra: [],
    SubContrataLite: []
}

export interface EntityLoadData {
    Delegacion: EntitySelectBase<EntityDelegacion>[];
    Empresa: EntitySelectBase<EntityEmpresa>[];
    Subcontrata: EntitySelectBase<EntityEmpresa>[];
    Cliente: EntitySelectBase<EntityEmpresa>[];
    Actividad: EntitySelectBase<EntityActividad>[];
    PersonalJefeObra: EntitySelectBase<EntityPersonal>[];
    OT: EntitySelectBase<EntityOTs>[];
    Personal: EntitySelectBase<EntityPersonal>[];
    EPCS: { label: string; value: any; dataComplete: EntityEPCS }[];
    MedidasPreventivas: { label: string; value: any; dataComplete: EntityMedidaPreventivas }[];
    TipoRiesgo: { label: string; value: any; dataComplete: EntityTipoRiesgo }[];
    OpcionAudio: { label: string; value: string; dataComplete: EntityOpcionAudio; }[]
}

export const initEntityLoadData: EntityLoadData = { Actividad: [],
    Cliente: [],
    Delegacion: [],
    Empresa: [],
    PersonalJefeObra: [],
    Subcontrata: [],
    OT: [],
    Personal: [],
    EPCS: [],
    MedidasPreventivas: [],
    TipoRiesgo: [],
    OpcionAudio: []
}

export interface EntityDataForm {

    // Autocomplete
    Pais: EntitySelectBase<{ Pais: string; IdPais: number }>
    RecursoPreventivo: EntitySelectBase<EntityPersonal>[];

    // tab 1
    OrdenServicio: string;
    OT: EntitySelectBase<EntityOTs>;
    Tipologia: Array<any>;

    // tab 2 - General
    Obra: string;
    Lugar: string;
    TrabajoRealizar: string;

    // tab2 - With auth
    Empresa: EntitySelectBase<EntityEmpresa>;
    SubContrata: EntitySelectBase<EntityEmpresa>[];
    Delegacion: EntitySelectBase<EntityDelegacion>;
    Actividad: EntitySelectBase<EntityActividad>;
    Cliente: EntitySelectBase<EntityEmpresa>;
    JefeObra: EntitySelectBase<EntityPersonal>;

    // tab 2 - Without auth
    OTGuest: EntitySelectBase<EntityLiteOT>;
    SubContrataGuest: EntitySelectBase<EntityLiteEmpresa>[];
    JefeObraGuest: EntitySelectBase<EntityLitePersonal>;
    UsuarioRegistraGuest: EntitySelectBase<EntityLitePersonal>;

    // quest
    grupoPreguntas: any;
    grupoTitulos: any;
    Riesgos: any[];

    IdMomento?: number;

    FechaRegistro: {
        Fecha: string;
        IdFecha: number;
    }
}

export const initEntityDataForm: EntityDataForm = {
    Pais: null as any,
    RecursoPreventivo: [],
    OrdenServicio: "",
    OT: null as any,
    Tipologia: [],
    Obra: "",
    Lugar: "",
    TrabajoRealizar: "",
    Empresa: null as any,
    SubContrata: [],
    Delegacion: null as any,
    Actividad: null as any,
    Cliente: null as any,
    JefeObra: null as any,
    OTGuest: null as any,
    SubContrataGuest: [],
    JefeObraGuest: null as any,
    UsuarioRegistraGuest: null as any,
    grupoPreguntas: {},
    grupoTitulos: {},
    Riesgos: [],
    FechaRegistro: {
        Fecha: '',
        IdFecha: 0
    }
}

export type EntityConfigModalComentarios = {
    show: boolean;
    disabledFunctions: boolean;
    comentarios: {
        Autor: {
            ApellidoMaterno: string;
            ApellidoPaterno: string;
            Apellidos: string;
            IdUsuario: number;
            Identificacion: string;
            Nombres: string;
            Usuario: string;
        };
        Comentario: string;
        Fecha: {
            Fecha: string;
            IdFecha: string;
            TimeZone: number;
        };
        Puntuacion: number;
    }[];
    bitacora: {
        Accion: string;
        Fecha: {
            IdFecha: number;
            Fecha: string;
        };
        DatosUsuario: {
            IdUsuario: number;
            Usuario: string;
            Identificacion: string;
            Nombres: string;
            ApellidoPaterno: string;
            ApellidoMaterno: string;
        }
        Datos: {}
    }[]
    form: {
        _id: string;
        Id: number;
        Comentario: string;
        Puntuacion: number;
        Notificacion: boolean;
    };
    list: {
        show: boolean;
        feedback: boolean;
    }
}

export const initEntityConfigModalComentarios: EntityConfigModalComentarios = {
    show: false,
    disabledFunctions: false,
    comentarios: [],
    form: {
        _id: "",
        Comentario: "",
        Puntuacion: 0,
        Id: 0,
        Notificacion: false
    },
    bitacora: [],
    list: {
        show: false,
        feedback: false
    }
}