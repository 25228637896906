import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Component/View";


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        return () => {
        }
        // eslint-disable-next-line
    }, []);

    return (
        <ViewMain
            redirectItem={controller.redirectItem}
            listNotification={controller.listNotification}
            isListNoRead={controller.isListNoRead}
            onChangeReadNotification={controller.onChangeReadNotification}
            reloadData={controller.reloadData}
        />
    );
};

export default ManagerEntity;