const SCHEMA: string = 'Maestro';
const ENTITY: string = 'Pais';
const REDIRECT_LIST: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_HOME}`;
const SAVE_LISTAAUTOCHEQUEO:string = '/send/AutochequeoPWA';
const SAVE_CONTROLPREVENTIVO:string = '';
const LIST_CONTROLPREVENTIVO = "/search/ControlPreventivo"; //:string = 'SelectControlPreventivo_process';
const CONTROLPREVENTIVO_DONWLOAD_PDF:string = '/download/PdfAutochequeo';
const CONTROLPREVENTIVO_DONWLOAD:string = '/download/Imagen';
const REDIRECT_CIERRECP: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_CIERRE_CONTROLPREVENTIVO}`;
const REDIRECT_GESTION: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GESTIONAUTOCHEQUEO}`;
const REDIRECT_PREVIEW: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GESTIONAUTOCHEQUEO}`;
const REDIRECT_CPT: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_CHARLA_PRETAREA}`;
const COLOR_CALIFICACION: any = {
    1: 'red',
    2: 'red',
    3: '#e4c01b',
    4: 'green',
    5: 'green'
}
const COUNT_MAX_LIST = 200;

export const AdapterConfigure = {
    REDIRECT_LIST,
    SCHEMA,
    ENTITY,
    SAVE_LISTAAUTOCHEQUEO,
    SAVE_CONTROLPREVENTIVO,
    LIST_CONTROLPREVENTIVO,
    CONTROLPREVENTIVO_DONWLOAD_PDF,
    CONTROLPREVENTIVO_DONWLOAD,
    REDIRECT_CIERRECP,
    REDIRECT_GESTION,
    REDIRECT_PREVIEW,
    REDIRECT_CPT,
    COLOR_CALIFICACION,
    COUNT_MAX_LIST,
};