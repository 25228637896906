const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';
const ROUTE_PREVIEW: string = '/main/RDI/form';
const RDI_LIST: string = '/search/ReporteIncidentes';
const DOWNLOAD_PDF: string = '/download/PdfRDI';
const DOWNLOAD_PDF_NEWBACKEND: string = '/SYSInspecciones/ReporteIncidentes/downloadPDF'

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    ROUTE_PREVIEW,
    RDI_LIST,
    DOWNLOAD_PDF,
    DOWNLOAD_PDF_NEWBACKEND
};