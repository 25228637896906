import { RepositoryMain } from "../Domain/RepositoryMain";

export class useCaseSearchCuadrillaPersonal {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(dni: string): Promise<string[]> {
        return await this._exec(dni);
    }

    private async _exec(dni: string): Promise<string[]> {
        return await this.repository.searchCuadrillaPersonal(dni);
    }

}