import { idioma_en } from "./idioma_en";
import { idioma_es } from "./idioma_es";
import { idioma_fr } from "./idioma_fr";
import { idioma_it } from "./idioma_it";
import { idioma_pt } from "./idioma_pt";

export const LIST_LANGUAGE: any = {
    es: idioma_es,
    en: idioma_en,
    it: idioma_it,
    fr: idioma_fr,
    pt: idioma_pt
}