// Library
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormikProps, useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';

// Domain
import { EntityAutochequeoItems, EntityAutochequeoItemsDisponibilidad, EntityAutochequeoItemsTipologia } from "../../../shared/Domain/EntityAutochequeoItems";
import { EntityAutochequeoTitulos } from "../../../shared/Domain/EntityAutochequeoTitulos";
import { EntityOTs, EntitySelectOTs } from "../../../shared/Domain/EntityOTs";
import { EntityExternalConditionals } from "../Domain/EntityConditionals";
import { EntityLoadQuestion } from "../Domain/EntityLoadQuestion";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityTipologiaCP } from "../Domain/EntityTipologiaCP";
import { EntityModuleForm, KeyModule } from "../Domain/EntityModuleForm";
import { EntityPersonal, EntitySelectPersonal } from "../../../shared/Domain/EntityPersonal";
import { EntityAutochequeoTipologia } from "../../../shared/Domain/EntityAutochequeoTipologia";
import { EntityConfigModalComentarios, EntityDataForm, EntityLoadData, EntityLoadDataGuest, initEntityConfigModalComentarios, initEntityDataForm, initEntityLoadData, initEntityLoadDataGuest, keysInputsDynamic } from "../Domain/EntityUtils";
import { EntityAutochequeo } from "../../../shared/Domain/EntityAutochequeo";

// Infraestructure
import { addLoading, changeSaludo, removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { AdapterValidator } from "../../../shared/Infraestructure/AdapterValidator";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
// import { AdapterGeolocation } from "../../../shared/Infraestructure/AdapterGeolocation";
import { changePreference } from "../../../shared/Infraestructure/SliceAuthentication";
import { RepositoryImplMain } from "./RepositoryImplMain";

// Adapters
import { AdapterConfigure } from "./AdapterConfigure";

// Use Case
import { UseCaseLoadData } from "../Application/useCaseLoadData";
import { UseCaseLoadQuestionsAUT } from "../Application/useCaseLoadQuestionsAUT";
import { useCaseSaveListaAutochequeo } from "../Application/useCaseSaveListaAutochequeo";
import { UseCaseLoadTipologiaCP } from "../Application/UseCaseLoadTipologiaCP";
import { UseCaseLoadQuestionsCP } from "../Application/useCaseLoadQuestionsCP";
import { UseCaseLoadQuestionsCCP } from "../Application/useCaseLoadQuestionsCCP";
import { useCaseSaveCierreControlPreventivo } from "../Application/useCaseSaveCierreControlPreventivo";
import { UseCaseLoadQuestionsCPT } from "../Application/useCaseLoadQuestionsCPT";
import { AdapterData } from "../../../shared/Infraestructure/AdapterData";
import { UseCaseLoadDataGuest } from "../Application/useCaseLoadDataGuest";
import { EntitySelectBase } from "../../../shared/Domain/EntitySelectBase";
import { EntityLiteOT } from "../../../shared/Domain/CatalogosLite/EntityLiteOT";

let prevRegister: EntityAutochequeo | null;
let currentRegister: EntityAutochequeo | null;
let isRegisterIncomplete: boolean = false;
const languageTranslate = LanguageTranslate();

export const Controller = () => {
    const [selectInformation, setSelectInformation] = useState<EntityLoadData>(initEntityLoadData);
    const [selectInformationGuest, setSelectInformationGuest] = useState<EntityLoadDataGuest>(initEntityLoadDataGuest);
    const { generico: { dbLocal, websocket }, auth: { user, preferencia, permisoVariables } } = useSelector((state: RootState) => state);
    const [configModalComentarios, setConfigModalComentarios] = useState<EntityConfigModalComentarios>(initEntityConfigModalComentarios);
    const [moduleForm, setModuleForm] = useState<EntityModuleForm>({ key: '', title: '' });
    const [navbarModule, setNavbarModule] = useState<{ navigate: boolean; path: string; text: string; }[]>([]);
    const [loading, setLoading] = useState(false);
    const [keyTab, setKeyTab] = useState(-99);
    const [generateId, setGenerateId] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    
    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const { formRegistro, onChange, autoCompleteOnSelectOT } = ControllerForm({ keyTab, repository, personal: preferencia.personal, selectInformation, moduleForm });
    const { loadQuestions, conditionals, validatedValues, listQuestionShow, titleQuestionGroupToDisplay, question, tipologiaCP } = ControllerFormDynamic({ keyTab, setKeyTab, OT: formRegistro.values.OT || formRegistro.values.OTGuest, repository, formRegistroMain: formRegistro, moduleForm, setSelectInformation, selectInformation })
    const arrPathname = window.location.pathname.split('/');
    const validationPathName = (module: string) => arrPathname.some(item => item === module);

    const verifyModule = async (): Promise<EntityModuleForm> => {
        const languageTranslateForm = languageTranslate.moduloAutoChequeo.form;
        let result: EntityModuleForm = { key: '', title: '' };
        const loadOptions = await new UseCaseLoadData(repository).exec(user, permisoVariables);
        
        // Autocompletado de fecha de registro
        const autoCompleteFechaRegistro = () => {
            const FechaInspeccion = AdapterGenerico.getFechaISO_Local().substring(0, 19);
            const IdFecha = AdapterGenerico.ObtenerIdFechaISO(FechaInspeccion);
            return {
                IdFecha: IdFecha,
                Fecha: FechaInspeccion
            }
        }
        
        // EJECUTAR
        formRegistro.setFieldValue('FechaRegistro', autoCompleteFechaRegistro());

        if (validationPathName(process.env.REACT_APP_ROUTE_PATH_MAIN_CONTROLPREVENTIVO + "")) {
            setNavbarModule([{ navigate: false, path: '', text: languageTranslateForm.navBarTextControlPreventivo }]);
            setKeyTab(-1);
            setGenerateId(AdapterGenerico.generateUniqueID());

            // Para continuar se necesita registrar una ListaAutochequeo o CharlaPretarea
            const resultRegistered = await repository.permissToRegisterControlPreventivo(user);
            if (resultRegistered) {
                result = ({ key: 'CP', title: languageTranslateForm.navBarTextControlPreventivo })
                const initialData = await new UseCaseLoadData(repository).exec(user, permisoVariables)
                setSelectInformation(() => ({...initialData}));
                
                // Autocompletar Datos del último registro AUT o CPT
                const resultOT = initialData.OT.find(row => row.dataComplete.IdOT === resultRegistered.OT.IdOT);
                if (!resultOT) {
                    AdapterGenerico.createMessage(languageTranslate.textoAlerta, `${languageTranslate.moduloAutoChequeo.form.advertenciaOTPreview(`${resultRegistered.Momento.Momento} (${resultRegistered.OT.OT})`)}`, 'warning', false).then(() => {
                        navigate(AdapterConfigure.REDIRECT_LIST);
                    });
                    return result;
                }

                const resultEmpresa = initialData.Empresa.find(row => row.dataComplete.NroDocumento === resultRegistered.Empresa.NroDocumento);
                const resultSubContrata = initialData.Subcontrata.filter(row => resultRegistered.ContrataSeleccionada.some(_row => _row.NroDocumento === row.dataComplete.NroDocumento))
                let resultDelegacion = initialData.Delegacion.find(row => row.dataComplete.IdDelegacion === resultRegistered.IdDelegacion);
                let resultActividad = initialData.Actividad.find(row => row.dataComplete.IdActividad === resultRegistered.IdActividad);
                let resultCliente = initialData.Cliente.find(row => row.dataComplete.IdEmpresa === resultRegistered.Cliente?.IdCliente);
                let jefeObra = initialData.Personal.find(row => row.value === resultRegistered.JefeObraRecurso.Identificacion);

                if (!resultCliente) resultCliente = AdapterGenerico.formatoPersonalizadoSelect(resultOT?.dataComplete.Cliente, 'IdCliente', 'Cliente') as any;
                if (!resultActividad) resultActividad = AdapterGenerico.formatoPersonalizadoSelect(resultOT?.dataComplete.Actividad, 'Codigo', 'Actividad') as any;
                if (!jefeObra) jefeObra = AdapterGenerico.formatoPersonalizadoSelect(resultOT?.dataComplete.JefeObra, 'Identificacion', ['Nombres', 'Apellidos']) as any;
                if (!resultDelegacion) resultDelegacion = AdapterGenerico.formatoPersonalizadoSelect(resultOT?.dataComplete.Delegacion, 'Codigo', 'Delegacion') as any;
                
                const dataAutoComplete: any = {
                    ...formRegistro.values,
                    Pais: AdapterGenerico.formatoPersonalizadoSelect(resultOT?.dataComplete.Pais, 'IdPais', 'Pais'),
                    Delegacion: resultDelegacion,
                    Cliente: resultCliente,
                    Proyecto: AdapterGenerico.formatoPersonalizadoSelect(resultOT?.dataComplete.Proyecto, 'IdProyecto', 'Proyecto'),
                    Actividad: resultActividad,
                    JefeObra: jefeObra,
                    Grupo: AdapterGenerico.formatoPersonalizadoSelect(resultOT?.dataComplete.Grupo, 'IdGrupo', 'Grupo'),
                    OT: resultOT,
                    Tipologia: [],
                    Obra: resultRegistered.Obra,
                    Lugar: resultRegistered.Lugar,
                    TrabajoRealizar: resultRegistered.TrabajoaRealizar,
                    Empresa: resultEmpresa,
                    SubContrata: resultSubContrata,
                    FechaRegistro: autoCompleteFechaRegistro()
                }
                
                prevRegister = resultRegistered;
                await formRegistro.setValues(dataAutoComplete);
                setLoading(false);
                return result;
            } else {
                // Retorna mensaje y redireccion al listado
                AdapterGenerico.createMessage(languageTranslate.textoAlerta, languageTranslate.moduloAutoChequeo.form.validate.formRegistro.otDirectoCP, 'warning', false).then(() => {
                    navigate(AdapterConfigure.REDIRECT_LIST);
                });
                return result;
            }
        };

        if (validationPathName(process.env.REACT_APP_ROUTE_PATH_MAIN_AUTOCHEQUEO + "")) {
            setNavbarModule([{ navigate: false, path: '', text: languageTranslateForm.navBarTextListaAutochequeo }]);
            setKeyTab(0);
            if (await repository.selfCheckListWasRegistered()) {
                setNavbarModule([{ navigate: false, path: '', text: languageTranslateForm.advertenciaListaAutochequeoRealizado }]);
                AdapterGenerico.createMessage(languageTranslate.textoAlerta, languageTranslateForm.advertenciaListaAutochequeoRealizado, 'warning', false).then(() => {
                    navigate(AdapterConfigure.REDIRECT_LIST);
                });
                return result;
            }
            setGenerateId(repository.generateId());
            if (preferencia.ot) autoCompleteOnSelectOT(preferencia.ot as any, loadOptions);
            if (preferencia.empresa) formRegistro.setFieldValue('Empresa', preferencia.empresa);

            autoCompleteAddress();
            result = ({ key: 'AUT', title: languageTranslateForm.navBarTextListaAutochequeo });
        }

        if (validationPathName(process.env.REACT_APP_ROUTE_PATH_MAIN_CHARLA_PRETAREA + "")) {
            setNavbarModule([{ navigate: false, path: '', text: languageTranslateForm.navBarTextCharlaPretarea }]);
            setKeyTab(0);
            setGenerateId(repository.generateId());

            if (preferencia.ot) autoCompleteOnSelectOT(preferencia.ot as any, loadOptions);
            if (preferencia.empresa) formRegistro.setFieldValue('Empresa', preferencia.empresa);

            autoCompleteAddress();
            result = ({ key: 'CPT', title: languageTranslateForm.navBarTextListaAutochequeo });
        }

        if (validationPathName(process.env.REACT_APP_ROUTE_PATH_MAIN_CIERRE_CONTROLPREVENTIVO + "")) {
            setNavbarModule([{ navigate: false, path: '', text: languageTranslateForm.navBarTextCierreControlPreventivo }]);
            modeCCP();
            result = ({ key: 'CCP', title: languageTranslateForm.navBarTextCierreControlPreventivo });
            setKeyTab(-2);
        }

        if (validationPathName(process.env.REACT_APP_ROUTE_PATH_MAIN_GESTIONAUTOCHEQUEO + "")) {
            setNavbarModule([{ navigate: true, path: AdapterConfigure.REDIRECT_GESTION, text: languageTranslate.moduloAutoChequeo.list.navbar[0].text }, { navigate: false, path: '', text: languageTranslateForm.navBarTextPreview }]);
            setKeyTab(-3);
            modePreview();
            result = ({ key: 'PREVIEW', title: languageTranslateForm.navBarTextPreview });
        }

        await loadRegisterIncomplete(result.key);
        setSelectInformation(() => ({ ...loadOptions }));
        setLoading(false);
        return result;
    }

    const moduleGuest = async (): Promise<EntityModuleForm> => {
        if (validationPathName(process.env.REACT_APP_ROUTE_PATH_MAIN_CHARLA_PRETAREA + "")) {
            const languageTranslateForm = languageTranslate.moduloAutoChequeo.form;
            setNavbarModule(languageTranslateForm.navBarGuest);
            setKeyTab(0);
            setGenerateId(AdapterGenerico.generateUniqueID());
            autoCompleteAddress();
            const response = await (new UseCaseLoadDataGuest(repository).exec());
            setSelectInformationGuest((prev) => ({
                ...prev,
                ...response
            }))
            setLoading(false);
            await loadRegisterIncomplete('CPTGuest');

            return ({
                key: 'CPTGuest',
                title: languageTranslateForm.navBarTextListaAutochequeo
            });
        }

        if (validationPathName(process.env.REACT_APP_ROUTE_PATH_MAIN_GESTIONAUTOCHEQUEO + "")) {
            setNavbarModule([{ navigate: true, path: AdapterConfigure.REDIRECT_GESTION, text: languageTranslate.moduloAutoChequeo.list.navbar[0].text }, { navigate: false, path: '', text: languageTranslate.moduloAutoChequeo.form.navBarTextPreview }]);
            setKeyTab(-3);
            modePreview();
            return ({ key: 'PREVIEW', title: languageTranslate.moduloAutoChequeo.form.navBarTextPreview });
        }

        return ({
            key: 'CPTGuest',
            title: ''
        });
    }

    const init = async () => {
        try {
            isRegisterIncomplete = false;
            setLoading(true);
            dispatch(changeSaludo(false));
            let result: EntityModuleForm = await ((user.IdUsuario === AdapterConfigure.ID_USER_INVITE) ? moduleGuest : verifyModule)();
            setModuleForm(result);
        } catch (error) {
            window.location.reload();
        }
    }

    const autoCompleteAddress = async () => {} //onChange("Lugar", (await AdapterGeolocation.getAllGeolocation()).address);

    const modeCCP = async () => {
        dispatch(addLoading({ textLoading: languageTranslate.moduloAutoChequeo.form.textCargandoPreguntas }))
        const response = await repository.getItemAutochequeo(params.id || '');

        if ([null, undefined].includes(response as any)) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, languageTranslate.moduloAutoChequeo.form.advertenciaSinRegistro, 'error', false).then(() => {
                navigate(AdapterConfigure.REDIRECT_GESTION);
            });
            return;
        }

        if (response?.EstadoCP.IdEstado === 1) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, languageTranslate.moduloAutoChequeo.form.advertenciaRegistroConCierre, 'error', false).then(() => {
                navigate(AdapterConfigure.REDIRECT_GESTION);
            });
            return;
        }

        const ot = AdapterData.ot;
        if ([null, undefined].includes(ot as any)) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, languageTranslate.moduloAutoChequeo.form.advertenciaCargarOT, 'error', false).then(() => {
                navigate(AdapterConfigure.REDIRECT_GESTION);
            });
            return;
        }

        const searchOT = ot.find((item) => item.IdOT === response?.OT.IdOT);

        if (searchOT === null) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, languageTranslate.moduloAutoChequeo.form.advertenciaBuscarOTRegistro, 'error', false).then(() => {
                navigate(AdapterConfigure.REDIRECT_GESTION);
            });
            return;
        };

        if (searchOT?.CierreCP.toLowerCase() !== 'si') {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, languageTranslate.moduloAutoChequeo.form.advertenciaRegistroSinCierre, 'error', false).then(() => {
                navigate(AdapterConfigure.REDIRECT_GESTION);
            });
            return;
        };

        setGenerateId(params.id || '');
        loadQuestions('CCP', searchOT);
    }

    const modePreview = async () => {
        dispatch(addLoading({ textLoading: languageTranslate.moduloAutoChequeo.form.textCargandoPreguntas }))
        const isOpenModalComentarios = !!(AdapterGenerico.getQueryUrl('openCommentDefault'))

        const response = await repository.getItemAutochequeo(params.id || '', isOpenModalComentarios);
        currentRegister = response;

        if ([null, undefined].includes(response as any)) {  
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, languageTranslate.moduloAutoChequeo.form.advertenciaSinRegistro, 'error', false).then(() => {
                navigate(AdapterConfigure.REDIRECT_GESTION);
            });
            return;
        }

        const ot = AdapterConfigure.ID_USER_INVITE === user.IdUsuario ? AdapterData.otLite : AdapterData.ot;
        const searchOT: any = [...ot].find((item) => item._id === response?.OT._id || (item as any).IdOT === response?.OT.IdOT);

        if (!searchOT) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, languageTranslate.moduloAutoChequeo.form.advertenciaBuscarOTRegistro, 'error', false).then(() => {
                navigate(AdapterConfigure.REDIRECT_GESTION);
            });
            return;
        };

        prevRegister = response;
        const arrIdTitles = response?.GrupoPreguntas.map(row => ({ IdTipologia: row.IdTitulo } as EntityAutochequeoTipologia))
        loadQuestions('PREVIEW', searchOT, response?.Momento.IdMomento, arrIdTitles);
        const resultGrupoPreguntas = {};
        const resultGrupoTitulos = {};

        let variablesDescarga = `${process.env.REACT_APP_PATH_IMAGES_CP}||${response?.FechaRegistro.IdFecha}||${response?.Archivos}`;
        let variablesDescarga_b64 = btoa(unescape(encodeURIComponent(variablesDescarga)));
        let audio = `${process.env.REACT_APP_URL_MASTER}/download/Imagen/${variablesDescarga_b64}`;
        let MedidasPreventivas: any[] = [], Riesgos: any[] = [], EPCS: any[] = [];

        let count = 0;
        // recolecta los EPCS
        const EPCS_ = (response?.CharlaPreTarea?.epcs || []).map(row => ({
            label: row.iteminIdioma.find((_row: any) => _row.codigo === languageTranslate.code)?.nombre || '',
            value: row.IdEPCS,
            dataComplete: {
                opcionPregunta: { 
                    codigo: row.otroEPCS ? AdapterConfigure.KEYS_FORM.specialInputs.indicarOtroEpcs : ""
                }
            }
        }))
        if (EPCS_.length > 0) EPCS = EPCS_;

        // recolecta las medidasPreventivas
        const medidasPreventivas_ = (response?.CharlaPreTarea?.medidasPreventivas || []).map(row => ({
            label: row.iteminIdioma.find((_row: any) => _row.codigo === languageTranslate.code)?.nombre || '',
            value: row.IdMedidasPreventivas,
            dataComplete: {
                opcionPregunta: { 
                    codigo: row.otroMedidasPreventivas ? AdapterConfigure.KEYS_FORM.specialInputs.indicarOtroMedidasPreventivas : ""
                }
            }
        }))
        MedidasPreventivas = medidasPreventivas_;

        // recolecta los riesgos
        const riesgos_ = (response?.CharlaPreTarea?.riesgos || []).reduce((prev, current) => {
            if (current.value === 'Si')
                Riesgos.push({
                    value: current.value,
                    dataComplete: current
                });
            prev[current.IdTipoRiesgo] = {
                value: current.value,
                dataComplete: current
            };
            return prev;
        }, {})

        let _audio: Blob | null = null, _firma: Blob | null = null;
        if (response?.dataSend) {
            _audio = response.dataSend.Files.find(row => row.name.match('.mp3'))?.value;
            _firma = response.dataSend.Files.find(row => row.name.match('.png'))?.value;
        }

        response?.GrupoPreguntas.forEach((row, index) => {
            let questGroup = {};
            /* if (index === 0 && response.Momento.IdMomento === 2) {
                return;
            } */
            row.Preguntas.forEach(itemQuest => {

                Object.assign(questGroup, {
                    [itemQuest.IdPregunta]: {
                        charlaPreTarea: _audio || audio,
                        respuesta: itemQuest.ResDisponibilidad,
                        observacion: itemQuest.ObservacionIngresada,
                        personal: AdapterGenerico.formatoPersonalizadoSelect(response.Personal || {}, 'Identificacion', ['Nombres', 'Apellidos']),
                        images: (itemQuest.NombreFotos || []).map(row =>
                        ({
                            type: 0,
                            value: "",
                            valueToShow: repository.formatterReadImage(response?.FechaRegistro.IdFecha, row)
                        })
                        ),
                        estadoEquipo: itemQuest.ResEstado,
                        PersonalResponsableTrabajo: response.CharlaPreTarea?.PersonalResponsableTrabajo ?  { value: response.CharlaPreTarea.PersonalResponsableTrabajo.Identificacion } : null,
                        riesgos: riesgos_,
                        RecursoPreventivo: response.CharlaPreTarea?.RecursoPreventivo ? AdapterGenerico.formatoPersonalizadoSelect(response.CharlaPreTarea?.RecursoPreventivo, 'Identificacion', ['Nombres', 'Apellidos']) : null,
                        medidasPreventivas: medidasPreventivas_,
                        epcs: EPCS_,
                        otroRiesgo: itemQuest.otroRiesgo,
                        otroEPCS: itemQuest.otroEPCS,
                        otroMedidasPreventivas: itemQuest.otroMedidasPreventivas,
                        firma: _firma || repository.formatterReadImage(response?.FechaRegistro.IdFecha, itemQuest?.NombreImageFirma || ''),
                        opcionAudio: (response.CharlaPreTarea?.opcionAudio || []).reduce((prev, current) => {
                            prev[current.codigo] = current.value
                            return prev;
                        }, {})
                    }
                })
            });

            Object.assign(resultGrupoTitulos, {
                [count + 1]: {
                    observacion: row.ObservacionGrupo,
                    images: row.FotoGrupo.map(row =>
                    ({
                        type: 0,
                        value: "",
                        valueToShow: repository.formatterReadImage(response?.FechaRegistro.IdFecha, row)

                    })
                    ),
                }
            })
            Object.assign(resultGrupoPreguntas, { [count + 1]: questGroup });
            count++;
        });

        formRegistro.setFieldValue('grupoPreguntas', resultGrupoPreguntas);
        formRegistro.setFieldValue('grupoTitulos', resultGrupoTitulos);
        formRegistro.setFieldValue('Personal', AdapterGenerico.formatoPersonalizadoSelect((response?.Personal || []), 'Identificacion', ['Nombres', 'Apellidos']))
        formRegistro.setFieldValue('MedidasPreventivas', MedidasPreventivas);
        formRegistro.setFieldValue('Riesgos', Riesgos);
        formRegistro.setFieldValue('EPCS', EPCS);
        formRegistro.setFieldValue('IdMomento', response?.Momento.IdMomento);
        setGenerateId(params.id || '');

        if (isOpenModalComentarios && currentRegister?.isHasNotification) {
            await repository.confirmReadNotification({ _id: currentRegister?._id || '', identificacion: currentRegister?.Tecnico.Identificacion || '' })
        }

        // Configuración para el modal de comentarios
        setConfigModalComentarios((prev) => ({
            ...prev,
            comentarios: currentRegister?.Puntuacion || [],
            list: {
                ...prev.list,
                show: isOpenModalComentarios
            }
        }))
    }

    const loadRegisterIncomplete = async (key: KeyModule) => {
        if (!['AUT', 'CPT', 'CPTGuest'].includes(key)) return null;

        const verifiedRegisterIncomplete = await repository.verifiedRegisterIncomplete(key);
        if (!verifiedRegisterIncomplete) return null;

        const confirmLoadData = await AdapterGenerico.createMessage('', languageTranslate.moduloAutoChequeo.form.textRegistroIncompleto, 'info', true);
        if (!confirmLoadData) {
            await repository.deleteRegisterIncomplete(verifiedRegisterIncomplete._id);
            return null;
        };

        isRegisterIncomplete = true;
        setGenerateId(verifiedRegisterIncomplete._id);
        await formRegistro.setValues(verifiedRegisterIncomplete.dataIncomplete);
    }

    const onChangeContentForTab = async (newKeyTab: number) => {
        if (newKeyTab === keyTab) return;
        if (newKeyTab < keyTab) return setKeyTab(newKeyTab);
        if (moduleForm.key === 'PREVIEW') {
            setKeyTab(newKeyTab);
            AdapterGenerico.scrollTopByClassName('ViewAutochequeoForm');
            return null;
        }

        try {
            try { await formRegistro.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formRegistro.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }
            let tempKeyTab = (newKeyTab - 1) === keyTab ? newKeyTab : (keyTab + 1);
            if (keyTab === -1) tempKeyTab = 1 // <-- Válido solo para CP, para skipear el form DatosGenerales
            const isLastStep = moduleForm.key === 'CCP' || ((question.AutochequeoTitulos.length > 0) && (question.AutochequeoTitulos.length === (tempKeyTab - 1)));
            if (tempKeyTab === 1) await loadQuestions();
            if (tempKeyTab !== 1) try { await validatedValues() } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }
            if (isLastStep) { save(); return null; }
            AdapterGenerico.scrollTopByClassName('ViewAutochequeoForm');
            if (['AUT', 'CPT', 'CPTGuest'].includes(moduleForm.key)) {
                await repository.autoSave({...formRegistro.values, _id: generateId}, moduleForm.key);
            };
            setKeyTab(tempKeyTab);
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        }
    }

    const save = async () => {
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoGuardando }));

            let data: any;
            if (moduleForm.key === 'CCP') {
                data = await repository.formatterSaveCCP(formRegistro.values, user, generateId, question);
            } else if (['AUT', 'CPT', 'CPTGuest'].includes(moduleForm.key)) {
                data = await repository.formatterSave(formRegistro.values, user, question, moduleForm, selectInformation.OpcionAudio, generateId);
            } else if (moduleForm.key === 'CP'){
                data = await repository.formatterSaveCP(prevRegister as any, formRegistro.values, user, question, generateId);
            } else {
                return null;
            }

            if (generateId) Object.assign(data, { autochequeo: { ...data.autochequeo, _id: generateId } });
            const response = (moduleForm.key === 'CCP' ? await new useCaseSaveCierreControlPreventivo(repository).exec(data) : await new useCaseSaveListaAutochequeo(repository).exec(data, moduleForm.key));

            // Automatiza el personal de Medios Humanos
            if (moduleForm.key !== 'CCP' && preferencia.automatizaPersonal && response) {

                let Personal: EntitySelectPersonal[] = [];
                // Busca todo el Personal del formulario
                Object.keys(formRegistro.values.grupoPreguntas).forEach((key: string, index) => {
                    const valueGroupQuestions = formRegistro.values.grupoPreguntas[key] || {};
                    Object.keys(valueGroupQuestions).forEach((idRegistroQuestion: string, indexPregunta) => {
                        const valueForQuestion = valueGroupQuestions[idRegistroQuestion] || {};
                        Personal = [...Personal, ...(valueForQuestion?.personal || [])];
                    })
                })
                // Guarda la nueva preferencia del personal de medios humanos
                dispatch(changePreference({ ...preferencia, personal: Personal }));
            }

            if (response && moduleForm.key === 'CP') return redirectGestion(response._id || '');

            navigate(AdapterConfigure.REDIRECT_GESTION);
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    const redirectGestion = (_id: string) => {
        const ot_: EntityOTs = formRegistro.values.OT.dataComplete;
        if (ot_?.CierreCP.toLowerCase() !== 'si' || !question.cierreControlPreventivoHasQuestion) return navigate(AdapterConfigure.REDIRECT_GESTION);

        AdapterGenerico.createMessage('', languageTranslate.moduloAutoChequeo.form.preguntarRealizarCierreControlPreventivo, 'question', true).then((res) => {
            res ? navigate(`${AdapterConfigure.REDIRECT_CIERRECP}/${_id}`) : navigate(AdapterConfigure.REDIRECT_GESTION);
        })
    }

    const autoCompletePersonal = async () => {
        dispatch(addLoading({ textLoading: languageTranslate.textoCargando }))
        const response = await repository.getPersonnelLastRegister();
        dispatch(removeLoading())
        return response;
    }

    // Funciones para Comentarios

    const openModalComentarios = () => {
        setConfigModalComentarios((prev) => ({
            ...prev,
            show: true,
            form: {
                _id: prev.form._id || currentRegister?._id || '',
                Id: prev.form.Id || currentRegister?.Id || 0,
                Comentario: '',
                Puntuacion: 0,
                Notificacion: false
            }
        }))
    }

    const closeModalComentarios = () => {
        setConfigModalComentarios((prev) => ({
            ...prev,
            show: false,
            form: {
                _id: currentRegister?._id || "",
                Id: currentRegister?.Id || 0,
                Comentario: '',
                Puntuacion: 0,
                Notificacion: false
            }
        }))
    }

    const openModalListaComentarios = async (currentRegisterFromOtheModule?: EntityAutochequeo) => {
        const payload: any = currentRegisterFromOtheModule || currentRegister;
        const percentegeAudio = payload.Revision?.Audio?.RevisionAudio || 0;
        const percentegePDF = payload.Revision?.Pdf?.RevisionPdf || 0;
        const language = LanguageTranslate();

        // Lista autochequeo
        /*if (payload.Momento.IdMomento === 1 && percentegePDF === 0) {
            AdapterGenerico.createMessage('', language.moduloAutoChequeo.comentario.requiredAutochequeo, 'info');
            return;
        }*/

        // CharlaPretarea
        if (payload.Momento.IdMomento === 3 && (percentegeAudio === 0 || percentegePDF === 0) && payload.Tecnico.Identificacion !== user.Identificacion) {
            AdapterGenerico.createMessage('', language.moduloAutoChequeo.comentario.requiredCharlaPretarea, 'info');
            return;
        }
        
        setConfigModalComentarios((prev) => ({
            ...prev,
            disabledFunctions: user.Identificacion === (currentRegisterFromOtheModule?.Tecnico.Identificacion || currentRegister?.Tecnico.Identificacion),
            list:{
                show: true,
                feedback: !!(currentRegisterFromOtheModule?.FeedBack?.FeedBack || currentRegister?.FeedBack?.FeedBack)
            },
            form: {
                _id: currentRegisterFromOtheModule?._id || currentRegister?._id || "",
                Id: currentRegisterFromOtheModule?.Id || currentRegister?.Id || 0,
                Comentario: '',
                Puntuacion: 0,
                Notificacion: false
            },
            comentarios: currentRegisterFromOtheModule?.Puntuacion || prev.comentarios
        }))

        if (currentRegisterFromOtheModule) currentRegister = currentRegisterFromOtheModule;

        if (currentRegisterFromOtheModule?.isHasNotification || currentRegister?.isHasNotification) {
            const item = currentRegisterFromOtheModule || currentRegister;
            await repository.confirmReadNotification({ _id: item?._id || '', identificacion: item?.Tecnico.Identificacion || '' })
        }
    }

    const closeModalListaComentarios = () => {
        setConfigModalComentarios((prev) => ({
            ...prev,
            list:{
                show: false,
                feedback: false
            }
        }))
    }
    
    const onChangeFormModalComentarios = (name: string, value: any) => {
        const PUNTUACION_MINIMA_AUTOCOMPLETAR = 3;
        const extra = (name === 'Puntuacion' && value <= PUNTUACION_MINIMA_AUTOCOMPLETAR) ? { Notificacion: true } : {  };

        setConfigModalComentarios((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                ...extra,
                [name]: value
            }
        }))
    }

    const onSubmitModalComentarios = async (type: 'save' | 'delete' = 'save') => {
        if (configModalComentarios.form.Comentario === '') return  AdapterGenerico.createMessage(languageTranslate.textoAlerta, 'Ingresa un Comentario', 'warning', false);
        if (configModalComentarios.form.Puntuacion === 0) return  AdapterGenerico.createMessage(languageTranslate.textoAlerta, 'Ingresa una Puntuación', 'warning', false);

        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoGuardando }));

            const dataFormatted = repository.formateSaveComentario(configModalComentarios, user);
            const result = await repository.saveComentario(dataFormatted);
            if (configModalComentarios.form.Notificacion) await repository.saveNotification(configModalComentarios, currentRegister as any);

            if (type === 'save' && result) {
                dispatch(removeLoading());
                await AdapterGenerico.createMessage('', '¡Gracias por calificar!', 'success', false).then(res => {
                    setConfigModalComentarios((prev) => ({
                        ...prev,
                        comentarios: result[0]?.Puntuacion || [],
                        show: false
                    }))
                })
            }

        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    // Función Feedback

    const onSubmitFeedback = async (_: string, value: boolean) => {
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoGuardando }));

            const dataFormatted = await repository.formateSaveFeedback(configModalComentarios, user, value);
            const result = await repository.saveFeedback(dataFormatted);
            setConfigModalComentarios((prev) => ({
                ...prev,
                list: {
                    ...prev.list,
                    feedback: !!(result?.FeedBack?.FeedBack)
                }
            }))
            // dispatch(removeLoading());
            // await AdapterGenerico.createMessage('', '¡Gracias por Feedback!', 'success', false);
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    return {
        location,
        init,
        onChangeContentForTab,
        autoCompletePersonal,
        keyTab,
        loading,
        selectInformation,
        selectInformationGuest,
        conditionals,
        listQuestionShow,
        titleQuestionGroupToDisplay,
        question,
        tipologiaCP,
        moduleForm,
        navbarModule,

        formRegistro,
        onChange,
        save,

        configModalComentarios,
        openModalComentarios,
        closeModalComentarios,
        onChangeFormModalComentarios,
        onSubmitModalComentarios,

        openModalListaComentarios,
        closeModalListaComentarios,
        onSubmitFeedback
    }
}

const ControllerForm = (props: { keyTab: number; repository: RepositoryImplMain; personal: EntityPersonal[] | null; selectInformation: EntityLoadData, moduleForm: EntityModuleForm }) => {
    const languageTranslate = LanguageTranslate();

    const formRegistro = useFormik({
        initialValues: initEntityDataForm,
        validationSchema: Yup.object({
            //tab1
            // when([], { is: () => props.keyTab === -1, then: Yup.string().required(languageTranslate.moduloAutoChequeo.form.validate.formRegistro.ordenServicio) })
            OrdenServicio: Yup.string().nullable(),
            OT: Yup.object().when([], {
                is: () => ([-1, 0].includes(props.keyTab) && props.moduleForm.key !== 'CPTGuest'),
                then: Yup.object().required(languageTranslate.moduloAutoChequeo.form.validate.formRegistro.ot).nullable()
            }).nullable(),
            Tipologia: Yup.array().when([], { is: () => props.keyTab === -1, then: Yup.array().min(1, languageTranslate.moduloAutoChequeo.form.validate.formRegistro.tipologia).nullable() }),

            // tab2
            Obra: Yup.string().when([], { is: () => (props.keyTab === 0), then: Yup.string().required(languageTranslate.moduloAutoChequeo.form.validate.formRegistro.obra).nullable() }),
            Lugar: Yup.string().when([], { is: () => ([-1, 0].includes(props.keyTab)), then: Yup.string().required(languageTranslate.moduloAutoChequeo.form.validate.formRegistro.lugar).nullable() }),
            TrabajoRealizar: Yup.string().when([], { is: () => (props.keyTab === 0), then: Yup.string().required(languageTranslate.moduloAutoChequeo.form.validate.formRegistro.trabajoRealizar).nullable() }),

            Empresa: Yup.object().when([], { is: () => (props.keyTab === 0  && props.moduleForm.key !== 'CPTGuest'), then: Yup.object().required(languageTranslate.moduloAutoChequeo.form.validate.formRegistro.empresa).nullable() }).nullable(),
            Delegacion: Yup.object().when([], { is: () => (props.keyTab === 0  && props.moduleForm.key !== 'CPTGuest'), then: Yup.object().required(languageTranslate.moduloAutoChequeo.form.validate.formRegistro.delegacion).nullable() }).nullable(),
            Actividad: Yup.object().when([], { is: () => (props.keyTab === 0  && props.moduleForm.key !== 'CPTGuest'), then: Yup.object().required(languageTranslate.moduloAutoChequeo.form.validate.formRegistro.actividad).nullable() }).nullable(),
            Cliente: Yup.object().when([], { is: () => (props.keyTab === 0  && props.moduleForm.key !== 'CPTGuest'), then: Yup.object().required(languageTranslate.moduloAutoChequeo.form.validate.formRegistro.cliente).nullable() }).nullable(),
            JefeObra: Yup.object().when([], { is: () => (props.keyTab === 0  && props.moduleForm.key !== 'CPTGuest'), then: Yup.object().required(languageTranslate.moduloAutoChequeo.form.validate.formRegistro.jefeObra).nullable() }).nullable(),
            
            OTGuest: Yup.object().when([], { is: () => (props.keyTab === 0  && props.moduleForm.key === 'CPTGuest'), then: Yup.object().required(languageTranslate.moduloAutoChequeo.form.validate.formRegistro.ot).nullable() }).nullable(),
            JefeObraGuest: Yup.object().when([], { is: () => (props.keyTab === 0  && props.moduleForm.key === 'CPTGuest'), then: Yup.object().required(languageTranslate.moduloAutoChequeo.form.validate.formRegistro.jefeObra).nullable() }).nullable(),
            UsuarioRegistraGuest: Yup.object().when([], { is: () => (props.keyTab === 0  && props.moduleForm.key === 'CPTGuest'), then: Yup.object().required(languageTranslate.moduloAutoChequeo.form.validate.formRegistro.usuarioRegistra).nullable() }).nullable(),
        }),
        onSubmit: (values, formikHelpers) => { },
    });

    const onChange = (name: keyof EntityDataForm, value: any) => {
        if (!['RecursoPreventivo', 'SubContrata'].includes(name)) {
            if (value === null) { return; }
        }
        if (["OT", "grupoPreguntas", "grupoTitulos", "OTGuest"].includes(name))
            extraActionsOnChange(name, value);
        else
            formRegistro.setFieldValue(name, value);
    };

    const extraActionsOnChange = (name: keyof EntityDataForm, value: any) => {
        switch (name) {
            case "OT": autoCompleteOnSelectOT(value); break;
            case "grupoPreguntas": configValuesFromFormDynamic(value); break;
            case "grupoTitulos": configValuesFromFormDynamicTitle(value); break;
            case "OTGuest": autoCompleteOTGuest(value); break;
        }
    }

    const autoCompleteOnSelectOT = (value: EntitySelectOTs, selectInformation?: EntityLoadData) => {
        const options = selectInformation || props.selectInformation;

        const dataAutoComplete: any = {
            ...formRegistro.values,
            Pais: AdapterGenerico.formatoPersonalizadoSelect(value.dataComplete.Pais, 'IdPais', 'Pais'),
            Delegacion: null,
            Cliente: null,
            Proyecto: AdapterGenerico.formatoPersonalizadoSelect(value.dataComplete.Proyecto, 'IdProyecto', 'Proyecto'),
            Actividad: null,
            JefeObra: null,
            Grupo: AdapterGenerico.formatoPersonalizadoSelect(value.dataComplete.Grupo, 'IdGrupo', 'Grupo'),
            OT: value,
            Tipologia: []
        }

        if (![0, undefined, null].includes(value.dataComplete.Cliente.IdCliente)) {
            const resultFinded = options.Cliente.find(row => row.dataComplete.IdEmpresa === value.dataComplete.Cliente.IdCliente) || null;
            dataAutoComplete.Cliente = resultFinded
        }

        if (![0, undefined, null].includes(value.dataComplete.Actividad.IdActividad)) {
            const resultFinded = options.Actividad.find(row => row.dataComplete.IdActividad === value.dataComplete.Actividad.IdActividad) || null;
            dataAutoComplete.Actividad = resultFinded
        }

        if (!['', undefined, null].includes(value.dataComplete.JefeObra.Identificacion)) {
            const resultFinded = options.PersonalJefeObra.find(row => row.value === value.dataComplete.JefeObra.Identificacion) || null;
            dataAutoComplete.JefeObra = resultFinded;
        }

        // AdapterGenerico.formatoPersonalizadoSelect(value.dataComplete.Delegacion, 'Codigo', 'Delegacion')
        const resultFindDelegacion = options.Delegacion.find(row => row.dataComplete.IdDelegacion === value.dataComplete.Delegacion.IdDelegacion);
        dataAutoComplete["Delegacion"] = resultFindDelegacion || AdapterGenerico.formatoPersonalizadoSelect(value.dataComplete.Delegacion, 'Codigo', 'Delegacion')

        const sameCountry = value.dataComplete.Pais.IdPais === formRegistro.values.OT?.dataComplete.Pais.IdPais;
        if (!sameCountry) Object.assign(dataAutoComplete, { Empresa: null, SubContrata: null });

        formRegistro.setValues(dataAutoComplete);
    }

    const autoCompleteOTGuest = (value: EntitySelectBase<EntityLiteOT>) => {
        const dataAutocomplete = {
            Pais: { label: '', value: value.dataComplete.IdPais, dataComplete: { IdPais: value.dataComplete.IdPais, Pais: '' } },
            Actividad: { label: '', value: value.dataComplete.IdActividad, dataComplete: {  } as any },
            OTGuest: value
        }

        formRegistro.setValues(prev => ({ ...prev, ...dataAutocomplete }));
    }

    const configValuesFromFormDynamic = (value: { data: string | FileList | Number | Blob; dataParent: EntityAutochequeoItems; key: keysInputsDynamic; idGroup: number; }) => {
        const { data, dataParent, key, idGroup } = value;
        let valuesCleaned = clearDependentValues({ key });
        let valuesGroup = formRegistro.values.grupoPreguntas[idGroup] || {};

        // CPT - AUT Validación en medios humanos por la respuesta y blockeo de módulo
        const answer = (JSON.parse(dataParent.Disponibilidad) as EntityAutochequeoItemsDisponibilidad[]).find(row => row.Respuesta === data);
        if (answer && (answer.BlockForModule || []).includes(props.moduleForm.key)) {
            return AdapterGenerico.createMessage('Alert', props.moduleForm.key === 'AUT' ? languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaModuloAUT : languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaModuloCPT, 'warning');
        }

        if (key === 'respuesta') {
            // Limpiar valores que dependan de otro Input
            const alternatives = (JSON.parse(dataParent.Disponibilidad) as EntityAutochequeoItemsDisponibilidad[]).filter(items => items.Respuesta !== data);
            alternatives.map(item => delete valuesGroup[item.IdHijo])

            // Asignar el personal de preferencia
            const alternativeSelected = (JSON.parse(dataParent.Disponibilidad) as EntityAutochequeoItemsDisponibilidad[]).find(items => items.Respuesta === data);
            if (alternativeSelected?.Personal === 1) Object.assign(valuesCleaned, { personal: props.personal })
        }

        // Cuando la llave es personal, verifica si no se eliminó el Jefe Trabajo
        if (value.key === 'personal') {
            const respuestas = formRegistro.values.grupoPreguntas[value.idGroup][value.dataParent.IdRegistro] || { PersonalResponsableTrabajo: null };
            const result = (Array.isArray(value.data) ? value.data : []).find(row => row.value === respuestas?.PersonalResponsableTrabajo?.value);
            if (!result) valuesCleaned = { ...valuesCleaned, PersonalResponsableTrabajo: null };
        };

        formRegistro.setValues({
            ...formRegistro.values,
            ...(value.key === 'personal' ? { Personal: value.data } : {}),
            ...(value.key === 'riesgos' ? { Riesgos: Object.values(value.data).filter(row => row.value === 'Si') } : {}),
            ...(value.key === 'otroRiesgo' ? { otroRiesgo: value.data } : {}),
            ...(value.key === 'medidasPreventivas' ? { MedidasPreventivas: value.data } : {}),
            ...(value.key === 'otroMedidasPreventivas' ? { otroMedidasPreventivas: value.data } : {}),
            ...(value.key === 'epcs' ? { EPCS: value.data } : {}),
            ...(value.key === 'otroEPCS' ? { otroEPCS: value.data } : {}),
            grupoPreguntas: {
                ...formRegistro.values.grupoPreguntas,
                [idGroup]: {
                    ...valuesGroup,
                    [dataParent.IdRegistro]: {
                        ...(valuesGroup[dataParent.IdRegistro] || {}),
                        ...valuesCleaned,
                        [key]: data
                    }
                }
            }
        })
    }

    const configValuesFromFormDynamicTitle = (value: { data: any[] | string; key: 'images' | 'observacion', idGroup: number }) => {
        const { data, key, idGroup } = value;

        formRegistro.setValues({
            ...formRegistro.values, grupoTitulos: {
                ...formRegistro.values.grupoTitulos,
                [idGroup]: {
                    ...(formRegistro.values.grupoTitulos[idGroup] || {}),
                    [key]: data
                }
            }
        })
    }

    const clearDependentValues = (props: { key: keysInputsDynamic }) => {
        const valuesCleaned = {};
        switch (props.key) {
            case 'respuesta':
                Object.assign(valuesCleaned, {
                    observacion: null,
                    charlaPreTarea: null,
                    personal: [],
                    images: [],
                    estadoEquipo: null,
                    RecursoPreventivo: null
                });
                break;
            case 'estadoEquipo':
                Object.assign(valuesCleaned, {
                    observacion: null,
                    charlaPreTarea: null,
                    personal: [],
                    images: []
                });
                break;
            case 'observacion':
            case 'charlaPreTarea':
            case 'personal':
            case 'images':
                break;
        }

        return valuesCleaned;
    }

    return { formRegistro, onChange, autoCompleteOnSelectOT }
}

const ControllerFormDynamic = (props: { keyTab: number; setKeyTab: React.Dispatch<React.SetStateAction<number>>; OT: EntitySelectOTs; repository: RepositoryMain; formRegistroMain: FormikProps<EntityDataForm>; moduleForm: EntityModuleForm; setSelectInformation: React.Dispatch<React.SetStateAction<EntityLoadData>>, selectInformation: EntityLoadData }) => {
    const [question, setQuestion] = useState<EntityLoadQuestion>({ AutochequeoItems: [], AutoChequeoTipologia: [], AutochequeoTitulos: [] });
    const [tipologiaCP, setTologiaCP] = useState<EntityTipologiaCP[]>([]);
    const [listQuestionShow, setListQuestionShow] = useState<EntityAutochequeoItems[]>([]);
    const [titleQuestionGroupToDisplay, setTitleQuestionGroupToDisplay] = useState<EntityAutochequeoTitulos>({} as EntityAutochequeoTitulos);
    const [schemaDynamic, setSchemaDynamic] = useState({} as any);
    const [conditionals, setConditionals] = useState<EntityExternalConditionals[]>([]);
    const languageTranslate = LanguageTranslate();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formRegistro = useFormik({
        initialValues: {} as any,
        validationSchema: Yup.object(schemaDynamic),
        onSubmit: (values, formikHelpers) => { },
        enableReinitialize: true
    });

    useEffect(() => {
        const conditionals = loadConditionals();
        updateSchema(conditionals);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listQuestionShow])

    useEffect(() => {
        updateListQuestionShow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [question.AutochequeoTitulos, props.keyTab])

    useEffect(() => {
        formRegistro.setValues({ ...props.formRegistroMain.values.grupoPreguntas[props.keyTab], ...props.formRegistroMain.values.grupoTitulos[props.keyTab] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formRegistroMain.values, props.keyTab]);

    useEffect(() => {
        loadTitlesCP();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.moduleForm, props.OT])

    const loadTitlesCP = async () => {
        const OTSelected = props.OT?.dataComplete;

        if (props.moduleForm.key !== 'CP') return;
        if (!(props.keyTab === -1)) return;
        if (!OTSelected) return;

        dispatch(addLoading({ textLoading: languageTranslate.moduloAutoChequeo.form.textCargandoTipologias }))

        const response = await new UseCaseLoadTipologiaCP(props.repository).exec(OTSelected);
        const result = response.AutoChequeoTipologia.reduce((total, currentValue, currentIndex) => {
            const idFamiliaTipologia = currentValue.FamiliaTipologia.IdFamiliaTipologia;
            const findIndex = total.findIndex(item => item.idFamilia === idFamiliaTipologia);
            if (findIndex === -1) {
                total.push({
                    idFamilia: idFamiliaTipologia,
                    titleFamilia: currentValue.FamiliaTipologia.Familia,
                    items: [currentValue]
                })
            } else {
                total[findIndex].items.push(currentValue);
            }
            return total;
        }, [] as EntityTipologiaCP[])

        setTologiaCP(result);
        dispatch(removeLoading());

        if (response.AutoChequeoTipologia.length === 0) AdapterGenerico.createMessage(languageTranslate.textoAlerta, `${languageTranslate.moduloAutoChequeo.form.advertenciaOTSinTipologias} - ${prevRegister?.OT.OT}`, 'warning', false);
    }

    const loadQuestions = async (moduleKey?: string, OT?: EntityOTs, typeMomentPreview?: number, arrTipologiaPreview?: EntityAutochequeoTipologia[]) => {
        if (!moduleKey && question.AutoChequeoTipologia.length > 0) return;

        dispatch(addLoading({ textLoading: languageTranslate.moduloAutoChequeo.form.textCargandoPreguntas }))
        const OTSelected = OT || props.OT.dataComplete;
        let responseQuestions: EntityLoadQuestion = { AutochequeoItems: [], AutoChequeoTipologia: [], AutochequeoTitulos: [] };
        const moduleKey_ = moduleKey || props.moduleForm.key;
        
        switch (moduleKey_) {
            case 'CP':
                responseQuestions = await new UseCaseLoadQuestionsCP(props.repository).exec(OTSelected, props.formRegistroMain.values.Tipologia);
                if (prevRegister) {
                    const resultMEDHUMRegistered = prevRegister.GrupoPreguntas.find(row => row.Titulo === 'MEDIOS HUMANOS');
                    const resultMEDHUM = responseQuestions.AutochequeoTitulos.find(row => 
                        resultMEDHUMRegistered?.IdTitulo === row.IdTitulo &&
                        resultMEDHUMRegistered.Titulo === row.Titulo
                    );

                    const listQuestionMEDHUM = responseQuestions.AutochequeoItems.filter(item =>
                        JSON.parse(item.TipologiaArray).some((tpl: EntityAutochequeoItemsTipologia) => tpl.Titulo.IdTitulo === resultMEDHUM?.IdTitulo) &&
                        resultMEDHUMRegistered?.Preguntas.some(row => row.IdPregunta === item.IdRegistro)
                    );

                    const autoCompleteValue = listQuestionMEDHUM.reduce((prev, current, index) => {
                        const value = resultMEDHUMRegistered?.Preguntas[index];
                        const disponibilidad: EntityAutochequeoItemsDisponibilidad = JSON.parse(current.Disponibilidad).find((row: EntityAutochequeoItemsDisponibilidad) => row.Respuesta === value?.ResDisponibilidad);
                        prev[current.IdRegistro] = {
                            "observacion": value?.ObservacionIngresada,
                            "charlaPreTarea": null,
                            "personal": [],
                            "images": value?.NombreFotos,
                            "estadoEquipo": value?.ResEstado || null,
                            "respuesta": value?.ResDisponibilidad || null
                        }
                        if (disponibilidad.Personal === 1) prev[current.IdRegistro]["personal"] = prevRegister?.Personal
                        if (disponibilidad.CharlaPreTarea === 1) prev[current.IdRegistro]["charlaPreTarea"] = prevRegister?.Archivos;

                        return prev;
                    }, {} as any);

                    props.formRegistroMain.setValues({
                        ...props.formRegistroMain.values,
                        grupoPreguntas: {
                            ...props.formRegistroMain.values.grupoPreguntas,
                            1: autoCompleteValue
                        }
                    })

                    if (resultMEDHUM) {
                        const findIndex = responseQuestions.AutochequeoTitulos.findIndex(row => row.IdTitulo === resultMEDHUM.IdTitulo);
                        responseQuestions.AutochequeoTitulos.splice(findIndex, 1);
                        //responseQuestions.AutochequeoTitulos[findIndex].Ocult = true;
                    }
                }
                break;
            case 'AUT':
                responseQuestions = await new UseCaseLoadQuestionsAUT(props.repository).exec(OTSelected);
            break;
            case 'CPT':
            case 'CPTGuest':
                let paramsCPT = {
                    IdPais: OTSelected?.Pais?.IdPais || props.formRegistroMain.values.OTGuest?.dataComplete.IdPais || 0,
                    CodigoGrupo: OTSelected?.Grupo?.Codigo || props.formRegistroMain.values.OTGuest.dataComplete?.CodigoGrupo || '',
                    IdDelegacion: OTSelected?.Delegacion?.IdDelegacion || props.formRegistroMain.values.OTGuest.dataComplete?.IdDelegacion || 0,
                    IdActividad: OTSelected?.Actividad?.IdActividad || props.formRegistroMain.values?.Actividad?.dataComplete?.IdActividad || props.formRegistroMain.values?.OTGuest.dataComplete?.IdActividad || 0
                };
                responseQuestions = await new UseCaseLoadQuestionsCPT(props.repository).exec(paramsCPT.IdPais, paramsCPT.CodigoGrupo, paramsCPT.IdDelegacion, paramsCPT.IdActividad);

                if (!isRegisterIncomplete) {
                    // Autocompletar riesgos por su campo default
                    const findItem = responseQuestions.AutochequeoItems.find(row => row.TypeInput === 'DES-TIPO-RIESGOS');
                    const findItemIndex = responseQuestions.AutochequeoTitulos.findIndex(row => row.CodigoTitulo === AdapterConfigure.KEYS_FORM.groupQuestion.CharlaPretarea);
                    const dataAutocomplete = {
                        [findItemIndex + 1]: {
                            [(findItem?.IdRegistro || 0)]: {
                                riesgos: responseQuestions.optionsCustom?.TipoRiesgo.reduce((prev: any, current) => {
                                    prev[current.dataComplete.IdTipoRiesgo] = {
                                        value: current.dataComplete.valorDefecto ? 'Si' : 'No',
                                        dataComplete: current.dataComplete
                                    };
                                    return prev;
                                }, {})
                            }
                        }
                    };
                    if (findItem && findItemIndex !== -1) props.formRegistroMain.setFieldValue('grupoPreguntas', dataAutocomplete);
                }

                if (responseQuestions.optionsCustom) props.setSelectInformation(prev => ({...prev, ...responseQuestions.optionsCustom}));
            break;
            case "CCP":
                responseQuestions = await new UseCaseLoadQuestionsCCP(props.repository).exec(OTSelected);
                responseQuestions.AutochequeoItems = responseQuestions.AutochequeoItems.map((item) => ({ ...item, Disponibilidad: item.OpcionesCierreCP }))
                if (responseQuestions.AutochequeoItems.length === 0) {
                    dispatch(removeLoading());
                    AdapterGenerico.createMessage(languageTranslate.textoAlerta, languageTranslate.moduloAutoChequeo.form.advertenciaOTSinPreguntas, 'error', false).then(() => {
                        navigate(AdapterConfigure.REDIRECT_GESTION);
                    });
                    return;
                }
                setTitleQuestionGroupToDisplay(() => responseQuestions.AutochequeoTitulos[0]);
                setListQuestionShow(responseQuestions.AutochequeoItems);
                break;
            case 'PREVIEW':
                if (typeMomentPreview === 1) responseQuestions = await new UseCaseLoadQuestionsAUT(props.repository).exec(OTSelected);
                if (typeMomentPreview === 2) responseQuestions = await new UseCaseLoadQuestionsCP(props.repository).exec(OTSelected, arrTipologiaPreview || [], true);
                if (typeMomentPreview === 3) {
                    let paramsCPTPreview = {
                        IdPais: prevRegister?.Pais.IdPais || 0,
                        CodigoGrupo: '',
                        IdDelegacion: prevRegister?.IdDelegacion || 0,
                        IdActividad: prevRegister?.IdActividad || 0
                    };
                    responseQuestions = await new UseCaseLoadQuestionsCPT(props.repository).exec(paramsCPTPreview.IdPais, paramsCPTPreview.CodigoGrupo, paramsCPTPreview.IdDelegacion, paramsCPTPreview.IdActividad);
                    if (responseQuestions.optionsCustom) props.setSelectInformation(prev => ({...prev, ...responseQuestions.optionsCustom}));
                }
                setTitleQuestionGroupToDisplay(() => responseQuestions.AutochequeoTitulos[0]);
                break;
        }

        setQuestion(responseQuestions);

        if (typeMomentPreview !== undefined) props.setKeyTab(1);
        dispatch(removeLoading());
    }

    const loadConditionals = () => {
        let conditionals_: EntityExternalConditionals[] = [];
        listQuestionShow.forEach(quest => {
            const _conditionals: any[] = JSON.parse(quest.Disponibilidad)?.filter((item: EntityAutochequeoItemsDisponibilidad) => ![0, "", null].includes(item.IdHijo));
            conditionals_ = conditionals_.concat(_conditionals.map((item) => ({ idHijo: item.IdHijo, valueMatch: item.Respuesta, idParent: quest.IdRegistro, result: 'show' })));
        })
        setConditionals(() => conditionals_);

        return conditionals_;
    }

    const updateListQuestionShow = () => {
        if (props.keyTab - 1 < 0) return; // keytab puede ser inclusive negativo
        const AutochequeoTituloSelected = question.AutochequeoTitulos[props.keyTab - 1];

        // Validar si AUT tiene mas de un título, este por defecto siempre tendrá 1
        if ((question.AutochequeoTitulos.length <= 1 || (question.AutochequeoItems.length === 3 && question.AutochequeoItems.filter(row => row.Momento.Momento === 'NEW').length === 3)) && props.moduleForm.key === 'AUT') {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, languageTranslate.moduloAutoChequeo.form.advertenciaOTSinPreguntasAutochequeo, 'warning', false);
            props.setKeyTab(0);
        }

        // Filtrar preguntas
        let listQuestionShow = question.AutochequeoItems.filter(item =>
            JSON.parse(item.TipologiaArray).some((tpl: EntityAutochequeoItemsTipologia) => (AutochequeoTituloSelected?.adittionalIdTitle || []).includes(tpl.Titulo.IdTitulo) && tpl.Titulo.CodigoTitulo === AutochequeoTituloSelected.CodigoTitulo)
        );

        // Ordenar preguntas
        listQuestionShow = listQuestionShow.map(item => {
            const tipologiaFinded: EntityAutochequeoItemsTipologia | null = (JSON.parse(item.TipologiaArray) || []).find((tpl: EntityAutochequeoItemsTipologia) => tpl.Titulo.IdTitulo === AutochequeoTituloSelected?.IdTitulo)
            if (!tipologiaFinded) return item;
            return { ...item, Orden: item.Orden || tipologiaFinded.Orden };
        }).sort((a, b) => a.Orden - b.Orden);
        console.log(listQuestionShow)

        // Validar si tiene preguntas
        if (listQuestionShow.length === 0 && props.keyTab === 1) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, languageTranslate.moduloAutoChequeo.form.advertenciaOTSinPreguntasAutochequeo, 'warning', false);
            setQuestion({ AutochequeoItems: [], AutoChequeoTipologia: [], AutochequeoTitulos: [] });
            if (props.moduleForm.key === 'CP') props.setKeyTab(-1)
            else props.setKeyTab(0);

            return;
        }

        if ([AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos].includes(AutochequeoTituloSelected?.CodigoTitulo) && !(listQuestionShow.length === 0)) {
            // ## Información hardcodeada solo para medios humanos! ## \\
            // Eliminar imágen y observación grupal
            Object.assign(AutochequeoTituloSelected, { Requerimientos: { Fotografia: 0, Observacion: 0 } })

            
            // Autocompletar Valores
            // AUT default -> SI | CPT default -> NO
            let autoComplete = props.moduleForm.key === 'AUT' ? 'SI' : (['CPT', 'CPTGuest'].includes(props.moduleForm.key) ? 'NO' : '');

            if (!!autoComplete && !isRegisterIncomplete) {
                const valuesGroup = (props.formRegistroMain.values.grupoPreguntas["1"] || {});
                props.formRegistroMain.setValues({
                    ...props.formRegistroMain.values,
                    grupoPreguntas: {
                        ...props.formRegistroMain.values.grupoPreguntas,
                        1: {
                            ...valuesGroup,
                            [listQuestionShow[0].IdRegistro]: {
                                ...(valuesGroup[listQuestionShow[0]?.IdRegistro] || {}),
                                'respuesta': autoComplete
                            }
                        }
                    }
                })
            }
            
            // Pregunta 1 - hardcodear
            const disponibilidadQuest1: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(listQuestionShow[0].Disponibilidad);
            if (props.moduleForm.key === 'AUT') {
                listQuestionShow[0].Help = languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaModuloAUT
                disponibilidadQuest1[1].BlockForModule = ['AUT'];
            }

            // Ocultar la primera pregunta si esta en modo visualizar
            // if (props.moduleForm.key === 'PREVIEW') listQuestionShow[0].VisibilidadPregunta = 0;

            if (props.moduleForm.key === 'CPT') {
                listQuestionShow[0].Help = languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaModuloCPT
                disponibilidadQuest1[0].BlockForModule = ['CPT', 'CPTGuest'];
            }
            listQuestionShow[0].Disponibilidad = JSON.stringify(disponibilidadQuest1);

            // Pregunta 2 - hardcodear
            const disponibilidadQuest2: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(listQuestionShow[1].Disponibilidad);
            disponibilidadQuest2[1].EnviarCorreo = 1;
            listQuestionShow[1].Disponibilidad = JSON.stringify(disponibilidadQuest2);
            listQuestionShow[1].VisibilidadPregunta = 0;
            
            // Pregunta 3 - hardcodear
            const disponibilidadQuest3: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(listQuestionShow[2].Disponibilidad);
            disponibilidadQuest2[1].EnviarCorreo = 1;
            listQuestionShow[2].Disponibilidad = JSON.stringify(disponibilidadQuest3);
            listQuestionShow[2].VisibilidadPregunta = 0;
        }

        // Actualizar preguntas
        setTitleQuestionGroupToDisplay(() => ({...AutochequeoTituloSelected}));
        setListQuestionShow(() => ([...listQuestionShow]));
    }

    const updateSchema = (conditionals_: EntityExternalConditionals[]) => {
        const grupoPreguntas = listQuestionShow.reduce((result, nextItem, currentIndex) => {
            const conditionFinded = conditionals_.find((condition) => condition.idHijo === nextItem.IdRegistro) || { idParent: 0, valueMatch: "" };
            result[nextItem.IdRegistro] = Yup.object().when([conditionFinded.idParent.toString()], {
                // validación por respuesta
                is: (valueParent: any) => (nextItem.VisibilidadPregunta === 1 || valueParent?.respuesta === conditionFinded.valueMatch),
                then: Yup.object().required(`${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaNoRespondido} ${currentIndex + 1}`).nullable()
                    // validación por estadoEquipo
                    .test('validarEstadoEquipo', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaNoEstadoEquipo} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;
                        const arrDisponibilidad: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(nextItem.Disponibilidad)
                        const disponibilidad = arrDisponibilidad.find(item => item.Respuesta === value.respuesta);
                        if (!disponibilidad) return true;
                        if (disponibilidad.Estados !== 1) return true;
                        if ([null, undefined, ""].includes(value.estadoEquipo)) return false;

                        return true;
                    })
                    // validación por observación
                    .test('validarObservacion', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaNoObservacion} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;

                        // validación normal
                        const arrDisponibilidad: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(nextItem.Disponibilidad)
                        const disponibilidad = arrDisponibilidad.find(item => (item.Respuesta || item.Opcion) === value.respuesta);
                        if (!disponibilidad) return true;
                        if (disponibilidad.Observacion !== 1) return true;
                        if ([null, undefined, ""].includes(value.observacion)) return false;

                        return true;
                    })
                    // validación por observación por Estado de equipo
                    .test('validarObservacionEquipo', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaNoObservacion} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;

                        const arrEstados: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(nextItem.Estados)
                        const estado = arrEstados.find(item => item.Respuesta === value.estadoEquipo);
                        if (!estado) return true;
                        if (estado.Observacion !== 1) return true;
                        if ([null, undefined].includes(value.observacion)) return false;

                        return true;
                    })
                    // validación por audio por Estado de equipo
                    .test('validarAudioEquipo', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaNoAudio} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;

                        // validación para estado de equipo
                        const arrEstados: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(nextItem.Estados)
                        const estado = arrEstados.find(item => item.Respuesta === value.estadoEquipo);
                        if (!estado) return true;
                        if (estado.CharlaPreTarea !== 1) return true;
                        if ([null, undefined].includes(value.charlaPreTarea)) return false;

                        return true;
                    })
                    // validación por Imagen
                    .test('validarImagen', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaImagen} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;

                        // validación normal
                        const arrDisponibilidad: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(nextItem.Disponibilidad)
                        const disponibilidad = arrDisponibilidad.find(item => (item.Respuesta || item.Opcion) === value.respuesta);
                        if (!disponibilidad) return true;
                        if (!disponibilidad.CantidadFotos && !disponibilidad.Foto) return true;
                        if ([null, undefined].includes(value.images) || (value.images as any[] || []).length === 0) {
                            throw new Yup.ValidationError(
                                `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaImagen} ${currentIndex + 1}`,
                                value,
                                'validarImagenCustom'
                            );
                        };
                        // if ((value.images as any[]).length === 0) return false;
                        if ((value.images as any[]).length > (disponibilidad.CantidadFotos || disponibilidad.Foto || 0)) {
                            throw new Yup.ValidationError(
                                `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaNoImagen(disponibilidad.CantidadFotos || disponibilidad.Foto || 0)} ${currentIndex + 1}`,
                                value,
                                'validarImagenCustom'
                            );
                        };

                        // validación para estado de equipo
                        const arrEstados: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(nextItem.Estados)
                        const estado = arrEstados.find(item => item.Respuesta === value.estadoEquipo);
                        if (!estado) return true;
                        if (!estado.CantidadFotos) return true;
                        if ([null, undefined].includes(value.images) || (value.images as any[] || []).length === 0) {
                            throw new Yup.ValidationError(
                                `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaImagen} ${currentIndex + 1}`,
                                value,
                                'validarImagenCustom'
                            );
                        };
                        if ((value.images as any[]).length > estado.CantidadFotos) {
                            throw new Yup.ValidationError(
                                `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaNoImagen(estado.CantidadFotos)} ${currentIndex + 1}`,
                                value,
                                'validarImagenCustom'
                            );
                        };

                        return true;
                    })
                    // validación por Imagen por Estado de equipo
                    .test('validarImagenEquipo', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaImagen} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;

                        // validación para estado de equipo
                        const arrEstados: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(nextItem.Estados)
                        const estado = arrEstados.find(item => item.Respuesta === value.estadoEquipo);
                        if (!estado) return true;
                        if (!estado.CantidadFotos) return true;
                        if ([null, undefined].includes(value.images) || (value.images as any[] || []).length === 0) {
                            throw new Yup.ValidationError(
                                `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaImagen} ${currentIndex + 1}`,
                                value,
                                'validarImagenEquipoCustom'
                            );
                        };
                        if ((value.images as any[]).length > estado.CantidadFotos) {
                            throw new Yup.ValidationError(
                                `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaNoImagen(estado.CantidadFotos)} ${currentIndex + 1}`,
                                value,
                                'validarImagenEquipoCustom'
                            );
                        };

                        return true;
                    })
                    // validación por "blockear pase"
                    .test('validarBlock', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaBloqueo} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;
                        const arrDisponibilidad: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(nextItem.Disponibilidad)
                        const disponibilidad = arrDisponibilidad.find(item => (item.Respuesta || item.Opcion) === value.respuesta);
                        if (!disponibilidad) return true;
                        if (disponibilidad.Block === 1) return false;

                        return true;
                    })
                    // Remover devolver 1
                    /*.test('validarBlockDevolverOrden', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaBlockDevolverOrden(currentIndex + 1)}`, (value, context) => {
                        if (!value) return true;
                        // La validación solo es válida para lista autochequeo
                        if (props.moduleForm.key !== 'AUT') return true;
                        // Validar si la pregunta general, tiene devolver orden 1, si es así se detiene el flujo hasta que su respuesta sea correcta
                        const arrAnswer: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(nextItem.Disponibilidad);
                        const answer = arrAnswer.find(row => (row.Respuesta || row.Opcion) === value.respuesta);
                        if (!answer) return true;
                        if (answer.DevolverOrden === 1) return false;

                        // Validar si la subpregunta, tiene devolver orden 1, si es así se detiene el flujo hasta que su respuesta sea correcta
                        const arrEstados: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(nextItem.Estados)
                        const estado = arrEstados.find(item => item.Respuesta === value.estadoEquipo);
                        if (!estado) return true;
                        if (estado.DevolverOrden === 1) return false;

                        // retorna por default true
                        return true;
                    })*/
                    .test('validarBlockForModule', props.moduleForm.key === 'AUT' ? languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaModuloAUT : languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaModuloCPT, (value, context) => {
                        if (!value) return true;

                        // Validar si la pregunta general, tiene bloqueo por módulo 1, si es así se detiene el flujo
                        const arrAnswer: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(nextItem.Disponibilidad);
                        const answer = arrAnswer.find(row => (row.Respuesta || row.Opcion) === value.respuesta);
                        if (!answer) return true;
                        if ((answer.BlockForModule || []).includes(props.moduleForm.key)) return false;

                        // retorna por default true
                        return true;
                    })
                    // validación Personal
                    .test('validarPersonalCustom', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaNoPersonal} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;
                        // Validar si la pregunta general, tiene devolver orden 1, si es así se detiene el flujo hasta que su respuesta sea correcta
                        const arrAnswer: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(nextItem.Disponibilidad);
                        const answer = arrAnswer.find(row => (row.Respuesta || row.Opcion) === value.respuesta);
                        if (!answer) return true;

                        // Validar si el TypeInput tiene PERSONAL
                        if (answer.TypeInput === 'PERS-MP-MULT') {
                            if ([null, undefined].includes(value.personal)) return false;
                            if ((value.personal as any[]).length === 0) return false;
                        }

                        // retorna por default true
                        return true;
                    })
                    // validación Jefe Trabajo
                    .test('validarJefeTrabajo', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaJefeTrabajo} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;
                        // Validar si la pregunta general, tiene devolver orden 1, si es así se detiene el flujo hasta que su respuesta sea correcta
                        const arrAnswer: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(nextItem.Disponibilidad);
                        const answer = arrAnswer.find(row => (row.Respuesta || row.Opcion) === value.respuesta);
                        if (!answer) return true;

                        // Validar EL Jefe de Trabajo
                        if (answer.TypeInput === 'PERS-MP-MULT') {
                            if ([null, undefined].includes(value.personal)) return false;
                            if ((value.personal as any[]).length === 0) return false;
                            if ([null, undefined].includes(value.PersonalResponsableTrabajo)) return false;
                        }

                        // retorna por default true
                        return true;
                    })
                    // Validar Riesgo
                    .test('validarRiesgo', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaRiesgo} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;
                        
                        // Validar el tipo de input sea Riegos
                        if (nextItem.TypeInput !== 'DES-TIPO-RIESGOS') return true;
                        
                        // Validar si escogío mínimo un riesgo
                        const riesgosSeleccionados = Object.values(value?.riesgos || {});
                        if (riesgosSeleccionados.length === 0) return false;

                        // Validar si no ingreso el comentario de otros, si se marcó
                        if (!value?.otroRiesgo && riesgosSeleccionados.some((row: any) => row.value === "Si" && row?.dataComplete?.opcionPregunta?.codigo === AdapterConfigure.KEYS_FORM.specialInputs.indicarOtrosRiesgos)) {
                            throw new Yup.ValidationError(
                                `Ingresar comentario en Riesgo`,
                                value,
                                'validarOtroRiesgo'
                            );
                        }

                        // if (riesgosSeleccionados.length !== props.selectInformation.TipoRiesgo.length) return false;

                        // retorna por default true
                        return true;
                    })
                    // Validar Medidas Preventivas
                    .test('validarMedidasPreventivas', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaMedidaPreventiva} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;
                        
                        // Validar el tipo de input sea Medidas Preventivas
                        if (nextItem.TypeInput !== 'DES-MEDIDAS-PREV') return true;
                        const medidasPreventivasSeleccionados = Array.isArray(value?.medidasPreventivas) ? value.medidasPreventivas : [];

                        // Valida si hay opciones por escoger
                        const itemsMedidasPreventivas = props.selectInformation.MedidasPreventivas.filter(row =>
                            (props.formRegistroMain.values.Riesgos || []).find((_row: any) => row.dataComplete.tipoRiesgo.includes(_row.dataComplete.codigo))
                        )
                        if (medidasPreventivasSeleccionados.length === 0 && itemsMedidasPreventivas.length > 0) return false;

                        // Validar si no ingreso el comentario de otros, si se marcó
                        if (!value?.otroMedidasPreventivas && medidasPreventivasSeleccionados.some((row: any) => row?.dataComplete?.opcionPregunta?.codigo === AdapterConfigure.KEYS_FORM.specialInputs.indicarOtroMedidasPreventivas)) {
                            throw new Yup.ValidationError(
                                `Ingresar comentario en Medidas Preventivas`,
                                value,
                                'validarOtroMedidasPreventivas'
                            );
                        }

                        // retorna por default true
                        return true;
                    })
                    // Validar EPCS
                    .test('validarEPCS', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaEPCS} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;
                        
                        // Validar el tipo de input EPCS
                        if (nextItem.TypeInput !== 'DES-EPCS') return true;
                        const epcsSeleccionados = Array.isArray(value?.epcs) ? value.epcs : [];

                        // Valida si hay opciones por escoger
                        const itemsMedidasPreventivas = props.selectInformation.EPCS.filter(row =>
                            (props.formRegistroMain.values.Riesgos || []).find(_row => row.dataComplete.tipoRiesgo.includes(_row.dataComplete.codigo))
                        )
                        if (epcsSeleccionados.length === 0 && itemsMedidasPreventivas.length > 0) return false;

                        // Validar si no ingreso el comentario de otros, si se marcó
                        if (!value?.otroEPCS && epcsSeleccionados.some((row: any) => row?.dataComplete?.opcionPregunta?.codigo === AdapterConfigure.KEYS_FORM.specialInputs.indicarOtroEpcs)) {
                            throw new Yup.ValidationError(
                                `Ingresar comentario en EPCS`,
                                value,
                                'validarOtroEPCS'
                            );
                        }

                        // retorna por default true
                        return true;
                    })
                    // Validar Recurso Preventivo
                    .test('validarRecursoPreventivo', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaRecursoPreventivo} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;
                        // Validar si la pregunta general, tiene devolver orden 1, si es así se detiene el flujo hasta que su respuesta sea correcta
                        const arrAnswer: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(nextItem.Disponibilidad);
                        const answer = arrAnswer.find(row => (row.Respuesta || row.Opcion) === value.respuesta);
                        if (!answer) return true;

                        // Validar si el TypeInput tiene PERSONAL
                        if (answer.TypeInput === 'DES-PERS-MEPR') {
                            if ([null, undefined].includes(value.RecursoPreventivo)) return false;
                        }

                        // retorna por default true
                        return true;
                    })
                    // Validar Audio
                    .test('validarAudio', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaNoAudio} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;

                        // validación normal
                        if (nextItem.TypeInput !== 'GUION-AUDIO') return true;
                        if ([null, undefined].includes(value.charlaPreTarea)) return false;

                        return true;
                    })
                    // Validar Firma
                    .test('validarFirma', `${languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaFirmaJefeTrabajo} ${currentIndex + 1}`, (value, context) => {
                        if (!value) return true;

                        // validación normal
                        if (nextItem.TypeInput !== 'FIR-OBLI-JEFE') return true;
                        if ([null, undefined].includes(value.firma)) return false;

                        return true;
                    })
            })

            return result;
        }, {} as any)

        const grupoTitulos: any = {};
        if (titleQuestionGroupToDisplay?.Requerimientos?.Fotografia === 1) Object.assign(grupoTitulos, { images: Yup.array().required(languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaNoImagenGrupal).nullable() })
        if (titleQuestionGroupToDisplay?.Requerimientos?.Observacion === 1) Object.assign(grupoTitulos, { observacion: Yup.string().required(languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaNoObservacionGrupal).nullable() })

        setSchemaDynamic(() => ({ ...grupoPreguntas, ...grupoTitulos }));
    }

    const validatedValues = async () => AdapterValidator.validate(await formRegistro.validateForm());

    return {
        loadQuestions,
        conditionals,
        validatedValues,
        listQuestionShow,
        titleQuestionGroupToDisplay,
        question,
        tipologiaCP
    }
}