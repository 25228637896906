// Library
import { memo } from 'react';
import './Style.scss';

// Components
import { InputCheck, InputSelect, InputSelectPersonnel } from '../../../shared/Components/ElementInputsCostume';
import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import ElementModalCostume from "../../../shared/Components/ElementModalCostume";
import { ElementEmptyList } from '../../../shared/Components/ElementEmptyList';
import { ViewItem } from './ViewItem';

// Infraestructure
import { ListFilterOptionSelect } from '../../../shared/Infraestructure/ListFilterOptionSelect';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';

// Domain
import { EntityAutochequeo } from "../../../shared/Domain/EntityAutochequeo";
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';
import { EntityFormatChronometer, EntityInitialData } from '../Domain/Utilts';
import { ElementSkeleton } from '../../../shared/Components/ElementSkeleton';

interface Props {
    IdAudio: number;
    isReproduce: boolean;
    data: EntityAutochequeo[];
    formRegistro: {
        values: any;
        touched: any;
        errors: any;
        handleBlur: Function;
    };
    loadData: () => void;
    onChange: (name: string, value: any) => void;
    loading: boolean;
    showModal: boolean;
    onSetModal: (valor: boolean) => void;
    onSearchSubmit: Function;
    onDownloadPdf: Function;
    onSetReproducirAudio: Function;
    onPlay: Function;
    redirectGestion: Function;
    redirectPreview: (_id: string) => void;
    user: EntityDataUsuario;
    redirectCharlaPreTarea: () => void;
    configDataView: EntityInitialData;
    openModalListaComentarios: Function;
    openModalPDF: (params: any) => Promise<void>
    playTimeAudio: EntityFormatChronometer;
    paginationList: () => Promise<void>;
    configList: { loading: boolean, completeLoad: boolean };
}

const View = (props: Props) => {
    const languageTranslate = LanguageTranslate();
    const { onChange, formRegistro } = props;

    return (
        <>
            <ElementModalCostume
                show={props.showModal}
                closeModal={() => props.onSetModal(!props.showModal)}
                title={languageTranslate.moduloAutoChequeo.list.search.Titulo}
                height='480px'
            >
                <div className="row pb-3 ViewAutochequeoList">
                    <div className="col-12">
                        <div className="row">
                            <div className='col-12 topInput'>
                                <InputCheck
                                    label={languageTranslate.moduloAutoChequeo.list.search.BusquedaGrupal}
                                    name='group'
                                    onChange={onChange}
                                    values={formRegistro.values}
                                    isHorizontal
                                />
                                <InputCheck
                                    label={languageTranslate.moduloAutoChequeo.list.search.BuscarRegistrosActivos}
                                    name='active'
                                    onChange={onChange}
                                    values={formRegistro.values}
                                    isHorizontal
                                />
                                <hr />
                            </div>
                            {
                                formRegistro.values.group ? 
                                    <div className="col-12 topInput">
                                        <InputSelect
                                            label={languageTranslate.moduloRDI.form.textoDelegacion}
                                            options={props.configDataView.delegacion}
                                            values={formRegistro.values}
                                            onChange={props.onChange}
                                            className='form-group w-100'
                                            disabledVirtualized
                                            name='Delegacion'
                                            isRequired
                                        />
                                    </div>
                                :
                                    <div className="col-12 topInput">
                                        
                                        <InputSelectPersonnel
                                            label={
                                                languageTranslate.moduloInspeccion.form.stepper.stepper3.content
                                                .personal
                                            }
                                            name="Personal"
                                            onChange={props.onChange}
                                            values={props.formRegistro.values}
                                            options={props.configDataView.personal}
                                            arrFilterOption={ListFilterOptionSelect.personal}
                                            isClearable={false}
                                            isAsync={true}
                                            delaySearch={1000}
                                            controlShouldRenderValue
                                            isRequired
                                        />
                                    </div>
                            }
                            <div className="col-12 mb-2">
                                <label className="mb-1">{languageTranslate.moduloAutoChequeo.list.search.FechaInicial} <span className="text-danger">*</span></label>
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={(value) => onChange('FechaInicial', value.target.value)}
                                    value={[formRegistro.values['FechaInicial']]}
                                />
                            </div>
                            <div className="col-12 topInput mb-2">
                                <label className="mb-1">{languageTranslate.moduloAutoChequeo.list.search.FechaFinal} <span className="text-danger">*</span></label>
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={(value) => onChange('FechaFinal', value.target.value)}
                                    value={[formRegistro.values['FechaFinal']]}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between mt-5 mb-3 enviar-email">
                    <button onClick={(evt) => {
                        props.onSearchSubmit(evt);
                    }} className="btn btn-primary w-100" style={{ minWidth: 120 }} > {languageTranslate.moduloAutoChequeo.list.search.BtnBuscar} </button>
                </div>
            </ElementModalCostume>

            <div className="ViewAutochequeoList">
                <div className="container-fluid">
                    <ElementBreadCrumb list={props.user.IdUsuario === 0 ? languageTranslate.moduloAutoChequeo.list.navbarGuest : languageTranslate.moduloAutoChequeo.list.navbar} />
                    {
                        props.user.IdUsuario === 0 ?
                            <div className="row">
                                <div className="col-12 text-end">
                                    <button className='btn btn-sm btn-primary btn-accion' onClick={props.redirectCharlaPreTarea}>+ {languageTranslate.moduloRDI.list.btnAgregar}</button>
                                </div>
                            </div>
                        : null
                    }

                    <div className="row pt-3 mb-5">
                        <div className="col-12 mb-5">
                            {
                                props.loading ?
                                    <>
                                        <ElementSkeleton styles={{ width: '100%', height: 200 }}/>
                                        <ElementSkeleton styles={{ width: '100%', height: 200 }}/>
                                        <ElementSkeleton styles={{ width: '100%', height: 200 }}/>
                                        <ElementSkeleton styles={{ width: '100%', height: 200 }}/>
                                    </>
                                :
                                <>
                                    {
                                        props.data.filter(c => c.IdControlPreventivo === 0).map((item, key) =>
                                            <ViewItem
                                                key={`viewItem${item.Id}${item.Procesos?.Registrar?.Fecha}`}
                                                IdAudio={props.IdAudio}
                                                isReproduce={props.isReproduce}
                                                data={item}
                                                subData={props.data.filter(c => (c.Estado?.IdEstado || 0) !== -2 && c.IdControlPreventivo !== 0 && c.Momento.IdMomento === 2)}
                                                onSetReproducirAudio={props.onSetReproducirAudio}
                                                onPlay={props.onPlay}
                                                onDownloadPdf={props.onDownloadPdf}
                                                openModalPDF={props.openModalPDF}
                                                redirectGestion={props.redirectGestion}
                                                redirectPreview={props.redirectPreview}
                                                openModalListaComentarios={props.openModalListaComentarios}
                                                playTimeAudio={props.playTimeAudio}
                                            />
                                        )
                                    }
                                    {
                                        props.configList.loading ?
                                        <div className='w-100' style={{
                                            margin: '20px 0',
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                            {languageTranslate.textoCargando}
                                        </div>
                                        :
                                            (
                                                !props.configList.completeLoad && !props.loading ?
                                                    <div className='w-100' style={{
                                                        margin: '20px 0',
                                                        display: 'flex',
                                                        justifyContent: 'center'
                                                    }} onClick={props.paginationList}>
                                                        Cargar mas
                                                    </div>
                                                : null
                                            )
                                    }
                                </>
                            }

                        </div>
                    </div>


                    {
                        props.data.length === 0 && (
                            <ElementEmptyList className="data-empty" text="Buscando Registro" loading={props.loading} />
                        )
                    }


                    {
                        props.user.IdUsuario === 0 ?
                            null :
                            <>
                                {
                                    props.user.DatosRol.Codigo !== "04U" ?
                                        <div className="position-fixed search bg-primary">
                                            <i className="fa-solid fa-magnifying-glass" style={{ fontSize: 20 }} onClick={() => {
                                                onChange('FechaInicial', "");
                                                onChange('FechaFinal', "");
                                                onChange('Identificacion', "");    
                                                props.onSetModal(true)
                                            }}></i>
                                        </div>
                                    : null
                                }
                                <div className="position-fixed reload bg-primary">
                                    <i className="fa-sharp fa-solid fa-arrow-rotate-right" style={{ fontSize: 20 }} onClick={props.loadData} />
                                </div>
                            </>
                    }


                </div>
            </div>
        </>

    );
};

export default memo(View);