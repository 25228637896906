import ReactDOM from 'react-dom/client';
import './index.scss';
import ManagerApp from './app';
import { Provider } from 'react-redux';
import { AdapterStore } from './context/shared/Infraestructure/AdapterStore';
import { LanguageTranslate } from './context/shared/Infraestructure/LanguageTranslate';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import reportWebVitals from './reportWebVitals';

window.addEventListener('load', () => window.history.pushState({ noBackExitsApp: true }, ''));
window.addEventListener('popstate', (event: PopStateEvent) => (event.state && event.state.noBackExitsApp) ? window.history.pushState({ noBackExitsApp: true }, '') : undefined);

document.documentElement.lang = LanguageTranslate().code;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <Provider store={AdapterStore}>
    <ToastContainer style={{ display: 'flex', paddingTop: 15, justifyContent: 'center' }}/>
    <ManagerApp />
  </Provider>
  // </React.StrictMode>
);

// reportWebVitals();
