import * as wavefile from 'wavefile';
import { simpleMp3 } from 'simple-mp3';

export function ConcatBlobs(blobs: Blob[], callback: (blob: Blob) => any) {
  const buffers: AudioBuffer[] = [];
  let result: AudioBuffer | null = null;
  let index = 0;

  function readAsArrayBuffer() {
    if (index >= blobs.length) {
      return resultBuffer();
    }

    const reader = new FileReader();
    reader.onload = function (e) {
      new AudioContext().decodeAudioData(e.target?.result as ArrayBuffer, function (buffer) {
        buffers.push(buffer);
        if (buffers.length > 1) {
          result = appendBuffer(buffers[0], buffers[1]);
          buffers.splice(0, 2, result);
        } else {
          result = buffer;
        }
        setTimeout(() => {
          readAsArrayBuffer(); // Llamar a la función de forma asíncrona
        }, 0);
      });
    };
    reader.readAsArrayBuffer(blobs[index]);
    index++;
  }

  readAsArrayBuffer();

  function appendBuffer(buffer1: AudioBuffer, buffer2: AudioBuffer): AudioBuffer {
    const numberOfChannels = Math.min(buffer1.numberOfChannels, buffer2.numberOfChannels);
    const tmp = new AudioContext().createBuffer(
      numberOfChannels,
      buffer1.length + buffer2.length,
      buffer1.sampleRate
    );

    for (let i = 0; i < numberOfChannels; i++) {
      const channel = tmp.getChannelData(i);
      channel.set(buffer1.getChannelData(i), 0);
      channel.set(buffer2.getChannelData(i), buffer1.length);
    }

    return tmp;
  }

  function resultBuffer() {
    const concatenatedBuffer = concatBuffers(buffers);

    const wav = new wavefile.WaveFile();
    wav.fromScratch(
      concatenatedBuffer.numberOfChannels,
      concatenatedBuffer.sampleRate,
      '32f',
      concatenatedBuffer.getChannelData(0)
    );

    const channelData: Float32Array[] = [];
    for (let i = 0; i < concatenatedBuffer.numberOfChannels; i++) {
      channelData.push(concatenatedBuffer.getChannelData(i));
    }

    const mp3Data = simpleMp3({
      channelData,
      sampleRate: concatenatedBuffer.sampleRate,
      quality: 320
    });

    const blob = new Blob(mp3Data, { type: 'audio/mpeg' });
    callback(blob);
  }

  function concatBuffers(buffers: AudioBuffer[]): AudioBuffer {
    const totalLength = buffers.reduce((length, buffer) => length + buffer.length, 0);
    const numberOfChannels = buffers[0].numberOfChannels;
    const sampleRate = buffers[0].sampleRate;
    const concatenatedBuffer = new AudioContext().createBuffer(numberOfChannels, totalLength, sampleRate);
    let offset = 0;

    buffers.forEach((buffer) => {
      for (let i = 0; i < numberOfChannels; i++) {
        const channel = concatenatedBuffer.getChannelData(i);
        const sourceData = buffer.getChannelData(i);
        channel.set(sourceData, offset);
      }
      offset += buffer.length;
    });

    return concatenatedBuffer;
  }
}