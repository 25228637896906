import { EntityOpcionAudio } from "../../../shared/Domain/Catalogos/EntityOpcionAudio";
import { LanguageApp } from "../../../shared/Infraestructure/LanguageTranslate";
import { EntityLoadQuestion } from "../Domain/EntityLoadQuestion";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { AdapterConfigure } from "../Infaestructure/AdapterConfigure";

export class UseCaseLoadQuestionsCPT {
    private repository: RepositoryMain;
    private IdMomentoAntesOperacion: number;
    private IdMomentoCierreControlPreventivo: number;
    private languageDefault: string;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
        this.IdMomentoAntesOperacion = 3;
        this.IdMomentoCierreControlPreventivo = 5;
        this.languageDefault = 'es';
    }

    public async exec(IdPais: number, CodigoGrupo: string, IdDelegacion: number, IdActividad: number): Promise<EntityLoadQuestion> {
        const [AutoChequeoTipologia, TipoPreguntas, PreguntasLA, EPCS, MedidasPreventivas, TipoRiesgo, OpcionAudio, hasQuestionInCCP] = await Promise.all([
            this.getAutochequeoTipologia(IdPais, IdDelegacion, IdActividad),
            this.getTipoPregunta(),
            this.getPreguntaLA(),
            this.getEPCS(),
            this.getMedidasPreventivas(),
            this.getTipoRiesgo(),
            this.getOpcionAudio(IdDelegacion, CodigoGrupo, IdActividad),
            this.hasQuestionInCCP(IdDelegacion, IdPais, IdActividad)
        ]);

        const codeLanguage = LanguageApp();
        return ({
            AutoChequeoTipologia,
            AutochequeoTitulos: TipoPreguntas,
            AutochequeoItems: PreguntasLA.map((row, index, arr) => {
                const titleQuestion = TipoPreguntas.find(_row => _row.CodigoTitulo === row.tipoPregunta.codigo) || {
                    _id: "",
                    CodigoTitulo: "",
                    IdTitulo: 0,
                    estado: []
                };

                return ({
                    _id: row._id,
                    IdRegistro: row.IdPreguntala,
                    Item: row.iteminIdioma.find(_row => _row.codigo === codeLanguage)?.nombre || row.iteminIdioma.find(_row => _row.codigo === this.languageDefault)?.nombre || row.iteminIdioma[0]?.nombre,
                    Help: row.iteminIdioma.find(_row => _row.codigo === codeLanguage)?.help || row.iteminIdioma.find(_row => _row.codigo === this.languageDefault)?.help || row.iteminIdioma[0]?.help || '',
                    Momento: {
                        IdMomento: titleQuestion?.IdTitulo || 0,
                        Momento: ""
                    },
                    Disponibilidad: JSON.stringify(row.disponibilidad.map(_row => ({
                        Respuesta: _row.respuesta,
                        Estados: _row.mostrarEstados ? 1 : 0,
                        Observacion: _row.requiereObservacion ? 1 : 0,
                        EnviarCorreo: 0,
                        DevolverOrden: 0,
                        CantidadFotos: _row.requiereFoto ? 1 : 0,
                        IdHijo: arr.find(__row => __row.codigo === _row.codigoHijo)?.IdPreguntala || 0,
                        NombreFoto: "",
                        TypeInput: _row.opcionPregunta.codigo
                    }))),
                    Estados: "[]",
                    VisibilidadPregunta: 1,
                    TipologiaArray: `[{ "IdTipologia": ${titleQuestion?.IdTitulo}, "CodigoTipologia": "${titleQuestion?.CodigoTitulo}", "Orden": 1, "Titulo": { "IdTitulo": ${titleQuestion?.IdTitulo}, "Titulo": "${titleQuestion?.CodigoTitulo}", "CodigoTitulo": "${titleQuestion?.CodigoTitulo}" } }]`,
                    Procesos: {},
                    IdPais: 0,
                    IdDelegacion: 0,
                    IdActividad: 0,
                    Orden: row.orden || index + 1,
                    TypeInput: row.opcionPregunta.codigo
                }) as any
            }).sort((a, b) => a.Orden - b.Orden),
            optionsCustom: {
                EPCS,
                MedidasPreventivas,
                TipoRiesgo,
                OpcionAudio
            },
            cierreControlPreventivoHasQuestion: hasQuestionInCCP
        })
    }

    private async getAutochequeoTipologia(IdPais: number, IdDelegacion: number, IdActividad: number) {
        const arr = await this.repository.getAutochequeoTipologia();
        return arr.filter(item => item.IdActividad === IdActividad && item.IdDelegacion === IdDelegacion && item.IdPais === IdPais && [this.IdMomentoAntesOperacion].includes(item.Momento.IdMomento));
    }

    private async getTipoPregunta() {
        const codeLanguage = LanguageApp();
        let response = (await this.repository.getTipoPreguntas()).filter(row => [AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos, AdapterConfigure.KEYS_FORM.groupQuestion.CharlaPretarea, AdapterConfigure.KEYS_FORM.groupQuestion.GrabacionAudio].includes(row.codigo));

        // Validar si hay items de medios humanos
        const responseMedHum = response.filter(row => [AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos].includes(row.codigo));
        if (responseMedHum.length === 0) 
            response = (await this.repository.reloadEntityPregunta()).filter(row =>
                [AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos, AdapterConfigure.KEYS_FORM.groupQuestion.CharlaPretarea, AdapterConfigure.KEYS_FORM.groupQuestion.GrabacionAudio].includes(row.codigo)
            );

        return response.map((row, index) => ({
            _id: row._id,
            Actividad: {
                Actividad: "",
                Codigo: "",
                IdActividad: 0
            },
            CodigoTitulo: row.codigo,
            Delegacion: {
                Codigo: "",
                Delegacion: "",
                IdDelegacion: 0,
            },
            Estado: {
                Estado: "Activo",
                IdEstado: 1,
            },
            IdTitulo: row.IdTipoPreguntas,
            adittionalIdTitle: [row.IdTipoPreguntas],
            Momento: {
                IdMomento: 0,
                Momento: ""
            },
            Orden: index + 1,
            Pais: {
                IdPais: 0,
                Pais: ""
            },
            Requerimientos: {
                Fotografia: 0,
                Observacion: 0,
                ObservacionCPT: row.requiereObservacion ? 1 : 0,
                FotografiaCPT: row.requiereFoto ? 1 : 0
            },
            Template: "",
            Tipologia: {
                CodigoTipologia: "",
                IdTipologia: 0,
                TipologíaTrabajo: ""
            },
            Titulo: (row.iteminIdioma.find(row => row.codigo === codeLanguage)?.nombre || row.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre || '').toUpperCase(),
        }));
    }

    private async getPreguntaLA() {
        let response = (await this.repository.getPreguntasLA()).filter(row => [AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos, AdapterConfigure.KEYS_FORM.groupQuestion.CharlaPretarea, AdapterConfigure.KEYS_FORM.groupQuestion.GrabacionAudio].includes(row.tipoPregunta.codigo));

        // Validar si hay items de medios humanos
        const responseMedHum = response.filter(row => [AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos].includes(row.tipoPregunta.codigo));
        if (responseMedHum.length === 0) 
            response = (await this.repository.reloadPreguntaLA()).filter(row =>
                [AdapterConfigure.KEYS_FORM.groupQuestion.MedioHumanos, AdapterConfigure.KEYS_FORM.groupQuestion.CharlaPretarea, AdapterConfigure.KEYS_FORM.groupQuestion.GrabacionAudio].includes(row.tipoPregunta.codigo)
            );

        return response;
    }

    private async getEPCS() {
        const languageApp = LanguageApp();
        const response = (await this.repository.getEPCS()).map(row => ({
            label: row.iteminIdioma.find(row => row.codigo === languageApp)?.nombre || row.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre || '',
            value: row.IdEPCS,
            dataComplete: row
        }));
        return response;
    }

    private async getMedidasPreventivas() {
        const languageApp = LanguageApp();
        const response = (await this.repository.getMedidasPreventivas()).map(row => ({
            label: row.iteminIdioma.find(row => row.codigo === languageApp)?.nombre || row.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre || '',
            value: row.IdMedidasPreventivas,
            dataComplete: row
        }));
        return response;
    }

    private async getTipoRiesgo() {
        const languageApp = LanguageApp();
        const icons: any = {
            "000002": "fa-solid fa-person-falling-burst",
            "000003": "fa-solid fa-bolt",
            "000004": "fa-solid fa-biohazard",
            "000005": "fa-solid fa-charging-station"
        }

        const response = (await this.repository.getTipoRiesgo()).map(row => ({
            label: row.iteminIdioma.find(row => row.codigo === languageApp)?.nombre ||row.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre || '',
            value: row.codigo,
            icon: icons[row.codigo] || 'fa-solid fa-person-falling-burst',
            dataComplete: row
        }));

        return response;
    }

    private async getOpcionAudio(IdDelegacion: number, CodigoGrupo: string, IdActividad: number) {
        const languageApp = LanguageApp();

        const response = (await this.repository.getOpcionAudio())
            .filter((row: EntityOpcionAudio) => row.grupo?.Codigo === CodigoGrupo)
            .map((row: EntityOpcionAudio) => ({
                label: row.iteminIdioma.find(row => row.codigo === languageApp)?.nombre || row.iteminIdioma.find(row => row.codigo === this.languageDefault)?.nombre || '',
                value: row.codigo,
                dataComplete: row
            }));

        let result = [];
        
        // Validación por Actividad y Delegación
        result = response.filter(row =>
            row.dataComplete.actividad?.IdActividad === IdActividad &&
            row.dataComplete.delegacion?.IdDelegacion === IdDelegacion
        )
        if (result.length > 0) return result;
        
        // Validación por Delegación
        result = response.filter(row =>
            row.dataComplete.delegacion?.IdDelegacion === IdDelegacion
        )
        if (result.length > 0) return result;

        // Validación por Actividad
        result = response.filter(row =>
            row.dataComplete.actividad?.IdActividad === IdActividad
        )
        if (result.length > 0) return result;

        // retorna default
        return response;
    }

    private async hasQuestionInCCP(IdDelegacion: number, IdPais: number, IdActividad: number): Promise<boolean> {
        const arr = await this.repository.getAutochequeoItems();
        return (arr.some(item =>
            item.IdActividad === IdActividad &&
            item.IdDelegacion === IdDelegacion &&
            item.IdPais === IdPais &&
            [this.IdMomentoCierreControlPreventivo].includes(item.Momento.IdMomento)
        ))
    }
}