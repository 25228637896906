import socketcluster from 'socketcluster-client';
import { AdapterMetrics } from './AdapterMetrics';

export class AdapterWebSocket {
    private host: string;
    private port: number;
    private secure: boolean;
    private path: string;
    private query: any;

    public online: boolean = false;
    public key: string = '';
    public socket: socketcluster.SCClientSocket | null = null;

    private events: {} = {};
    private channels: {} = {};

    constructor(host: string = 'localhost', port: number = 80, secure: boolean = false, path: string = '/sockercluster/', query: any = {}) {
        this.host = host;
        this.port = port;
        this.secure = secure;
        this.path = path;
        this.query = query;
    }

    public create() {
        try {
            let opts: socketcluster.SCClientSocket.ClientOptions = {
                hostname: this.host,
                port: this.port,
                autoConnect: false,
                secure: this.secure,
                path: this.path,
                connectTimeout: 10000,
                disconnectOnUnload: false,
                autoReconnect: true,
                autoReconnectOptions: { initialDelay: 10000, randomness: 10000, multiplier: 1.5, maxDelay: 60000 },
                ackTimeout: 600000,
                query: this.query
            };

            this.socket = socketcluster.create(opts);

            this.socket.on('connect', () => {
                this.online = true;
            });

            this.socket.on('disconnect', () => {
                this.online = false;
            });

            this.socket.on('error', (error) => {
                this.online = false;
            });

            this.socket.on('KeyEncrypt', (response: any) => {
                this.key = response.KeyEncrypt;
            });

        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public addEventListener(req: Array<{ event: string; listener: socketcluster.SCClientSocket.AnyFunction; }> | { event: string; listener: socketcluster.SCClientSocket.AnyFunction; }) {
        try {
            if (Array.isArray(req)) {
                for (let row of req) {
                    this.socket?.on(row.event, row.listener);
                    Object.assign(this.events, { [row.event]: row.listener });
                }
            }
            else {
                this.socket?.on(req.event, req.listener);
                Object.assign(this.events, { [req.event]: req.listener });
            }
        } catch (error) {
            throw error;
        }
    }

    public removeEventListener(req: Array<string> | string) {
        try {
            if (Array.isArray(req)) {
                for (let row of req) {
                    if (Reflect.has(this.events, row)) {
                        this.socket?.off(row, this.events[row as keyof typeof this.events]);
                        delete this.events[row as keyof typeof this.events];
                    }
                }
            }
            else {
                if (Reflect.has(this.events, req)) {
                    this.socket?.off(req, this.events[req as keyof typeof this.events]);
                    delete this.events[req as keyof typeof this.events];
                }
            }
        } catch (error) {
            throw error;
        }
    }

    public addChannelListener(req: Array<{ channel: string; listener: socketcluster.SCClientSocket.WatcherFunction }> | { channel: string; listener: socketcluster.SCClientSocket.WatcherFunction }) {
        try {
            if (Array.isArray(req)) {
                for (let row of req) {
                    this.socket?.subscribe(row.channel);
                    this.socket?.watch(row.channel, row.listener);
                    Object.assign(this.channels, { [row.channel]: row.listener });
                }
            }
            else {
                this.socket?.subscribe(req.channel);
                this.socket?.watch(req.channel, req.listener);
                Object.assign(this.channels, { [req.channel]: req.listener });
            }
        } catch (error) {
            throw error;
        }
    }

    public removeChannelListener(req: Array<string> | string) {
        try {
            if (Array.isArray(req)) {
                for (let row of req) {
                    if (Reflect.has(this.channels, row)) {
                        this.socket?.unwatch(row, this.channels[row as keyof typeof this.channels]);
                        this.socket?.unsubscribe(row);
                        delete this.channels[row as keyof typeof this.channels];
                    }
                }
            }
            else {
                if (Reflect.has(this.channels, req)) {
                    this.socket?.unwatch(req, this.channels[req as keyof typeof this.channels]);
                    this.socket?.unsubscribe(req);
                    delete this.channels[req as keyof typeof this.channels];
                }
            }
        } catch (error) {
            throw error;
        }
    }

    public init() {
        try {
            // if (this.socket !== null) { if (this.socket.state === 'closed') { this.socket.connect(); } }
            // else { throw new Error('No se puede inicar el socket sin antes haberlo creado'); }
        } catch (error) {
            throw error;
        }
    }

    public emit<T>(event: string, params: Object): Promise<T> {
        return new Promise((resolve, reject) => {
            const adapterMetrics = AdapterMetrics();
            adapterMetrics.startMetric();
            this.socket?.emit(event, params, (error: Error, response: T) => {
                if (!!error) { reject(error); return; }
                let countRegister = typeof response === 'string' ? JSON.parse(`${response}`).length : (response as Array<any> ?? []).length;
                adapterMetrics.endMetric({ serviceName: event, countRegister })
                resolve(response);
            })
        })

    }

}