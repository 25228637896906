import { AdapterGenerico } from '../Infraestructure/AdapterGenerico';
import { LanguageTranslate } from '../Infraestructure/LanguageTranslate';
import ImageCompression from 'browser-image-compression';
import Compressor from 'compressorjs';
import './ElementInputFileImage.scss'

interface PropsView {
    onChange: (fileList: File[]) => any;
    icon: string;
    disabled?: boolean;
    size?: number;
    capture?: boolean;
    multiple?: boolean;
};

const options: imageCompression.Options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
};

const compressImage = (image: File): Promise<File | Blob> => {
    return new Promise((resolve, reject) => {
        new Compressor(image, {
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            success: (compressedResult) => {
                resolve(compressedResult);
            },
            error(error) {
                reject(error);
            },
        });
    })
}

export const ElementInputFileImage = (props: PropsView) => {
    const languageTranslate = LanguageTranslate();
    const isImage = async (fileList: FileList | null) => {
        if (!fileList) return;
        let FileListParsed = Array.from(fileList);

        let error = { isError: false, fileName: '' };
        FileListParsed.forEach(file => {
            if (!file.type.match('image/*')) error = { isError: true, fileName: file.name }
        });

        if (error.isError) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, `${error.fileName} no es una imagen`, 'warning', false);
            return;
        }

        let newFileListParsed: Array<File> = [];

        for (let file of FileListParsed) {
            // console.log("file: ", file.size);
            let newFile1: File = await compressImage(file) as File;
            // console.log("file 1: ", newFile1.size);
            let newFile2: File = await ImageCompression(newFile1, options);
            // console.log("file 2: ", newFile2.size);
            newFileListParsed.push(newFile2);
        }
        // FileListParsed.map(async (file) => await ImageCompression(file, options));
        props.onChange(newFileListParsed);
    }

    return (
        <label className="ElementInputFileImage">
            <input type="file" accept="image/*" multiple={props.multiple} capture={props.capture} onChange={(evt) => isImage(evt.target.files)} disabled={props.disabled} value="" />
            <i className={props.icon} style={{ fontSize: props.size || 30 }} />
        </label>
    );
};
