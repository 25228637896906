import { EntityOTs } from "../../../shared/Domain/EntityOTs";
import { EntityLoadQuestion } from "../Domain/EntityLoadQuestion";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseLoadTipologiaCP {
    private repository: RepositoryMain;
    private IdMomentoControlAutochequeo: number;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
        this.IdMomentoControlAutochequeo = 2;
    }

    public async exec(OT:EntityOTs): Promise<EntityLoadQuestion> {
        const [AutoChequeoTipologia] = await Promise.all([
            this.getAutochequeoTipologia(OT),
        ]);
        
        return ({
            AutoChequeoTipologia,
            AutochequeoTitulos: [],
            AutochequeoItems: []
        })
    }

    private async getAutochequeoTipologia(OT: EntityOTs) {
        const arr = await this.repository.getAutochequeoTipologia();
        return arr.filter(item =>
            item.IdActividad === OT.Actividad.IdActividad &&
            item.IdDelegacion === OT.Delegacion.IdDelegacion &&
            item.IdPais === OT.Pais.IdPais &&
            [this.IdMomentoControlAutochequeo].includes(item.Momento.IdMomento));
    }
}