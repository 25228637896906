export class AdapterLimitProccess {

    public static async exec(tasks: Array<Function>, limit: number = 2, executeProgress?: (text: string) => void) {
        const results = [];
        const executing = new Set();
        let completed = 0;
        const totalTasks = tasks.length;
        
        // Función para mostrar el progreso en la consola
        const showProgress = () => {
            executeProgress && executeProgress(`${completed}/${totalTasks}`)
        };

        // Actualiza el progreso después de que cada tarea se completa
        const updateProgress = () => {
            completed++;
            showProgress();
        };


        for (const task of tasks) {
            const p = Promise.resolve().then(() => task()).finally(updateProgress);
            results.push(p);

            executing.add(p);
            if (executing.size >= limit) {
                const done = await Promise.race(executing);
                executing.delete(done);
            }
        }

        return Promise.all(results);
    }
}