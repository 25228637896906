const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';
const PROCESOUSUARIO = '/send/ProcesoUsuario';
const REACT_APP_LOGIN_KEY_ENCRIPT_: string = process.env.REACT_APP_LOGIN_KEY_ENCRIPT_ || '';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    PROCESOUSUARIO,
    REACT_APP_LOGIN_KEY_ENCRIPT_
};