const SCHEMA: string = 'Maestro';
const ENTITY: string = 'Pais';
const REDIRECT_LIST: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_HOME}`;
const REDIRECT_CIERRECP: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_CIERRE_CONTROLPREVENTIVO}`;
const REDIRECT_GESTION: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GESTIONAUTOCHEQUEO}`;
const SAVE_LISTAAUTOCHEQUEO = '/send/AutochequeoPWA';
const SAVE_COMENTARIO = '/send/PuntuacionLA';
const SAVE_FEEDBACK = '/send/FeedBackLA';
const SAVE_NOTIFICATION = '/Maestro/Notificacion/saveOne';
const LIST_CONTROLPREVENTIVO = '/search/ControlPreventivo2';
const ID_USER_INVITE = 0;
const KEYS_FORM = {
    groupQuestion: {
        MedioHumanos: "TP05",
        CharlaPretarea: "TP06",
        GrabacionAudio: "TP07",
    },
    specialInputs: {
        riesgos: 'DES-TIPO-RIESGOS',
        indicarOtrosRiesgos: 'OTROS-GEN',
        medidaspreventivas: 'DES-MEDIDAS-PREV',
        listOpcionDefaultMedidasPreventivas: ['OTROS-GEN', 'OPCION-DEFAULT'],
        indicarOtroMedidasPreventivas: 'OTROS-GEN',
        epcs: 'DES-EPCS',
        indicarOtroEpcs: 'OTROS-GEN',
        listOpcionDefaultEPCS: ['OTROS-GEN', 'OPCION-DEFAULT'],
        firmaJefeObra: 'FIR-OBLI-JEFE',
        audio: 'GUION-AUDIO',
        personal: 'PERS-MP-MULT',
        personalPreventivo: 'DES-PERS-MEPR',
        helperEPCS: 'MOSTRAR-EPCS',
        helperMedidasPreventivas: 'MOSTRAR-MEDIDAS-PREV',
        helperTipoRiesgo: 'MOSTRAR-TIPO-RIESGOS',
    }
}

export const AdapterConfigure = {
    REDIRECT_LIST,
    REDIRECT_GESTION,
    REDIRECT_CIERRECP,
    SCHEMA,
    ENTITY,
    SAVE_LISTAAUTOCHEQUEO,
    KEYS_FORM,
    ID_USER_INVITE,
    SAVE_COMENTARIO,
    SAVE_FEEDBACK,
    SAVE_NOTIFICATION,
    LIST_CONTROLPREVENTIVO
};