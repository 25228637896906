//Dependency External
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';

//Adapters
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { addLoading, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterConfigure } from './AdapterConfigure';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';

//Repository
import { RepositoryImplMain } from './RepositoryImplMain';

//UseCase
import { UseCaseLogin } from '../Application/UseCaseLogin';

//Entities
import { DtoRequestLogin } from '../Domain/DtoRequestLogin';
import { DtoResponseLogin } from '../Domain/DtoResponseLogin';
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';

// Infraestructure
import { changePermisoVariable, changePreference, signIn } from '../../../shared/Infraestructure/SliceAuthentication';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { AdapterLoadMaster } from '../../../shared/Infraestructure/AdapterLoadMaster';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { AdapterEncrypt } from '../../../shared/Infraestructure/AdapterEncrypt';
import { AdapterLoadMasterGuestMode } from '../../../shared/Infraestructure/AdapterLoadMasterGuestMode';

export const Controller = () => {
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
    const dispatch: Dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();
    const languageTranslate = LanguageTranslate();

    let { recordar } = AdapterStorage.get('recordar');

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const repositoryLoadMaster = new AdapterLoadMaster(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const repositoryLoadMasterGuestMode = new AdapterLoadMasterGuestMode(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const [showPassword, setShowPassword] = useState(false);

    const formLogin = useFormik({
        // initialValues: { username: 'lhuaman', password: 'G3n3r4l*/*@#', recordar: false, },
        initialValues: { username: !!recordar ? recordar.username : '', password: !!recordar ? recordar.password : '', recordar: !!recordar, },
        validationSchema: Yup.object({
            username: Yup.string().required(languageTranslate.moduloLogin.validate.formLogin.username).min(3, languageTranslate.moduloLogin.validate.formLogin.usernameMinimo),
            password: Yup.string().required(languageTranslate.moduloLogin.validate.formLogin.password).min(3, languageTranslate.moduloLogin.validate.formLogin.passwordMinimo),
            recordar: Yup.boolean().notRequired(),
        }),

        onSubmit: (values, formikHelpers) => { },
    });

    const init = async () => {
        const modeRedirectGuest = new URLSearchParams(window.location.search).get('redirectGuest');
        if (modeRedirectGuest) {
            const resultModeGuest = await repositoryLoadMasterGuestMode.onInit();
            const objRedirectGuest: any = {
                'RDI': `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_RDI}/${process.env.REACT_APP_ROUTE_PATH_MAIN_RDI_FORM}`,
                'CPT': `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_CHARLA_PRETAREA}`
            }
            if (resultModeGuest) navigate(objRedirectGuest[modeRedirectGuest] || `/${process.env.REACT_APP_ROUTE_PATH_MAIN}`, { replace: true })
        }
    };

    const onChange = (name: string, value: any) => {
        if (value === null) { return; }
        formLogin.setFieldValue(name, value);
    };

    const onSubmit = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await formLogin.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formLogin.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

            let params: DtoRequestLogin = {
                Usuario: AdapterEncrypt.encrypt(formLogin.values.username, AdapterConfigure.LOGIN_KEY_ENCRIPT, false),
                Contrasenia: AdapterEncrypt.encrypt(formLogin.values.password, AdapterConfigure.LOGIN_KEY_ENCRIPT, false),
                Version: 999,
                IP_Public: '',
                IP_Private: ''
            };

            let response: DtoResponseLogin | null = await (new UseCaseLogin(repository)).exec(params);
            if (response === null) { return; }

            let user: EntityDataUsuario = {
                _id: response._id,
                ApellidoMaterno: response.ApellidoMaterno,
                ApellidoPaterno: response.ApellidoPaterno,
                DatosPersonal: response.DatosPersonal,
                DatosRol: response.DatosRol,
                Delegacion: response.Delegacion,
                Email: response.Email,
                Empresa: response.Empresa,
                Foto: response.Foto,
                Identificacion: response.Identificacion,
                IdUsuario: response.IdUsuario,
                Nombres: response.Nombres,
                Pais: response.Pais,
                PaisBase: response.PaisBase,
                DelegacionBase: response.Sistemas.filter(row => row.Sistema.IdSistema === 6)[0].Delegacion,
                Perfil: response.Perfil,
                TipoOperacion: response.TipoOperacion,
                TipoPerfil: response.TipoPerfil,
                Usuario: response.Usuario
            };

            let arrIdPaises: number[] = [];
            let arrIdGrupos: number[] = [];
            let arrIdDelegaciones: number[] = [];
            let arrIdOT: number[] = [];

            switch (user.DatosRol.Codigo) {
                case '02AP':
                    arrIdPaises = user.Pais.map(row => row.IdPais);
                    arrIdGrupos = user.Pais.flatMap(row => row.Grupos.map(subrow => subrow.IdGrupo));
                    break;
                case '03AD':
                case '05DC':
                case '04U':
                case "Supervisor":
                    arrIdPaises = user.Pais.map(row => row.IdPais);
                    arrIdGrupos = user.Pais.flatMap(row => row.Grupos.map(subrow => subrow.IdGrupo));
                    arrIdDelegaciones = user.Delegacion.map((item) => item.IdDelegacion);
                    arrIdOT = user.Delegacion.flatMap(row => row.OT.map(subrow => subrow.IdOT));
                break;
            }

            if (formLogin.values.recordar) { AdapterStorage.set('recordar', JSON.stringify({ username: formLogin.values.username, password: formLogin.values.password })); }
            else { AdapterStorage.remove('recordar'); }

            AdapterStorage.set('user', user);
            var { [`preference${user.IdUsuario}`]: preference } = AdapterStorage.get(`preference${user.IdUsuario}`);
            await loadMaestro({ pais: arrIdPaises, grupo: arrIdGrupos, delegacion: arrIdDelegaciones, ot: arrIdOT });
            
            dispatch(changePermisoVariable({ arrIdPaises, arrIdGrupos, arrIdDelegaciones, arrIdOT }));
            dispatch(changePreference(preference));
            dispatch(signIn({ token: '', tokenRefresh: '', user, menu: response.permisos.menu, }));
            dispatch(removeLoading());
            formLogin.resetForm();
            AdapterStorage.set('servicesCalledsWeek', new Date().toISOString());
        } catch (error) {
            dispatch(removeLoading());
            AdapterStorage.remove('user');
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        };
    };

    const loadMaestro = async (params: { pais: Array<number>, grupo: Array<number>, delegacion: Array<number>, ot: Array<number> }) => {
        try {
            let servicesCalleds = repositoryLoadMaster.initServicesCalled();
            await repositoryLoadMaster.loadMasterFromLogin(params, servicesCalleds);
            AdapterStorage.set('servicesCalleds', servicesCalleds);
        } catch (error) {
            throw error;
        }
    };

    const onClickRecuperarPassword = () => {
        navigate(`/${process.env.REACT_APP_ROUTE_PATH_RECOVERPASSWORD}`, { replace: true });
    };

    const onClickModeGuest = async () => {
        let result = await repositoryLoadMasterGuestMode.onInit();
        if (result) navigate(`/${process.env.REACT_APP_ROUTE_PATH_MAIN}`, { replace: true });
    }

    return {
        init,
        showPassword,
        formLogin,
        onChange,
        onSubmit,
        setShowPassword,
        onClickRecuperarPassword,
        onClickModeGuest
    };
}