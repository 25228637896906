import { EntityDelegacion } from "../../../shared/Domain/EntityDelegacion";
import { EntityPersonal } from "../../../shared/Domain/EntityPersonal";
import { EntitySelectBase } from "../../../shared/Domain/EntitySelectBase";

export interface EntityConfigModalPDF {
    show: boolean;
    title: string;
    src: string;
    name: string;
    idPDF: number;
}

export const initEntityConfigModalPDF: EntityConfigModalPDF = {
    show: false,
    src: "",
    title: "",
    name: "",
    idPDF: 0
}

export interface EntityRequestRevision {
    Id: number
    IdControlPreventivo: any
    Tecnico: {
        _id: string
        IdPersonal: number
        Codigo: string
        Identificacion: string
        Apellidos: string
        Nombres: string
    }
    Procesos: {
        Registrar: Proceso;
        Eliminar: Proceso;
        Modificar: Proceso;
    }
    Revision: {
        Audio?: {
            PersonalRevision: {
                IdPersonal: number
                Codigo: string
                Identificacion: string
                Apellidos: string
                Nombres: string
            }
            RevisionAudio: number
        }
        Pdf?: {
            PersonalRevision: {
                IdPersonal: number
                Codigo: string
                Identificacion: string
                Apellidos: string
                Nombres: string
            }
            RevisionPdf: number
        }
    }
    Bitacora: {
        Accion: string
        Fecha: {}
        DatosUsuario: {
            IdUsuario: number
            Usuario: string
            Identificacion: string
            Nombres: string
            ApellidoPaterno: string
            ApellidoMaterno: string
        }
        Datos: {}
    }
    Archivos: string
}

type Proceso = {
    Fecha: string
    DatosUsuario: {
        ApellidoMaterno: string
        ApellidoPaterno: string
        Nombres: string
    }
    IdUsuario: number
    Usuario: string
    IdFecha: number
    Observacion: string
}

export interface EntityInitialData {
    personal: EntitySelectBase<EntityPersonal>[];
    delegacion: EntitySelectBase<EntityDelegacion>[];
}

export interface EntityFormatChronometer {
    sec: number;
    min: number;
    hrs: number;
}