import * as React from 'react';
import { ViewMain } from "./Components/View";
import { Controller } from "./Infraestructure/Controller";


const ManagerEntity = () => {
    const controller = Controller();

    React.useEffect(() => {
        controller.init();
        return () => { }
        // eslint-disable-next-line
    }, []);

    return (<ViewMain
        menu={controller.menu}
        onClickAyuda={controller.onClickAyuda}
        onClickReport={controller.onClickReport}
        onClickMenu={controller.onClickMenu}
        showModal={controller.showModal}
        setShowModal={controller.setShowModal}
        Notifications={controller.Notifications}
        onAcceptTerms={controller.onAcceptTerms}
        changeCountryGuest={controller.changeCountryGuest}
        user={controller.user}
    />);
};

export default ManagerEntity;