export class AdapterBlockScreen {
    waveLock: any;

    public async active() {
        // console.log('activo block screen')
        // alert(`Acpeta wakeLock? -> ${('wakeLock' in navigator)}`)
        try { this.waveLock = (await (navigator as any).wakeLock?.request('screen')); } catch(err: any){ /*alert(`${err.name}, ${err.message}`)*/}
    }

    public async stop() {
        try { await this.waveLock?.release(); } catch(err){}
        this.waveLock = null;
    }
}