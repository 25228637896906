import { ElementBreadCrumb } from "../../../shared/Components/ElementBreadCrumb"
import { InputCheck } from "../../../shared/Components/ElementInputsCostume";
import { EntityNotification } from "../../../shared/Domain/Generic/EntityNotification";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import './View.scss';

const languageTranslate = LanguageTranslate();
interface ContainerProps {
    redirectItem: (key: any, payload: EntityNotification) => void;
    listNotification: EntityNotification[]
    onChangeReadNotification: Function;
    reloadData: () => Promise<void>;
    isListNoRead: boolean;
}

export const ViewMain = (props: ContainerProps) => {
    return (
        <div className='ViewNotification'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={[{ text: languageTranslate.moduloMain.textoNotificacion, navigate: false, path: '' }]} />

                <div>
                    <div className="ContainerCheck">
                        <InputCheck
                            label={languageTranslate.moduloNotificacion.textoNoLeido}
                            name="noRead"
                            onChange={(name, value) => props.onChangeReadNotification(value)}
                            values={{ noRead: props.isListNoRead }}
                            isHorizontal
                        />
                    </div>
                    <div className="row pt-3 mb-5 gap-3">
                        {
                            props.listNotification.map(row =>{
                                if (row.personalInspeccionado[0]?.revisado && props.isListNoRead) return null;

                                const enumModule = {
                                    'CHPT': languageTranslate.menu['031'].titulo,
                                    'RDI': languageTranslate.menu['050'].titulo,
                                    'LA': languageTranslate.menu['030'].titulo,
                                    'CP': languageTranslate.menu['040'].titulo,
                                    'INSP': languageTranslate.menu['010'].titulo,
                                    'NA': 'Módulo no identificado'
                                }
                                const codigoModulo = row.modulo ? row.modulo[0]?.codigo : 'NA';

                                const title = enumModule[codigoModulo];

                                return (
                                    <div className={`container-item-notification ${row.personalInspeccionado[0]?.revisado ? 'container-item-notification-readed': ''}`} onClick={() => props.redirectItem(codigoModulo, row)}>
                                        <div className="content-text">
                                            <label className="title">{title}</label>
                                            <span className="text-muted">{languageTranslate.moduloNotificacion.textoAcabaRecibirNotificacion} {row.modulo[0]._id || '-'}</span>
                                        </div>
                                        <span className="text-time text-danger">{ AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.fechaNotificacion.fechaInicial.fecha), 3)}</span>
                                        <i className="fa-solid fa-eye" />
                                        {
                                            !row.personalInspeccionado[0]?.revisado ?
                                                <span className="badget-new">{languageTranslate.moduloNotificacion.textoNuevo}</span>
                                                :
                                                null
                                        }
                                    </div>
                            )})
                        }
                    </div>
                </div>

                <div className="position-fixed reload bg-primary">
                    <i className="fa-sharp fa-solid fa-arrow-rotate-right" style={{ fontSize: 20 }} onClick={props.reloadData} />
                </div>
            </div>
        </div>
    )
}