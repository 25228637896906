interface InputBase {
  label: string;
  name: string;
  onChange: (name: string, value:any) => void;
  values: any;
  isRequired?: boolean;
  disabled?: boolean;
}
interface IInput extends InputBase {
  type?: string;
}

export const InputText = (props: IInput) => {
  const { label, name, onChange, values,  isRequired, type } = props;
  return (
      <div className="form-row d-flex mb-3">
        <div className="form-group w-100">
          <label className="mb-1">{label} {" "} { isRequired && <span className="text-danger">*</span> }</label>
          <input
            type={type ? type : "text"}
            className="form-control"
            placeholder={label}
            onChange={(evt) => onChange(name, evt.target.value)}
            value={values}
          />
        </div>
      </div>
  )
}

