import * as React from 'react';
import { Controller } from "./Infraestructure/Controller";
import { ViewApp } from "./Components/View";
import { isMobile } from 'react-device-detect';
// import ReactGA from 'react-ga4';

// if (process.env.REACT_APP_PUBLISH_ENV === 'production') {
//     const trackID: string = process.env.REACT_APP_GOOGLE_ANALYTICS || '';
//     ReactGA.initialize(trackID);
// }

const ManagerApp = () => {
    const controller = Controller();

    React.useEffect(() => {
        try {
            // if (process.env.REACT_APP_PUBLISH_ENV === 'production') {
            //     ReactGA.send({ hitType: "pageview", page: `${window.location.pathname}${window.location.search}`, title: window.location.search });
            // }
            
            if (isMobile) controller.init();
            // controller.termns()
            return () => {
                if (isMobile) controller.end();
            }
            // eslint-disable-next-line
        } catch (error) {
            window.location.reload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (controller.forceUpdateApp && isMobile) {
            controller.updateApp();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller.forceUpdateApp])

    React.useEffect(() => {
        if (!controller.isForceModeOffline && navigator.onLine && !controller.loadingMaestros) controller.onOnline();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller.isForceModeOffline])

    return (<ViewApp loadingMaestros={controller.loadingMaestros} availableUpdate={controller.availableUpdate} updateApp={controller.updateApp}  updating={controller.updating} />);
};

export default ManagerApp;