import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { EntitySelectEmpresa } from "../Domain/EntitySelectEmpresa";
import { EntityLoadData } from "../Domain/EntityLoadData";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntitySelectArea } from "../Domain/EntitySelectArea";
import { EntitySelectPersonal } from "../Domain/EntitySelectPersonal";
import { EntitySelectActividad } from "../Domain/EntitySelectActividad";
import { EntitySelectFormato } from "../Domain/EntitySelectFormato";
import { EntitySelectTipoMomento } from "../Domain/EntitySelectTipoMomento";
import { EntitySelectTipoInspeccion } from "../Domain/EntitySelectTipoInspeccion";
import { EntitySelectAnomaliaInspeccion } from "../Domain/EntitySelectAnomaliaInspeccion";
import { EntityAnomaliaInspeccion } from "../../../shared/Domain/EntityAnomaliaInspeccion";
import { EntitySelectResultado } from "../Domain/EntitySelectResultado";
import { EntitySelectTipoSituacion } from "../Domain/EntitySelectTipoSituacion";
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { EntitySelectOTs } from "../../../shared/Domain/EntityOTs";
import { EntitySelectNivelGravedad } from "../Domain/EntitySelectNivelGravedad";
import { LanguageAppCatalog, LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { EntitySelectBase } from "../../../shared/Domain/EntitySelectBase";
import { EntityTipoOperacion } from "../../../shared/Domain/Catalogos/EntityTipoOperacion";
import { EntityArea } from "../../../shared/Domain/EntityArea";
import { AdapterConfigure } from "../Infraestructure/AdapterConfigure";

const languageTranslate = LanguageTranslate();

export class UseCaseLoadData {
  private repository: RepositoryMain;

  constructor(_repository: RepositoryMain) {
    this.repository = _repository;
  }

  public async exec(user: EntityDataUsuario, permisoVariables: {
    arrIdPaises: Array<number>;
    arrIdGrupos: Array<number>;
    arrIdDelegaciones: Array<number>;
    arrIdOT: Array<number>;
  }): Promise<EntityLoadData> {
    let [
      OT,
      Empresas,
      Area,
      Actividad,
      Personal,
      TipoMomento,
      Formato,
      TipoInspeccion,
      AnomaliaInspeccion,
      Resultado,
      TipoSituacion,
      NivelGravedad,
      TipoOperacion,
    ] = await Promise.all([
      this.getOT(user),
      this.getEmpresas(),
      this.getArea(),
      this.getActividad(),
      this.getPersonal(permisoVariables.arrIdGrupos),
      this.getTipoMomento(),
      this.getFormatoInspecciones(),
      this.getTipoInspeccion(),
      this.getAnomaliaInspeccion(),
      this.getResultado(),
      this.getTipoSituacion(),
      this.getNivelGravedad(),
      this.getTipoOperacion(user),
    ]);

    if (permisoVariables.arrIdGrupos.length > 0) {
      OT = OT.filter(row => permisoVariables.arrIdGrupos.includes(row.dataComplete.Grupo.IdGrupo));
      Area = Area.filter(row => permisoVariables.arrIdGrupos.includes(row.dataComplete.Grupo.IdGrupo));
      Empresas.Empresa = Empresas.Empresa.filter(row => permisoVariables.arrIdGrupos.includes(row.dataComplete.Grupo.IdGrupo));
      Empresas.SubContrata = Empresas.SubContrata.filter(row => permisoVariables.arrIdGrupos.includes(row.dataComplete.Grupo.IdGrupo));
      Empresas.Cliente = Empresas.Cliente.filter(row => permisoVariables.arrIdGrupos.includes(row.dataComplete.Grupo.IdGrupo));
      Formato = Formato.filter(row => row.dataComplete.Grupo.find(_row => permisoVariables.arrIdGrupos.includes(_row.IdGrupo)));
      AnomaliaInspeccion = AnomaliaInspeccion.filter(row => permisoVariables.arrIdGrupos.includes(row.dataComplete.Grupo?.IdGrupo));
    }

    return {
      OT: OT,
      Empresa: Empresas.Empresa,
      SubContrata: Empresas.SubContrata,
      Cliente: Empresas.Cliente,
      Area: Area,
      Actividad: Actividad,
      JefeObra: Personal.JefeObra,
      Coordinador: Personal.Coordinador,
      Encargado: Personal.Encargado,
      ResponsableLevantamiento: Personal.ResponsableLevantamiento,
      InspeccionConjunta: Personal.InspeccionConjunta,
      Formato: Formato,
      TipoMomento: TipoMomento,
      Personal: Personal.Personal,
      TipoInspeccion: TipoInspeccion,
      TipoOperacion: TipoOperacion,
      AnomaliaInspeccion: AnomaliaInspeccion,
      Resultado: Resultado,
      TipoSituacion: TipoSituacion,
      NivelGravedad: NivelGravedad,
    };
  }

  private async getOT(user: EntityDataUsuario): Promise<EntitySelectOTs[]> {
    const OT = await this.repository.getOtForRol(user);
    return OT.map((item) => ({
      ...item,
      label: AdapterGenerico.formattingNameOT(
        item.dataComplete.Codigo,
        item.dataComplete.OT
      ),
    }));
  }

  private async getEmpresas(): Promise<{
    Empresa: EntitySelectEmpresa[];
    SubContrata: EntitySelectEmpresa[];
    Cliente: EntitySelectEmpresa[];
  }> {
    const Empresas: EntitySelectEmpresa[] =
      AdapterGenerico.formatoPersonalizadoSelect(
        await this.repository.getEmpresa(),
        "IdEmpresa",
        "RazonSocial"
      ) as EntitySelectEmpresa[];
    const TIPO_EMPRESA = 1;
    const TIPO_SUBCONTRATA = 2;
    const TIPO_CLIENTE = 3;
    let EmpresasPorTipo: any = {
      Empresa: [],
      SubContrata: [],
      Cliente: [],
    };

    return Empresas.reduce((total, currentValue) => {
      const arrTipoEmpresa = currentValue.dataComplete.TipoEmpresa;
      if (arrTipoEmpresa.some((tipo) => tipo.IdTipoEmpresa === TIPO_EMPRESA && !!tipo.EstadoPNET)) {
        total.Empresa.push(currentValue);
      }
      if (
        arrTipoEmpresa.some((tipo) => tipo.IdTipoEmpresa === TIPO_SUBCONTRATA)
      ) {
        total.SubContrata.push(currentValue);
      }
      if (arrTipoEmpresa.some((tipo) => tipo.IdTipoEmpresa === TIPO_CLIENTE && !!tipo.EstadoPNET)) {
        total.Cliente.push(currentValue);
      }
      return total;
    }, EmpresasPorTipo);
  }

  private async getPersonal(arrIdGrupos: number[]): Promise<{
    JefeObra: EntitySelectPersonal[];
    Coordinador: EntitySelectPersonal[];
    Encargado: EntitySelectPersonal[];
    ResponsableLevantamiento: EntitySelectPersonal[];
    InspeccionConjunta: EntitySelectPersonal[];
    Personal: EntitySelectPersonal[];
  }> {
    const Personal: EntitySelectPersonal[] =
      AdapterGenerico.formatoPersonalizadoSelect(
        await this.repository.getPersonal(),
        "Identificacion",
        ["Nombres", "Apellidos"]
      ) as EntitySelectPersonal[];
    const TIPO_JEFEOBRA = ["02"];
    const TIPO_COORDINADOR = ["05"];
    const TIPO_ENCARGADO = [
      "02",
      "03",
    ];
    const TIPO_RESPONSABLELEVANTAMIENTO = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
    ];
    const IdRolSuperAdmin = 1;
    const IdRolAdminPais = 2;

    let PersonalPorTipo: any = {
      JefeObra: [],
      Coordinador: [],
      Encargado: [],
      ResponsableLevantamiento: [],
      InspeccionConjunta: [],
      Personal: [],
    };
    return Personal.reduce((total, currentValue) => {
      if (arrIdGrupos.length > 0 && !arrIdGrupos.includes(currentValue.dataComplete.Grupo)) return total;

      const CodePerfil = currentValue.dataComplete.CodePerfil;
      const isConjunta = !["", undefined, null, false].includes(
        currentValue.dataComplete.PermisoPersonalConjunta
      );

      if (TIPO_JEFEOBRA.includes(CodePerfil)) {
        total.JefeObra.push(currentValue);
      }
      if (TIPO_COORDINADOR.includes(CodePerfil)) {
        total.Coordinador.push(currentValue);
      }
      if (TIPO_ENCARGADO.includes(CodePerfil)) {
        total.Encargado.push(currentValue);
      }
      if (TIPO_RESPONSABLELEVANTAMIENTO.includes(CodePerfil)) {
        total.ResponsableLevantamiento.push(currentValue);
      }

      if (isConjunta || [IdRolSuperAdmin, IdRolAdminPais].includes(currentValue.dataComplete?.IdRol || 0) || AdapterConfigure.TIPO_EXTRA_CONJUNTA.includes(CodePerfil)) {
        total.InspeccionConjunta.push(currentValue);
      }

      total.Personal.push(currentValue);
      return total;
    }, PersonalPorTipo);
  }

  private async getArea(): Promise<EntitySelectArea[]> {
    const listArea: EntityArea[] = await this.repository.getArea();
    const result: EntitySelectArea[] =
      AdapterGenerico.formatoPersonalizadoSelect(
        listArea.map(row => ({
          ...row,
          label: LanguageAppCatalog('standard', row.Idioma, row.Area)
        })),
        "_id",
        "label"
      ) as EntitySelectArea[];
    return result.sort((item1, item2) => {
      if (item1.label < item2.label) return -1;
      if (item1.label > item2.label) return 1;
      return 0;
    });
  }

  private async getActividad(): Promise<EntitySelectActividad[]> {
    return AdapterGenerico.formatoPersonalizadoSelect(
      await this.repository.getTranslateCatalog('Actividad'),
      "Codigo",
      "label"
    ) as EntitySelectActividad[];
  }

  private async getFormatoInspecciones(): Promise<EntitySelectFormato[]> {
    return AdapterGenerico.formatoPersonalizadoSelect(
      await this.repository.getFormatoInspecciones(),
      "_id",
      "FormatoInspeccion"
    ) as EntitySelectFormato[];
  }

  private async getTipoInspeccion(): Promise<EntitySelectTipoInspeccion[]> {
    const payload = await this.repository.getTipoInspeccion();
    return AdapterGenerico.formatoPersonalizadoSelect(
      payload.map(row => ({
        ...row,
        label: LanguageAppCatalog('standard', row.iteminIdioma, row.TipoInspeccion)
      })),
      "_id",
      "label"
    ) as EntitySelectTipoInspeccion[];
  }

  private async getTipoOperacion(
    user: EntityDataUsuario
  ): Promise<EntitySelectBase<EntityTipoOperacion>[]> {
    const data = user.TipoOperacion.map(row => ({
      ...row,
      label: LanguageAppCatalog('standard', row.Idioma, row.TipoOperacion)
    }))
    return AdapterGenerico.formatoPersonalizadoSelect(
      data,
      "IdTipoOperacion",
      "label"
    ) as EntitySelectBase<EntityTipoOperacion>[];
  }

  private async getAnomaliaInspeccion(): Promise<
    EntitySelectAnomaliaInspeccion[]
  > {
    const Anomalia: EntityAnomaliaInspeccion[] =
      await this.repository.getAnomaliaInspeccion();
    return AdapterGenerico.formatoPersonalizadoSelect(
      Anomalia.map((item) => ({
        ...item,
        Anomalia: AdapterGenerico.formatoPersonalizadoSelect(
          item.Anomalia.map((aml) => ({
            ...aml,
            TipoInspeccion: aml.TipoInspeccion.map((tpi) => ({
              ...tpi,
              DetalleAnomalia: AdapterGenerico.formatoPersonalizadoSelect(
                tpi.DetalleAnomalia.map(dtl => ({
                  ...dtl,
                  label: LanguageAppCatalog('standard', dtl.iteminIdioma, dtl.DetalleAnomalia)
                })),
                "Codigo",
                "label"
              ),
            })),
            label: LanguageAppCatalog('standard', aml.iteminIdioma, aml.Anomalia)
          })),
          "Codigo",
          "label"
        ),
        label: LanguageAppCatalog('standard', item.iteminIdioma, item.TipoAnomalia)
      })),
      "Codigo",
      "label"
    ) as EntitySelectAnomaliaInspeccion[];
  }

  private getTipoMomento(): EntitySelectTipoMomento[] {
    return [
      {
        value: 0,
        label: languageTranslate.moduloInspeccion.form.TipoMomento.inicio,
        dataComplete: { id: 0, Incidente: "Inicio del Trabajo" },
      },
      {
        value: 1,
        label: languageTranslate.moduloInspeccion.form.TipoMomento.durante,
        dataComplete: { id: 1, Incidente: "Durante Trabajo" },
      },
      {
        value: 2,
        label: languageTranslate.moduloInspeccion.form.TipoMomento.fin,
        dataComplete: { id: 2, Incidente: "Fin Trabajo" },
      }
    ];
  }

  private getResultado(): EntitySelectResultado[] {
    const list = [
      { value: 1, label: languageTranslate.moduloInspeccion.form.textoNoConforme },
      { value: 0, label: languageTranslate.moduloInspeccion.form.textoCorrecto },
    ];
    return AdapterGenerico.formatoPersonalizadoSelect(
      list,
      "value",
      "label"
    ) as EntitySelectResultado[];
  }

  private getTipoSituacion(): EntitySelectTipoSituacion[] {
    return [
      {
        value: 1,
        label: languageTranslate.moduloInspeccion.form.tipoSituacion.textoActoInseguro,
        dataComplete: { id: 1, TipoSituacion: "Acto Inseguro" },
      },
      {
        value: 2,
        label: languageTranslate.moduloInspeccion.form.tipoSituacion.textoCondicionInsegura,
        dataComplete: { id: 2, TipoSituacion: "Condición Insegura" },
      },
    ];
  }

  private getNivelGravedad(): EntitySelectNivelGravedad[] {
    const list = [
      { value: "01", label: languageTranslate.moduloInspeccion.form.nivelSeguridad.textoLeve, textotOriginal: 'Leve' }, // Gravedad 1
      { value: "02", label: languageTranslate.moduloInspeccion.form.nivelSeguridad.textoMedia, textotOriginal: 'Media' },
      { value: "03", label: languageTranslate.moduloInspeccion.form.nivelSeguridad.textoAlta, textotOriginal: 'Alta' },
    ];
    return AdapterGenerico.formatoPersonalizadoSelect(
      list,
      "value",
      "label"
    ) as EntitySelectNivelGravedad[];
  }
}
