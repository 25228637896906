import * as React from 'react';
import View from "./Components/View";
import { Controller } from './Infraestructure/controller';

const ManagerEntity = () => {
    const controller = Controller();
    React.useEffect(() => {
        try {
            controller.init();
            controller.verifiedIsOPS();
        } catch (error) {
            window.location.reload();
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        controller.setInitialValuesFormItem((prev: any) => ({
            ...prev,
            Personal: controller.formRegistro.values.Personal
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller.formRegistro.values.Personal])

    return (
        <View
            keyTab={controller.keyTab}
            onChangeContentForTab={controller.onChangeContentForTab}
            onChange={controller.onChange}
            onChangeItem={controller.onChangeItem}
            actionsFormItem={controller.actionsFormItem}
            aplica={controller.aplica}
            save={controller.save}
            onChangeModalFormItem={controller.onChangeModalFormItem}
            showModalFormItem={controller.showModalFormItem}
            mode={controller.mode}
            formRegistro={controller.formRegistro}
            formRegistroItem={controller.formRegistroItem}
            isBlockInputs={controller.isBlockInputs}
            isModePreview={controller.isModePreview}
            isModeSurvey={controller.isModeSurvey}
            data={controller.data}
            DetalleInspeccion={controller.DetalleInspeccion}
            selectInformation={controller.selectInformation}
            loading={controller.loading}
            isOPS={controller.isOPS}
        />);
};

export default ManagerEntity;