import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { ElementEmptyList } from '../../../shared/Components/ElementEmptyList';
import { EntityList } from '../Domain/EntityList';
import { EntityListEmail } from '../Domain/EntityListEmail';
import { EntityPersonal } from "../../../shared/Domain/EntityPersonal";
import { ViewItem } from './ViewItem';
import './Style.scss';
import ElementModalCostume from "../../../shared/Components/ElementModalCostume";
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { InputText } from "./Inputs";
import { useState } from 'react';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { InputCheck, InputSelectPersonnel, InputSelectSimple } from '../../../shared/Components/ElementInputsCostume';
import { EntitySelectBase } from '../../../shared/Domain/EntitySelectBase';
import { ListFilterOptionSelect } from '../../../shared/Infraestructure/ListFilterOptionSelect';
interface PropsViewMain {
    onRedirectForm: (_id?: string | undefined) => void;
    loadData: () => void;
    data: EntityList[];
    ListaCorreos: EntityListEmail[];
    ListaCorreosCC: EntityListEmail[];
    Correo: string;
    CorreoCC: string;
    responsePersonal: EntitySelectBase<EntityPersonal>[];
    onloadListaCorreos: (ListaCorreos: EntityListEmail[]) => void;
    isOPS: boolean;
    loading: boolean;
    onEliminarCorreo: (Codigo: number) => void;
    onEliminarCorreoCC: (Codigo: number) => void;
    onChangeCorreoCC: (name: string, value: any) => void;
    onChangeCorreo: (name: string, value: any) => void;
    onAddCorreo: (correo: string, type: number, ListaCorreos: EntityListEmail[]) => void;
    onEnviarEmailInspecciones: (IdInspeccion: number) => void;
    deleteInspeccion: (_id: string) => Promise<void>;
    onChangeFormSearch: (key: string, value: any) => void;
    onChangeModalSearch: (show: boolean) => void;
    onSubmitSearch: () => void;
    modalSearch: {
        show: boolean;
        TipoOperacion: any[];
    };
    formSearch: {
        values: any;
        touched: any;
        errors: any;
        handleBlur: Function;
    };
    isRolAdmin: boolean;
};

//const { user } = AdapterStorage.get(['user']);
//const ListaCorreos: Array<any> = [];

export const ViewMain = (props: PropsViewMain) => {
    const [showModal, setModal] = useState(false);
    const languageTranslate = LanguageTranslate();
    const [IdInspeccion, setIdInspeccion] = useState(0);

    return (
        <>
            <ElementModalCostume
                show={showModal}
                closeModal={() => setModal(!showModal)}
                title={languageTranslate.moduloInspeccion.list.modalEmail.title}
                height=''
            >
                <div className="row pb-3 ViewInspeccionItem">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-10">
                                <InputText
                                    label={languageTranslate.moduloInspeccion.list.modalEmail.correoElectronico}
                                    name="CorreoElectronico"
                                    values={props.Correo}
                                    isRequired
                                    onChange={props.onChangeCorreo}
                                />
                            </div>
                            <div className='col-2'>
                                <button className='btn btn-primary mt-0' style={{ height: 38, display: 'flex', alignItems: 'center' }}>
                                    <i onClick={() => {

                                        if (props.Correo) {

                                            if (/\S+@\S+\.\S+/.test(props.Correo)) {
                                                props.onAddCorreo(props.Correo, 1, props.ListaCorreos);
                                                props.onChangeCorreo("CorreoElectronico", "");
                                            } else {
                                                AdapterGenerico.createMessage(languageTranslate.textoAlerta, "Correo invalido", 'warning');
                                            }

                                        }


                                    }} className="fa-solid fa-plus" style={{ fontSize: 15, marginRight: 8 }} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    props.ListaCorreos.length > 0 &&
                    <table className="table table-striped mb-5 mt-5 tbl-margin">
                        <thead>
                            <tr>
                                <th scope="col">{languageTranslate.moduloInspeccion.list.modalEmail.tableEmail}</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.ListaCorreos?.map((lc: EntityListEmail) =>
                                    <tr key={lc.Correo}>
                                        {/* <th scope="row">{lc.Codigo}</th> */}
                                        <td>{lc.Correo}</td>
                                        <td >
                                            <i onClick={() => props.onEliminarCorreo(lc.Codigo)} className="fa-solid fa-trash" style={{ fontSize: 15 }} />
                                        </td>
                                    </tr>

                                )
                            }


                        </tbody>
                    </table>
                }

                <div className="d-flex justify-content-between mt-5 mb-3 enviar-email">
                    <button onClick={() => {
                        setModal(false)
                        props.onEnviarEmailInspecciones(IdInspeccion);
                    }} className="btn btn-primary w-100" style={{ minWidth: 120 }} > {languageTranslate.moduloInspeccion.list.modalEmail.btnEnviar} </button>
                </div>

            </ElementModalCostume>

            <ElementModalCostume
                title={languageTranslate.moduloInspeccion.list.modalSearch.titulo}
                closeModal={() => props.onChangeModalSearch(false)}
                show={props.modalSearch.show}
            >
                <div className="row pb-3 ViewInspeccionListar">
                    <div className="col-12">
                        <div className="row">
                            {
                                props.isRolAdmin ? 
                                <div className='GroupFiltersAdmin'>
                                    <div className="col-12 topInput">
                                        <InputText
                                            label={languageTranslate.moduloInspeccion.list.modalSearch.codigoInspeccion}
                                            isRequired
                                            name="code"
                                            onChange={props.onChangeFormSearch}
                                            values={props.formSearch.values['code']}
                                        />
                                    </div>
                                    <div className='Line' />
                                </div>
                                : null
                            }
                            <div className="col-12 mb-1">
                                <label className="mb-1">{languageTranslate.moduloInspeccion.list.modalSearch.fechaInicial} <span className="text-danger">*</span></label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={props.formSearch.values['starDate']}
                                    onChange={(evt) => props.onChangeFormSearch('starDate', evt.target.value)}
                                />
                            </div>
                            <div className="col-12 topInput mb-1">
                                <label className="mb-1">{languageTranslate.moduloInspeccion.list.modalSearch.fechaFinal} <span className="text-danger">*</span></label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={props.formSearch.values['endDate']}
                                    onChange={(evt) => props.onChangeFormSearch('endDate', evt.target.value)}
                                />
                            </div>
                            <div className="col-12 topInput mb-1">
                                <label className="mb-1">{languageTranslate.moduloInspeccion.list.modalSearch.tipoOperacion} <span className="text-danger">*</span></label>
                                <InputSelectSimple
                                    label=''
                                    name='tipoOperacion'
                                    options={props.modalSearch.TipoOperacion}
                                    onChange={props.onChangeFormSearch}
                                    values={props.formSearch.values}
                                    isMulti
                                />
                            </div>
                            <div className="col-12 topInput mb-1">
                                <InputSelectPersonnel
                                    label={'Personal'}
                                    name="personal"
                                    onChange={props.onChangeFormSearch}
                                    values={props.formSearch.values}
                                    options={props.responsePersonal}
                                    arrFilterOption={ListFilterOptionSelect.personal}
                                    isClearable={false}
                                    isAsync={true}
                                    delaySearch={1000}
                                    controlShouldRenderValue
                                />
                            </div>
                            <div className='col-12 topInput mb-1'>
                                <InputCheck
                                    label={languageTranslate.moduloInspeccion.list.modalSearch.buscarInspeccionesFinalizadas}
                                    name='buscarItemsFinalizado'
                                    onChange={props.onChangeFormSearch}
                                    values={props.formSearch.values}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between mt-5 mb-3 enviar-email">
                    <button className="btn btn-primary w-100" style={{ minWidth: 120 }} onClick={props.onSubmitSearch}> {languageTranslate.moduloInspeccion.list.modalSearch.btnBuscar} </button>
                </div>
            </ElementModalCostume>

            <div className='ViewInspeccionListar'>
                <div className="container-fluid">
                    <div className="row mb-2 mt-2 ml-1 position-relative">
                        <ElementBreadCrumb list={props.isOPS ? languageTranslate.moduloInspeccion.list.navbarOPS : languageTranslate.moduloInspeccion.list.navbar} />
                        <button className='position-absolute btn btn-sm btn-primary w-auto btn-accion' onClick={() => props.onRedirectForm()}>+ {languageTranslate.moduloInspeccion.list.btnAgregar}</button>
                    </div>

                    <div className="row pt-3 mb-5">
                        <div className="col-12 mb-5">
                            {
                                props.data.map((item, key) =>
                                    <ViewItem
                                        key={key}
                                        data={item}
                                        onRedirectForm={props.onRedirectForm}
                                        onDelete={props.deleteInspeccion}
                                        onSendEmail={(_id, data) => {
                                            setModal(true)
                                            setIdInspeccion(data.IdInspeccion)
                                            let ListaCorreos_: EntityListEmail[] = [];
                                            let Codigo = 0;

                                            //Correo Inspector.
                                            if (props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.Inspector.Identificacion).length > 0) {
                                                if (props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.Inspector.Identificacion)[0]?.dataComplete?.CorreoElectronico) {
                                                    Codigo += 1;
                                                    let items = {
                                                        "Codigo": Codigo,
                                                        "Correo": props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.Inspector.Identificacion)[0]?.dataComplete?.CorreoElectronico,
                                                        "IdPersonal": props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.Inspector.Identificacion)[0]?.dataComplete?.IdPersonal
                                                    }

                                                    ListaCorreos_.push(items);
                                                }
                                            }
                                            //Jefe de Obra
                                            if (props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.JefeObra.Identificacion).length > 0) {
                                                if (props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.JefeObra.Identificacion)[0]?.dataComplete?.CorreoElectronico) {
                                                    Codigo += 1;
                                                    let items = {
                                                        "Codigo": Codigo,
                                                        "Correo": props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.JefeObra.Identificacion)[0]?.dataComplete.CorreoElectronico,
                                                        "IdPersonal": props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.JefeObra.Identificacion)[0]?.dataComplete?.IdPersonal
                                                    }

                                                    ListaCorreos_.push(items);
                                                }
                                            }
                                            //Coordinador
                                            if (props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.Coordinador.Identificacion).length > 0) {
                                                if (props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.Coordinador.Identificacion)[0]?.dataComplete?.CorreoElectronico) {
                                                    Codigo += 1;
                                                    let items = {
                                                        "Codigo": Codigo,
                                                        "Correo": props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.Coordinador.Identificacion)[0]?.dataComplete?.CorreoElectronico,
                                                        "IdPersonal": props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.Coordinador.Identificacion)[0]?.dataComplete?.IdPersonal
                                                    }

                                                    ListaCorreos_.push(items);
                                                }
                                            }

                                            //Responsable de Grupo
                                            if (props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.ResponsableGrupo.Identificacion).length > 0) {
                                                if (props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.ResponsableGrupo.Identificacion)[0].dataComplete.CorreoElectronico) {
                                                    Codigo += 1;
                                                    let items = {
                                                        "Codigo": Codigo,
                                                        "Correo": props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.ResponsableGrupo.Identificacion)[0].dataComplete.CorreoElectronico,
                                                        "IdPersonal": props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.ResponsableGrupo.Identificacion)[0].dataComplete.IdPersonal
                                                    }

                                                    ListaCorreos_.push(items);
                                                }

                                            }

                                            //Inspector Conjunta
                                            if (props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.Conjunta.Personal.Identificacion).length > 0) {
                                                if (props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.Conjunta.Personal.Identificacion)[0].dataComplete.CorreoElectronico) {
                                                    Codigo += 1;
                                                    let items = {
                                                        "Codigo": Codigo,
                                                        "Correo": props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.Conjunta.Personal.Identificacion)[0].dataComplete.CorreoElectronico,
                                                        "IdPersonal": props.responsePersonal.filter(row => row.dataComplete.Identificacion === data.DatosInspeccion.Conjunta.Personal.Identificacion)[0].dataComplete.IdPersonal
                                                    }

                                                    ListaCorreos_.push(items);
                                                }
                                            }

                                            props.onloadListaCorreos(ListaCorreos_);

                                        }}
                                    />
                                )
                            }
                        </div>
                    </div>
                    {
                        props.data.length === 0 && (
                            <ElementEmptyList className="data-empty" text={props.isOPS ? languageTranslate.moduloInspeccion.list.textoVacioOPS : languageTranslate.moduloInspeccion.list.textoVacio} loading={props.loading} />
                        )
                    }

                    <div className="position-fixed search bg-primary">
                        <i className="fa-solid fa-magnifying-glass" style={{ fontSize: 20 }} onClick={() => props.onChangeModalSearch(true)} />
                    </div>

                    <div className="position-fixed reload bg-primary">
                        <i className="fa-sharp fa-solid fa-arrow-rotate-right" style={{ fontSize: 20 }} onClick={props.loadData} />
                    </div>
                </div>
            </div >
        </>
    )
};