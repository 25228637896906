import { LIST_LANGUAGE } from "../../../Language/init";
import { EntityLanguage } from "../Domain/EntityLanguage";
import { AdapterStorage } from './AdapterStorage';

const LANGUAGE_NAME_FROM_LOCAL_STORAGE = 'language';
const NAVIGATOR_LANGUAGE = navigator.language.split('-')[0];

const LANGUAGE_APP_SUPORT = ['es', 'en', 'it', 'pt', 'fr'];
const DEFAULT_LANGUAGE = LANGUAGE_APP_SUPORT.includes(NAVIGATOR_LANGUAGE) ? NAVIGATOR_LANGUAGE : 'es';

export const LanguageTranslate = ():EntityLanguage => {
    let { language } = AdapterStorage.get([LANGUAGE_NAME_FROM_LOCAL_STORAGE]);
    const languageTranslate: EntityLanguage = LIST_LANGUAGE[language || DEFAULT_LANGUAGE];
    return languageTranslate;
};

export const LanguageApp = () => {
    const { language } = AdapterStorage.get([LANGUAGE_NAME_FROM_LOCAL_STORAGE]);
    return language || DEFAULT_LANGUAGE;
}

export const LanguageAppCatalog = (type: 'standard' | 'tipoincidente', arr: any[] = [], defaultText: string): string => {
    const language_app = LanguageApp();
    const language_default = 'es';
    // const text_default = `<< ${defaultText} >>`;
    const text_default = `${defaultText}`;
    if (!Array.isArray(arr) || arr.length === 0) return text_default;

    switch (type) {
        case 'standard':
            const text_language_default = arr.find(row => row.codigo === language_default)?.nombre;
            return arr.find(row => row.codigo === language_app)?.nombre || arr.find(row => row.codigo === language_app)?.texto || (text_language_default ? `${text_language_default}` : text_default); //  `<< ${text_language_default} >>`
        case 'tipoincidente':
            const text_language_default_tipinc = arr.find(row => row.Idioma === language_default)?.NombreIncidente;
            return arr.find(row => row.Idioma === language_app)?.NombreIncidente || (text_language_default_tipinc ? `${text_language_default_tipinc}` : text_default); // `<< ${text_language_default_tipinc} >>`
        default:
            return text_default;
    }
}