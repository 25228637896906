import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { EntityDelegacion } from "../../../shared/Domain/EntityDelegacion";
import { EntityOTs } from "../../../shared/Domain/EntityOTs";
import { EntityPais } from "../../../shared/Domain/EntityPais";
import { DtoReqChangePassword } from "../Domain/DtoReqChangePassword";
import { AdapterEncrypt } from "../../../shared/Infraestructure/AdapterEncrypt";
import { EntityPersonal } from "../../../shared/Domain/EntityPersonal";
import { AdapterData } from "../../../shared/Infraestructure/AdapterData";
import { AdapterConfigure } from "./AdapterConfigure";
import { EntityEmpresa } from "../../../shared/Domain/EntityEmpresa";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { EntitySelectBase } from "../../../shared/Domain/EntitySelectBase";
export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {

    public async selectEmpresa(): Promise<EntitySelectBase<EntityEmpresa>[]> {
        const TIPO_EMPRESA = 1;
        const result = (await this.dbLocal.selectAllStore('Empresa') as EntityEmpresa[]).filter(row => row.TipoEmpresa.some(tipo => tipo.IdTipoEmpresa === TIPO_EMPRESA));
        const arrTemplate = AdapterGenerico.formatoPersonalizadoSelect(result, 'Codigo', 'Nombre') as EntitySelectBase<EntityEmpresa>[];
        return arrTemplate;
    }

    public async selectPais(): Promise<EntityPais[]> {
        return this.dbLocal.selectAllStore('Pais');
    }

    public async selectDelegaciones(): Promise<EntityDelegacion[]> {
        return this.dbLocal.selectAllStore('Delegacion');
    }

    public async selectOTs(): Promise<EntityOTs[]> {
        return AdapterData.ot;
        // return this.dbLocal.selectAllStore('OT');
    }

    public async selectPersonal(): Promise<EntityPersonal[]> {
        return AdapterData.personal;
        // return this.dbLocal.selectAllStore('Personal');
    }

    public async changePassword(params: DtoReqChangePassword): Promise<void> {
        try {
            let url: string = `${this.urlBase}${AdapterConfigure.PROCESOUSUARIO}`;
            params.Contrasenia = AdapterEncrypt.encrypt(params.Contrasenia, AdapterConfigure.REACT_APP_LOGIN_KEY_ENCRIPT_, false);
            params.NuevaContrasenia = AdapterEncrypt.encrypt(params.NuevaContrasenia, AdapterConfigure.REACT_APP_LOGIN_KEY_ENCRIPT_, false);
            await this.service.bgCall<any>("POST", url, JSON.stringify({
                IdProceso: 7,
                DataUsuario: params
            }), "basic", "json", 'json', {}, 0);
            // await this.websocket.emit('ProcesoUsuario', {
            //     IdProceso: 7,
            //     DataUsuario: params
            // });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}