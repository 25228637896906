import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ElementModalCostume from "./ElementModalCostume";
import './ElementModalPDF.scss';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { LanguageTranslate } from "../Infraestructure/LanguageTranslate";
// import { LanguageTranslate } from "../Infraestructure/LanguageTranslate";

const languageTranslate = LanguageTranslate()

interface IPropsView {
    show: boolean;
    title: string;
    onCloseModal: () => void;
    src: string | null;
    downloadFile?: (src: string) => Promise<void>;
    executeLastPage?: Function;
}

// const languageTranslate = LanguageTranslate();

const ElementModalPDF = (props: IPropsView) => {
    const { src, show, title, onCloseModal } = props;

    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [loadingDownload, setLoadingDownload] = useState(false);

    const onDocumentLoadSuccess = ({ numPages }: any) => setNumPages(numPages);

    const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

    const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1,);

    const prevDownload = async () => {
        if (props.downloadFile && src) {
            setLoadingDownload(true);
            await props.downloadFile(src);
            setLoadingDownload(false);
        }
    }

    useEffect(() => {
        setPageNumber(1);
    }, [src]);

    useEffect(() => {
        setPageNumber(1);
    }, [show]);

    useEffect(() => {
        if ((pageNumber === numPages || numPages === 1) && props.executeLastPage) {
            props.executeLastPage();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, numPages, show]);

    return (
        <ElementModalCostume
            show={show}
            title={title}
            closeModal={onCloseModal}
        >
            <div className="">
                <div className="col-12">
                    {
                        !!src ?
                            <div style={{ height: '100%' }}>
                                <Document file={src} onLoadSuccess={onDocumentLoadSuccess} >
                                    <Page pageNumber={pageNumber} />
                                </Document>
                            </div>
                            : <></>
                    }
                </div>
                <div className="col-12">
                    <div className="divPaginado">
                        <button className="btn btn-sm btn-primary" onClick={goToPrevPage}>{`<`}</button>
                        <p>
                            {languageTranslate.textoPagina} {pageNumber} {languageTranslate.textoPaginaDe} {numPages}
                        </p>
                        <button className="btn btn-sm btn-primary" onClick={goToNextPage}>{`>`}</button>
                    </div>
                    {
                        (props.downloadFile && props.src) ?
                        <button disabled={loadingDownload} className="btn btn-primary mt-4 w-100" onClick={prevDownload}>
                            { loadingDownload ? languageTranslate.btnDescargando : languageTranslate.btnDescargar }
                        </button>
                        :
                        null
                    }
                </div>
            </div>
        </ElementModalCostume>
    );
};

export default ElementModalPDF;