import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { EntityNotificacionTerminos } from "../../../shared/Domain/EntityNotificacionTerminos";
import { AdapterCheckConnection } from "../../../shared/Infraestructure/AdapterCheckConnection";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { addCountProcess } from "../../../shared/Infraestructure/SliceGenerico";
import { DtoRequestForm } from "../Domain/DtoRequestForm";
import { EntityTermsForUser } from "../Domain/EntityTermsForUser";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { AdapterConfigure } from "./AdapterConfigure";
export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async callTermns(user: EntityDataUsuario): Promise<any[]> {
        let url : string = `${this.urlBase}${AdapterConfigure.NOTIFICACIONHISTORICO}`;
        const params = {
            Query: JSON.stringify({ Filtros: [{
                $match: {
                    "Usuario.IdUsuario": user.IdUsuario,
                    "Usuario.Usuario": user.Usuario,
                    "DatosPlataforma.IdPlataforma": 1
                }
            }] }),
        };
                        
        return await this.service.call<any>(
            "POST",
            url,
            JSON.stringify(params),
            "basic",
            "json",
            "json",
            {},
            0
            )
    }

    public async getNotificacionTerminos(): Promise<EntityNotificacionTerminos[]> {
        return await this.dbLocal.selectAllStore('NotificacionesTerminos');
    }

    public async getTermsAccepted(): Promise<EntityTermsForUser[]> {
        return await this.dbLocal.selectAllStore('TermsAccept');
    }

    public async onSaveAcceptTerms(params: DtoRequestForm): Promise<EntityTermsForUser> {
        try {
            let response = await this._save(params);
            let rowLocal = await this._saveLocal(params);

            if (response !== null) {
                await this.dbLocal.deleteByIndexStore({ nameStore: 'TermsAccept', value: rowLocal._id });
                await this.dbLocal.insertDataStore({ nameStore: 'TermsAccept', data: response ? response : rowLocal });
            }

            return !!response ? response : rowLocal;
        } catch (error) {
            throw error;
        }
    }

    private async _saveLocal(params: DtoRequestForm): Promise<EntityTermsForUser> {
        const StatusOffline = { Estado: "No Enviado", IdEstado: -1 }
        let response: any = { ...params, Estado: StatusOffline, dataSend: params, _id: Date.now().toString() };

        await this.dbLocal.insertDataStore({ nameStore: 'TermsAccept', data: response });
        return response;
    }

    private async _save(params: DtoRequestForm): Promise<EntityTermsForUser | null> {
        if (!new AdapterCheckConnection().isStable()) { this.dispatch(addCountProcess()); return null; }
        let url: string = `${this.urlBase}${AdapterConfigure.REGISTRONOTIFICACIONHISTORICO}`;
        const response = await this.service.bgCall<any>("POST", url, JSON.stringify(params), "basic", "json", 'json', {}, 0);
        const _response = response[0] as EntityTermsForUser;
        await this.onSendEmailAcceptTerms(params, _response);
        return {..._response, _id: Date.now().toString()};
    }

    private async onSendEmailAcceptTerms(params: DtoRequestForm, notificacionAccepted: EntityTermsForUser): Promise<any | null> {
        const infoEmail = {
            NotificacionHistorico: {
                IdNotificacionHistorico: notificacionAccepted.IdNotificacionHistorico,
                TituloDocumento: notificacionAccepted.TituloDocumento,
                ContenidoAceptado: notificacionAccepted.ContenidoAceptado,
                Plataforma: notificacionAccepted.DatosPlataforma.Plataforma
            },
            Usuario: { ...params.Usuario, Email: params.Usuario.Email },
        }
        let url: string = `${this.urlBase}${AdapterConfigure.ENVIAREMAILACEPTATERMINOS}`;
        await this.service.bgCall<any>("POST", url, JSON.stringify(infoEmail), "basic", "json", 'json', {}, 0);
        //await this.websocket.emit('EnviarEmailAceptaTerminos', infoEmail);
    }
}