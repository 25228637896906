import * as React from 'react';
import { ViewMain } from "./Components/View";
import { Controller } from './Infraestructure/controller';

const ManagerEntity = () => {
    const controller = Controller();
    React.useEffect(() => {
        try {
            controller.init();
            controller.verifiedIsOPS();
            // eslint-disable-next-line
        } catch (error) {
            window.location.reload();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<ViewMain
        data={controller.data}
        ListaCorreos={controller.ListaCorreos}
        ListaCorreosCC={controller.ListaCorreosCC}
        CorreoCC={controller.CorreoCC}
        Correo={controller.Correo}
        responsePersonal={controller.responsePersonal}
        loadData={controller.loadData}
        onRedirectForm={controller.onRedirectForm}
        onloadListaCorreos={controller.onloadListaCorreos}
        onEliminarCorreo={controller.onEliminarCorreo}
        onEliminarCorreoCC={controller.onEliminarCorreoCC}
        onChangeCorreoCC={controller.onChangeCorreoCC}
        onChangeCorreo={controller.onChangeCorreo}
        onAddCorreo={controller.onAddCorreo}
        onEnviarEmailInspecciones={controller.onEnviarEmailInspecciones}
        deleteInspeccion={controller.deleteInspeccion}
        isOPS={controller.isOPS}
        loading={controller.loading}
        onChangeModalSearch={controller.onChangeModalSearch}
        onSubmitSearch={controller.onSubmitSearch}
        modalSearch={controller.modalSearch}
        formSearch={controller.formSearch}
        onChangeFormSearch={controller.onChangeFormSearch}
        isRolAdmin={controller.isRolAdmin}
    />);
};

export default ManagerEntity;