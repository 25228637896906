import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { DtoRequestList } from "../Domain/DtoRequestList";
import { DtoResponseList } from "../Domain/DtoResponseList";
import { AdapterConfigure } from "./AdapterConfigure";

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {

    public async getList(params: DtoRequestList): Promise<DtoResponseList[] | null> {
        return this._getList(params);
    }

    private async _getList(params: DtoRequestList): Promise<DtoResponseList[] | null> {
        let url: string = `${this.urlBase}${AdapterConfigure.RDI_LIST}`;
        return await this.service.bgCall<any>("POST", url, JSON.stringify(params), "basic", "json", 'json', {}, 0);
    }

    public async downloadPDF(_id: string): Promise<Blob | null> {
        let url = `${process.env.REACT_APP_URL_MASTER_NEW_BACKEND}${AdapterConfigure.DOWNLOAD_PDF_NEWBACKEND}/${_id}`
        return await this.service.bgCall<Blob>("GET", url, '{}', 'basicNewBackend', 'form', 'blob', { "Request-Decrypt-Response": true }) as any;
    }
}