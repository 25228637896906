import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityLoadDataGuest } from "../Domain/Utils";

export class UseCaseLoadDataGuest {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(): Promise<EntityLoadDataGuest> {
        return this.repository.loadDataGuest();
    }

}