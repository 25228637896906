const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';
const ROUTE_PREVIEW = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_INSPECCION}/${process.env.REACT_APP_ROUTE_PATH_MAIN_INSPECCION_FORM}`;
const ROUTE_PREVIEW_OPS = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_OPS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_OPS_FORM}`;
const INSPECCIONES_LIST = '/search/Inspecciones';//: string = 'SelectInspeccion_INSP';
const ENVIAR_EMAIL_INSPECCIONES = '/envioCorreo/Inspeccion';//: string = 'EnviarEmailInspeccion';

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    INSPECCIONES_LIST,
    ENVIAR_EMAIL_INSPECCIONES,
    ROUTE_PREVIEW,
    ROUTE_PREVIEW_OPS,
};