import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../context/shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { EntityProcessOffline } from "../../context/shared/Domain/EntityProcessOffline";
import { EntityUserUpdate } from "../Domain/EntityUserUpdate";
import { EntityMenu, MenuAllModule } from "../../context/shared/Domain/EntityMenu";
import { EntityNotification } from "../../context/shared/Domain/Generic/EntityNotification";
import { AdapterCheckConnection } from "../../context/shared/Infraestructure/AdapterCheckConnection";
import { changeCountNotifications } from "../../context/shared/Infraestructure/SliceGenerico";
import { EntityInformationDataInitial } from "../../context/shared/Domain/EntityInformationDataInitial";
import { AdapterStorage } from "../../context/shared/Infraestructure/AdapterStorage";
import { AdapterLoadMaster } from "../../context/shared/Infraestructure/AdapterLoadMaster";

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {

    public async UpdateUserInformation(params: { identificacion: string; }): Promise<{ user: EntityUserUpdate, menu: EntityMenu[] } | null> {
        let url = `${this.urlBase}/search/Usuario`;
        let payloadRequest = { Version: 999, Query: JSON.stringify({ "Filtros": [{ "$match": { "Estado.IdEstado": 1, "Identificacion": params.identificacion } }, { "$project": { IdUsuario: 1, Usuario: 1, Identificacion: 1, ApellidoPaterno: 1, ApellidoMaterno: 1, Nombres: 1, Email: 1, TipoPerfil: 1, Perfil: 1, Empresa: 1, DatosRol: 1, PaisBase: 1, DelegacionBase: 1, Foto: 1, TipoOperacion: 1, Pais: 1, Delegacion: 1, OpcionesApp: 1 } }] }) };
        let response: EntityUserUpdate | null = (await this.service.call<any>("POST", url, JSON.stringify(payloadRequest), "basic", "json", 'json', {}, 0))[0];
        if (!response) return null;

        if (!response.OpcionesApp) response.OpcionesApp = [];
        response.OpcionesApp.push(1, 2, 3);
        const menu = MenuAllModule.filter(row => response?.OpcionesApp?.some(opcApp => row.codigoModule === opcApp));

        const listTipoOperacion = await (new AdapterLoadMaster(this.websocket, this.dbLocal, this.dispatch, "", "", this.urlBase).selectTipoOperacion(response.TipoOperacion.map(row => row.IdTipoOperacion)));
        response.TipoOperacion = listTipoOperacion.length > 0 ? listTipoOperacion : response.TipoOperacion;

        return ({ user: response, menu: menu });
    }
    
    public async getNotificationsPending(params: { identificacion: string; reload?: boolean }): Promise<void> {
        const reloadNotification = async () => {
            const allNotification: EntityNotification[] = await this.dbLocal.selectAllStore('Notificacion');
            const pendingRead =  allNotification.filter(row => !row.personalInspeccionado.some(x => x.revisado)).length;
            this.dispatch(changeCountNotifications(pendingRead))
        }

        if (!(new AdapterCheckConnection().isStable())) {
            reloadNotification();
            return;
        };

        let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial | null; } = AdapterStorage.get(["servicesCalleds"]);

        try {
            let url = `${this.urlBaseNewBackend}/Maestro/Notificacion/find`;

            const payload = {
                "fields":{ "actualizar": 0, "estado": 0, "rol": 0, "registrar": 0, "pais": 0 },
                "filter":{ "personalInspeccionado.identificacion": params.identificacion, "fechaNotificacion.fechaInicial.fecha": { $gte: new Date(servicesCalleds?.Notificacion.date || '').toISOString() } } //, "fechaNotificacion.fechaInicial.fecha": { $gt: servicesCalleds?.Notificacion.date } },
            };

            if (params.reload) {
                payload.filter = { "personalInspeccionado.identificacion": params.identificacion } as any;
            }
    
            // , "force": true 
            let response: EntityNotification[] | null = (await this.service.call<any>("POST", url, JSON.stringify(payload), "basicNewBackend", "json", 'json', { "Request-Decrypt-Response": true }, 0));

            if (servicesCalleds) {
                const date = new Date();
                servicesCalleds.Notificacion.date = date;
                AdapterStorage.set('servicesCalleds', servicesCalleds);
            }
            if (!Array.isArray(response)) {
                await reloadNotification();
                return;
            };
            
            await this.dbLocal.insertDataStore({nameStore: 'Notificacion', data: response});
            await reloadNotification();
        } catch(err) {}
    }

    public async executeProcess<T>(params: EntityProcessOffline): Promise<T> {
        let response: T | null = null;
        switch (params.type) {
            case 'api':
                response = await this.service.call<any>(params.method, this.urlBase + params.url, params.body, params.typeAuth, params.typeRequest, params.typeResponse, {}, 3);
                break;
            case 'ws':
                response = await this.websocket.emit(params.url, params.body);
                break;
            default:
                break;
        }
        return response as T;
    }
}