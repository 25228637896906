import { EntityDataAccess } from "../Domain/EntityDataAccess";
import { EntityInformationDataInitial } from "../Domain/EntityInformationDataInitial";
import { RepositoryImplGenerico } from "./RepositoryImplGenerico";
import { AdapterGenerico } from "./AdapterGenerico";
import { AdapterData } from "./AdapterData";
import { EntityLitePais } from "../Domain/CatalogosLite/EntityLitetPais";
import { AdapterStorage } from "./AdapterStorage";
import Swal from "sweetalert2";
import { LanguageTranslate } from "./LanguageTranslate";
import { addLoading, removeLoading } from "./SliceGenerico";
import { changePermisoVariable, signInInvite } from "./SliceAuthentication";


export class AdapterLoadMasterGuestMode extends RepositoryImplGenerico<any> {

    public async onInit(): Promise<boolean> {
        const languageTranslate = LanguageTranslate();

        try {
            let { countryGuest, servicesCalleds }: { countryGuest: number[] | null, servicesCalleds: EntityInformationDataInitial } = AdapterStorage.get(["countryGuest", "servicesCalleds"]);
            if (!countryGuest) {

                // Llamada al servicio de País para consultar los datos a Lite a cargar
                const paisesOptions = await this.selectInitPais();
                let options = paisesOptions.reduce((obj, row) => { Object.assign(obj, { [row._id]: `${row.Pais}` }); return obj; }, {});

                const result = await Swal.fire({
                    title: languageTranslate.moduloLogin.textoTitleSelectPais,
                    input: 'select',
                    inputOptions: options,
                    inputPlaceholder: languageTranslate.moduloLogin.textoPlaceholderSelectPais,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    backdrop: true,
                    reverseButtons: false,
                    focusConfirm: true,
                    target: '#root',
                    inputValidator: (value: any) => {
                        return new Promise((resolve) => {
                            if (value.trim() === '') {
                                resolve(languageTranslate.moduloLogin.textoErrorSelectPais);
                            }
                            else {
                                resolve(null);
                            }
                        })
                    }
                });
    
                let item = paisesOptions.find(row => row._id === result.value);
                if (!item) { return false; }

                // Actualizar la infomación
                countryGuest = [item.IdPais];
                AdapterStorage.set('countryGuest', countryGuest);

                // Llamar a los servicios modo guest
                this.dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
                await this.loadMasterFromInstallApp({ pais: [item.IdPais], grupo: [], delegacion: [], ot: [] }, servicesCalleds);
            } else {
                this.dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
                if (AdapterData.otLite.length === 0 || AdapterData.personalLite.length === 0) {
                    const [otLite, personalLite] = await this.dbLocal.selectAllStore(['CTLiteOT', 'CTLitePersonal']);
                    AdapterData.otLite = otLite;
                    AdapterData.personalLite = personalLite
                }
            }
            
            this.dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            this.dispatch(changePermisoVariable({ arrIdPaises: countryGuest, arrIdGrupos: [], arrIdDelegaciones: [], arrIdOT: [] }));
            this.dispatch(removeLoading());
            this.dispatch(signInInvite());
            return true;
        } catch (error) {
            this.dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
            return false
        }
    }

    public async loadMasterFromInstallApp(params: EntityDataAccess, information: EntityInformationDataInitial): Promise<void> {
        if (params.pais.length === 0 || params.pais.includes(0)) return;
        await Promise.all([
            // Main
            this.selectPais(params, information, 'Update'),
            // this.selectDelegacion(params, information, 'Update'),
            this.selectOT(params, information, 'Update'),
            this.selectPersonal(params, information, 'Update'),
            this.selectTipoIncidente(params, information, 'Update'),

            // RDI
            this.selectFactorRiesgo(params, information, 'Update'),
            this.selectEmpresa(params, information, 'Update'),

            // CPT
            this.selectEPCS(params, information, 'Update'),
            this.selectCTMedidaPreventivas(params, information, 'Update'),
            this.selectCTOpcionAudio(params, information, 'Update'),
            this.selectCTOpcionLA(params, information, 'Update'),
            this.selectCTPreguntaLA(params, information, 'Update'),
            this.selectCTTipoPregunta(params, information, 'Update'),
            this.selectCTTipoRiesgo(params, information, 'Update'),
        ])
    }

    public async loadMasterFromChangeCountry(params: EntityDataAccess, information: EntityInformationDataInitial): Promise<void> {
        if (params.pais.length === 0 || params.pais.includes(0)) return;
        await Promise.all([
            // Main
            this.selectOT(params, information, 'All'),
            this.selectPersonal(params, information, 'All'),
            this.selectEmpresa(params, information, 'All'),
        ])
    }

    // MAIN
    public async selectInitPais(): Promise<EntityLitePais[]> {
        let url: string = `${this.urlBase}/search/Pais/`;
        let Filtros: Array<any> = [{ $project: { _id: 1, IdPais: 1, Pais: 1 } }];

        let result: EntityLitePais[] = []
        try {
            const response = (await this.service.bgCall<EntityLitePais>('POST', url, JSON.stringify({ Filtros: JSON.stringify(Filtros) }), 'basic', 'json', 'json', {}));
            if (response && Array.isArray(response)) result = response;
        } catch {}
        if (result.length === 0) {
            result = [
                {
                    "_id": "60495e4f51df5d5ee4664744",
                    "IdPais": 4,
                    "Pais": "ARGENTINA"
                },
                {
                    "_id": "60495e4f51df5d5ee4664754",
                    "IdPais": 22,
                    "Pais": "EL SALVADOR"
                },
                {
                    "_id": "60495e4f51df5d5ee4664755",
                    "IdPais": 23,
                    "Pais": "HONDURAS"
                },
                {
                    "_id": "60495e4f51df5d5ee4664750",
                    "IdPais": 18,
                    "Pais": "RUMANIA"
                },
                {
                    "_id": "60495e4f51df5d5ee466475a",
                    "IdPais": 29,
                    "Pais": "MARRUECOS"
                },
                {
                    "_id": "60495e4f51df5d5ee466474e",
                    "IdPais": 15,
                    "Pais": "URUGUAY"
                },
                {
                    "_id": "60495e4f51df5d5ee4664753",
                    "IdPais": 21,
                    "Pais": "GUATEMALA"
                },
                {
                    "_id": "60495e4f51df5d5ee466474c",
                    "IdPais": 13,
                    "Pais": "PANAMA"
                },
                {
                    "_id": "60495e4f51df5d5ee4664749",
                    "IdPais": 9,
                    "Pais": "BRASIL"
                },
                {
                    "_id": "60495e4f51df5d5ee466474b",
                    "IdPais": 11,
                    "Pais": "VENEZUELA"
                },
                {
                    "_id": "621ce7d3b310e097dc19c0a7",
                    "IdPais": 30,
                    "Pais": "HOLANDA"
                },
                {
                    "_id": "60495e4f51df5d5ee4664748",
                    "IdPais": 8,
                    "Pais": "BOLIVIA"
                },
                {
                    "_id": "60495e4f51df5d5ee4664751",
                    "IdPais": 19,
                    "Pais": "TAJIKISTAN"
                },
                {
                    "_id": "60495e4f51df5d5ee466474d",
                    "IdPais": 14,
                    "Pais": "NICARAGUA"
                },
                {
                    "_id": "60495e4f51df5d5ee4664758",
                    "IdPais": 27,
                    "Pais": "ARMENIA"
                },
                {
                    "_id": "60495e4f51df5d5ee4664759",
                    "IdPais": 28,
                    "Pais": "REPUBLICA DOMINICANA"
                },
                {
                    "_id": "60495e4f51df5d5ee4664743",
                    "IdPais": 3,
                    "Pais": "COLOMBIA"
                },
                {
                    "_id": "60495e4f51df5d5ee4664746",
                    "IdPais": 6,
                    "Pais": "ITALIA"
                },
                {
                    "_id": "60495e4f51df5d5ee4664747",
                    "IdPais": 7,
                    "Pais": "ECUADOR"
                },
                {
                    "_id": "60495e4f51df5d5ee466474f",
                    "IdPais": 17,
                    "Pais": "ALEMANIA"
                },
                {
                    "_id": "60495e4f51df5d5ee4664752",
                    "IdPais": 20,
                    "Pais": "GEORGIA"
                },
                {
                    "_id": "60495e4f51df5d5ee4664757",
                    "IdPais": 25,
                    "Pais": "FRANCIA"
                },
                {
                    "_id": "621ce8cfb5aadfc9c2d60172",
                    "IdPais": 31,
                    "Pais": "PAKISTAN"
                },
                {
                    "_id": "60495e4f51df5d5ee4664742",
                    "IdPais": 2,
                    "Pais": "PERU"
                },
                {
                    "_id": "60495e4f51df5d5ee4664745",
                    "IdPais": 5,
                    "Pais": "CHILE"
                },
                {
                    "_id": "60495e4f51df5d5ee4664756",
                    "IdPais": 24,
                    "Pais": "ARGELIA"
                },
                {
                    "_id": "60495e4f51df5d5ee4664741",
                    "IdPais": 1,
                    "Pais": "ESPAÑA"
                },
                {
                    "_id": "60495e4f51df5d5ee466474a",
                    "IdPais": 10,
                    "Pais": "MEXICO"
                },
                {
                    "_id": "6509ad15e64bdda162df68a1",
                    "IdPais": 32,
                    "Pais": "SUECIA"
                }
            ];
        }
        
        return result;
    }

    public async selectPais(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/search/Pais`;
        let entity: keyof EntityInformationDataInitial = 'CTLitePais';
        let Filtros: Array<any> = [{ $project: { _id: 1, IdPais: 1, Pais: 1 } }];

        await (
            mode === 'All' ?
            this.templateReloadServiceAll(entity, Filtros, informationDataInitial, url) :
            this.templateReloadServiceUpdate(entity, Filtros, informationDataInitial, url)
        )
        // let body = { Query: JSON.stringify({ Filtros }) };
    }

    public async selectOT(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        const url = `${this.urlBase}/search/OTs2`;
        const urlUpdate = `${this.urlBase}/search/OTs`;
        let entity: keyof EntityInformationDataInitial = 'CTLiteOT';
        const Filtros = [
            {
                $match: {
                    "Estado.IdEstado": 1,
                    IdOT: { [!params.ot.length ? '$nin' : '$in']: !params.ot.length ? [0] : params.ot },
                    "Grupo.IdGrupo": {
                        [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo 
                    },
                    "Pais.IdPais": { [!params.pais.length ? '$nin' : '$in']: !params.pais.length ? [0] : params.pais },
                }
            },
            { $project: { _id: 1, Codigo: 1, OT: 1, IdActividad: "$Actividad.IdActividad", IdPais: "$Pais.IdPais", CodigoGrupo: "$Grupo.Codigo", IdDelegacion: "$Delegacion.IdDelegacion", Delegacion: "$Delegacion.Delegacion", IdCliente: "$Cliente.IdCliente", Cliente: "$Cliente.Cliente", Actividad: "$Actividad.Actividad", Pais: "$Pais.Pais" } }
        ]

        await (
            mode === 'All' ?
            this.templateReloadServiceAll(entity, Filtros, informationDataInitial, url) :
            this.templateReloadServiceUpdate(entity, Filtros, informationDataInitial, urlUpdate)
        )
    }

    public async selectPersonal(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        const url = `${this.urlBase}/search/PersonalPWALite`;
        const entity: keyof EntityInformationDataInitial = 'CTLitePersonal';
        const Filtros = [
            {
                $match: {
                    "Estado.IdEstado": 1,
                    EmpresaHistorial: {
                        $elemMatch: {
                            "Pais.IdPais": { [!params.pais.length ? '$nin' : '$in']: !params.pais.length ? [0] : params.pais },
                            "Grupo.IdGrupo": {
                                [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo 
                            }
                        }
                    }
                }
            },
            /*{
                $project: { _id: 1, Nombres: 1, Apellidos: 1, IdPais: 1, Grupo: 1, CodePerfil: 1 }
            }*/
        ];

        await (
            mode === 'All' ?
            this.templateReloadServiceAll(entity, Filtros, informationDataInitial, url) :
            this.templateReloadServiceUpdate(entity, Filtros, informationDataInitial, url)
        )
    }

    public async selectEmpresa(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url = `${this.urlBase}/search/Empresas`;
        let entity: keyof EntityInformationDataInitial = 'CTLiteEmpresa';
        let Filtros = [
            {
                $match: {
                    "Pais.IdPais": {
                        [!params.pais.length ? '$nin' : '$in']: !params.pais.length ? [0] : params.pais 
                    },
                    "Estado.IdEstado": 1 ,
                    "Grupo.IdGrupo": {
                        [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo 
                    },
                    // "TipoEmpresa.IdTipoEmpresa": [2] // TIPO SUBCONTRATA
                }
            },
            {
                $project: { _id: 1, Nombre: 1, IdGrupo: "$Grupo.IdGrupo", IdPais: "$Pais.IdPais", IdTipoEmpresa: "$TipoEmpresa.IdTipoEmpresa", EstadoPNET: "$SincronizacionPNET.Estado" }
            }
        ]

        await (
            mode === 'All' ?
            this.templateReloadServiceAll(entity, Filtros, informationDataInitial, url) :
            this.templateReloadServiceUpdate(entity, Filtros, informationDataInitial, url)
        )
    }

    // RDI
    public async selectFactorRiesgo(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/FactorRiesgo`;
        let entity: keyof EntityInformationDataInitial = 'FactorRiesgo';
        let Filtros: Array<any> = [{ $project: { Procesos: 0, Estado: 0 } }];

        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            await this.selectEntityREST(entity, informationDataInitial, url, { Query: JSON.stringify(Filtros) }, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);

            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                await this.selectEntityREST(entity, informationDataInitial, url, { Query: JSON.stringify(Filtros) }, true);
            }
        }
    }

    public async selectTipoIncidente(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/TipoIncidente/1`;
        let entity: keyof EntityInformationDataInitial = 'TipoIncidente';

        // Modo para recarga de variable
        if (mode === 'All') {
            await this.templateReloadServiceAll(entity, [], informationDataInitial, url)
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);

            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                await this.selectEntityREST(entity, informationDataInitial, url, {}, true, '', 'GET');
            }
        }
    }

    // CPT

    public async selectEPCS(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/LA/EPCS/Search`;
        let entity: keyof EntityInformationDataInitial = 'CTEPCS';
        let Filtros = [{ $match: { "estado": true } }, { $project: { bitacora: 0, registrar: 0, eliminar: 0, estado: 0, actualizar: 0 } }];

        await (
            mode === 'All' ?
            this.templateReloadServiceAll(entity, Filtros, informationDataInitial, url) :
            this.templateReloadServiceUpdate(entity, Filtros, informationDataInitial, url)
        )
    }

    public async selectCTMedidaPreventivas(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/LA/MedidadPreventivas/Search`;
        let entity: keyof EntityInformationDataInitial = 'CTMedidaPreventivas';
        let Filtros = [{ $match: { "estado": true } }, { $project: { actualizar: 0, bitacora: 0, eliminar: 0, estado: 0, registrar: 0 } }];

        await (
            mode === 'All' ?
            this.templateReloadServiceAll(entity, Filtros, informationDataInitial, url) :
            this.templateReloadServiceUpdate(entity, Filtros, informationDataInitial, url)
        )
    }

    public async selectCTOpcionAudio(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/LA/OpcionAudio/Search`;
        let entity: keyof EntityInformationDataInitial = 'CTOpcionAudio';
        let Filtros = [{ $match: { "estado": true } }, { $project: { actualizar: 0, bitacora: 0, eliminar: 0, estado: 0, registrar: 0 } }];

        await (
            mode === 'All' ?
            this.templateReloadServiceAll(entity, Filtros, informationDataInitial, url) :
            this.templateReloadServiceUpdate(entity, Filtros, informationDataInitial, url)
        )
    }

    public async selectCTOpcionLA(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/LA/OpcionPreguntas/Search`;
        let entity: keyof EntityInformationDataInitial = 'CTOpcionLA';
        let Filtros = [{ $match: { "estado": true } }, { $project: { actualizar: 0, bitacora: 0, eliminar: 0, estado: 0, registrar: 0 } }];

        await (
            mode === 'All' ?
            this.templateReloadServiceAll(entity, Filtros, informationDataInitial, url) :
            this.templateReloadServiceUpdate(entity, Filtros, informationDataInitial, url)
        )
    }

    public async selectCTPreguntaLA(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/LA/Preguntas/Search`;
        let entity: keyof EntityInformationDataInitial = 'CTPreguntaLA';
        let Filtros = [{ $match: { "estado": true } }, { $project: { actualizar: 0, bitacora: 0, eliminar: 0, estado: 0, registrar: 0 } }];

        await (
            mode === 'All' ?
            this.templateReloadServiceAll(entity, Filtros, informationDataInitial, url) :
            this.templateReloadServiceUpdate(entity, Filtros, informationDataInitial, url)
        )
    }

    public async selectCTTipoPregunta(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/LA/TipoPregunta/Search`;
        let entity: keyof EntityInformationDataInitial = 'CTTipoPregunta';
        let Filtros = [{ $match: { "estado": true } }, { $project: { actualizar: 0, bitacora: 0, eliminar: 0, estado: 0, registrar: 0 } }];

        await (
            mode === 'All' ?
            this.templateReloadServiceAll(entity, Filtros, informationDataInitial, url) :
            this.templateReloadServiceUpdate(entity, Filtros, informationDataInitial, url)
        )
    }

    public async selectCTTipoRiesgo(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/LA/TipoRiesgo/Search`;
        let entity: keyof EntityInformationDataInitial = 'CTTipoRiesgo';
        let Filtros = [{ $match: { "estado": true } }, { $project: { actualizar: 0, bitacora: 0, eliminar: 0, estado: 0, registrar: 0 } }];

        await (
            mode === 'All' ?
            this.templateReloadServiceAll(entity, Filtros, informationDataInitial, url) :
            this.templateReloadServiceUpdate(entity, Filtros, informationDataInitial, url)
        )
    }

    // Generic

    private async templateReloadServiceAll(entity: keyof EntityInformationDataInitial, Filtros: Array<any>, informationDataInitial: EntityInformationDataInitial, url: string) {
        await this.dbLocal.clearStore(entity);
        const body = { Filtros: JSON.stringify(Filtros) };
        await this.selectEntityREST(entity, informationDataInitial, url, body);
        await this.updateDataGeneral(entity);
    }

    private async templateReloadServiceUpdate(entity: keyof EntityInformationDataInitial, Filtros: Array<any>, informationDataInitial: EntityInformationDataInitial, url: string) {
        // Modo automático
        let count: number = await this.dbLocal.countStore(entity);
        let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;

        let _params: any;
        if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
            // Si no cuenta con ninguna información, llama al servicio
            _params = { Filtros: JSON.stringify([...Filtros ]), Query: JSON.stringify({ Filtros }) };
            await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            await this.updateDataGeneral(entity);
        } else if (!validData) {
            // Solo llama una vez por día los campos a actualizar
            let fecha: Date = new Date(informationDataInitial[entity].date as string);
            let match: any = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } } ] } };
            
            _params = { Filtros: JSON.stringify([match, ...Filtros ]) };
            await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
            await this.updateDataGeneral(entity);
        } else {
            await this.updateDataGeneral(entity);
        }
    }
    
    private async updateDataGeneral(entity: keyof EntityInformationDataInitial) {
        if (['CTLiteOT', 'CTLitePersonal'].includes(entity)) {
            let info = await this.dbLocal.selectAllStore(entity);
            if (info.length === 0) return;
            entity === 'CTLiteOT' ? AdapterData.otLite = info : AdapterData.personalLite = info;
        }
    }

    private async selectEntityREST(entity: keyof EntityInformationDataInitial, informationDataInitial: EntityInformationDataInitial, url: string, body: any, cache: boolean = false, mode: 'Update' | '' = '', method: 'GET' | 'POST' = 'POST') {
        let data: Array<any> = [];
        try {
            data = await this.service.call<any>(method, url, JSON.stringify(body), "basic", "json", 'json', {}, 0);
            if (typeof data === 'string') data = JSON.parse(data);

            if (mode === '') 
                Object.assign(informationDataInitial, { [entity]: { called: true, count: data.length, date: new Date() } });
            else
                Object.assign(informationDataInitial, { [entity]: { ...informationDataInitial[entity], date: new Date() } });

            if (['CTLiteOT', 'CTLitePersonal'].includes(entity)) {
                entity === 'CTLiteOT' ? AdapterData.otLite = data : AdapterData.personalLite = data;
            }

            await this.dbLocal.insertDataStore({ nameStore: entity, data });
            return data;
        } catch(err) {

        } finally {
            return data
        }
    }
}