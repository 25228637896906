// Library
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Dispatch } from 'redux';
import * as Yup from 'yup';

// Infraestructure
import { changeOnline, changeSaludo, hideIconMenu, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { changePreference } from '../../../shared/Infraestructure/SliceAuthentication';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { RepositoryImplMain } from './RepositoryImplMain';
import { AdapterConfigure } from './AdapterConfigure';

// Domain
import { EntityInformation } from '../Domain/EntityInformation';

// Aplication
import { UseCaseSelectDelegacion } from '../Application/UseCaseSelectDelegacion';
import { UseCaseChangePassword } from '../Application/UseCaseChangePassword';
import { UseCaseSelectPais } from '../Application/UseCaseSelectPais';
import { UseCaseSelectOTs } from '../Application/UseCaseSelectOTs';
import { UseCaseSelectEmpresa } from '../Application/UseCaseSelectEmpresa';


export const Controller = () => {
    const { auth, user, preferencia, permisoVariables } = useSelector((state: RootState) => state.auth);
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
    const dispatch: Dispatch = useDispatch();
    const languageTranslate = LanguageTranslate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const [loading, setLoading] = useState<boolean>(true);
    const [showPreference, setShowPreference] = useState<boolean>(true);
    const [showLanguage, setShowLanguage] = useState<boolean>(false);
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const [information, setInformation] = useState<EntityInformation>({ pais: [], delegacion: [], ot: [], empresa: [] });

    const { language } = AdapterStorage.get('language');

    const formPreferencia = useFormik({
        initialValues: {
            pais: preferencia.pais,
            delegacion: preferencia.delegacion,
            ot: preferencia.ot,
            personal: preferencia.personal,
            buscarCuadrilla: preferencia.buscarCuadrilla,
            automatizaPersonal: preferencia.automatizaPersonal,
            empresa: preferencia.empresa,
            forceOffline: !!preferencia.forceOffline
        } as any,
        validationSchema: Yup.object({
            pais: Yup.object().required(languageTranslate.moduloConfiguracion.validate.formPreferencia.pais),
            delegacion: Yup.object({}).required(languageTranslate.moduloConfiguracion.validate.formPreferencia.delegacion),
            ot: Yup.object().required(languageTranslate.moduloConfiguracion.validate.formPreferencia.ot),
            personal: Yup.array().required(languageTranslate.moduloConfiguracion.validate.formPreferencia.personal).nullable()
        }),

        onSubmit: (values, formikHelpers) => { },
    });

    const formChangePassword = useFormik({
        initialValues: {
            password: '',
            newPassword: '',
            reNewPassword: '',
        } as any,
        validationSchema: Yup.object({
            password: Yup.string().required(languageTranslate.moduloConfiguracion.validate.formChangePassword.password),
            newPassword: Yup.string().required(languageTranslate.moduloConfiguracion.validate.formChangePassword.newPassword),
            reNewPassword: Yup.string().required(languageTranslate.moduloConfiguracion.validate.formChangePassword.reNewPassword),
        }),

        onSubmit: (values, formikHelpers) => { },
    });


    const init = async () => {
        try {
            dispatch(changeSaludo(false));
            dispatch(hideIconMenu());
            await loadData();
            setLoading(false);
        } catch (error) {
            dispatch(removeLoading())
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning');
        }
    };

    const loadData = async () => {
        let [pais, delegacion, ot, empresa] = await Promise.all([
            new UseCaseSelectPais(repository).exec(),
            new UseCaseSelectDelegacion(repository).exec(),
            new UseCaseSelectOTs(repository).exec(),
            new UseCaseSelectEmpresa(repository).exec()
        ]);

        if (user.IdUsuario !== 0) {
            if (permisoVariables.arrIdPaises.length > 0) {
                pais = pais.filter(row => permisoVariables.arrIdPaises.includes(row.IdPais) ? true : false);
            }

            if (permisoVariables.arrIdDelegaciones.length > 0) {
                delegacion = delegacion.filter(row => permisoVariables.arrIdDelegaciones.includes(row.IdDelegacion) ? true : false);
            }

            if (permisoVariables.arrIdOT.length > 0) {
                ot = ot.filter(row => permisoVariables.arrIdOT.includes(row.IdOT) ? true : false);
            }
        }

        if (user.IdUsuario === 0) {
            setShowLanguage(true);
        }

        setInformation({
            pais: pais.map(row => ({ label: row.Pais, value: row.IdPais, dataComplete: row })),
            delegacion: delegacion.map(row => ({ label: `${row.Codigo} - ${row.Delegacion}`, value: row.Codigo, dataComplete: row })),
            ot: ot.map(row => ({ label: AdapterGenerico.formattingNameOT(row.Codigo, row.OT), value: row.Codigo, dataComplete: row })),
            empresa: empresa
        });
    };

    const onChangeLanguage = async (code: string) => {
        AdapterStorage.set('language', code);
        window.location.reload();
    };

    const onChangePreferencia = async (name: string, value: any) => {
        let preference = { ...formPreferencia.values };
        switch (name) {
            case 'ot':
                let delegacion = information.delegacion.find(row => row.value === value.dataComplete.Delegacion.Codigo);
                let pais = information.pais.find(row => row.value === value.dataComplete.Pais.IdPais);
                let personal = preference.personal;
                let empresa = preference.empresa;

                let isSamePais = preference.pais?.dataComplete.IdPais === pais?.dataComplete.IdPais;
                if (!isSamePais) {
                    personal = [];
                    empresa = null;
                    Object.assign(preference, { personal: [], empresa });
                }

                formPreferencia.setValues({ ...formPreferencia.values, delegacion: delegacion, pais: pais, personal, empresa });
                Object.assign(preference, { delegacion: delegacion, pais: pais })
                break;
            case 'personal':
                formPreferencia.setFieldValue(name, value);
                break;
            case 'forceOffline':
                dispatch(changeOnline(!value))
                break;
            default:
                break;
        }
        formPreferencia.setFieldValue(name, value);
        Object.assign(preference, { [name]: value });
        dispatch(changePreference({ ...preference }));
    };

    const onChangePassword = async (name: string, value: any) => {
        formChangePassword.setFieldValue(name, value);
    };

    const onSubmitChangePassword = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await formChangePassword.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formChangePassword.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }

            if (!(/^(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{12,}$/.test(formChangePassword.values.newPassword))) {
                AdapterGenerico.createMessage(
                    languageTranslate.textoAlerta,
                    languageTranslate.moduloConfiguracion.validate.formChangePassword.formatNewPassword,
                    'warning',
                    false
                )
                return;
            }

            if (formChangePassword.values.newPassword !== formChangePassword.values.reNewPassword) {
                AdapterGenerico.createMessage(languageTranslate.textoAlerta, 'Las contraseñas no coinciden', 'warning', false); return null;
            }

            await (new UseCaseChangePassword(repository)).exec({ IdUsuario: user.IdUsuario, Contrasenia: formChangePassword.values.password, NuevaContrasenia: formChangePassword.values.newPassword });
            formChangePassword.resetForm();
            AdapterGenerico.createMessage(languageTranslate.textoExitoso, languageTranslate.moduloConfiguracion.validate.cambioPasswordCorrecto, 'success');
        } catch (error) {
            console.error(error);

            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        }
    }

    return {
        init,
        information,
        auth,
        user,
        loading,

        showPreference,
        setShowPreference,
        formPreferencia,
        onChangePreferencia,

        showLanguage,
        setShowLanguage,
        language,
        onChangeLanguage,

        showChangePassword,
        setShowChangePassword,
        formChangePassword,
        onChangePassword,
        onSubmitChangePassword,
    };
}