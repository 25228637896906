import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { AdapterStorage } from "../../../shared/Infraestructure/AdapterStorage";
import { EntityInformationDataInitial } from "../../../shared/Domain/EntityInformationDataInitial";
import { AdapterData } from "../../../shared/Infraestructure/AdapterData";
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {
    public async logout(accessToken: string, user: EntityDataUsuario): Promise<void> {
        await this._logout(accessToken);
    }

    private async _logout(accessToken: string): Promise<void> {
        // let url: string = `${this.urlBase}/${AdapterConfigure.SCHEMA}/${AdapterConfigure.ENTITY}/logout`;
        // await this.service.bgCall<void>("POST", url, JSON.stringify({ accessToken }), "basic", "json", 'json', {}, 0);
        let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial } = AdapterStorage.get('servicesCalleds');

        const pendingAUT = await this.customClearAutochequeo();
        const pendingRDI = await this.customClearRDI();
        await this.dbLocal.insertDataStore([{ data: pendingRDI, nameStore: 'RDI' }, { data: pendingAUT, nameStore: 'Autochequeo' }]);
        
        await this.dbLocal.clearStore(['TermsAccept', 'Inspeccion', 'Personal', 'Delegacion', 'OT', 'AutochequeoItems', 'AutochequeoTitulos', 'AutochequeoTipologia', 'Empresa', 'Notificacion']);
        servicesCalleds.Empresa = { called: false, count: 0, date: null, };
        servicesCalleds.AutochequeoTipologia = { called: false, count: 0, date: null, };
        servicesCalleds.AutochequeoTitulos = { called: false, count: 0, date: null, };
        servicesCalleds.AutochequeoItems = { called: false, count: 0, date: null, };
        servicesCalleds.Delegacion = { called: false, count: 0, date: null, };
        servicesCalleds.OT = { called: false, count: 0, date: null, };
        servicesCalleds.Personal = { called: false, count: 0, date: null, };
        AdapterData.ot = [];
        AdapterData.personal = [];
        AdapterStorage.set('servicesCalleds', servicesCalleds);
    }
}