import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { EntityMenu } from '../../../shared/Domain/EntityMenu';
import { EntityNotificacionTerminos } from '../../../shared/Domain/EntityNotificacionTerminos';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { addLoading, changeSaludo, hideIconMenu, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterConfigure } from './AdapterConfigure';
import { RepositoryImplMain } from './RepositoryImpl';
import { AdapterLoadMasterGuestMode } from '../../../shared/Infraestructure/AdapterLoadMasterGuestMode';
import Swal from 'sweetalert2';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';
import { changePermisoVariable } from '../../../shared/Infraestructure/SliceAuthentication';
import { EntityInformationDataInitial } from '../../../shared/Domain/EntityInformationDataInitial';
import { AdapterCheckConnection } from '../../../shared/Infraestructure/AdapterCheckConnection';

export const Controller = () => {
    let { auth: { menu, user }, generico: { websocket, dbLocal } } = useSelector((state: RootState) => state);
    const [Notifications, setNotificacions] = useState<EntityNotificacionTerminos[]>([]);
    const [showModal, setShowModal] = useState(false);
    const languageTranslate = LanguageTranslate();
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const repositoryLoadMasterGuestMode = new AdapterLoadMasterGuestMode(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    menu = menu.filter(row => row.esOpcion);

    const init = async () => {
        dispatch(hideIconMenu());
        dispatch(changeSaludo(true));
        terms();
    };

    const onClickAyuda = async () => {
        let confirmacion: boolean = await AdapterGenerico.createMessage(languageTranslate.moduloMain.textoTituloPreguntaAyuda, languageTranslate.moduloMain.textoDescripcionPreguntaAyuda, 'question', true);
        if (!confirmacion) return;
        window.open('https://www.cobraperu.com.pe/helpcenter/sistema-sgso/', '_blank');
    };

    const onClickReport = async () => {
        let confirmacion: boolean = await AdapterGenerico.createMessage(languageTranslate.moduloMain.textoTituloPreguntaReporte, languageTranslate.moduloMain.textoDescripcionPreguntaReporte, 'question', true);
        if (!confirmacion) return;
        window.open('https://www.cobraperu.com.pe/glpi/', '_blank')
    };

    const onClickMenu = (element: EntityMenu) => {
        navigate(`/${element.ruta}`, { replace: true });
    };

    const terms = async () => {
        const termsAccepted = await repository.getTermsAccepted();
        if (!new AdapterCheckConnection().isStable() || user.IdUsuario === 0 || termsAccepted.length > 0) return;

        const [NotificicacionesAceptadas, Notificaciones] = await Promise.all([ repository.callTermns(user), repository.getNotificacionTerminos() ]);
        await dbLocal.insertDataStore({ nameStore: 'TermsAccept', data: NotificicacionesAceptadas })

        const _Notificaciones = Notificaciones.filter(row => (
            row.TipoNotificacion.IdTipoNotificacion === 1 &&
            row.Plataformas.some(platform => platform.IdPlataforma === 1) &&
            row.FechaNotificacion.FechaFinal.IdFecha === AdapterGenerico.getIdFechaGlobal() &&
            !NotificicacionesAceptadas.some(nta => row.IdNotificacion === nta.IdNotificacion)
        ) || (
            row.Rol.some(rol => rol.IdRol === user.DatosRol.IdRol) &&
            row.Pais.some(pais => pais.IdPais === user.PaisBase.IdPais) &&
            !NotificicacionesAceptadas.some(nta => row.IdNotificacion === nta.IdNotificacion)
        ))

        if (_Notificaciones.length > 0) {
            const userGroup = user.DatosPersonal[0]?.Grupo?.IdGrupo || 0;
            const _tempNotificacion = _Notificaciones[0];
            const ID_GROUP_MAETEL = 8;
            
            if (userGroup === ID_GROUP_MAETEL) {
                // Configuración de texto solo para maetel
                _tempNotificacion.TituloNotificacion = _tempNotificacion.TituloNotificacion.replace('GRUPO COBRA', 'MAETEL');
                _tempNotificacion.Contenido.Cuerpo = _tempNotificacion.Contenido.Cuerpo.replace('COBRA', 'MAETEL');
                _tempNotificacion.Contenido.PiePagina = _tempNotificacion.Contenido.PiePagina.replace('Cobra S.A.', 'Maetel');
            }

            setNotificacions([_tempNotificacion]);
            setShowModal(true);
        }
    }

    const onAcceptTerms = async () => {
        const value = (document.getElementById('AceptoTerminos_1') as HTMLInputElement | null )?.checked;

        if (!value) {
            AdapterGenerico.createMessage(languageTranslate.textoIncompleto, languageTranslate.textoValidacionTerminos, 'warning', false)
            return null;
        }

        dispatch(addLoading({ textLoading: 'Enviando...' }));
        try {
            const _notification = Notifications[0];
            const NroDocumento = user.DatosPersonal[0].EmpresaHistorial[0].Empresa.NroDocumento ?? "20253881438";
            const RazonSocial = user.DatosPersonal[0].EmpresaHistorial[0].Empresa.RazonSocial ?? "COBRA S.A.";
            const TipoDocumento = user.DatosPersonal[0].EmpresaHistorial[0].Empresa.TipoDocumento.TipoDocumento ?? "NIF";
            const subContenido = user.DatosPersonal[0].Pais.IdPais === 1 ? _notification.Contenido.SubContenido : '';

            const userGroup = user.DatosPersonal[0]?.Grupo?.IdGrupo || 0;
            const ID_GROUP_MAETEL = 8;
            
            if (userGroup === ID_GROUP_MAETEL) {
                // Configuración de texto solo para maetel
                _notification.TituloNotificacion = _notification.TituloNotificacion.replace('GRUPO COBRA', 'MAETEL');
                _notification.Contenido.Cuerpo = _notification.Contenido.Cuerpo.replace('COBRA', 'MAETEL');
            }
    
            const ContenidoAceptado = `${RazonSocial}, ${TipoDocumento} ${NroDocumento}, ${_notification.Contenido.Cuerpo + subContenido}`;
            const params = {
                IdNotificacion: _notification.IdNotificacion,
                TituloDocumento: _notification.TituloNotificacion,
                ContenidoAceptado: ContenidoAceptado,
                TipoNotificacion: _notification.TipoNotificacion,
                Usuario: {
                    IdUsuario: user.IdUsuario,
                    Usuario: user.Usuario,
                    Identificacion: user.Identificacion,
                    ApellidoPaterno: user.ApellidoPaterno,
                    ApellidoMaterno: user.ApellidoMaterno,
                    Nombres: user.Nombres,
                    Email: user.Email,
                    Pais: {
                        IdPais: user.PaisBase.IdPais ?? 0,
                        Pais: user.PaisBase.Pais ?? ''
                    },
                    Delegacion: {
                        IdDelegacion: user.DelegacionBase?.IdDelegacion ?? 0,
                        Codigo: user.DelegacionBase?.Codigo ?? '',
                        Delegacion: user.DelegacionBase?.Delegacion ?? ''
                    },
                    Grupo: {
                        IdGrupo: user.DatosPersonal[0]?.Grupo.IdGrupo || 0,
                        Grupo: user.DatosPersonal[0]?.Grupo.Grupo || ''
                    }
                },
                DatosPlataforma: {
                    VersionCode: process.env.REACT_APP_INDEXEDDB_VERSION,
                    IdPlataforma: 1,
                    Plataforma: "Movil",
                    Latitud: "",
                    Longitud: ""
                },
                FechaAceptacion: { IdFecha: AdapterGenerico.getIdFechaGlobal(), Fecha: new Date() },
                Estado: { IdEstado: 1, Estado: "ACTIVO" },
                Procesos: { 
                    Registrar: {
                        IdFecha: AdapterGenerico.getIdFechaGlobal(),
                        Fecha: new Date(),
                        IdUsuario: user.IdUsuario,
                        Usuario: user.Usuario,
                        DatosUsuario: {
                            ApellidoPaterno: user.ApellidoPaterno,
                            ApellidoMaterno: user.ApellidoMaterno,
                            Nombres: user.Nombres
                        },
                        Observacion: "REGISTRO DESDE MOVIL"
                    }
                }
            }
    
            await repository.onSaveAcceptTerms(params);
        } catch(error){
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            setShowModal(false);
            dispatch(removeLoading());
        }
    }

    const changeCountryGuest = async () => {
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

            let { servicesCalleds, countryGuest }: { servicesCalleds: EntityInformationDataInitial; countryGuest: number[] } = AdapterStorage.get(["servicesCalleds", "countryGuest"]);
            const paisesOptions = await repositoryLoadMasterGuestMode.selectInitPais();
            let options: any = paisesOptions.reduce((obj, row) => { Object.assign(obj, { [row._id]: `${row.Pais}` }); return obj; }, {});
            let optionSelected = paisesOptions.find(row => countryGuest.includes(row.IdPais))?._id || '';

            dispatch(removeLoading());
    
            const result = await Swal.fire({
                title: LanguageTranslate().moduloLogin.textoTitleSelectPais,
                input: 'select',
                inputOptions: options,
                inputPlaceholder: LanguageTranslate().moduloLogin.textoPlaceholderSelectPais,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                confirmButtonText: 'OK',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                showCancelButton: true,
                backdrop: false,
                reverseButtons: true,
                focusConfirm: true,
                target: '#root',
                inputValue: optionSelected,
                inputValidator: (value: any) => {
                    return new Promise((resolve) => {
                        if (value.trim() === '') {
                            resolve(LanguageTranslate().moduloLogin.textoErrorSelectPais);
                        }
                        else {
                            resolve(null);
                        }
                    })
                }
            });
    
            let item = paisesOptions.find(row => row._id === result.value);
            if (!item) { return; }
    
            // Actualizar la infomación
            let _countryGuest = [item.IdPais];
            AdapterStorage.set('countryGuest', _countryGuest);
    
            // Llamar a los servicios modo guest
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            await repositoryLoadMasterGuestMode.loadMasterFromChangeCountry({ pais: [item.IdPais], grupo: [], delegacion: [], ot: [] }, servicesCalleds);

            dispatch(changePermisoVariable({ arrIdPaises: countryGuest, arrIdGrupos: [], arrIdDelegaciones: [], arrIdOT: [] }));
            dispatch(removeLoading());

            AdapterGenerico.createToast(`${languageTranslate.textoCambioPais} ${item.Pais}`, 'success');
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        }
    }

    return {
        init,
        menu,

        onClickAyuda,
        onClickReport,
        onClickMenu,

        showModal,
        setShowModal,
        Notifications,
        onAcceptTerms,
        changeCountryGuest,
        user
    };
}