import { InputCheck, InputText } from "../../../../shared/Components/ElementInputsCostume";
import ElementModalCostume from "../../../../shared/Components/ElementModalCostume";
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";
import { EntityConfigModalComentarios } from "../../Domain/EntityUtils";

interface ContainerProps {
    configModalComentarios: EntityConfigModalComentarios;
    Comentarios: any[];
    show: boolean;
    closeModal: () => void;
    onSubmitForm: (payload?: 'save' | 'delete') => Promise<any>;
    onChange: (name: string, value: any) => void;
}

const LIST_STAR = [1, 2, 3, 4, 5];
const COLOR_CALIFICACION: any = {
    1: 'red',
    2: 'red',
    3: '#e4c01b',
    4: 'green',
    5: 'green'
}
const languageTranslate = LanguageTranslate();

export const ViewFormComentarios = (props: ContainerProps) => {

    return (
        <ElementModalCostume
            show={props.show}
            closeModal={props.closeModal}
            title={languageTranslate.moduloAutoChequeo.comentario.titleModalAgregar}
            height="300px"
        >
            <div className="py-4">
                <div className="d-flex justify-content-center gap-2 mb-3">
                    {
                        LIST_STAR.map(row => 
                            <i
                                className="fa-sharp fa-solid fa-star"
                                onClick={() => props.onChange('Puntuacion', row)}
                                style={{ fontSize: 30, color: row <= props.configModalComentarios.form.Puntuacion ? COLOR_CALIFICACION[props.configModalComentarios.form.Puntuacion] : '#bcbfbc' }}
                            ></i>    
                        )
                    }
                </div>

                <InputText
                    label={languageTranslate.moduloAutoChequeo.comentario.textComentario}
                    name="Comentario"
                    onChange={props.onChange}
                    values={props.configModalComentarios.form}
                    ocultLabel
                />

                <InputCheck
                    label={languageTranslate.moduloMain.textoNotificacion}
                    name="Notificacion"
                    onChange={props.onChange}
                    values={props.configModalComentarios.form}
                />

                <button className="btn btn-primary w-100" onClick={() => props.onSubmitForm()}>{languageTranslate.btnAgregar}</button>
            </div>
        </ElementModalCostume>
    )
}