import * as React from 'react';
import ViewMain from "./Components/View";
import { Controller } from './Infaestructure/controller';
import { ManagerEntityModule } from '../Form';
import ElementModalPDF from '../../shared/Components/ElementModalPDF';
import { EntityAutochequeo } from '../../shared/Domain/EntityAutochequeo';
import { AdapterGenerico } from '../../shared/Infraestructure/AdapterGenerico';
import { LanguageTranslate } from '../../shared/Infraestructure/LanguageTranslate';

const ManagerEntity = () => {

    const controller = Controller();
    const { componentFormComentario, componentListComentarios, controllerFormAutochequeo } = ManagerEntityModule();

    const prevCloseModalComentario = async () => {
        controllerFormAutochequeo.closeModalListaComentarios();
        await controller.reloadList();
    }

    const prevOpenModalComentario = async (payload: EntityAutochequeo) => {
        controllerFormAutochequeo.openModalListaComentarios(payload);
        if (payload.isHasNotification) controller.init();
    }

    React.useEffect(() => {
        try {
            controller.init();
        } catch (error) {
            window.location.reload();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller.countProcess])

    return (
        <>
            <ViewMain
                IdAudio={controller.configAudio.idAudio}
                isReproduce={controller.configAudio.isReproduce}
                data={controller.data}
                loadData={controller.loadData}
                loading={controller.loading}
                onChange={controller.onChange}
                formRegistro={controller.formRegistro}
                onSearchSubmit={controller.onSearchSubmit}
                showModal={controller.showModal}
                onSetModal={controller.onSetModal}
                onDownloadPdf={controller.onDownloadPdf}
                onSetReproducirAudio={controller.onSetReproducirAudio}
                redirectGestion={controller.redirectGestion}
                redirectPreview={controller.redirectPreview}
                redirectCharlaPreTarea={controller.redirectCharlaPreTarea}
                user={controller.user}
                configDataView={controller.configDataView}
                openModalListaComentarios={prevOpenModalComentario}
                openModalPDF={controller.openModalPDF}
                onPlay={controller.onPlayAudio}
                playTimeAudio={controller.playTimeAudio}
                paginationList={controller.paginationList}
                configList={controller.configList}
            />

            <ElementModalPDF
                show={controller.configModalPDF.show}
                title={`${controller.configModalPDF.title}`}
                onCloseModal={controller.closeModalPDF}
                src={controller.configModalPDF.src}
                downloadFile={(src: string) => controller.downloadCurrentPDF()}
                executeLastPage={() => controller.onCompleteRevision('pdf')}
            />

            {
                componentListComentarios({
                    Comentarios: controllerFormAutochequeo.configModalComentarios.comentarios,
                    show: controllerFormAutochequeo.configModalComentarios.list.show,
                    closeModal: prevCloseModalComentario,
                    openModalForm: controllerFormAutochequeo.openModalComentarios,
                    onSubmitFeedback: controllerFormAutochequeo.onSubmitFeedback,
                    valueFeedback: controllerFormAutochequeo.configModalComentarios.list.feedback,
                    disabledFunctions: controllerFormAutochequeo.configModalComentarios.disabledFunctions
                })
            }

            {
                componentFormComentario({
                    Comentarios: controllerFormAutochequeo.configModalComentarios.comentarios,
                    show: controllerFormAutochequeo.configModalComentarios.show,
                    closeModal: controllerFormAutochequeo.closeModalComentarios,
                    onSubmitForm: controllerFormAutochequeo.onSubmitModalComentarios,
                    configModalComentarios: controllerFormAutochequeo.configModalComentarios,
                    onChange: controllerFormAutochequeo.onChangeFormModalComentarios
                })
            }
        </>
    );
};

export default ManagerEntity;