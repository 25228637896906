import './Style.scss';
import { AppRoutes } from './routes';
import { isMobile } from 'react-device-detect';
import { PageNotAvailable } from '../../context/shared/Components/PageNotAvailable';
import ViewSplash from '../../context/Master/Splash';
import { LanguageTranslate } from '../../context/shared/Infraestructure/LanguageTranslate';
import mobileSoftwareUpdate from '../../assets/icons/mobile-software-update-icon.svg';

interface PropsView {
  loadingMaestros: boolean;
  availableUpdate: boolean;
  updating: boolean;
  updateApp: Function;
}

export const ViewApp = ({ loadingMaestros, updateApp, availableUpdate, updating }: PropsView) => {
  const languageTranslate = LanguageTranslate()
  return (
    isMobile
      ? loadingMaestros
        ? <ViewSplash />
        : <>
          {
            availableUpdate && !updating
              ? <div className="panel shadow text-center" onClick={() => updateApp()}>
                <img src={mobileSoftwareUpdate} alt="update" width={15} style={{ marginRight: '10px' }} />
                <span>{languageTranslate.textoNuevaVersion}</span>
              </div>
              : <></>
          }
          <AppRoutes />
        </>

      : <PageNotAvailable />

  );
}
