import React, { useState } from 'react';
import { ElementEmptyList } from './ElementEmptyList';
import ElementModalCostume from './ElementModalCostume';
import './ElementViewImagesCostume.scss'

interface IElementViewImagesCostume {
    show: boolean;
    closeModal: () => void;
    title?: string;
    children?: any;
    disabled?: boolean;
    deleteImage: (indexImage: number) => void;
    images: {
      type: any;
      value: any;
      valueToShow: string;
    }[];
}

const ElementViewImagesCostume = (props: IElementViewImagesCostume) => {
    const [indexImage, selectImage] = useState(0);
    const prevClose = () => {
      selectImage(0);
      props.closeModal();
    }
    const prevDelete = () => {
      props.deleteImage(indexImage);
      let focusImage = indexImage === 0 ? 0 : indexImage - 1;
      selectImage(focusImage);
    }
    return (
        <ElementModalCostume
            show={props.show}
            closeModal={prevClose}
            title={props.title}
        >
            {
                props.images.length === 0 ? (
                  <>
                    <ElementEmptyList className="top-50 start-50 translate-middle position-absolute" text="Sin imágenes" />
                  </>
                ) : (
                  <div className="ElementViewImagesCostume">
                    <div className="main-image">
                      <img
                        className={`shadow`}
                        height={330}
                        width="100%"
                        src={props.images[indexImage]?.valueToShow}
                        alt="not source"
                      />
                      <button disabled={props.disabled} className="btn btn-dark" onClick={prevDelete}> Eliminar imagen </button>
                    </div>
                    <div className="group-image">
                      {props.images?.map((image, index) => (
                        <img
                          key={index}
                          onClick={() => selectImage(index)}
                          className={ indexImage === index ? "img-selected" : ""}
                          height={50}
                          width={50}
                          src={image?.valueToShow}
                          alt="not source"
                        />
                      ))}
                    </div>
                  </div>
                )
              }
            {props.children}
        </ElementModalCostume>
    )
}

export default ElementViewImagesCostume;