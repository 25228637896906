import React from 'react';
import './ElementSkeleton.scss'; // Asegúrate de que la ruta sea correcta

interface IProps {
    styles: React.CSSProperties | undefined;
}

export const ElementSkeleton = ({ styles }: IProps) => {
    return (
        <div
            className="skeleton shadow-lg"
            style={{
                ...styles,
                // boxShadow: `0 0 8px rgb(1, 57, 125)`,
                width: styles?.width || '100px',
                height: styles?.height || '100px',
                marginBottom: 14,
                borderRadius: 10
            }}
        />
    );
};
