import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { ErrorCostume } from '../../../shared/Domain/ErrorCostume';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { addLoading, changeSaludo, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { UseCaseList } from '../Application/UseCaseList';
import { UseEnvioCorreo } from '../Application/UseEnvioCorreo';
import { EntityPersonal } from "../../../shared/Domain/EntityPersonal";
import { EntityList } from '../Domain/EntityList';
import { EntityListEmail } from '../Domain/EntityListEmail';
import { AdapterConfigure } from './AdapterConfigure';
import { RepositoryImplMain } from './RepositoryImplMain';
import { UseCaseDelete } from '../Application/UseCaseDelete';
import { AdapterData } from '../../../shared/Infraestructure/AdapterData';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';
import { EntitySelectBase } from '../../../shared/Domain/EntitySelectBase';

export const Controller = () => {
    const { generico: { websocket, dbLocal }, auth: { user } } = useSelector((state: RootState) => state);
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();
    const languageTranslate = LanguageTranslate();
    const [data, setData] = useState<EntityList[]>([]);
    const [responsePersonal, setDataPersonal] = useState<EntitySelectBase<EntityPersonal>[]>([]);
    const [ListaCorreos, setListaCorreo] = useState<EntityListEmail[]>([]);
    const [ListaCorreosCC, setListaCorreoCC] = useState<EntityListEmail[]>([]);

    const [Correo, setCorreo] = useState<string>("");
    const [CorreoCC, setCorreoCC] = useState<string>("");
    const [isOPS, setOPS] = useState(false);
    const [loading, setLoading] = useState(true);

    const [modalSearch, setModalSearch] = useState({ show: false, TipoOperacion: [] as any[] });

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const regexOPS = new RegExp(process.env.REACT_APP_ROUTE_PATH_MAIN_OPS + "", 'g');

    const formSearch = useFormik({
        initialValues: {
            endDate: AdapterGenerico.convertDateToString(new Date(), 6),
            starDate: '',
            tipoOperacion: AdapterGenerico.formatoPersonalizadoSelect(user.TipoOperacion, 'IdTipoOperacion', 'TipoOperacion') as any[],
            personal: null as null | EntitySelectBase<EntityPersonal>,
            buscarItemsFinalizado: false,
            code: '',
        },
        onSubmit: () => { },
        validationSchema: Yup.object({
            starDate: Yup.date().required(languageTranslate.moduloInspeccion.list.validate.fechaInicio).test('ValidarRangoFechaInicio', languageTranslate.moduloInspeccion.list.validate.fechaRangoInicioFinal, (value, context) => {
                if (!value) return true;
                if (!context.parent.endDate) return true;
                if (new Date(value).getTime() > new Date(context.parent.endDate).getTime()) return false;

                return true;
            }).nullable(),
            endDate: Yup.date().required(languageTranslate.moduloInspeccion.list.validate.fechaFinal).nullable(),
            tipoOperacion: Yup.array().min(1, languageTranslate.moduloInspeccion.list.validate.tipoOperacion).nullable(),
            // identificacion: Yup.string().required(languageTranslate.moduloInspeccion.list.validate.identificacion).nullable(),
            personal: Yup.object().required(languageTranslate.moduloInspeccion.list.validate.identificacion).nullable(),
            buscarItemsFinalizado: Yup.boolean().nullable(),
            code: Yup.string()
        })
    })

    const init = async () => {
        try {
            dispatch(changeSaludo(false));
            const response: EntityList[] = await dbLocal.selectAllStore('Inspeccion');
            setModalSearch(() => ({ show: false, TipoOperacion: AdapterGenerico.formatoPersonalizadoSelect(user.TipoOperacion, 'IdTipoOperacion', 'TipoOperacion') as any[] }))
            const responsePersonal = (AdapterGenerico.formatoPersonalizadoSelect(AdapterData.personal, 'Identificacion', ['Nombres', 'Apellidos'])) as EntitySelectBase<EntityPersonal>[];
            setDataPersonal(() => (responsePersonal));
            setDataFormModule(response);
            setLoading(false);
        } catch (error) {
            window.location.reload();
        }
    };

    const verifiedIsOPS = () => {
        try {
            if (window.location.pathname.match(regexOPS)) setOPS(true);
        } catch (error) {
            window.location.reload();
        }
    }

    const onloadListaCorreos = async (ListaCorreos: EntityListEmail[]) => {
        setListaCorreo(() => (ListaCorreos));
    }

    const onloadListaCorreosCC = async (ListaCorreosCC: EntityListEmail[]) => {
        setListaCorreoCC(() => (ListaCorreosCC));
    }

    const onEliminarCorreo = async (Codigo: number) => {
        setListaCorreo(() => (ListaCorreos.filter(row => row.Codigo !== Codigo)));
    }

    const onEliminarCorreoCC = async (Codigo: number) => {
        setListaCorreoCC(() => (ListaCorreosCC.filter(row => row.Codigo !== Codigo)));
    }

    const onChangeCorreo = async (name: string, value: any) => {
        setCorreo(() => (value));
    }

    const onChangeCorreoCC = async (name: string, value: any) => {
        setCorreoCC(() => (value));
    }

    const onAddCorreo = async (correo: string, type: number, ListaCorreos: EntityListEmail[]) => {

        if (ListaCorreos.length === 0) {
            let Codigo: number = 1;
            let items = {
                "Codigo": Codigo,
                "Correo": correo,
                "IdPersonal": 1
            }
            ListaCorreos.push(items)
        } else {
            if (ListaCorreos.filter(row => row.Correo === correo).length === 0) {
                let Codigo: number = ListaCorreos[ListaCorreos.length - 1].Codigo + 1;
                let items = {
                    "Codigo": Codigo,
                    "Correo": correo,
                    "IdPersonal": 1
                }
                ListaCorreos.push(items)
            } else {
                AdapterGenerico.createMessage(languageTranslate.textoAlerta, languageTranslate.moduloInspeccion.list.validate.correoExiste, 'warning');
            }
        }
        if (type === 1) {
            setListaCorreo(() => (ListaCorreos));
        }

        if (type === 2) {
            setListaCorreoCC(() => (ListaCorreos));
        }

    }

    const loadData = async () => {
        const params = {
            "Filtros": [
                {
                    "$match": {
                        "$and": [{
                            "DatosGenerales.Pais.IdPais": { "$in": user.Pais.map((pais) => pais.IdPais) },
                            "DatosInspeccion.Inspector.Identificacion": { "$in": [user.Identificacion] },
                            "DatosInspeccion.TipoOperacion.IdTipoOperacion": { "$in": user.TipoOperacion.map((item) => item.IdTipoOperacion) }
                        }]
                    }
                }
            ]
        }
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const response = await (new UseCaseList(repository).exec(params));
            if (response === null) return;
            setDataFormModule(response);
        } catch (err) {
            let error: ErrorCostume = new ErrorCostume((err as Error).message);
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    const onEnviarEmailInspecciones = async (IdInspeccion: number) => {
        const ListaCorreos_envio: Array<any> = []

        for await (const correo_envio of ListaCorreos) {
            ListaCorreos_envio.push(correo_envio.Correo)
        }

        const Email = {
            IdInspeccion: IdInspeccion,
            Correos: ListaCorreos_envio
        }

        try {
            dispatch(addLoading({ textLoading: languageTranslate.moduloInspeccion.list.textoEnviandoCorreo }));
            const response = await (new UseEnvioCorreo(repository).envio(Email));
            if (response === null) return;
            AdapterGenerico.createMessage(languageTranslate.textoExitoso, languageTranslate.moduloInspeccion.list.validate.correoEnviado, 'success', false);
        } catch (err) {
            let error: ErrorCostume = new ErrorCostume((err as Error).message);
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, error.message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    const deleteInspeccion = async (_id: string) => {
        try {
            const indexFinded = data.findIndex(item => item._id === _id)
            if (indexFinded === -1) return;

            await (new UseCaseDelete(repository).exec(_id));
            data.splice(indexFinded, 1);
            setData(() => ([...data]));

        } catch (err) {
            let error: ErrorCostume = new ErrorCostume((err as Error).message);
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, error.message, 'warning', false);
        }
    }

    const handleOrder = (data: EntityList[]): EntityList[] => {
        if (!Array.isArray(data)) return [];
        return data.sort((a, b) =>
            Number(a.Procesos?.Registrar ? new Date(a.Procesos.Registrar.Fecha) : new Date()) - Number(new Date(b.Procesos?.Registrar ? new Date(b.Procesos.Registrar.Fecha) : new Date()))).reverse();
    }

    const setDataFormModule = (data: EntityList[]) => {
        const orderData = handleOrder(data);
        orderData.map(row => ({
            ...row,
            Estado: (row.Estado && row.Estado?.IdEstado) ? row.Estado :
            {
                IdEstado: -2,
                Estado: 'Incompleta',
                isOps: false
            }
        }))
        if (window.location.pathname.match(regexOPS)) {
            const filterIsOPS = orderData.filter(item => ["SI", "NO"].includes(item.DatosInspeccion.ReconocimientoOPS) || (item.Estado?.isOps && item.Estado?.IdEstado === -2));
            setData(() => filterIsOPS);
        } else {
            const filterNotOPS = orderData.filter(item => !["SI", "NO"].includes(item.DatosInspeccion.ReconocimientoOPS) && (!item.Estado?.isOps));
            setData(() => filterNotOPS);
        }
    }

    const onRedirectForm = (_id?: string) => {
        const params = _id ? `/${_id}` : '';
        const routeDynamic = isOPS ? AdapterConfigure.ROUTE_PREVIEW_OPS : AdapterConfigure.ROUTE_PREVIEW;
        navigate(`${routeDynamic}${params}`, { replace: true })
    };

    const onChangeModalSearch = (show: boolean) => {
        formSearch.resetForm();
        setModalSearch((prev) => ({ ...prev, show }))
    }

    const onChangeFormSearch = (key: string, value: any) => {
        formSearch.setFieldValue(key, value);
    }

    const onSubmitSearch = async () => {
        try {
            try { await formSearch.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formSearch.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }
            let FechaInicial = parseInt(formSearch.values.starDate.replace(new RegExp("-", "g"), ""));
            let FechaFinal = parseInt(formSearch.values.endDate.replace(new RegExp("-", "g"), ""));

            const params = {
                "Filtros": [
                    {
                        "$match": {
                            "$and": ![null, undefined, ""].includes(formSearch.values.code) ?
                                [{
                                    "IdInspeccion": { "$in": [parseInt(formSearch.values.code)] }
                                }]
                                :
                                [{
                                    "DatosGenerales.Pais.IdPais": { "$in": user.Pais.map((pais) => pais.IdPais) },
                                    "DatosInspeccion.Inspector.Identificacion": { "$in": [formSearch.values.personal?.dataComplete.Identificacion] },
                                    "DatosInspeccion.TipoOperacion.IdTipoOperacion": { "$in": formSearch.values.tipoOperacion.map((item) => item.value) },
                                    "DatosInspeccion.FechaInspeccion.IdFecha": { '$gte': FechaInicial, '$lte': FechaFinal },
                                    "Estado.IdEstado": { "$in": formSearch.values.buscarItemsFinalizado ? [5] : [6] }
                                }]
                        }
                    }
                ]
            }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const response = await (new UseCaseList(repository).exec(params));
            if (response === null) return;
            setDataFormModule(response);
            onChangeModalSearch(false);
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    return {
        init,
        loadData,
        data,
        ListaCorreos,
        ListaCorreosCC,
        responsePersonal,
        Correo,
        CorreoCC,
        onloadListaCorreos,
        onloadListaCorreosCC,
        onEliminarCorreo,
        onEliminarCorreoCC,
        onChangeCorreoCC,
        onChangeCorreo,
        onRedirectForm,
        onAddCorreo,
        onEnviarEmailInspecciones,
        isOPS,
        verifiedIsOPS,
        loading,
        deleteInspeccion,

        onChangeFormSearch,
        onChangeModalSearch,
        onSubmitSearch,
        modalSearch,
        formSearch,
        isRolAdmin: user.DatosRol.Codigo === '01SA'
    };
}