export interface EntityMenu {
    id: number;
    codigo: string;
    codigoModule: number;
    sigla: string;
    orden: number;
    ruta: string;
    icono: string;
    padre: number | null;
    esOpcion: boolean;
    permitirSubOpcion: boolean;
    estado: boolean;
}

export const MenuAllModule: Array<EntityMenu> = [
    { id: 1, codigo: '001', codigoModule: 1, icono: 'fa-solid fa-person-falling-burst', orden: 1, padre: null, ruta: 'main/home', sigla: 'SGSOAPP_RDI_FORM', esOpcion: false, permitirSubOpcion: false, estado: true },
    { id: 2, codigo: '002', codigoModule: 2, icono: 'fa-solid fa-person-falling-burst', orden: 2, padre: null, ruta: 'main/profile', sigla: 'SGSOAPP_RDI_FORM', esOpcion: false, permitirSubOpcion: false, estado: true },
    { id: 3, codigo: '003', codigoModule: 3, icono: 'fa-solid fa-person-falling-burst', orden: 3, padre: null, ruta: 'main/variable', sigla: 'SGSOAPP_RDI_FORM', esOpcion: false, permitirSubOpcion: false, estado: true },

    { id: 10, codigo: '010', codigoModule: 200, icono: 'fa-solid fa-person', orden: 10, padre: null, ruta: 'main/Inspeccion', sigla: 'SGSOAPP_INSPEC', esOpcion: true, permitirSubOpcion: false, estado: true },
    { id: 11, codigo: '011', codigoModule: 200, icono: 'fa-solid fa-person', orden: 11, padre: null, ruta: 'main/Inspeccion/form', sigla: 'SGSOAPP_INSPEC_FORM', esOpcion: false, permitirSubOpcion: true, estado: true },

    { id: 20, codigo: '020', codigoModule: 201, icono: 'fa-solid fa-person', orden: 20, padre: null, ruta: 'main/OPS', sigla: 'SGSOAPP_OPS', esOpcion: true, permitirSubOpcion: false, estado: true },
    { id: 21, codigo: '021', codigoModule: 201, icono: 'fa-solid fa-person', orden: 21, padre: null, ruta: 'main/OPS/form', sigla: 'SGSOAPP_OPS_FORM', esOpcion: false, permitirSubOpcion: true, estado: true },

    { id: 30, codigo: '030', codigoModule: 203, icono: 'fa-solid fa-list-check', orden: 30, padre: null, ruta: 'main/Autochequeo', sigla: 'SGSOAPP_CHEQUEO', esOpcion: true, permitirSubOpcion: false, estado: true },
    { id: 31, codigo: '031', codigoModule: 203, icono: 'fa-solid fa-voicemail', orden: 31, padre: null, ruta: 'main/CharlaPretarea', sigla: 'SGSOAPP_CHARLA', esOpcion: true, permitirSubOpcion: false, estado: true },

    { id: 40, codigo: '040', codigoModule: 204, icono: 'fa-solid fa-person-chalkboard', orden: 40, padre: null, ruta: 'main/ControlPreventivo', sigla: 'SGSOAPP_CONTPREV', esOpcion: true, permitirSubOpcion: false, estado: true },
    { id: 41, codigo: '041', codigoModule: 204, icono: 'fa-solid fa-person-chalkboard', orden: 41, padre: null, ruta: 'main/CierreCP', sigla: 'SGSOAPP_CIERRECP', esOpcion: false, permitirSubOpcion: true, estado: true },

    { id: 60, codigo: '060', codigoModule: 205, icono: 'fa-solid fa-bars', orden: 60, padre: null, ruta: 'main/GestionAutochequeo', sigla: 'SGSOAPP_GESAUTO', esOpcion: true, permitirSubOpcion: false, estado: true },
    { id: 61, codigo: "061", codigoModule: 205, icono: "fa-solid fa-bars", orden: 61, padre: null, ruta: "main/GestionAutochequeo", sigla: "SGSOAPP_GESAUTOId", esOpcion: false, permitirSubOpcion: true, estado: true },

    { id: 50, codigo: '050', codigoModule: 206, icono: 'fa-solid fa-person-falling-burst', orden: 50, padre: null, ruta: 'main/RDI', sigla: 'SGSOAPP_RDI', esOpcion: true, permitirSubOpcion: false, estado: true },
    { id: 51, codigo: '051', codigoModule: 206, icono: 'fa-solid fa-person-falling-burst', orden: 51, padre: null, ruta: 'main/RDI/form', sigla: 'SGSOAPP_RDI_FORM', esOpcion: false, permitirSubOpcion: true, estado: true },
];