import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityLoadData } from "../Domain/Utils";

export class UseCaseLoadData {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(): Promise<EntityLoadData> {
        return await this.repository.loadData();
    }
}